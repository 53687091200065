import { addCategoryValue } from "../../components/pages/categoryWork/CategoryForm";
import { failure, request, success } from "../dispatchFunctions";
import { searchParams } from "../../helpers/commonInterface";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addCategoryService,
  getCategoryListService,
  getCategoryService,
  updateCategoryService,
  updateCategoryStatusService,
} from "./categoryService";
import {
  ADD_CATEGORY_FAILURE,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_LIST_FAILURE,
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addCategory = (
  data: addCategoryValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_CATEGORY_REQUEST));

    await addCategoryService(data).then(
      (result: any) => {
        dispatch(success(ADD_CATEGORY_SUCCESS, result));
        showToastMessageSuccess("Categoy added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.CATEGORIES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_CATEGORY_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getCategoryList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_CATEGORY_LIST_REQUEST));

    getCategoryListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_CATEGORY_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CATEGORY_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getCategory = (categoryId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_CATEGORY_REQUEST));

    getCategoryService(categoryId).then(
      (result: any) => dispatch(success(GET_CATEGORY_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_CATEGORY_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const updateCategory = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_CATEGORY_REQUEST));

    await updateCategoryService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_CATEGORY_SUCCESS, result.data));
        showToastMessageSuccess("Category updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.CATEGORIES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CATEGORY_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const updateCategoryStatus = (
  categoryId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_CATEGORY_REQUEST));

    updateCategoryStatusService(categoryId).then(
      (result: any) => {
        dispatch(success(UPDATE_CATEGORY_SUCCESS, result.data));
        showToastMessageSuccess("Category status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.CATEGORIES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CATEGORY_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
