import PulseLoader from "react-spinners/PulseLoader";


function Loader() {
    return (
        <div className="text-center">
        <PulseLoader color="#bfbfbf" size={11}/>
        </div>
    )
}

export default Loader