import { ADD_EVENING_REPORTING_REQUEST, ADD_EVENING_REPORTING_SUCCESS, ADD_EVENING_REPORTING_FAILURE } from "../actionTypes"

interface addEveningReportingState {
    loading  : boolean,
    addEveningReporting : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:addEveningReportingState = {
    loading  : false,
    addEveningReporting: {},
    error : ""
}

export const addEveningReportingReducer = (state = initialState, action : action) => {
    switch(action.type){
        case ADD_EVENING_REPORTING_REQUEST : return {
            ...state,
            loading : true
        }
        case ADD_EVENING_REPORTING_SUCCESS : return {
            ...state,
            loading : false,
            addEveningReporting : 'Task added successfully',
            error : ""
        }
        case ADD_EVENING_REPORTING_FAILURE : return {
            ...state,
            loading : false,
            addEveningReporting : 'Task not added',
            error : action.payload
        }
        default : return state
    }
}