export const updateStatusFun = (
  state: any,
  currentObj: string,
  itemId: string | number
) => {
  return {
    ...state,
    [currentObj]: {
      ...state[currentObj],
      data: {
        ...state[currentObj].data,
        records: state[currentObj].data.records.map((item: any) => {
          if (String(item.Id) === String(itemId)) {
            return { ...item, IsActive: !item.IsActive };
          }
          return item;
        }),
      },
    },
  };
};

export interface customError {
  success: boolean;
  data: object;
  message: string;
  status: number;
  error: {
    errorCode: number;
    errorMessage: string;
  };
}

export const GetErrorMessage = (error: customError | undefined) => {
  if (error?.status === 400 && error?.error?.errorMessage)
    return error?.error?.errorMessage;
  else return "Something went wrong";
};
