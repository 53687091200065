import { FC, useMemo, useState } from "react";

interface subDropDown {
  value: string | number;
  name: string;
}

interface option {
  value: string | number | undefined;
  name: string | undefined;
  subDropDown?: Array<subDropDown>;
}

interface currentValueObj {
  value: string | number | undefined;
  name?: string | undefined;
}

interface props {
  placeholder: string;
  options: Array<option>;
  setFieldValue?: Function;
  name?: string;
  apiCallDispatch?: Function;
  defaultValue?: Array<option>;
  className: string;
  currentValue?: Array<currentValueObj>;
  disabled?: boolean;
}

interface selected {
  value: number | string | null | undefined;
  name: string | null | undefined;
}

const MultiSelectDropDown: FC<props> = ({
  disabled,
  currentValue,
  apiCallDispatch,
  placeholder,
  options,
  setFieldValue,
  name,
  defaultValue,
  className,
}) => {
  const [selected, setSelected] = useState<Array<selected>>(
    defaultValue
      ? [
          ...defaultValue.map((data: option) => {
            return { value: data.value, name: data.name };
          }),
        ]
      : []
  );
  const showData = useMemo(
    () =>
      currentValue
        ? currentValue.length
          ? currentValue.length
          : ""
        : selected.length,
    [currentValue, selected]
  );

  return (
    <div className="btn-group w-100">
      <button
        className={className}
        type="button"
        id="dropdownMenuClickableInside"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        disabled={disabled}
      >
        {showData ? `${showData} selected` : placeholder}
        {/* {showData ? `${selected.length} selected` : placeholder} */}
      </button>
      <ul
        id="custom-dropdown"
        className="dropdown-menu w-100 mh-200px overflow-auto vertical-scroll"
        aria-labelledby="dropdownMenuClickableInside"
        onClick={(e) => {
          if ((e.target as HTMLLIElement).dataset.id) {
            const updatedData = selected.some(
              (data: selected) =>
                data.value == (e.target as HTMLLIElement).dataset.id
            )
              ? selected.filter(
                  (data: selected) =>
                    data.value != (e.target as HTMLLIElement).dataset.id
                )
              : [
                  ...selected,
                  {
                    value: (e.target as HTMLLIElement).dataset.id,
                    name: (e.target as HTMLLIElement).textContent,
                  },
                ];

            setSelected(updatedData);
            setFieldValue && setFieldValue(name, updatedData);
            apiCallDispatch && apiCallDispatch(e);
          }
        }}
      >
        {options?.map((option: option, index: number) => {
          if (option.subDropDown) {
            return (
              <li key={index} className="p-3">
                <span
                  className="dropdown-item dropdown-toggle ps-0"
                  id="dropdownMenuClickableInside1"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {option.name}
                </span>
                <ul
                  className="dropdown-menu w-75"
                  aria-labelledby="dropdownMenuClickableInside1"
                >
                  {option.subDropDown.map(
                    (subDropDownItem: subDropDown, index: number) => (
                      <li
                        key={index}
                        className={`dropdown-item p-3 position-relative cursor-pointer
                        ${
                          (
                            currentValue
                              ? currentValue.some(
                                  (item: currentValueObj) =>
                                    item.value == subDropDownItem.value
                                )
                              : selected.some(
                                  (item: selected) =>
                                    item.value === subDropDownItem.value
                                )
                          )
                            ? "dropdown-option-selected"
                            : ""
                        }`}
                        data-id={subDropDownItem.value}
                      >
                        {subDropDownItem.name}
                      </li>
                    )
                  )}
                </ul>
              </li>
            );
          } else {
            return (
              <li
                key={index}
                className={`p-3 dropdown-item d-flex flex-column position-relative cursor-pointer 
                ${
                  (
                    currentValue
                      ? currentValue.some((item: currentValueObj) => {
                          return item.value == option.value;
                        })
                      : selected.some(
                          (item: selected) => item.value === option.value
                        )
                  )
                    ? "dropdown-option-selected"
                    : ""
                }`}
                data-id={option.value}
              >
                {option.name}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default MultiSelectDropDown;
