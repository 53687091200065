import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { string, object, number } from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import { addBankBookData } from "../BankBookForm";
import { adminHomePage } from "../../main/admin/AdminMain";
import { bankBookListPage } from "../BankBookList";
import { changeTextToFirstUppercase } from "app/helpers/helperFunction";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { emptyObject } from "app/helpers/helperFunction";
import { FIRM_BANK_BOOK_LIST } from "app/helpers/routes";
import { firmListPage } from "../../firmMaster/FirmMasterList";
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { connect } from "react-redux";

interface props {
  firmName: string
  title: string;
  firmDispatch: Function;
  initialValue: addBankBookData;
}

const firmBreadCrumbs = (firmId: number): Array<PageLink> => [
  adminHomePage,
  firmListPage,
  bankBookListPage(firmId),
  emptyObject,
];

const OPTIONS = [
  { value: "saving", name: "Saving" },
  { value: "current", name: "Current" },
];

const firmValidationSchema = object().shape({
  name: string().required("Bank book name is required"),
  bankName: string().required("Bank name is required"),
  ifscCode: string().required("IFSC code is required"),
  branchName: string().required("Branch name is required"),
  accountNumber: number()
    .required("Account number is required")
    .typeError("Account number is required"),
  accountType: string().required("Account type is required"),
});

const AddBankBook: FC<props> = ({ title, firmDispatch, initialValue, firmName }) => {
  const navigate = useNavigate();
  const params = useParams();
  
  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={firmBreadCrumbs(Number(params.id))}>
        {title}
      </PageTitle>
      <PageSubTitle>{ firmName || ""}</PageSubTitle>

      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={firmValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await firmDispatch(
              values,
              params.bankBookId
                ? () => {
                    navigate(
                      absolutePath(`${FIRM_BANK_BOOK_LIST}/${params.id}`)
                    );
                  }
                : () => {
                    resetForm();
                  }
            );
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Bank Book Name 
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.name &&
                              errors.name &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Bank Book Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">

                  <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Account Number
                      </label>

                      <div className="">
                        <Field
                          type="number"
                          className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.accountNumber &&
                          errors.accountNumber &&
                          "is-invalid inValidBorder"
                        }`}
                          placeholder="Account Number"
                          id="accountNumber"
                          name="accountNumber"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Bank Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.bankName &&
                          errors.bankName &&
                          "is-invalid inValidBorder"
                        }`}
                          placeholder="Bank Name"
                          id="bankName"
                          name="bankName"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="bankName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                   
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        IFSC Code
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                         ${
                           touched.ifscCode &&
                           errors.ifscCode &&
                           "is-invalid inValidBorder"
                         }`}
                          placeholder="IFSC Code"
                          id="ifscCode"
                          name="ifscCode"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="ifscCode"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Branch Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.branchName &&
                          errors.branchName &&
                          "is-invalid inValidBorder"
                        }`}
                          placeholder="Branch Name"
                          id="branchName"
                          name="branchName"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="branchName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Account Type
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            touched.accountType &&
                            errors.accountType &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Select Account Type"
                          options={OPTIONS}
                          setFieldValue={setFieldValue}
                          name="accountType"
                          currentValue={{ value: values.accountType }}
                          defaultValue={{
                            value: params.bankBookId ? values.accountType : "",
                            name: params.bankBookId
                              ? changeTextToFirstUppercase(values.accountType)
                              : "",
                          }}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="accountType"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(
                        absolutePath(`${FIRM_BANK_BOOK_LIST}/${params.id}`)
                      )
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddBankBook;
