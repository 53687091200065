import { GET_MORNING_PLANNER_BY_ID_REQUEST, GET_MORNING_PLANNER_BY_ID_SUCCESS, GET_MORNING_PLANNER_BY_ID_FAILURE } from "../actionTypes"

interface morningPlannerByIdState {
    loading  : boolean,
    morningPlannerById : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:morningPlannerByIdState = {
    loading  : false,
    morningPlannerById : {},
    error : ""
}

export const morningPlannerByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_MORNING_PLANNER_BY_ID_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_MORNING_PLANNER_BY_ID_SUCCESS : return {
            ...state,
            loading : false,
            morningPlannerById : action.payload,
            error : ""
        }
        case GET_MORNING_PLANNER_BY_ID_FAILURE : return {
            ...state,
            loading : false,
            morningPlannerById : {},
            error : action.payload
        }
        default : return state
    }
}