import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import {
  EDIT_DAILY_TASK,
  VIEW_DAILY_TASK_BY_ARTICLE,
} from "../../../../helpers/routes";
import { KTSVG } from "../../../../../_metronic/helpers";


interface props {
  row: {
    AssineeName: string;
    Date: string;
    Description: string;
    Id: number;
    StartTime:string;
    EndTime : string;
    Status:number;
    TaskName:string;
    ActivityName:string;
    ClientName:string
  };
}

const AllDailyTaskTableRow: FC<props> = ({ row }) => {
  
  return (
    <tr>
    <td className="text-dark fw-bold ps-2">{row?.AssineeName || "-"}</td>
    <td>{row.ClientName != undefined ? row.ClientName : '-'}</td>
      <td>{row.TaskName}{row.ActivityName}</td>
      <td>
                <span className={`fs-7 badge ${row.Status === 4 ? "badge-light-primary" :
                        row.Status === 6 ? "badge-light-success" :
                            row.Status === 3 ? "badge-light-primary" :
                                "badge-light-default" // default class if no condition matches
                    }`}>
                    {
                        row.Status === 4 ? "Sent For Approval" :
                            row.Status === 6 ? "Approved" :
                                row.Status === 3 ? "Added" :
                                    "Default" // default text if no condition matches
                    }
                </span>
            </td>
    </tr>
  );
};

export default AllDailyTaskTableRow;
