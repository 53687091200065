import { loginData } from "../../helpers/commonInterface";
import {
  forgotPasswordService,
  loginService,
  resetPasswordService,
} from "./loginService";
import { failure, request, success } from "../dispatchFunctions";
import { resetPasswordInitialValue } from "app/components/pages/auth/components/ResetPassword";
import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";

export const loginAction = (
  data: loginData,
  successCallback: Function,
  failureCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(LOGIN_REQUEST));

    await loginService(data).then(
      (result: any) => {
        dispatch(success(LOGIN_SUCCESS, result));
        successCallback(result.data.data.records);
      },
      (error: any) => {
        dispatch(
          failure(LOGIN_FAILURE, GetErrorMessage(error?.response?.data))
        );
        failureCallback(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const forgotPassword = (
  data: string,
  successCallback: Function,
  failureCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(FORGOT_PASSWORD_REQUEST));

    await forgotPasswordService(data).then(
      (result: any) => {
        dispatch(success(FORGOT_PASSWORD_SUCCESS, result));
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            FORGOT_PASSWORD_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        failureCallback();
      }
    );
  };
};

export const resetPassword = (
  data: resetPasswordInitialValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(FORGOT_PASSWORD_REQUEST));

    await resetPasswordService(data).then(
      (result: any) => {
        dispatch(success(FORGOT_PASSWORD_SUCCESS, result));
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            FORGOT_PASSWORD_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        successCallback();
      }
    );
  };
};
