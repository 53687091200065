import { ErrorMessage, Field, FormikHelpers, FormikState, FormikValues, useFormikContext } from "formik"
import ReactQuill from 'react-quill';
import { useRef } from "react";
import 'react-quill/dist/quill.snow.css';

import { addVariableFunction, onChangeBodyDataHandler } from "./TemplateDetailForm";

const EditorModules = {
    toolbar: [
        ['bold', 'italic', 'strike']
    ]
}

const EditorFormats = [
    'bold', 'italic', 'strike',
]

const TemplateBody = () => {
    const { values, setFieldValue, errors }: FormikState<FormikValues> & FormikHelpers<FormikValues> = useFormikContext();
    const editorRef = useRef<any>(null);
    
    return (
        <div className="mt-3">
            <label className="col-form-label fw-semibold fs-5 required">Body</label>

            <div className="col-12 col-xl-9">
                <ReactQuill theme="snow"
                    value={values.body.text}
                    onChange={(data: string) => onChangeBodyDataHandler(data, "body", values, setFieldValue)}
                    modules={EditorModules}
                    formats={EditorFormats}
                    ref={editorRef}
                />
            </div>
            <div className="mb-2 d-flex justify-content-between align-items-start flex-column flex-xl-row mb-2 col-md-9 col-12">
                <ErrorMessage name="body.text" component="div" className="errorMsg" />
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn p-0 w-125px"
                        onClick={() => addVariableFunction("body", "text", "example", values, setFieldValue)}>
                        <i className="fa-solid fa-plus"></i> Add Variable
                    </button>
                </div>
            </div>
            {values.body.example?.length ?
                <div>
                    {values.body.example.map((example: string, index: number) => {
                        return <div className="d-flex align-items-start mb-4 col-9 gap-5" key={index}>
                            <label className="col-form-label fw-semibold fs-6 required">{`{{${index + 1}}}`}</label>

                            <div className="w-100">
                                <Field
                                    type="text"
                                    className={`form-control form-control-lg form-control-solid`}
                                    placeholder="Varaible Example"
                                    id={`body.example[${index}]`}
                                    name={`body.example[${index}]`}
                                />
                                <ErrorMessage name={`body.example[${index}]`} component="div" className="errorMsg" />
                            </div>
                        </div>
                    })}
                </div> :
                <></>}
        </div>
    )
}

export default TemplateBody