import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useEffect } from "react";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";

import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import {
  getWhatsappAccountDetail,
  whatsappAccountDetail,
} from "app/reducers/whatsapp/whatsappAction";
import { getAuth } from "../../auth";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";

interface whatsappData {
  Name: string;
  WhatsAppPermanentToken: string;
  WhatsAppPhoneNumberId: string;
  WhatsAppBusinessAccountId: string;
  Id: number;
  IsActive: boolean;
}

interface props {
  whatsappAccountDetailDispatch: Function;
  getWhatsappAccountDetailDispatch: Function;
  whatsappDetails: {
    loading: boolean;
    whatsappData: {
      data: {
        records: whatsappData;
      };
    };
    error: string;
  };
}
export interface whatsappDataValue {
  whatsAppBusinessAccountId: string;
  whatsAppPermanentToken: string;
  whatsAppPhoneNumberId: string;
}
const initialValues = (whatsappData: whatsappData): whatsappDataValue => {
  return {
    whatsAppBusinessAccountId: whatsappData?.WhatsAppBusinessAccountId || "",
    whatsAppPermanentToken: whatsappData?.WhatsAppPermanentToken || "",
    whatsAppPhoneNumberId: whatsappData?.WhatsAppPhoneNumberId || "",
  };
};

const validateFun = object().shape({
  whatsAppPhoneNumberId: string().required("PhoneNumber id is requiired"),
  whatsAppPermanentToken: string().required("Access token required"),
});

const WhatsappDetailsForm: FC<props> = ({
  whatsappAccountDetailDispatch,
  getWhatsappAccountDetailDispatch,
  whatsappDetails,
}) => {
  const navigate = useNavigate();
  const user = getAuth();

  useEffect(() => {
    if (!whatsappDetails.whatsappData?.data && user?.CompanyId) {
      getWhatsappAccountDetailDispatch(user?.CompanyId);
    }
  }, []);

  return (
    <>
      {whatsappDetails.loading ? (
        <Loader />
      ) : whatsappDetails.error ? (
        <ErrorDisplay error={whatsappDetails.error} />
      ) : (
        <div className="card px-8 py-7">
          <Formik
            enableReinitialize
            initialValues={initialValues(
              whatsappDetails.whatsappData.data.records
            )}
            onSubmit={async (values, helpers) => {
              await whatsappAccountDetailDispatch(values, () => {
                helpers.resetForm();
              });
            }}
            validationSchema={validateFun}
          >
            {({ isSubmitting, touched, errors }) => {
              return (
                <Form>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      BusinessAccountId
                    </label>

                    <div className="col-lg-8 fv-row">
                      <Field
                        type="text"
                        className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.whatsAppBusinessAccountId &&
                          errors.whatsAppBusinessAccountId &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="BusinessAccountId"
                        id="whatsAppBusinessAccountId"
                        name="whatsAppBusinessAccountId"
                      />
                      <ErrorMessage
                        name="whatsAppBusinessAccountId"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      PhoneNumberId
                    </label>

                    <div className="col-lg-8 fv-row">
                      <Field
                        type="text"
                        className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.whatsAppPhoneNumberId &&
                          errors.whatsAppPhoneNumberId &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="PhoneNumberId"
                        id="whatsAppPhoneNumberId"
                        name="whatsAppPhoneNumberId"
                      />
                      <ErrorMessage
                        name="whatsAppPhoneNumberId"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Access Token
                    </label>

                    <div className="col-lg-8 fv-row">
                      <Field
                        type="text"
                        className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.whatsAppPermanentToken &&
                          errors.whatsAppPermanentToken &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="Access Token"
                        id="whatsAppPermanentToken"
                        name="whatsAppPermanentToken"
                      />
                      <ErrorMessage
                        name="whatsAppPermanentToken"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end py-6 gap-3">
                    <ResetButton
                      name="Cancel"
                      className="btn btn-light btn-active-light-primary"
                      onClickCallback={() => navigate(-1)}
                    />
                    <SubmitButton
                      className="btn btn-primary"
                      isSubmitting={isSubmitting}
                      name="Submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    whatsappDetails: state.whatsappAccountDetail,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    whatsappAccountDetailDispatch: (wpData: whatsappDataValue) =>
      dispatch(whatsappAccountDetail(wpData)),
    getWhatsappAccountDetailDispatch: (companyId: number) =>
      dispatch(getWhatsappAccountDetail(companyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappDetailsForm);
