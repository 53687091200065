import { AxiosRequestConfig } from 'axios'

import { axiosInstance } from '../../../../reducers/axiosInstance'
import { AuthModel } from './_models'
import { createCookie, getCookie } from '../../../../helpers/helperFunction'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_COOKIE_KEY = "auth-token"
const AUTH_TIMEOUT_IN_MINUTES = 1380;

const getAuth = (): AuthModel | undefined => {
  // const cookieValue = getCookie(AUTH_COOKIE_KEY)

  // if (!cookieValue) {
  //   removeAuth();
  //   return;
  // }

  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    createCookie(AUTH_COOKIE_KEY, auth.Token, AUTH_TIMEOUT_IN_MINUTES);
   
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    setupAxios();
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// const useCheckForSession = () => {
//   const cookieValue = getCookie(AUTH_COOKIE_KEY)
//   const { logout } = useAuth()

//   if (!cookieValue) {
//     authHelper.removeAuth()
//     logout()
//   }
// }

export function setupAxios(sessionExpired ?: Function) {
  // axios.defaults.headers.Accept = 'application/json'
  const cookieValue = getCookie(AUTH_COOKIE_KEY)
  const auth = getAuth()
  
  if(auth && auth.Token && !cookieValue){
    sessionExpired && sessionExpired();
  }

  if(!(auth && auth.Token)){
    delete axiosInstance.defaults.headers.common["Authorization"];
  }

  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const auth = getAuth();
      if (config.headers && auth && auth.Token) {
        config.headers.Authorization = `Bearer ${auth.Token}`
      }
      const abortController = new AbortController();
      const timeoutId = setTimeout(
        () => abortController.abort(), 1000000)
      return { ...config, timeoutId, signal: abortController.signal } as AxiosRequestConfig
      // return config
    },
    (err: any) => Promise.reject(err)
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
