import { ErrorMessage, Field, FormikHelpers, FormikState, FormikValues, useFormikContext } from "formik"

import { buttonObjData, buttonObjInterface } from "../TemplateRegister"
import DropDown from "app/components/commonComponent/dropDown/DropDown"
import { getNameFromValue } from "./TemplateDetailForm"

const BUTTON_OPTION = [
    { value: "none", name: "None" },
    { value: "callToAction", name: "Call to Action" },
]

const BUTTON_TYPE_ACTIONS = [
    { value: "callPhoneNumber", name: "Call Phone Number" },
    { value: "visitWebsite", name: "Visit Website" },
]

const TemplateButtons = () => {
    const { values, setFieldValue, setValues }: FormikState<FormikValues> & FormikHelpers<FormikValues> = useFormikContext()

    const addButtonObject = (name: string) => {
        const id = values[name][values[name].length - 1].id + 1
        setFieldValue(name, [...values[name], { ...buttonObjData, id: id }])
    }

    const deleteButtonObject = (name: string, id: number) => {
        setFieldValue(name, values[name].filter((btn: buttonObjInterface) => btn.id !== id))
    }

    return (
        <div className="mt-3">
            <label className="col-form-label fw-semibold fs-6 required">Button</label>

            <div className="col-6 col-md-3 col-sm-3 mb-6">
                <DropDown
                    className="text-start form-control form-control-lg form-control-solid form-select"
                    placeholder='Select Type'
                    options={BUTTON_OPTION}
                    name="buttonType"
                    currentValue={{ value: values.buttonType }}
                    apiCallDispatch={(e : any) => setValues({...values, buttonType : e.target.dataset.id, 
                        buttons : e.target.dataset.id === "callToAction" ? [{...buttonObjData}] : []})}
                    defaultValue={{ value: values.buttonType, name: getNameFromValue(BUTTON_OPTION, values.buttonType) }}
                />
                <ErrorMessage name="buttonType" component="div" className="errorMsg" />
            </div>
            {values.buttonType === "callToAction" && <div className="col-12 col-xl-9">
                {values.buttons?.map((button: buttonObjInterface, index: number) => {
                    return <div className="mb-4 d-flex flex-wrap align-items-start gap-3" key={index}>
                        <div className="d-flex gap-3 flex-wrap">
                            <div className="">
                                <DropDown
                                    className="text-start form-control form-control-lg form-control-solid form-select w-200px"
                                    placeholder='Select Type'
                                    options={BUTTON_TYPE_ACTIONS}
                                    name={`buttons[${index}].type`}
                                    currentValue={{ value: values.buttons[index].type }}
                                    setFieldValue={setFieldValue}
                                    defaultValue={{
                                        value: values.buttons[index].type,
                                        name: getNameFromValue(BUTTON_TYPE_ACTIONS, values.buttons[index].type)
                                    }}
                                />
                                <ErrorMessage name={`buttons[${index}].type`} component="div" className="errorMsg" />
                            </div>
                            <div className="">
                                <Field
                                    type="text"
                                    className={`form-control form-control-lg form-control-solid`}
                                    placeholder="Button Text"
                                    id={`buttons[${index}].text`}
                                    name={`buttons[${index}].text`}
                                />
                                <ErrorMessage name={`buttons[${index}].text`} component="div" className="errorMsg" />
                            </div>
                        </div>

                        <div className="">
                            <div className="d-flex align-items-center gap-3">
                                {values.buttons[index].type === "callPhoneNumber" && <div className="w-100">
                                    <div className="d-flex align-items-start gap-2">
                                        <div className="form-control form-control-solid w-auto">+91</div>
                                        <div>
                                            <Field type="number"
                                                className={`form-control form-control-lg form-control-solid`}
                                                placeholder="Phone Number"
                                                id={`buttons[${index}].phone_number`}
                                                name={`buttons[${index}].phone_number`} />
                                        </div>
                                    </div>
                                </div>}
                                {values.buttons[index].type === "visitWebsite" && <div className="w-100">
                                    <Field type="text"
                                        className={`form-control form-control-lg form-control-solid`}
                                        placeholder="Website url"
                                        id={`buttons[${index}].url`}
                                        name={`buttons[${index}].url`} />
                                </div>}
                                {index !== 0 && <div className="">
                                    <button
                                        type="button"
                                        className="align-items-center btn btn-active-light-danger btn-light d-flex justify-content-center px-4 py-3 rounded-circle"
                                        onClick={() => deleteButtonObject("buttons", button.id)}>
                                        <i className="fa-solid fa-close p-0"></i>
                                    </button>
                                </div>}
                            </div>
                            <div>
                                <ErrorMessage name={`buttons[${index}].url`} component="div" className="errorMsg" />
                                <ErrorMessage name={`buttons[${index}].phone_number`} component="div" className="errorMsg" />
                            </div>
                        </div>
                    </div>
                })}
                <div>
                    <button type="button" className={`btn p-0 ${values.buttons?.length === 2 ? "text-muted" : ""}`}
                        disabled={values.buttons?.length === 2}
                        onClick={() => addButtonObject("buttons")}
                    >
                        <i className="fa-solid fa-plus"></i>
                        Add More Button
                    </button>
                </div>
            </div>}
        </div>
        // <></>
    )
}

export default TemplateButtons