import { response } from "app/helpers/commonInterface";
import {
  GET_ASSIGNED_TASK_FAILURE,
  GET_ASSIGNED_TASK_REQUEST,
  GET_ASSIGNED_TASK_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface assignedTaskDataState {
  loading: boolean;
  assignedTask: response<taskData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: assignedTaskDataState = {
  loading: false,
  assignedTask: {},
  error: "",
};

export const getAssignedTaskReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_ASSIGNED_TASK_REQUEST:
      return {
        loading: true,
      };
    case GET_ASSIGNED_TASK_SUCCESS:
      return {
        loading: false,
        assignedTask: action.payload,
        error: "",
      };
    case GET_ASSIGNED_TASK_FAILURE:
      return {
        loading: false,
        assignedTask: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
