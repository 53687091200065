import { connect } from "react-redux";
import { FC, useEffect } from "react";

import CategoryTableRow from "./CategoryTableRow";
import { getCategoryList } from "../../../../reducers/category/categoryAction";
import { searchParams } from "../../../../helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

const HEADERS = [
  {
    columnName: "Category Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-sm-125px min-w-225px text-end pe-5",
  },
];

interface props {
  categoryDetails: any;
  getCategoryList: Function;
}

const CategoryTable: FC<props> = ({ categoryDetails, getCategoryList }) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getCategoryList(searchObj);
  }, [getCategoryList, searchObj]);

  return (
    <>
      
      <Table
        checkBoxColumn={{ show: false }}
        error={categoryDetails.error}
        headers={HEADERS}
        success={categoryDetails.categoryList?.success}
        loading={categoryDetails.loading}
        paginate={{
          show: true,
          pagination: categoryDetails.categoryList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={categoryDetails.categoryList?.data?.records}
        tableRow={(row: any, index: number) => (
          <CategoryTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    categoryDetails: state.categoryList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCategoryList: (searchObj: searchParams) =>
      dispatch(getCategoryList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
