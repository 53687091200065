import {SET_VIEW_EVENING_ACTION } from "../actionTypes"

interface viewEveningActionState {
    deleteLoading:boolean,
    loading  : boolean,
    viewEveningAction : boolean,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:viewEveningActionState = {
    deleteLoading : false,
    loading  : false,
    viewEveningAction : true,
    error : ""
}

export const viewEveningActionReducer = (state = initialState, action : action) => {
    switch(action.type){
        case SET_VIEW_EVENING_ACTION : return {
            ...state,
            viewEveningAction : action.payload
        }
        default : return state
    }
}