import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import { getTransactionReportList } from "app/reducers/transaction/transactionAction";
import { searchParams } from "app/helpers/commonInterface";
import { transactionReportListState } from "app/reducers/transaction/getTransactionReportListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import { PageTitle } from "_metronic/layout/core";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import Table from "app/components/commonComponent/table/Table";
import TransactionReportTableRow from "./component/TransactionReportTableRow";
import TransactionReportFilter from "./component/TransactionReportFilter";
import TransactionAmountDetail from "./component/TransactionAmountDetail";

const accountBreadCrumbs = [adminHomePage, emptyObject];

const HEADERS = [
  {
    columnName: "Type",
    id: "Type",
    className: "min-w-150px ps-2",
  },
  {
    columnName: "Narration",
    id: "NULL",
    className: "min-w-250px min-w-xxl-125px",
  },
  {
    columnName: "Firm Name",
    id: "FirmName",
    className: "min-w-125px",
  },
  {
    columnName: "Book Name",
    id: "BookName",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Transaction Date",
    id: "TransactionDate",
    className: "min-w-125px",
  },
  {
    columnName: "Amount",
    id: "Amount",
    className: "min-w-125px",
  },
];

interface props {
  getTransactionReportDispatch: Function;
  transactionReportDetails: transactionReportListState;
}

const TransactionReportList: FC<props> = ({
  getTransactionReportDispatch,
  transactionReportDetails,
}) => {
  const { searchObj } = useGetSearchObject();
  useEffect(() => {
    getTransactionReportDispatch(searchObj);
  }, [searchObj]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Transactions </PageTitle>
      <div className="d-flex justify-content-end align-items-end flex-wrap gap-3 mb-3">
        <UsersListSearchComponent />
        <TransactionReportFilter />
      </div>

      {transactionReportDetails.loading && <Loader />}
      {transactionReportDetails.error && (
        <ErrorDisplay error={transactionReportDetails.error} />
      )}

      {!transactionReportDetails.loading &&
        transactionReportDetails.transactionDetails?.success && (
          <>
            <TransactionAmountDetail
              Total={
                transactionReportDetails.transactionDetails.data?.records.Total
              }
            />
            <KTCard>
              <Table
                checkBoxColumn={{ show: false }}
                error={transactionReportDetails.error}
                headers={HEADERS}
                success={transactionReportDetails.transactionDetails?.success}
                loading={transactionReportDetails.loading}
                paginate={{
                  show: true,
                  pagination:
                    transactionReportDetails.transactionDetails?.pagination,
                  PageNumber: Number(searchObj.PageNumber || 1),
                  PageSize: Number(searchObj.PageSize) || 10,
                }}
                tableData={
                  transactionReportDetails.transactionDetails?.data?.records
                    ?.Transactions
                }
                tableRow={(row: any, index: number) => (
                  <TransactionReportTableRow
                    row={row}
                    key={`row-${index}-${row.id}`}
                  />
                )}
              />
            </KTCard>
          </>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    transactionReportDetails: state.transactionReportDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionReportDispatch: (searchObj: searchParams) =>
      dispatch(getTransactionReportList(searchObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionReportList);
