import { axiosInstance } from "../axiosInstance"
import { loginData } from "../../helpers/commonInterface"
import { FORGOT_PASSWORD_API_ENDPOINT, LOGIN_API_ENDPOINT, RESET_PASSWORD_API_ENDPOINT } from "../../helpers/config"
import { resetPasswordInitialValue } from "app/components/pages/auth/components/ResetPassword"

export const loginService = async (data: loginData) => {
    return await axiosInstance.post(LOGIN_API_ENDPOINT, data)
}

export const forgotPasswordService = async (data: string) => {
    return await axiosInstance.post(FORGOT_PASSWORD_API_ENDPOINT, data, {
        headers: {
            "Content-type": "application/json"
        }
    })
}

export const resetPasswordService = async (data: resetPasswordInitialValue) => {
    return await axiosInstance.post(RESET_PASSWORD_API_ENDPOINT, data, {
        headers: {
            "Content-type": "application/json"
        }
    })
}