import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { FC, useState } from "react";
import { object, string } from "yup";

import {
  addRemarks,
  editRemarks,
  generalFileUpload,
} from "app/reducers/task/taskAction";
import { addFileObject } from "app/helpers/commonInterface";
import { getFormData, getImage } from "app/helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { remarksInitialValue } from "./TaskRemarksDetails";
import {
  ResetButton,
  SmallLoader,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";

interface props {
  showForm: { form: boolean; edit: boolean };
  initialValue: remarksInitialValue;
  setShowForm: Function;
  uploadFileToRemarksDispatch: Function;
  editRemarkDispatch: Function;
  addRemarkDispatch: Function;
}

export interface remarkData {
  id: number;
  remark: string;
  taskId: number;
}

const validateFun = object().shape({
  remark: string().test({
    name: "required-without-filePath",
    test: function (value) {
      const filePath = this.parent.filePath;
      return (
        (value && !filePath) || (!value && filePath) || (value && filePath)
      );
    },
    message: "Remark or File is required",
  }),
  filePath: string().test({
    name: "required-without-remark",
    test: function (value) {
      const remark = this.parent.remark;
      return (value && !remark) || (!value && remark) || (value && remark);
    },
    message: "Remark or File is required",
  }),
});

const TaskRemarksForm: FC<props> = ({
  showForm,
  initialValue,
  setShowForm,
  editRemarkDispatch,
  addRemarkDispatch,
  uploadFileToRemarksDispatch,
}) => {
  const [loading, setLoading] = useState(false);

  const onChangeFileHandler = (
    file: any,
    setValues: (
      values: React.SetStateAction<remarksInitialValue>,
      shouldValidate?: boolean | undefined
    ) => void,
    values: FormikValues
  ) => {
    setLoading(true);
    uploadFileToRemarksDispatch(
      getFormData({ file: file }),
      (fileObject: addFileObject) => {
        if (showForm.edit) {
          setValues({
            id: values.id,
            remark: values.remark,
            taskId: values.taskId,
            fileName: fileObject.FileName,
            filePath: fileObject.FilePath,
            fileUrl: fileObject.FileUrl,
            mimeType: fileObject.MimeType,
          });
        } else {
          setValues({
            remark: values.remark,
            taskId: values.taskId,
            fileName: fileObject.FileName,
            filePath: fileObject.FilePath,
            fileUrl: fileObject.FileUrl,
            mimeType: fileObject.MimeType,
          });
        }
        setLoading(false);
      }
    );
  };

  return (
    <div className="card-body px-7 py-4">
      <Formik
        enableReinitialize
        initialValues={initialValue}
        validationSchema={validateFun}
        onSubmit={async (values) => {
          if (!showForm.edit) {
            await addRemarkDispatch(values, () => {
              setShowForm({ form: false, edit: false });
            });
          } else {
            await editRemarkDispatch(values, () => {
              setShowForm({ form: false, edit: false });
            });
          }
        }}
      >
        {({ isSubmitting, setValues, values }) => {
          return (
            <Form noValidate className="form">
              <div className="">
                <div className="row mb-6">
                  <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                    Remark
                  </label>

                  <div className="col-lg-9">
                    <Field
                      as="textarea"
                      placeholder="Remark"
                      className="form-control form-control-lg form-control-solid vertical-scroll"
                      name="remark"
                    />
                    <ErrorMessage
                      name="remark"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-3 col-form-label fw-semibold fs-6 required">
                    File
                  </div>

                  {!values.filePath && (
                    <label htmlFor="filePath" className="col-lg-9">
                      <div className="d-inline-flex gap-3 btn btn-light-primary btn-active-light-primary btn-sm">
                        {loading ? (
                          <SmallLoader />
                        ) : (
                          <i className="bi bi-upload me-1"></i>
                        )}
                        Upload File
                      </div>
                      <Field
                        type="file"
                        value={undefined}
                        className="d-none"
                        id="filePath"
                        name="filePath"
                        onChange={(e: any) =>
                          onChangeFileHandler(
                            e.target.files[0],
                            setValues,
                            values
                          )
                        }
                      />
                      <ErrorMessage
                        name="filePath"
                        component="div"
                        className="errorMsg"
                      />
                    </label>
                  )}
                  {values.filePath && (
                    <div className="col-lg-9 ms-3 upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="w-35px">
                          <ImageDisplay
                            path={getImage(values?.mimeType || "pdf")}
                            errorPath="/media/task/doc-1.png"
                            className="img-fit-to-div"
                            altText="documentType"
                          />
                        </div>
                        <div className="fw-semibold">
                          <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                            {values.fileName}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          className="btn p-0"
                          onClick={() =>
                            setValues({
                              ...values,
                              remark: values.remark,
                              taskId: values.taskId,
                              fileName: "",
                              filePath: "",
                              fileUrl: "",
                              mimeType: "",
                            })
                          }
                        >
                          <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end py-6 gap-3">
                <ResetButton
                  name="Cancel"
                  className="btn btn-light btn-active-light-primary"
                  onClickCallback={() =>
                    setShowForm({ form: false, edit: false })
                  }
                />
                <SubmitButton
                  className="btn btn-primary"
                  isSubmitting={isSubmitting}
                  name="Submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addRemarkDispatch: (data: remarkData, successCallback: Function) =>
      dispatch(addRemarks(data, successCallback)),
    editRemarkDispatch: (data: remarkData, successCallback: Function) =>
      dispatch(editRemarks(data, successCallback)),
    uploadFileToRemarksDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TaskRemarksForm);
