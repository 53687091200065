import { FC } from "react";
import {
  Field,
  FieldArray,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";

import { clientContactPerson } from "../ClientForm";

interface props {
  contactArr: Array<clientContactPerson>;
  allContactsArr: Array<clientContactPerson> | undefined;
}

const ShowGroupContactPerson: FC<props> = ({ contactArr, allContactsArr }) => {
  const {
    setFieldValue,
  }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
    useFormikContext();

  const findContactAvailable = (contactId: number | undefined) => {
    if (contactId) {
      const data = contactArr.some((contactData: clientContactPerson) => {
        if (contactData?.contactPerson?.id) {
          return Number(contactData?.contactPerson?.id) === Number(contactId);
        }
        return false;
      });
      return data;
    }
    return false;
  };

  return (
    <FieldArray
      name="groupContacts"
      render={(arrayHelpers) => (
        <div>
          {allContactsArr?.map(
            (contact: clientContactPerson, index: number) => {
              if (contact) {
                return (
                  <div
                    key={index}
                    className="border border-2 rounded-2 row px-4 mb-4"
                  >
                    <div
                      className="align-items-center d-flex fs-4 fw-semibold min-h-50px"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#group-contact-person-${index}`}
                    >
                      Group Contact Person {index + 1}
                    </div>
                    <div
                      id={`group-contact-person-${index}`}
                      className="border-top collapse pt-5"
                    >
                      <div className="d-flex gap-1">
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name={`isDefault`}
                            id={`isDefault`}
                            checked={findContactAvailable(
                              contact.contactPerson.id
                            )}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setFieldValue("groupContacts", [
                                  ...contactArr,
                                  contact,
                                ]);
                              } else {
                                setFieldValue(
                                  "groupContacts",
                                  contactArr.filter(
                                    (contactData: clientContactPerson) =>
                                      Number(contactData.contactPerson.id) !==
                                      Number(contact.contactPerson.id)
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <label className="col-form-label fw-semibold fs-6">
                          Default
                        </label>
                      </div>
                      <div className="row mb-6">
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6">
                            Name
                          </label>

                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid`}
                              placeholder="Person Name"
                              id={`name`}
                              name={`name`}
                              value={contact.contactPerson.name}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6">
                            Email
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid`}
                              placeholder="Person Email"
                              id={`email`}
                              name={`email`}
                              value={contact.contactPerson.email}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6">
                            Mobile Number
                          </label>

                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid`}
                              placeholder="Person Mobile Number"
                              id={`phoneNumber`}
                              name={`phoneNumber`}
                              value={contact.contactPerson.phoneNumber}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return <></>
            }
          )}
        </div>
      )}
    />
  );
};

export default ShowGroupContactPerson;
