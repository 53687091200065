import { connect } from "react-redux"
import { FC } from "react"

import { adminHomePage } from "../main/admin/AdminMain"
import { absolutePath } from "../../../helpers/relativePath"
import { ADD_USER, USER_LIST } from "../../../helpers/routes"
import { emptyObject } from "../../../helpers/helperFunction"
import { getUserList } from "../../../reducers/user/userAction"
import { KTCard } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { searchParams } from "../../../helpers/commonInterface"
import UsersListSearchComponent from "../../commonComponent/table/UsersListSearchComponent"
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar"
import { useAuth } from "../auth"
import UsersTable from "./component/UsersTable"
import UserFilter from "./component/UserFilter"

interface props {
    getUserList: Function
}

export const userListPage = {
    title: 'Users',
    path: absolutePath(USER_LIST),
    isSeparator: true,
    isActive: true,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

const UserList: FC<props> = ({ getUserList }) => {
    const { currentUser } = useAuth();

    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Users</PageTitle>
            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListSearchComponent
                    addCompanyId={{ name: "id", value: currentUser?.CompanyId }}
                    getDataDispatchFunction={getUserList} />
                <UserFilter />
                <UsersListToolbar buttons={[{ title: "Add User", to: ADD_USER }]} />
            </div>
            <KTCard>
                <UsersTable />
            </KTCard>
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getUserList: (searchObj: searchParams) => dispatch(getUserList(searchObj))
    }
}

export default connect(null, mapDispatchToProps)(UserList)