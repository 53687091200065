import { GET_RECORD_IN_BANK_BOOK_FAILURE, GET_RECORD_IN_BANK_BOOK_REQUEST, GET_RECORD_IN_BANK_BOOK_SUCCESS } from "../actionTypes"


export interface bankBookDataState {
    loading  : boolean,
    bankBook : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:bankBookDataState = {
    loading  : false,
    bankBook : {},
    error : ""
}

export const getByIdBankBookReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_RECORD_IN_BANK_BOOK_REQUEST : return {
            loading : true
        }
        case GET_RECORD_IN_BANK_BOOK_SUCCESS : 
        return {
            loading : false,
            bankBook : action.payload,
            error : ""
        }
        case GET_RECORD_IN_BANK_BOOK_FAILURE : return {
            loading : false,
            bankBook : {},
            error : action.payload
        }
        default : return state
    }
}