import { FC } from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";

import { addClientValue, clientContactPerson } from "../ClientForm";

interface props {
  values: FormikValues;
  touched: FormikTouched<addClientValue>;
  errors: FormikErrors<addClientValue>;
  newGroupContactPersonObject: clientContactPerson;
  isSubmitting: boolean;
}

const AddClientContactPerson: FC<props> = ({
  isSubmitting,
  newGroupContactPersonObject,
  values,
  touched,
  errors,
}) => {
  return (
    <div className="d-flex flex-column gap-5 gap-md-7">
      <FieldArray
        name="clientContacts"
        render={(arrayHelpers) => (
          <div className="">
            {values.clientContacts.length === 0 && (
              <div>
                <button
                  className="btn btn-light-primary btn-sm"
                  onClick={() => arrayHelpers.push(newGroupContactPersonObject)}
                  disabled={isSubmitting}
                >
                  <i className="fa-solid fa-plus"></i>
                  Add Contact Person
                </button>
              </div>
            )}
            <div className="">
              {values.clientContacts.map(
                (person: clientContactPerson, index: number) => (
                  <div
                    key={index}
                    className="border border-2 rounded-2 row px-2 mb-4"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="align-items-center d-flex gap-4 fs-4 fw-semibold min-h-50px w-100"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#client-contact-person-${index}`}
                      >
                        <div>Contact Person {index + 1}</div>
                      </div>
                      <div className="align-items-center d-flex justify-content-start gap-3 w-auto">
                        {
                          <button
                            type="button"
                            style={{ color: "#ff5959" }}
                            className="btn btn-active-light-danger btn-light px-4 py-2 rounded-circle text-center"
                            onClick={() => arrayHelpers.remove(index)}
                            disabled={isSubmitting}
                          >
                            <i
                              style={{ color: "#ff5959" }}
                              className="fa-solid fa-close p-0"
                            ></i>
                          </button>
                        }
                        {values.clientContacts.length - 1 == index && (
                          <button
                            type="button"
                            className="btn btn-active-light-primary btn-light px-4 py-2 rounded-circle text-center"
                            onClick={() =>
                              arrayHelpers.insert(
                                index + 1,
                                newGroupContactPersonObject
                              )
                            }
                            disabled={isSubmitting}
                          >
                            <i
                              style={{ color: "#1da9f8" }}
                              className="fa-solid fa-plus p-0"
                            ></i>
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      id={`client-contact-person-${index}`}
                      className={`border-top collapse pt-5 ${
                        values.clientContacts.length - 1 === index ? "show" : ""
                      }`}
                    >
                      <div className="row mb-6">
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6 required">
                            Name
                          </label>

                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.clientContacts &&
                              touched.clientContacts[index]?.contactPerson
                                ?.name &&
                              errors.clientContacts &&
                              (
                                errors.clientContacts[
                                  index
                                ] as FormikErrors<clientContactPerson>
                              )?.contactPerson?.name &&
                              "is-invalid inValidBorder"
                            }`}
                              placeholder="Person Name"
                              id={`clientContacts.${index}.contactPerson.name`}
                              name={`clientContacts.${index}.contactPerson.name`}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name={`clientContacts.${index}.contactPerson.name`}
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6 ">
                            Email
                          </label>

                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid 
                                ${
                                  touched.clientContacts &&
                                  touched.clientContacts[index]?.contactPerson
                                    ?.email &&
                                  errors.clientContacts &&
                                  (
                                    errors.clientContacts[
                                      index
                                    ] as FormikErrors<clientContactPerson>
                                  )?.contactPerson?.email &&
                                  "is-invalid inValidBorder"
                                }`}
                              placeholder="Person Email"
                              id={`clientContacts.${index}.contactPerson.email`}
                              name={`clientContacts.${index}.contactPerson.email`}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name={`clientContacts.${index}.contactPerson.email`}
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6 required">
                            Mobile Number
                          </label>

                          <div className="">
                            <Field
                              type="number"
                              className={`form-control form-control-lg form-control-solid 
                                ${
                                  touched.clientContacts &&
                                  touched.clientContacts[index]?.contactPerson
                                    ?.phoneNumber &&
                                  errors.clientContacts &&
                                  (
                                    errors.clientContacts[
                                      index
                                    ] as FormikErrors<clientContactPerson>
                                  )?.contactPerson?.phoneNumber &&
                                  "is-invalid inValidBorder"
                                }`}
                              placeholder="Person Mobile Number"
                              id={`clientContacts.${index}.contactPerson.phoneNumber`}
                              name={`clientContacts.${index}.contactPerson.phoneNumber`}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name={`clientContacts.${index}.contactPerson.phoneNumber`}
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default AddClientContactPerson;
