import { addClientValue } from "../../components/pages/client/ClientForm";
import { importClientDataValue } from "app/components/pages/client/component/ImportModel";
import { axiosInstance } from "../axiosInstance";
import {
  CLIENT_API_ENDPOINT,
  CLIENT_GET_ALL_API_ENDPOINT,
  CLIENT_IMPORT_DETAILS,
  CLIENT_REPORT_DETAILS_API_ENDPOINT,
  LEGALSTATUS_GET_ALL_API_ENDPOINT,
  UPDATE_CLIENT_CONTACT_PERSON_API_ENDPOINT,
} from "../../helpers/config";
import { getSearchQueryString } from "../../helpers/helperFunction";
import { searchParams } from "../../helpers/commonInterface";

export const addClientService = async (data: addClientValue) => {
  return await axiosInstance.post(CLIENT_API_ENDPOINT, data);
};

export const importClientDataService = async (data: any) => {
  console.log(data);
  return await axiosInstance.post(CLIENT_IMPORT_DETAILS, data);
  // return await axiosInstance.post("", data);
};

export const getCLientListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${CLIENT_GET_ALL_API_ENDPOINT}?${queryString}`);
};

export const getLegalStatusListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${LEGALSTATUS_GET_ALL_API_ENDPOINT}?Paginate=false`);
};

export const updateClientService = async (data: addClientValue) => {
  return await axiosInstance.put(CLIENT_API_ENDPOINT, data);
};

export const updateClientStatusService = (clientId: number) => {
  return axiosInstance.delete(`${CLIENT_API_ENDPOINT}?id=${clientId}`);
};

export const updateContactStatusService = (clientId: number) => {
  return axiosInstance.delete(
    `${UPDATE_CLIENT_CONTACT_PERSON_API_ENDPOINT}?id=${clientId}`
  );
};

export const getCLientByIdService = (clientId: number) => {
  return axiosInstance.get(`${CLIENT_API_ENDPOINT}?id=${clientId}`);
};

export const getCLientReportDetailByIdService = (clientId: number) => {
  return axiosInstance.get(
    `${CLIENT_REPORT_DETAILS_API_ENDPOINT}?id=${clientId}`
  );
};
