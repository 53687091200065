import { connect } from "react-redux";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";
import { FC, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { object, string, number } from "yup";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { addTransactionValue } from "../TransactionForm";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { getTransactionList } from "app/reducers/transaction/transactionAction";
import { getSearchParameter } from "app/helpers/helperFunction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { searchParams } from "app/helpers/commonInterface";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import { transactionDetail } from "app/reducers/transaction/transactionDataInterface";

interface props {
  transactionDispatch: Function;
  initialValue: addTransactionValue;
  transactionDetails?: transactionDetail;
  closeModal: Function;
  transaction: { edit: boolean };
  getTransactionListDetails: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

interface task {
  Id: number;
  TaskCode: string;
  ClientId: number;
  ClientName: string;
}

interface bill {
  Id: number;
  BillNumber: string;
  ClientId: number;
  ClientName: string;
}

const RECEIPT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE["Advance"], name: "Advance" },
  { value: TRANSACTION_TYPE["GovernmentFess/Tax"], name: "Govt.Fees/Tax" },
  { value: TRANSACTION_TYPE["Against Bill"], name: "Against Bill" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const PAYMENT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE.Reimbursement, name: "Reimbuse" },
  { value: TRANSACTION_TYPE.Expense, name: "Expense" },
  { value: TRANSACTION_TYPE["GovernmentFess/Tax"], name: "Govt.Fees/Tax" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const AddTransaction: FC<props> = ({
  masterDataList,
  getTransactionListDetails,
  transaction,
  closeModal,
  transactionDispatch,
  initialValue,
  transactionDetails,
}) => {
  const location = useLocation();
  const params = useParams();
  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const [transactionTypeValue, setTransactionTypeValue] = useState<
    TRANSACTION_TYPE | undefined
  >(transactionDetails?.Type);

  const transactionValidationSchema = object().shape({
    isPayment: string().required("Transaction type is required"),
    type: number()
      .required("Transaction type is required")
      .typeError("Transaction type is required"),
    firmId: number().test(
      "checkFirmValidation",
      "Firm is required",
      (value) => {
        if (!location.pathname.includes("transaction") && !value) return false;
        return true;
      }
    ),
    bookId: number().test(
      "checkBookValidation",
      "Book is required",
      (value) => {
        if (!location.pathname.includes("transaction") && !value) return false;
        return true;
      }
    ),
    clientId: number().when("type", {
      is: (type: TRANSACTION_TYPE) =>
        type !== TRANSACTION_TYPE.Expense && type !== TRANSACTION_TYPE.Other,
      then: number().required("Client is required"),
    }),
    billId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE["Against Bill"],
      then: number().required("BillId is required"),
    }),
    expenseId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Expense,
      then: number().required("ExpenseId is required"),
    }),
    amount: number()
      .required("Transaction amount is required!!")
      .typeError("Transaction amount is required!!"),
    narration: string().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Other,
      then: string().required("Narration is requiured"),
    }),
  });

  const GetDataAsPerType = () => {
    const {
      values,
      setFieldValue,
      isSubmitting,
    }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
      useFormikContext();

    
    switch (Number(transactionTypeValue)) {
      case TRANSACTION_TYPE["GovernmentFess/Tax"]:
      case TRANSACTION_TYPE["Advance"]:
      case TRANSACTION_TYPE.Reimbursement:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="row mb-6">
                <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                  Task
                </label>

                <div className="col-lg-9 fv-row">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                    placeholder="Select Task"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Tasks?.filter(
                      (task: task) => task.ClientId === Number(values.clientId)
                    ).map((row: { Id: number; TaskCode: string}) => {
                      return { value: row.Id, name: row.TaskCode };
                    })}
                    setFieldValue={setFieldValue}
                    name="taskId"
                    currentValue={{ value: values.taskId }}
                    defaultValue={{
                      value: transactionDetails
                        ? transactionDetails.TaskId
                        : "",
                      name: transactionDetails
                        ? transactionDetails.TaskCode 
                        : "",
                    }}
                    disabled={isSubmitting}
                    showSearch={true}
                  />
                  <ErrorMessage
                    name="taskId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE["Against Bill"]:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="row mb-6">
                <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                  Invoice
                </label>

                <div className="col-lg-9 fv-row">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                    placeholder="Select Bill"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Bills?.filter(
                      (bill: bill) => bill.ClientId === Number(values.clientId)
                    ).map((row: { Id: number; BillNumber: string }) => {
                      return { value: row.Id, name: row.BillNumber };
                    })}
                    setFieldValue={setFieldValue}
                    name="billId"
                    currentValue={{ value: values.billId }}
                    defaultValue={{
                      value: transactionDetails
                        ? transactionDetails.BillId
                        : "",
                      name: transactionDetails
                        ? transactionDetails.BillNumber
                        : "",
                    }}
                    disabled={isSubmitting}
                    showSearch={true}
                  />
                  <ErrorMessage
                    name="billId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE.Expense:
        return (
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
              Expense
            </label>

            <div className="col-lg-9 fv-row">
              <DropDown
                className={`text-start form-control form-control-lg form-control-solid form-select ${masterDataList.loading ? "display-dropdown-loader" : ""
                  }`}
                placeholder="Select Expense"
                displayLoader={masterDataList.loading}
                options={masterDataList.masterData?.data?.records?.Expenses?.map(
                  (row) => {
                    return { value: row.Id, name: row.Name };
                  }
                )}
                setFieldValue={setFieldValue}
                name="expenseId"
                currentValue={{ value: values.expenseId }}
                defaultValue={{
                  value: transactionDetails ? transactionDetails.ExpenseId : "",
                  name: transactionDetails
                    ? transactionDetails.ExpenseName
                    : "",
                }}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="expenseId"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValue}
        validationSchema={transactionValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          await transactionDispatch(
            {
              ...values,
              isPayment: values.isPayment === "false" ? false : true,
              oldIsPayment: transaction.edit
                ? String(transactionDetails?.IsPayment) === "false"
                  ? false
                  : true
                : null,
            },
            transaction.edit
              ? () => {
                closeModal();
                params.bookId &&
                  getTransactionListDetails({
                    ...searchObj,
                    bookId: params.bookId,
                  });
              }
              : () => {
                resetForm();
                params.bookId &&
                  getTransactionListDetails({
                    ...searchObj,
                    bookId: params.bookId,
                  });
              }
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          setFieldValue,
          setValues,
        }) => {
          return (
            <Form noValidate className="form">
              <Modal.Body>
                <div className="">
                  <div className="row mb-6">
                    <div className="col-lg-9 row">
                      <div className="d-flex flex-row gap-3 col-lg-4">
                        <div className="form-check form-check-custom form-check-solid">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="isPayment"
                            value={"false"}
                            onChange={() => {
                              setValues({
                                ...values,
                                isPayment: "false",
                                type: undefined,
                                taskId: undefined,
                                expenseId: undefined,
                                billId: undefined,
                                clientId: undefined,
                              });
                              setTransactionTypeValue(undefined);
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                        <div className="col-form-label fw-semibold fs-6">
                          Receipt
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-3 col-lg-4">
                        <div className="form-check form-check-custom form-check-solid">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="isPayment"
                            value={"true"}
                            onChange={() => {
                              setValues({
                                ...values,
                                isPayment: "true",
                                type: undefined,
                                taskId: undefined,
                                expenseId: undefined,
                                billId: undefined,
                                clientId: undefined,
                              });
                              setTransactionTypeValue(undefined);
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                        <div className="col-form-label fw-semibold fs-6">
                          Payment
                        </div>
                      </div>
                      <ErrorMessage
                        name="isPayment"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Transaction Type
                    </label>

                    <div className="col-lg-9 fv-row">
                      <DropDown
                        className="text-start form-control form-control-lg form-control-solid form-select"
                        placeholder="Select Transaction Type"
                        options={
                          values.isPayment === "false"
                            ? RECEIPT_TYPE_OPTIONS
                            : PAYMENT_TYPE_OPTIONS
                        }
                        setFieldValue={setFieldValue}
                        name="type"
                        apiCallDispatch={(e: any) => {
                          setTransactionTypeValue(e.target.dataset.id);
                          setValues({
                            ...values,
                            type: e.target.dataset.id,
                            taskId: undefined,
                            expenseId: undefined,
                            billId: undefined,
                            clientId: undefined,
                          });
                        }}
                        currentValue={{ value: values.type }}
                        defaultValue={{
                          value: transactionDetails ? values.type : "",
                          name:
                            transactionDetails && values.type
                              ? TRANSACTION_TYPE[values.type]
                              : "",
                        }}
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  {values.type &&
                    TRANSACTION_TYPE.Expense !== Number(values.type) &&
                    TRANSACTION_TYPE.Other !== Number(values.type) && (
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Client
                        </label>

                        <div className="col-lg-9 fv-row">
                          <DropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                              }`}
                            placeholder="Select Client"
                            displayLoader={masterDataList.loading}
                            options={masterDataList.masterData?.data?.records?.Clients?.map(
                              (client: { Id: number; Name: string }) => {
                                return { value: client.Id, name: client.Name };
                              }
                            )}
                            setFieldValue={setFieldValue}
                            name="clientId"
                            currentValue={{ value: values.clientId }}
                            defaultValue={{
                              value: transactionDetails ? values.clientId : "",
                              name: transactionDetails ? values.clientName : "",
                            }}
                            disabled={isSubmitting}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="clientId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    )}
                  <GetDataAsPerType />
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Amount 
                    </label>

                    <div className="col-lg-9">
                      <Field
                        type="number"
                        placeholder="Amount"
                        className={`form-control form-control-lg form-control-solid vertical-scroll
                        ${touched.amount &&
                          errors.amount &&
                          "is-invalid inValidBorder"
                          }`}
                        name="amount"
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6">
                      Narration
                    </label>

                    <div className="col-lg-9">
                      <Field
                        as="textarea"
                        placeholder="Narration"
                        className={`form-control form-control-lg form-control-solid vertical-scroll
                         ${touched.narration &&
                          errors.narration &&
                          "is-invalid inValidBorder"
                          }`}
                        name="narration"
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name="narration"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3 pt-6">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => closeModal()}
                  />
                  <SubmitButton
                    className="btn btn-primary"
                    isSubmitting={isSubmitting}
                    name="Submit"
                  />
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionListDetails: (searchObj: searchParams) =>
      dispatch(getTransactionList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTransaction);
