import { PageLink, PageTitle } from "_metronic/layout/core";
import { searchParams } from "app/helpers/commonInterface";
import { absolutePath } from "app/helpers/relativePath";
import { getBillReportList } from "app/reducers/billing/billingAction";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import { useGetSearchObject } from "app/helpers/customHooks";
import Table from "app/components/commonComponent/table/Table";
import { billReportListState } from "app/reducers/billing/getBillReportListReducer";
import BillingReportTableRow from "./component/BillingReportTableRow";
import { BILL_REPORT_LIST } from "app/helpers/routes";
import BillFilter from "./component/BillFilter";
import { PAYMENT_STATUS } from "app/helpers/constants";

const HEADERS = [
  {
    columnName: "Invoice No",
    id: "BillNumber",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Task",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Firm",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Total Amount",
    id: "TotalAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Paid Amount",
    id: "PaidAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Invoice Date",
    id: "Date",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "PaymentStatus",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end",
  },
];

export const billingReportListPage = {
  title: "Invice Report",
  path: absolutePath(BILL_REPORT_LIST),
  isSeparator: true,
  isActive: true,
};

interface props {
  getBillReportListDispatch: Function;
  billReportDetails: billReportListState;
}

const BILL_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS["Proforma Generated"], name: "Proforma Generated" },
  { value: PAYMENT_STATUS["Payment Pending"], name: "Payment Pending" },
  { value: PAYMENT_STATUS["Partially Paid"], name: "Partially Paid" },
  { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const BillingReportList: FC<props> = ({
  billReportDetails,
  getBillReportListDispatch,
}) => {
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getBillReportListDispatch(searchObj);
  }, [searchObj]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Invoice Report</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <BillFilter BILL_STATUS_OPTIONS={BILL_STATUS_OPTIONS} />
      </div>
      <KTCard>
        <Table
          checkBoxColumn={{ show: false }}
          error={billReportDetails.error}
          headers={HEADERS}
          success={billReportDetails.billDetails?.success}
          loading={billReportDetails.loading}
          paginate={{
            show: true,
            pagination: billReportDetails.billDetails?.pagination,
            PageNumber: Number(searchObj.PageNumber || 1),
            PageSize: Number(searchObj.PageSize) || 10,
          }}
          tableData={billReportDetails.billDetails?.data?.records}
          tableRow={(row: any, index: number) => (
            <BillingReportTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    billReportDetails: state.billReportDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBillReportListDispatch: (searchObj: searchParams) =>
      dispatch(getBillReportList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingReportList);
