import { FC } from "react"

type props = {
    error : string
}

const ErrorDisplay :FC<props>= ({error}) => {
    return(
        <div className="text-danger fs-6 text-center">{error}</div>
    )
}

export default ErrorDisplay