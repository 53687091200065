import { connect } from 'react-redux';

interface Props {
  // Define any props you might use
  validatePopUpdata : any, 
  setValidatePopUpdata:Function
}

const ValidationPopUp = ({ validatePopUpdata, setValidatePopUpdata}: Props) => {
    const handleCancle = ()=>{
        setValidatePopUpdata((data: any) => {
            return {
              ...data,
              showPopUp: false
            };
          });
    }
  return (
    <>
      <div
        className="modal fade show d-block"
        id="exampleModal"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-3" id="exampleModalLabel">
                {validatePopUpdata.title}
              </h5>
              <button
                type="button"
                className="btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancle}
              >
                <span className="svg-icon svg-icon-muted svg-icon-2hx">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="white"
                    ></rect>
                    <rect
                      x="7"
                      y="15.3137"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-45 7 15.3137)"
                      fill="currentColor"
                    ></rect>
                    <rect
                      x="8.41422"
                      y="7"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(45 8.41422 7)"
                      fill="currentColor"
                    ></rect>
                  </svg>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid px-0">
                <h3 className='mb-5'> Pending Task From daily Task</h3>
                {validatePopUpdata.bodyData && (
                    validatePopUpdata.bodyData.map((data:any,index:number) =>(
                        <div key={index}  className='m-4'>-  {data.ClientName != undefined ? `${data.ClientName} - ${data.TaskName}` : `${data.ActivityName}`} 
                         &nbsp;<span className='fs-8 badge badge-light-primary rounded-pill'>{data.TasktypeName}</span>
                         </div>
                    ))
                )}
                 </div>
            </div>
            <div className="modal-footer" style={{padding:'calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)* 1.9)'}}>
              <button type="button" className="btn btn-secondary btn-sm font-weight-bold" onClick={handleCancle}>
                Cancle
              </button>
              <button type="submit" className={`btn btn-${validatePopUpdata.color} btn-sm font-weight-bold`} onClick={validatePopUpdata.submitFunc}>
               {validatePopUpdata.submitBtnName}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = (state: any) => {
//     return {};
// };

// const mapDispatchToProps = (dispatch: any) => {
//     return {};
// };

export default connect(null, null)(ValidationPopUp);
