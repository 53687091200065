import {EDIT_USER_FAILURE, EDIT_USER_REQUEST, EDIT_USER_SUCCESS, GET_USER_BY_ID_FAILURE, GET_USER_BY_ID_REQUEST, GET_USER_BY_ID_SUCCESS, GET_USER_LIST_REQUEST } from "../actionTypes"

export interface editUserState {
    loading : boolean,
    editUser : any,
    error : string
}

interface action {
    type  : string,
    payload : any
}

const initialState : editUserState = {
    loading : false,
    editUser : {},
    error : ""
}

const editUserReducer = (state = initialState, action:action) => {
    switch(action.type){
        case EDIT_USER_REQUEST : return{
            ...state,
            loading : true
        }
        case EDIT_USER_SUCCESS : return{
            ...state,
            loading : false,
            editUser : action.payload,
            error : "",
        }
        case EDIT_USER_FAILURE : return{
            ...state,
            loading : false,
            editUser : {},
            error : action.payload
        }
        default : return state;
    }
}

export {editUserReducer}