import { connect } from "react-redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import { DOCUMENT_TYPE_REPEATATION } from "app/helpers/constants";
import { EDIT_DOCUMENT_TYPE } from "../../../../helpers/routes";
import { KTSVG } from "../../../../../_metronic/helpers";
import StatusCell from "../../../commonComponent/table/StatusCell";
import { updateDocumentTypeStatus } from "../../../../reducers/documentType/documentTypeAction";
import UserActionsCell from "../../../commonComponent/table/UserActionsCell";

interface row {
  Id: number;
  Name: string;
  IsActive: boolean;
  Repeatation: DOCUMENT_TYPE_REPEATATION;
}

interface props {
  row: row;
  updateDocumentTypeStatusDispatch: Function;
}

const DocumentTypeTableRow: FC<props> = ({
  row,
  updateDocumentTypeStatusDispatch,
}) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_DOCUMENT_TYPE}/${row.Id}`));
  };

  const updateList = (id: number, callBack: Function) => {
    updateDocumentTypeStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="text-gray-800 fw-bold ps-2">{row.Name}</td>
      <td>
        {row?.Repeatation ? DOCUMENT_TYPE_REPEATATION[row.Repeatation] : "-"}
      </td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateList}
            isActive={row?.IsActive}
            id={row?.Id}
          />
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDocumentTypeStatusDispatch: (id: number, successCallback: Function) =>
      dispatch(updateDocumentTypeStatus(id, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(DocumentTypeTableRow);
