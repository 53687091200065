import { connect } from "react-redux"
import { FC, useEffect } from "react"

import BankDetails from "./component/BankDetails"
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay"
import ErrorBoundary from "app/helpers/ErrorBoundary"
import { getProfileData } from "app/reducers/profile/profileAction"
import Loader from "app/components/commonComponent/loader/Loader"
import UserDetails from "./component/UserDetails"

interface props {
    getProfile: Function
    profileDetails: any
}

const ProfilePage: FC<props> = ({ getProfile, profileDetails }) => {

    useEffect(() => {
        // if (!profileDetails.profileData) {
            getProfile();
        // }
    }, [])

    return (
        <ErrorBoundary>
            {profileDetails.loading && <div className='loader-container'><Loader /></div>}
            {profileDetails.error && <ErrorDisplay error={profileDetails.error} />}
            {(!profileDetails.loading && profileDetails.profileData) &&
                <div className='p-0 container-xxl d-flex flex-column gap-5'>
                    <UserDetails />
                    <BankDetails />
                </div>}
                </ErrorBoundary>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileDetails: state.profile
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getProfile: () => dispatch(getProfileData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
