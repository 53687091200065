import { connect } from "react-redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import { EDIT_CATEGORY, VIEW_CATEGORY_TYPES } from "../../../../helpers/routes";
import { updateCategoryStatus } from "../../../../reducers/category/categoryAction";
import { KTSVG } from "../../../../../_metronic/helpers";
import StatusCell from "../../../commonComponent/table/StatusCell";
import UserActionsCell from "../../../commonComponent/table/UserActionsCell";
interface props {
  row: { IsActive: boolean; Name: string; Id: number };
  updateCategoryStatusDispatch: Function;
}

const CategoryTableRow: FC<props> = ({ row, updateCategoryStatusDispatch }) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_CATEGORY}/${row.Id}`));
  };

  const updateList = (id: number, callBack: Function) => {
    updateCategoryStatusDispatch(row.Id, callBack);
  };

  const viewTypes = () => {
    navigate(absolutePath(`${VIEW_CATEGORY_TYPES}/${row.Id}`));
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row?.Name || "-"}</td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateList}
            isActive={row?.IsActive}
            id={row?.Id}
          />
          <button
            className="btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary"
            title="View Types"
            onClick={viewTypes}
          >
            View Types
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCategoryStatusDispatch: (
      categoryId: number,
      successCallback: Function
    ) => dispatch(updateCategoryStatus(categoryId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(CategoryTableRow);
