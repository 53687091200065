import {
    GET_CHAT_MSG_LIST_FAILURE,
    GET_CHAT_MSG_LIST_REQUEST,
    GET_CHAT_MSG_LIST_SUCCESS,
    GET_OPEN_CHAT_ID_FAILURE,
    GET_OPEN_CHAT_ID_REQUEST,
    GET_OPEN_CHAT_ID_SUCCESS,
  } from "../actionTypes";
  
  export interface chatListState {
    loading: boolean;
    openChat: number;
    error: string;
  }
  
  interface action {
    type: string;
    payload: any;
  }
  
  const initialState: chatListState = {
    loading: false,
    openChat: 0,
    error: "",
  };
  
  export const openChatReducer = (state = initialState, action: action) => {

    // console.log(action)
    switch (action.type) {
      case GET_OPEN_CHAT_ID_REQUEST:
        return {
          loading: true,
        };
      case GET_OPEN_CHAT_ID_SUCCESS:
        return {
          loading: false,
          openChat: action.payload,
          error: "",
        };
      case GET_OPEN_CHAT_ID_FAILURE:
        return {
          loading: false,
          openChat: 0,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  