import { response } from "app/helpers/commonInterface";
import {
  GET_BILL_FAILURE,
  GET_BILL_REQUEST,
  GET_BILL_SUCCESS,
} from "../actionTypes";
import { billData } from "./billingDataInterface";

export interface billDataState {
  loading: boolean;
  bill: response<billData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: billDataState = {
  loading: false,
  bill: {},
  error: "",
};

export const getByIdBillReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_BILL_REQUEST:
      return {
        loading: true,
      };
    case GET_BILL_SUCCESS:
      return {
        loading: false,
        bill: action.payload,
        error: "",
      };
    case GET_BILL_FAILURE:
      return {
        loading: false,
        bill: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
