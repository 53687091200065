import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { getWhatsappAccountDetail } from "app/reducers/whatsapp/whatsappAction";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getAuth } from "../../auth";
import { CodeBlock } from "_metronic/partials";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";

interface props {
  getWhatsappAccountDetailDispatch: Function;
  whatsappDetails: {
    loading: boolean;
    whatsappData: {
      data: {
        records: {
          Name: string;
          WhatsAppPermanentToken: string;
          WhatsAppPhoneNumberId: string;
          WhatsAppBusinessAccountId: string;
          Id: number;
          IsActive: boolean;
        };
      };
    };
    error: string;
  };
}

const WhatsappAccountDetail: FC<props> = ({
  getWhatsappAccountDetailDispatch,
  whatsappDetails,
}) => {
  const user = getAuth();

  useEffect(() => {
    if (user?.CompanyId) {
      getWhatsappAccountDetailDispatch(user?.CompanyId);
    }
  }, []);
  const [copied, setCopied] = useState({ copy: false, id: "" });
  useEffect(() => {
    if (!copied) {
      return;
    }

    setTimeout(() => {
      setCopied({ copy: false, id: "" });
    }, 3000);
  }, [copied]);

  return (
    <ErrorBoundary>
      {whatsappDetails.loading ? (
        <Loader />
      ) : whatsappDetails.error ? (
        <ErrorDisplay error={whatsappDetails.error} />
      ) : (
        // <div className="card px-8 py-7">
        //   <div className={`row mb-4 align-items-center`}>
        //     <div className="fw-semibold fs-6 text-gray-600 col-4">
        //       WhatsappPhoneNumberId
        //     </div>
        //     <div className="col-7 row align-items-center">
        //       <div className="col-10">
        //         <div
        //           className={clsx(
        //             "me-3 flex-grow-1 form-control form-control-solid",
        //             {
        //               "bg-success text-white":
        //                 copied.copy && copied.id === "WhatsAppPhoneNumberId",
        //             }
        //           )}
        //         >
        //           {whatsappDetails.whatsappData?.data?.records
        //             ?.WhatsAppPhoneNumberId || "No Data Available"}
        //         </div>
        //       </div>
        //       <div className="bg-light col-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
        //         <CopyToClipboard
        //           text={
        //             whatsappDetails.whatsappData?.data?.records
        //               ?.WhatsAppPhoneNumberId || "No Data Available"
        //           }
        //           onCopy={() =>
        //             setCopied({ copy: true, id: "WhatsAppPhoneNumberId" })
        //           }
        //         >
        //           <div>
        //             {copied.copy && copied.id === "WhatsAppPhoneNumberId"
        //               ? "copied!"
        //               : "copy"}
        //           </div>
        //         </CopyToClipboard>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="row mb-4 align-items-center">
        //     <div className="fw-semibold fs-6 text-gray-600 col-4">
        //       WhatsappToken
        //     </div>
        //     <div className="col-7 row align-items-center">
        //       <div className="col-10">
        //         <div
        //           style={{ wordWrap: "normal" }}
        //           className={clsx(
        //             "me-3 flex-grow-1 form-control form-control-solid",
        //             {
        //               "bg-success text-white":
        //                 copied.copy && copied.id === "WhatsAppPermanentToken",
        //             }
        //           )}
        //         >
        //           <div className="overflow-auto horizontal-scroll pb-1">
        //             {whatsappDetails.whatsappData?.data?.records
        //               ?.WhatsAppPermanentToken || "No Data Available"}
        //           </div>
        //         </div>
        //       </div>
        //       <div className="bg-light col-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
        //         <CopyToClipboard
        //           text={
        //             whatsappDetails.whatsappData?.data?.records
        //               ?.WhatsAppPermanentToken || "No Data Available"
        //           }
        //           onCopy={() =>
        //             setCopied({ copy: true, id: "WhatsAppPermanentToken" })
        //           }
        //         >
        //           <div>
        //             {copied.copy && copied.id === "WhatsAppPermanentToken"
        //               ? "copied!"
        //               : "copy"}
        //           </div>
        //         </CopyToClipboard>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="row mb-4 align-items-center">
        //     <div className="fw-semibold fs-6 text-gray-600 col-4">
        //       WhatsAppBusinessAccountId
        //     </div>
        //     <div className="col-7 row align-items-center">
        //       <div className="col-10">
        //         <div
        //           className={clsx(
        //             "me-3 flex-grow-1 form-control form-control-solid",
        //             {
        //               "bg-success text-white":
        //                 copied.copy &&
        //                 copied.id === "WhatsAppBusinessAccountId",
        //             }
        //           )}
        //         >
        //           {whatsappDetails.whatsappData?.data?.records
        //             ?.WhatsAppBusinessAccountId || "No Data Available"}
        //         </div>
        //       </div>
        //       <div className="bg-light col-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
        //         <CopyToClipboard
        //           text={
        //             whatsappDetails.whatsappData?.data?.records
        //               ?.WhatsAppBusinessAccountId || "No Data Available"
        //           }
        //           onCopy={() =>
        //             setCopied({ copy: true, id: "WhatsAppBusinessAccountId" })
        //           }
        //         >
        //           <div>
        //             {copied.copy && copied.id === "WhatsAppBusinessAccountId"
        //               ? "copied!"
        //               : "copy"}
        //           </div>
        //         </CopyToClipboard>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        <div className="card px-3 px-md-5 py-4 py-md-5">
          <div className="row mb-3 mb-md-4 align-items-center">
            <div className="fw-semibold fs-6 text-gray-600 col-md-4">
              WhatsappPhoneNumberId
            </div>
            <div className="col-md-7 row align-items-center">
              <div className="col-md-10">
                <div
                  className={clsx(
                    "me-3 flex-grow-1 form-control form-control-solid",
                    {
                      "bg-success text-white":
                        copied.copy && copied.id === "WhatsAppPhoneNumberId",
                    }
                  )}
                >
                  {whatsappDetails.whatsappData?.data?.records
                    ?.WhatsAppPhoneNumberId || "No Data Available"}
                </div>
              </div>
              <div className="bg-light col-md-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
                <CopyToClipboard
                  text={
                    whatsappDetails.whatsappData?.data?.records
                      ?.WhatsAppPhoneNumberId || "No Data Available"
                  }
                  onCopy={() =>
                    setCopied({ copy: true, id: "WhatsAppPhoneNumberId" })
                  }
                >
                  <div>
                    {copied.copy && copied.id === "WhatsAppPhoneNumberId"
                      ? "copied!"
                      : "copy"}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-md-4 align-items-center">
            <div className="fw-semibold fs-6 text-gray-600 col-md-4">
              WhatsappToken
            </div>
            <div className="col-md-7 row align-items-center">
              <div className="col-md-10">
                <div
                  style={{ wordWrap: "normal" }}
                  className={clsx(
                    "me-3 flex-grow-1 form-control form-control-solid",
                    {
                      "bg-success text-white":
                        copied.copy && copied.id === "WhatsAppPermanentToken",
                    }
                  )}
                >
                  <div className="overflow-auto horizontal-scroll pb-1">
                    {whatsappDetails.whatsappData?.data?.records
                      ?.WhatsAppPermanentToken || "No Data Available"}
                  </div>
                </div>
              </div>
              <div className="bg-light col-md-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
                <CopyToClipboard
                  text={
                    whatsappDetails.whatsappData?.data?.records
                      ?.WhatsAppPermanentToken || "No Data Available"
                  }
                  onCopy={() =>
                    setCopied({ copy: true, id: "WhatsAppPermanentToken" })
                  }
                >
                  <div>
                    {copied.copy && copied.id === "WhatsAppPermanentToken"
                      ? "copied!"
                      : "copy"}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-md-4 align-items-center">
            <div className="fw-semibold fs-6 text-gray-600 col-md-4">
              WhatsAppBusinessAccountId
            </div>
            <div className="col-md-7 row align-items-center">
              <div className="col-md-10">
                <div
                  className={clsx(
                    "me-3 flex-grow-1 form-control form-control-solid",
                    {
                      "bg-success text-white":
                        copied.copy &&
                        copied.id === "WhatsAppBusinessAccountId",
                    }
                  )}
                >
                  {whatsappDetails.whatsappData?.data?.records
                    ?.WhatsAppBusinessAccountId || "No Data Available"}
                </div>
              </div>
              <div className="bg-light col-md-2 d-flex flex-center fw-bold p-3 rounded-2 text-hover-primary cursor-pointer">
                <CopyToClipboard
                  text={
                    whatsappDetails.whatsappData?.data?.records
                      ?.WhatsAppBusinessAccountId || "No Data Available"
                  }
                  onCopy={() =>
                    setCopied({ copy: true, id: "WhatsAppBusinessAccountId" })
                  }
                >
                  <div>
                    {copied.copy && copied.id === "WhatsAppBusinessAccountId"
                      ? "copied!"
                      : "copy"}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    whatsappDetails: state.whatsappAccountDetail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getWhatsappAccountDetailDispatch: (companyId: number) =>
      dispatch(getWhatsappAccountDetail(companyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappAccountDetail);
