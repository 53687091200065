import { PageLink, PageTitle } from "_metronic/layout/core";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import StatusCell from "app/components/commonComponent/table/StatusCell";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { emptyObject } from "app/helpers/helperFunction";
import { getClientReportDetails } from "app/reducers/client/clientAction";
import { clientDetailsState } from "app/reducers/client/getClientReportDetailsReducer";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { clientReportListPage } from "../ClientReportList";
import { adminHomePage } from "../../main/admin/AdminMain";
import { CLIENT_REPORT_DETAILS } from "app/helpers/routes";
import { absolutePath } from "app/helpers/relativePath";
import ClientReportContactDetails from "./ClientReportContactDetails";
import ClientReportTaskDetails from "./ClientReportTaskDetails";

interface props {
  clientReportDetails: clientDetailsState;
  getClientDetailsDispatch: Function;
}

export const clientReportDetailsPage = (clientId: string) => {
  return {
    title: "Client Details",
    path: absolutePath(`${CLIENT_REPORT_DETAILS}/${clientId}`),
    isSeparator: true,
    isActive: true,
  };
};

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  clientReportListPage,
  emptyObject,
];

const ClientReportDetail: FC<props> = ({
  getClientDetailsDispatch,
  clientReportDetails,
}) => {
  const params = useParams();

  useEffect(() => {
    getClientDetailsDispatch(params.clientId);
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Client Details</PageTitle>
      <ErrorBoundary>
        {clientReportDetails.loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : clientReportDetails.error ? (
          <ErrorDisplay error={clientReportDetails.error} />
        ) : (
          <>
            <div className="row mb-6">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header pt-5 align-items-center border-0">
                    <div className="card-title flex-column">
                      <div className="fw-bold">Client Details</div>
                    </div>
                  </div>
                  <div className="card-body pt-7">
                    <div className="row mb-4 align-items-center">
                      <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                        Name
                      </div>
                      <div className="col-5">
                        {clientReportDetails.clientDetails.data?.records.Name}
                      </div>
                    </div>
                    <div className="row mb-4 align-items-center">
                      <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                        GroupName
                      </div>
                      <div className="col-5">
                        {
                          clientReportDetails.clientDetails.data?.records
                            .GroupName
                        }
                      </div>
                    </div>
                    <div className="row mb-4 align-items-center">
                      <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                        Status
                      </div>
                      <div className="col-5">
                        <StatusCell
                          isActive={
                            clientReportDetails.clientDetails.data?.records
                              .IsActive || false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ClientReportContactDetails
                contactPersonArr={
                  clientReportDetails.clientDetails.data?.records
                    .ContactPersons || []
                }
              />
            </div>

            <ClientReportTaskDetails
              taskArr={
                clientReportDetails.clientDetails.data?.records.Tasks || []
              }
            />
          </>
        )}
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    clientReportDetails: state.clientReportDetails,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientDetailsDispatch: (clientId: number) =>
      dispatch(getClientReportDetails(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientReportDetail);
