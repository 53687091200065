import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addActivityService,
  getActivityByArticleService,
  getActivityListService,
  getActivityService,
  getPlannerActivityService,
  updateActivityService,
} from "./activityService";
import {
  ADD_ACTIVITY_FAILURE,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  GET_ACTIVITY_BY_ARTICLE_FAILURE,
  GET_ACTIVITY_BY_ARTICLE_REQUEST,
  GET_ACTIVITY_BY_ARTICLE_SUCCESS,
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_LIST_FAILURE,
  GET_ACTIVITY_LIST_REQUEST,
  GET_ACTIVITY_LIST_SUCCESS,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_PLANNER_ACTIVITY_FAILURE,
  GET_PLANNER_ACTIVITY_REQUEST,
  GET_PLANNER_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from "../actionTypes";
import { searchParams } from "app/helpers/commonInterface";
import { initialActivityModal } from "app/components/pages/activity/ActivityForm";
import { GetErrorMessage } from "../stateHelperFunction";

export const addActivity = (
  data: initialActivityModal,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_ACTIVITY_REQUEST));

    await addActivityService(data).then(
      (result: any) => {
        dispatch(success(ADD_ACTIVITY_SUCCESS, result));
        showToastMessageSuccess("Activity added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(ADD_ACTIVITY_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getActivityList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_ACTIVITY_LIST_REQUEST));

    getActivityListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_ACTIVITY_LIST_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_ACTIVITY_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getActivity = (activityId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_ACTIVITY_REQUEST));

    getActivityService(activityId).then(
      (result: any) => dispatch(success(GET_ACTIVITY_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_ACTIVITY_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const updateActivity = (
  data: initialActivityModal,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_ACTIVITY_REQUEST));

    await updateActivityService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_ACTIVITY_SUCCESS, data));
        showToastMessageSuccess("Activity updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_ACTIVITY_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getAssigneedActivityToUser = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_ACTIVITY_BY_ARTICLE_REQUEST));

    getActivityByArticleService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_ACTIVITY_BY_ARTICLE_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_ACTIVITY_BY_ARTICLE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getPlannerActivity = (searchObj: searchParams) => {

  return (dispatch: any) => {
    dispatch(request(GET_PLANNER_ACTIVITY_REQUEST));

    getPlannerActivityService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_PLANNER_ACTIVITY_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_PLANNER_ACTIVITY_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};