import { connect } from "react-redux";
import DOMPurify from "dompurify";
import {
  ErrorMessage,
  Field,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";
import { FC, useState } from "react";

import { addFileObject } from "app/helpers/commonInterface";
import {
  getReplacedStr,
  selectedTemplateComponent,
} from "../ClientMessagePopup";
import { getFormData, getImage } from "app/helpers/helperFunction";
import { generalFileUpload } from "app/reducers/task/taskAction";
import ImageDisplay from "../../ImageDisplay";
import { SmallLoader } from "../../buttons/Buttons";

interface props {
  component: selectedTemplateComponent;
  uploadFileDispatch: Function;
}

const WPTemplateHeader: FC<props> = ({ component, uploadFileDispatch }) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
  }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
    useFormikContext();
  const [uploadedFile, setUploadedFile] = useState<addFileObject>();
  const [loading, setLoading] = useState(false);

  return (
    <div className="header mb-4 mt-2">
      {component.format === "TEXT" && (
        <div
          className="fw-bold"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              getReplacedStr(
                component.text,
                "form-text header-text w-auto form-text",
                errors,
                touched
              )
            ),
          }}
        ></div>
      )}
      {(component.format === "IMAGE" || component.format === "DOCUMENT") && (
        <>
          {!values.components[0].headerParameter.value && (
            <label htmlFor="File" className="">
              <div className="d-inline-flex gap-3 btn btn-light-primary btn-active-light-primary btn-sm">
                {loading ? (
                  <SmallLoader />
                ) : (
                  <i className="bi bi-upload me-1"></i>
                )}
                Upload{" "}
                {component.format.charAt(0).toUpperCase() +
                  component.format.slice(1).toLowerCase()}
              </div>
              <Field
                type="file"
                value={undefined}
                className="d-none"
                id="File"
                name="File"
                accept={
                  component.format === "IMAGE" ? ".jpg, .jpeg, .png" : ".pdf"
                }
                onChange={(e: any) => {
                  setLoading(true);
                  uploadFileDispatch(
                    getFormData({ file: e.target.files[0] }),
                    (fileData: addFileObject) => {
                      setUploadedFile(fileData);
                      setFieldValue(
                        "components[0].headerParameter.value",
                        fileData.FileUrl
                      );
                      setLoading(false);
                    }
                  );
                }}
              />
              <ErrorMessage name="File" component="div" className="errorMsg" />
            </label>
          )}

          {values.components[0].headerParameter.value && (
            <div className="upload-file-display bg-light w-200px d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 text-gray-600">
              <div className="d-flex gap-3 align-items-center">
                <div className="w-35px">
                  <ImageDisplay
                    path={getImage(uploadedFile?.MimeType || "")}
                    errorPath="/media/task/doc-1.png"
                    className="img-fit-to-div"
                    altText="documentType"
                  />
                </div>
                <div className="fw-semibold">
                  <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                    {uploadedFile?.FileName}
                  </div>
                </div>
              </div>
              <div className="">
                <button
                  className="btn p-0"
                  onClick={() => {
                    setUploadedFile({
                      MimeType: "",
                      FileName: "",
                      FileUrl: "",
                      FilePath: "",
                    });
                    setFieldValue("components[0].headerParameter.value", "");
                  }}
                >
                  <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadFileDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(WPTemplateHeader);
