import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  DOCUMENT_REPORT_API_ENDPOINT,
  DOWNLOAD_ZIP_FILE_API_ENDPOINT,
  FILE_API_ENDPOINT,
} from "app/helpers/config";
import { searchParams } from "app/helpers/commonInterface";

export const getFileListService = async (fileFilterObject: {
  year?: string;
  clientId: number;
}) => {
  const queryString = getSearchQueryString(fileFilterObject);
  return await axiosInstance.get(`${FILE_API_ENDPOINT}?${queryString}`);
};

export const downloadZipFileService = async (fileId: Array<number>) => {
  return await axiosInstance
    .get(`${DOWNLOAD_ZIP_FILE_API_ENDPOINT}?fileIds=${fileId.join(",")}`, {
      responseType: "blob",
    })
    .then((res) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(res.data);
      downloadLink.download = "allDocument.zip";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
};

export const getFileReportListService = async (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return await axiosInstance.get(
    `${DOCUMENT_REPORT_API_ENDPOINT}?${queryString}`
  );
};
