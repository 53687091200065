import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string, ref } from "yup";
import { useNavigate } from "react-router-dom";

import { resetPassword } from "app/reducers/login/loginAction";
interface props {
  resetPasswordDispatch: Function;
}

export interface resetPasswordInitialValue {
  confirmPassword?: string;
  password: string;
  userId: string;
  token: string;
}

const WHITE_SPACE_REGEX = /\s/g;
const getInitialValue = (
  userId: string | null,
  token: string | null
): resetPasswordInitialValue => {
  return {
    confirmPassword: "",
    password: "",
    userId: userId || "",
    token: token ? token.replaceAll(WHITE_SPACE_REGEX, "+") : "",
  };
};

const validateFun = object().shape({
  password: string()
    .required("Password is required")
    .matches(
      /(?=.*[A-Z])(?=.*\W+)(?=.*\d+).+/,
      "Password must contain one uppercase, one number and one special case character"
    ),
  confirmPassword: string()
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: string().oneOf(
        [ref("password")],
        "Password and Confirm password didn't match"
      ),
    })
    .required("Password confirmation is required"),
});
const ResetPassword: FC<props> = ({ resetPasswordDispatch }) => {
  let params = new URLSearchParams(document.location.search);
  let token = params.get("token");
  let id = params.get("Id");
  const navigate = useNavigate();

  return (
    <div className="card new-password-container">
      <div className="text-center py-8">
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Please enter your new password
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={getInitialValue(id, token)}
        validationSchema={validateFun}
        onSubmit={async (values, { setStatus }) => {
          delete values.confirmPassword;
          await resetPasswordDispatch(values, () => {
            navigate("/auth");
          });
        }}
      >
        {({ status, touched, errors, isSubmitting }) => {
          return (
            <Form noValidate className="form">
              <div className="p-7">
                {status && (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      There was an error updating password
                    </div>
                  </div>
                )}
                <div className="fv-row mb-8">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    New Password
                  </label>

                  <div className="">
                    <Field
                      type="password"
                      className={`form-control form-control-lg form-control-solid ${
                        touched.password &&
                        errors.password &&
                        "is-invalid inValidBorder"
                      }`}
                      placeholder="New Password"
                      id="password"
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
                <div className="fv-row mb-8">
                  <label className="form-label fs-6 fw-bolder text-dark required">
                    Confirm New Password
                  </label>

                  <div className="">
                    <Field
                      type="password"
                      className={`form-control form-control-lg form-control-solid ${
                        touched.confirmPassword &&
                        errors.confirmPassword &&
                        "is-invalid inValidBorder"
                      }`}
                      placeholder="Confirm New Password"
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end pb-6 px-7">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {!isSubmitting && <div>Submit</div>}
                  {isSubmitting && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetPasswordDispatch: (
      data: resetPasswordInitialValue,
      successCallback: Function
    ) => dispatch(resetPassword(data, successCallback)),
  };
};
export default connect(null, mapDispatchToProps)(ResetPassword);
