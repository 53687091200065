import {
    GET_PLANNER_ACTIVITY_FAILURE,
    GET_PLANNER_ACTIVITY_REQUEST,
    GET_PLANNER_ACTIVITY_SUCCESS,
  } from "../actionTypes";
  
  export interface plannerActivityListState {
    loading: boolean;
    plannerActivity: any;
    error: string;
  }
  
  interface action {
    type: string;
    payload: any;
  }
  
  const initialState: plannerActivityListState = {
    loading: false,
    plannerActivity: {},
    error: "",
  };
  
  export const getPlannerActivityReducer = (
    state = initialState,
    action: action
  ) => {
    switch (action.type) {
      case GET_PLANNER_ACTIVITY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PLANNER_ACTIVITY_SUCCESS:
        return {
          loading: false,
          plannerActivity: action.payload,
          error: "",
        };
      case GET_PLANNER_ACTIVITY_FAILURE:
        return {
          loading: false,
          articleActivity: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  