import {
  GET_ACTIVITY_BY_ARTICLE_FAILURE,
  GET_ACTIVITY_BY_ARTICLE_REQUEST,
  GET_ACTIVITY_BY_ARTICLE_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
} from "../actionTypes";

export interface activityListState {
  loading: boolean;
  articleActivity: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: activityListState = {
  loading: false,
  articleActivity: {},
  error: "",
};

export const getActivityByArticleReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_ACTIVITY_BY_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_BY_ARTICLE_SUCCESS:
      return {
        loading: false,
        articleActivity: action.payload,
        error: "",
      };
    case GET_ACTIVITY_BY_ARTICLE_FAILURE:
      return {
        loading: false,
        articleActivity: {},
        error: action.payload,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      if (state.articleActivity?.data?.records) {
        return {
          ...state,
          articleActivity: {
            ...state.articleActivity,
            data: {
              ...state.articleActivity.data,
              records: state.articleActivity.data.records.map(
                (activity: any) => {
                  if (action.payload.Id === activity.Id) {
                    return action.payload;
                  }
                  return activity;
                }
              ),
            },
          },
        };
      }
      return state;
    default:
      return state;
  }
};
