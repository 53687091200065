import { connect } from "react-redux";
import { FC, useEffect, useMemo } from "react";

import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { getSearchParameter } from "app/helpers/helperFunction";
import Loader from "app/components/commonComponent/loader/Loader";
import RegisteredTemplateTableRow from "./RegisteredTemplateTableRow";
import { searchParams } from "app/helpers/commonInterface";
import { UserCustomHeader } from "app/components/commonComponent/table/UserCustomHeader";
import { useSearchParams } from "react-router-dom";
import { whatsappTemplateData } from "app/reducers/whatsapp/whatsappAction";

const HEADERS = [
    {
        columnName: "Template Name",
        id: "name",
        className: "min-w-125px ps-2"
    },
    {
        columnName: "Category",
        id: "category",
        className: "min-w-125px"
    },
    {
        columnName: "Status",
        id: "status",
        className: "min-w-125px"
    },
    {
        columnName: "Actions",
        id: "NULL",
        className: "min-w-125px"
    }
]

interface props {
    whatsappTemplateDataDispatch: Function
    wpTemplateListDetails: any
}

const RegisteredTemplateTable: FC<props> = ({ whatsappTemplateDataDispatch, wpTemplateListDetails }) => {
    const [searchParam] = useSearchParams();
    const searchObj = useMemo(() => getSearchParameter(searchParam), [searchParam]);

    useEffect(() => {
        whatsappTemplateDataDispatch(searchObj)
    }, [searchObj])

    return (
        <>
            <div className='px-7 py-4'>
                <div className='table-responsive horizontal-scroll'>
                    <table
                        id='kt_table_users'
                        className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-striped'
                    >
                        <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 gs-0'>
                                <UserCustomHeader headers={HEADERS} />
                            </tr>
                        </thead>
                        <tbody className='text-gray-600'>
                            {wpTemplateListDetails.loading &&
                                <tr><td colSpan={HEADERS.length}><Loader /></td></tr>}
                            {wpTemplateListDetails.error &&
                                <tr><td colSpan={HEADERS.length}><ErrorDisplay error={wpTemplateListDetails.error} /></td></tr>}
                            {(wpTemplateListDetails?.wpTemplateList?.success &&
                                !wpTemplateListDetails?.wpTemplateList?.data?.records?.length) &&
                                <tr>
                                    <td colSpan={HEADERS.length}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            }
                            {(!wpTemplateListDetails.loading &&
                                wpTemplateListDetails?.wpTemplateList?.success &&
                                wpTemplateListDetails?.wpTemplateList?.data?.records?.length !== 0) && (
                                    wpTemplateListDetails?.wpTemplateList?.data?.records?.map((row: any, i: number) => {
                                        return <RegisteredTemplateTableRow row={row} key={`row-${i}-${row.id}`} />
                                    })
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        wpTemplateListDetails: state.wpTemplateList
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        whatsappTemplateDataDispatch: (searchObj: searchParams) => dispatch(whatsappTemplateData(searchObj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredTemplateTable)