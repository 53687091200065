import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string, array, number } from "yup";
import { useNavigate, useParams } from "react-router-dom";

import AddGroupContactPerson from "./AddGroupContactPerson";
import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import { emptyObject } from "../../../../helpers/helperFunction";
import { GROUP_LIST } from "../../../../helpers/routes";
import { groupContactPerson } from "../GroupForm";
import { groupListPage } from "../GroupList";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { PHONE_NUMBER_REGX } from "app/helpers/constants";

interface props {
  initialValue: any;
  groupDataDispatch: Function;
  title: string;
  newGroupContactPersonObject: groupContactPerson;
}

const validateFun = object().shape({
  name: string().required("Group name is required"),
  contactPersons: array().of(
    object().shape({
      name: string().required("Name is required"),
      // email: string()
      //   .email("Invalid email format")
      //   .required("Email is required"),
      phoneNumber: string()
        .matches(PHONE_NUMBER_REGX, "Phone number is not valid")
        .required("Phone number is required")
        .typeError("Phone number is required"),
    })
  ),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  groupListPage,
  emptyObject,
];

const AddGroup: FC<props> = ({
  newGroupContactPersonObject,
  initialValue,
  groupDataDispatch,
  title,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0 client-form-container">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            await groupDataDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(GROUP_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ values, touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-8">
                    <div className="col-lg-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Group Name
                      </label>
                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid ${
                            touched.name &&
                            errors.name &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Group Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  {values.contactPersons.length ? (
                    <div className="fs-3 fw-semibold mb-6">Contact Person</div>
                  ) : (
                    ""
                  )}
                  <AddGroupContactPerson
                    isSubmitting={isSubmitting}
                    errors={errors}
                    touched={touched}
                    values={values}
                    newGroupContactPersonObject={newGroupContactPersonObject}
                  />
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(GROUP_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddGroup;
