import { PageLink, PageTitle } from "_metronic/layout/core";
import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import ProformaInvoiceFilter from "./component/ProformaInvoiceFilter";
import ProformaInvoiceTable from "./component/ProformaInvoiceTable";
import { PROFORMA_INVOICE_LIST } from "app/helpers/routes";

export const proformaInvoiceListPage = {
  title: "Proforma Invoice",
  path: absolutePath(PROFORMA_INVOICE_LIST),
  isSeparator: true,
  isActive: true,
};

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ProformaInvoiceList = () => {
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Proforma Invoice</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <ProformaInvoiceFilter />
      </div>
      <KTCard>
        <ProformaInvoiceTable />
      </KTCard>
    </>
  );
};

export default ProformaInvoiceList;
