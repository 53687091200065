import { response } from "app/helpers/commonInterface";
import {
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
} from "../actionTypes";
import { transactionDetail } from "./transactionDataInterface";

export interface transactionDataState {
  loading: boolean;
  transaction: response<transactionDetail>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: transactionDataState = {
  loading: false,
  transaction: {},
  error: "",
};

export const getTransactionByIdReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_TRANSACTION_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transaction: action.payload,
        error: "",
      };
    case GET_TRANSACTION_FAILURE:
      return {
        loading: false,
        transaction: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
