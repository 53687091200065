import { GET_MORNING_PLANNER_FAILURE, GET_MORNING_PLANNER_SUCCESS, GET_MORNING_PLANNER_REQUEST, DELETE_MORNING_PLANNER_REQUEST, DELETE_MORNING_PLANNER_SUCCESS, DELETE_MORNING_PLANNER_FAILURE } from "../actionTypes"

interface morningPlannerListState {
    deleteLoading:boolean,
    loading  : boolean,
    morningPlannerList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:morningPlannerListState = {
    deleteLoading : false,
    loading  : false,
    morningPlannerList : {},
    error : ""
}

export const morningPlannerReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_MORNING_PLANNER_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_MORNING_PLANNER_SUCCESS : return {
            ...state,
            loading : false,
            morningPlannerList : action.payload,
            error : ""
        }
        case GET_MORNING_PLANNER_FAILURE : return {
            ...state,
            loading : false,
            morningPlannerList : {},
            error : action.payload
        }

        case DELETE_MORNING_PLANNER_REQUEST : return {
            ...state,
            deleteLoading : true
        }
        case DELETE_MORNING_PLANNER_SUCCESS : return {
            ...state,
            deleteLoading : false,
            morningPlannerList : action.payload,
            error : ""
        }
        case DELETE_MORNING_PLANNER_FAILURE : return {
            ...state,
            deleteLoading : false,
            morningPlannerList : {},
            error : action.payload
        }
       
        default : return state
    }
}