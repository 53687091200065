import { connect } from "react-redux";
import { FC, useEffect } from "react";

import Table from "app/components/commonComponent/table/Table";
import { userListState } from "../../../../reducers/user/userListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import MPCustomRow from "./MPCustomRow";
import { viewMorningAction } from "app/reducers/morningPlanner/plannerAction";

const HEADERS = [

  {
    columnName: "Task Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "StartTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "EndTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Duration",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Date",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "NULL",
    className: "min-w-125px",
  }
];

interface props {
  morningPlannerList:any,
  viewMorningActionDispatch:Function
}

const MorningPlannerTable: FC<props> = ({morningPlannerList,viewMorningActionDispatch}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
  }, [searchObj]);

  useEffect(()=>{
    if(morningPlannerList && morningPlannerList.morningPlannerList.data){
       if(morningPlannerList.morningPlannerList.data.records[0]?.Status > 3){
        viewMorningActionDispatch(false);
       }
       else{
        viewMorningActionDispatch(true);
       }
    }
},[morningPlannerList])

  return (
    <>
      <Table
        checkBoxColumn={{ show: false } }
        error={morningPlannerList.error}
        headers={HEADERS}
        success={morningPlannerList.morningPlannerList?.success}
        loading={morningPlannerList.loading}
        paginate={{
          show: false,
          pagination: morningPlannerList.morningPlannerList?.pagination,
          PageNumber: Number(searchObj.PageNumber) || 1,
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={morningPlannerList.morningPlannerList?.data?.records}
        tableRow={(row: any, index: number) => (
          <MPCustomRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );  
};
const mapStateToProps = (state: any) => {
  return {
    morningPlannerList: state.morningPlannerList, 
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    viewMorningActionDispatch :(data:boolean)=>dispatch(viewMorningAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MorningPlannerTable);
