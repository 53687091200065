import { PageTitle } from "_metronic/layout/core";
import { emptyObject } from "app/helpers/helperFunction";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { billingReportListPage } from "../BillingReportList";
import { adminHomePage } from "../../main/admin/AdminMain";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { getBill } from "app/reducers/billing/billingAction";
import { billDataState } from "app/reducers/billing/getByIdBillReducer";
import ProformaInvoiceBillDetail from "../../proformaInvoice/proformaInvoiceDetailComponent/ProformaInvoiceBillDetail";
import ProformaInvoiceBankDetail from "../../proformaInvoice/proformaInvoiceDetailComponent/ProformaInvoiceBankDetail";
import ProformaInvoiceTaskDetail from "../../proformaInvoice/proformaInvoiceDetailComponent/ProformaInvoiceTaskDetail";
import ProformaInvoiceTransactionDetail from "../../proformaInvoice/proformaInvoiceDetailComponent/ProformaInvoiceTransactionDetail";
import hasPaymentAccess from "../../auth/core/paymentAccess";

interface props {
  getBillDetailsDispatch: Function;
  billDetails: billDataState;
}

const accountBreadCrumbs = [adminHomePage, billingReportListPage, emptyObject];

const BillingReportDetails: FC<props> = ({
  billDetails,
  getBillDetailsDispatch,
}) => {
  const params = useParams();
  useEffect(() => {
    getBillDetailsDispatch(params.billId);
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Invoice Detail</PageTitle>
      <ErrorBoundary>
        {billDetails.loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : billDetails.error ? (
          <ErrorDisplay error={billDetails.error} />
        ) : billDetails.bill?.success ? (
          <div>
            <div className="row mb-6">
              <div className="col-lg-12 col-md-8 col-xl-8 mb-6 mb-lg-6 mb-md-0 mb-xl-0">
                <ProformaInvoiceBillDetail
                  billDetails={billDetails?.bill.data?.records}
                />
              </div>
              <div className="col-lg-12 col-md-4 col-xl-4 pe-0 pe-xl-3">
                <ProformaInvoiceBankDetail
                  firmDetails={billDetails?.bill.data?.records?.Firm}
                />
              </div>
            </div>
            <div className="row mb-6">
              <div className="col-xl-6 mb-6">
                <ProformaInvoiceTaskDetail
                  taskListDetails={billDetails?.bill.data?.records?.Tasks || []}
                />
                <ProformaInvoiceTransactionDetail
                  title="Receipts"
                  transactionData={
                    billDetails?.bill.data?.records?.Receipts || []
                  }
                />
              </div>
              <div className="col-xl-6">
                <ProformaInvoiceTransactionDetail
                  title="Payments"
                  transactionData={
                    billDetails?.bill.data?.records?.Payments || []
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return { billDetails: state.bill };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBillDetailsDispatch: (billId: number) => dispatch(getBill(billId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingReportDetails);
