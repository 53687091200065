import {
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  UPDATE_USER_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface userListState {
  loading: boolean;
  userList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: userListState = {
  loading: false,
  userList: {},
  error: "",
};

export const userListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
        error: "",
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        userList: {},
        error: action.payload,
      };
    case UPDATE_USER_STATUS_SUCCESS:
      return updateStatusFun(state, "userList", action.payload.data.records.Id);
    default:
      return state;
  }
};
