import { searchParams } from "app/helpers/commonInterface";
import { ADD_EVENING_REPORTING_FAILURE, ADD_EVENING_REPORTING_REQUEST, ADD_EVENING_REPORTING_SUCCESS, ADD_MORNING_PLANNER_FAILURE, ADD_MORNING_PLANNER_REQUEST, ADD_MORNING_PLANNER_SUCCESS, DELETE_EVENING_REPORTING_FAILURE, DELETE_EVENING_REPORTING_REQUEST, DELETE_EVENING_REPORTING_SUCCESS, DELETE_MORNING_PLANNER_FAILURE, DELETE_MORNING_PLANNER_REQUEST, DELETE_MORNING_PLANNER_SUCCESS, DELETE_USERS_EVENING_REPORTING_FAILURE, DELETE_USERS_EVENING_REPORTING_REQUEST, DELETE_USERS_EVENING_REPORTING_SUCCESS, DELETE_USERS_MORNING_PLANNER_FAILURE, DELETE_USERS_MORNING_PLANNER_REQUEST, DELETE_USERS_MORNING_PLANNER_SUCCESS, EDIT_EVENING_REPORTING_FAILURE, EDIT_EVENING_REPORTING_REQUEST, EDIT_EVENING_REPORTING_SUCCESS, EDIT_MORNING_PLANNER_FAILURE, EDIT_MORNING_PLANNER_REQUEST, EDIT_MORNING_PLANNER_SUCCESS, GET_ALL_DAILY_MORNING_PLANNER_FAILURE, GET_ALL_DAILY_MORNING_PLANNER_REQUEST, GET_ALL_DAILY_MORNING_PLANNER_SUCCESS, GET_DAILY_MORNING_PLANNER_FAILURE, GET_DAILY_MORNING_PLANNER_REQUEST, GET_DAILY_MORNING_PLANNER_SUCCESS, GET_EVENING_REPORTING_BY_ID_FAILURE, GET_EVENING_REPORTING_BY_ID_REQUEST, GET_EVENING_REPORTING_BY_ID_SUCCESS, GET_EVENING_REPORTING_FAILURE, GET_EVENING_REPORTING_REQUEST, GET_EVENING_REPORTING_SUCCESS, GET_MORNING_PLANNER_BY_ID_FAILURE, GET_MORNING_PLANNER_BY_ID_REQUEST, GET_MORNING_PLANNER_BY_ID_SUCCESS, GET_MORNING_PLANNER_FAILURE, GET_MORNING_PLANNER_REQUEST, GET_MORNING_PLANNER_SUCCESS, GET_USERS_EVENING_REPORTING_FAILURE, GET_USERS_EVENING_REPORTING_REQUEST, GET_USERS_EVENING_REPORTING_SUCCESS, GET_USERS_MORNING_PLANNER_FAILURE, GET_USERS_MORNING_PLANNER_REQUEST, GET_USERS_MORNING_PLANNER_SUCCESS, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS, SET_VIEW_EVENING_ACTION, SET_VIEW_MORNING_ACTION } from "../actionTypes";
import { failure, request, success } from "../dispatchFunctions";
import { GetErrorMessage } from "../stateHelperFunction";
import { addEveningReportingService, addMorningPlannerService, deleteEveningReportingService, deleteMorningPlannerService, editEveningReportingService, editMorningPlannerService, getAllDailyMorningPlannerService, getDailyMorningPlannerService, getEveningReportingByIdService, getEveningReportingService, getMorningPlannerByIdService, getMorningPlannerService, getUsersEveningReportingService, getUsersMorningPlannerService } from "./plannerService";
import { showToastMessageFailure, showToastMessageSuccess } from "app/helpers/helperFunction";

export const getMorningPlanner = () => {
    return (dispatch: any) => {
        dispatch(request(GET_MORNING_PLANNER_REQUEST));

        getMorningPlannerService().then(
            (result: any) => {
                dispatch(success(GET_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getMorningPlannerById = (id:number) => {
    return (dispatch: any) => {
        dispatch(request(GET_MORNING_PLANNER_BY_ID_REQUEST));

        getMorningPlannerByIdService(id).then(
            (result: any) => {
                dispatch(success(GET_MORNING_PLANNER_BY_ID_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_MORNING_PLANNER_BY_ID_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getDailyMorningPlanner = (userId : string | null) => {
    return (dispatch: any) => {
        dispatch(request(GET_DAILY_MORNING_PLANNER_REQUEST));

        getDailyMorningPlannerService(userId).then(
            (result: any) => {
                dispatch(success(GET_DAILY_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_DAILY_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getAllDailyMorningPlanner = (searchObj:searchParams) => {
    return (dispatch: any) => {
        dispatch(request(GET_ALL_DAILY_MORNING_PLANNER_REQUEST));

        getAllDailyMorningPlannerService(searchObj).then(
            (result: any) => {
                dispatch(success(GET_ALL_DAILY_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_ALL_DAILY_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const sentEveningReportingForDailyMorningPlanner =  (userId : string | null, callback:Function,data:any) => {
    return  (dispatch: any) => {
        dispatch(request(SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST));

        getDailyMorningPlannerService(userId).then(
            (result: any) => {
                dispatch(success(SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS, result.data));
                callback(userId,result.data,data)      
            },
            (error: any) => {
                dispatch(
                    failure(
                        SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getEveningReportingById = (id:number) => {
    return (dispatch: any) => {
        dispatch(request(GET_EVENING_REPORTING_BY_ID_REQUEST));

        getEveningReportingByIdService(id).then(
            (result: any) => {
                dispatch(success(GET_EVENING_REPORTING_BY_ID_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_EVENING_REPORTING_BY_ID_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getEveningReporting = () => {
    return (dispatch: any) => {
        dispatch(request(GET_EVENING_REPORTING_REQUEST));

        getEveningReportingService().then(
            (result: any) => {
                dispatch(success(GET_EVENING_REPORTING_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_EVENING_REPORTING_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const getUsersMorningPlanner = (searchObj:searchParams) => {
    return (dispatch: any) => {
        dispatch(request(GET_USERS_MORNING_PLANNER_REQUEST));

        getUsersMorningPlannerService(searchObj).then(
            (result: any) => {
                dispatch(success(GET_USERS_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_USERS_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const deleteMorningPlanner = (id : any,searchObj:searchParams)=>{
    return (dispatch: any) => {
         dispatch(request(DELETE_MORNING_PLANNER_REQUEST));

        deleteMorningPlannerService(id,searchObj).then(
            (result: any) => {
                dispatch(success(DELETE_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        DELETE_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    }
}

export const deleteEveningReporting = (id : any,searchObj:searchParams)=>{
    return (dispatch: any) => {
         dispatch(request(DELETE_EVENING_REPORTING_REQUEST));

        deleteEveningReportingService(id,searchObj).then(
            (result: any) => {
                dispatch(success(DELETE_EVENING_REPORTING_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        DELETE_EVENING_REPORTING_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    }
}

export const deleteUsersMorningPlanner = (id : any,searchObj:searchParams)=>{
    return (dispatch: any) => {
         dispatch(request(DELETE_USERS_MORNING_PLANNER_REQUEST));

        deleteMorningPlannerService(id,searchObj).then(
            (result: any) => {
                dispatch(success(DELETE_USERS_MORNING_PLANNER_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        DELETE_USERS_MORNING_PLANNER_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    }
}

export const deleteUsersEveningReporting = (id : any,searchObj:searchParams)=>{
    return (dispatch: any) => {
         dispatch(request(DELETE_USERS_EVENING_REPORTING_REQUEST));

        deleteEveningReportingService(id,searchObj).then(
            (result: any) => {
                dispatch(success(DELETE_USERS_EVENING_REPORTING_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        DELETE_USERS_EVENING_REPORTING_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    }
}

export const getUsersEveningReporting= (searchObj:searchParams) => {
    return (dispatch: any) => {
        dispatch(request(GET_USERS_EVENING_REPORTING_REQUEST));

        getUsersEveningReportingService(searchObj).then(
            (result: any) => {
                dispatch(success(GET_USERS_EVENING_REPORTING_SUCCESS, result.data));
            },
            (error: any) => {
                dispatch(
                    failure(
                        GET_USERS_EVENING_REPORTING_FAILURE,
                        GetErrorMessage(error?.response?.data)
                    )
                );
            }
        );
    };
};

export const addMorningPlanner=(data:any)=>{
    return (dispatch: any) => {
        dispatch(request(ADD_MORNING_PLANNER_REQUEST));
    addMorningPlannerService(data).then(
        (result: any) => { 
            dispatch(success(ADD_MORNING_PLANNER_SUCCESS,data));
            showToastMessageSuccess("Task added");
        },
        (error: any) => {
            failure(
                ADD_MORNING_PLANNER_FAILURE,
                GetErrorMessage(error?.response?.data),
            );
            showToastMessageFailure("Something Want Wrong")
        }
    );
}
}

export const editMorningPlanner=(data:any)=>{
    return (dispatch: any) => {
        dispatch(request(EDIT_MORNING_PLANNER_REQUEST));
        editMorningPlannerService(data).then(
        (result: any) => { 
            dispatch(success(EDIT_MORNING_PLANNER_SUCCESS,data));
            if(data.length == 1){
                showToastMessageSuccess("Task edited");
            }
        },
        (error: any) => {
            failure(
                EDIT_MORNING_PLANNER_FAILURE,
                GetErrorMessage(error?.response?.data)
            );
            showToastMessageFailure("Something Want Wrong")
        }
    );
}
}

export const editEveningReporting=(data:any)=>{
    return (dispatch: any) => {
        dispatch(request(EDIT_EVENING_REPORTING_REQUEST));
        editEveningReportingService(data).then(
        (result: any) => { 
            dispatch(success(EDIT_EVENING_REPORTING_SUCCESS,data));
            if(data.length == 1){
            showToastMessageSuccess("Task edited");
            }
        },
        (error: any) => {
            failure(
                EDIT_EVENING_REPORTING_FAILURE,
                GetErrorMessage(error?.response?.data)
            );
            showToastMessageFailure("Something Want Wrong")
        }
    );
}
}


export const addEveningReporting=(data:any)=>{
    return (dispatch: any) => {
        dispatch(request(ADD_EVENING_REPORTING_REQUEST));
    addEveningReportingService(data).then(
        (result: any) => {  
            dispatch(success(ADD_EVENING_REPORTING_SUCCESS,data));
            showToastMessageSuccess("Task added");
        },
        (error: any) => {
            failure(
                ADD_EVENING_REPORTING_FAILURE,
                GetErrorMessage(error?.response?.data)
            );
            showToastMessageFailure("Something Want Wrong")
        }
    );
}
}


export const viewMorningAction=(data:boolean)=>{
    return (dispatch: any) => {
            dispatch(success(SET_VIEW_MORNING_ACTION,data));
}
}

export const viewEveningAction=(data:boolean)=>{
    return (dispatch: any) => {
            dispatch(success(SET_VIEW_EVENING_ACTION,data));
}
}

