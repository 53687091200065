import { connect } from 'react-redux'
import { FC } from 'react'

import { absolutePath } from '../../../helpers/relativePath'
import { adminHomePage } from '../main/admin/AdminMain'
import { ADD_CATEGORY, CATEGORY_LIST } from '../../../helpers/routes'
import CategoryTable from './component/CategoryTable'
import { emptyObject } from '../../../helpers/helperFunction'
import { getCategoryList } from '../../../reducers/category/categoryAction'
import { KTCard } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { searchParams } from '../../../helpers/commonInterface'
import UsersListSearchComponent from '../../commonComponent/table/UsersListSearchComponent'
import UsersListToolbar from '../../commonComponent/table/UsersListToolbar'
import { useAuth } from '../auth'

interface props {
    getCategoryList: Function
}

export const categoryListPage = {
    title: 'Categories',
    path: absolutePath(CATEGORY_LIST),
    isSeparator: true,
    isActive: true,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

const CategoryList: FC<props> = ({ getCategoryList }) => {
    const { currentUser } = useAuth()
    return (
        <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Categories</PageTitle>
                <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                    <UsersListSearchComponent
                        addCompanyId={{ name: "companyId", value: currentUser?.CompanyId }}
                        getDataDispatchFunction={getCategoryList} />
                    <UsersListToolbar
                        buttons={[{ title: "Add Category", to: ADD_CATEGORY }]} />
                </div>
                <KTCard>
                    <CategoryTable />
                </KTCard>
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCategoryList: (searchObj: searchParams) => dispatch(getCategoryList(searchObj))
    }
}

export default connect(null, mapDispatchToProps)(CategoryList)