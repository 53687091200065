import { FC } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";

import { adminHomePage } from "../../main/admin/AdminMain";
import { addExpenseValue } from "../ExpenseForm";
import { absolutePath } from "app/helpers/relativePath";
import { emptyObject } from "app/helpers/helperFunction";
import { expenseListPage } from "../ExpenseList";
import { EXPENSE_LIST } from "app/helpers/routes";
import { PageLink, PageTitle } from "_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

interface props {
  title: string;
  expenseDispatch: Function;
  initialValue: addExpenseValue;
}

const firmBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  expenseListPage,
  emptyObject,
];

const expenseValidationSchema = object().shape({
  name: string().required("Expense is required"),
});

const AddExpense: FC<props> = ({ title, expenseDispatch, initialValue }) => {
  const navigate = useNavigate();
  const params = useParams();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={firmBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={expenseValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await expenseDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(EXPENSE_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Expense
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                         ${
                           touched.name &&
                           errors.name &&
                           "is-invalid inValidBorder"
                         }`}
                          placeholder="Expense"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(EXPENSE_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddExpense;
