import { FC } from "react";
import { connect } from "react-redux";

import { socketData } from "app/reducers/websocket/websocketReducer";
import clsx from "clsx";

interface props {
  socketDetails: socketData;
}

const DataChangedMessage: FC<props> = ({ socketDetails }) => {
  return (
    <div
      className={`bg-light-warning border border-dashed border-warning fs-6 fw-semibold mb-5 px-5 py-2 rounded text-warning w-100 ${
        socketDetails.dataChangeMessage.IsMasterDataChange &&
        socketDetails.dataChangeMessage.Type == "in"
          ? "d-block"
          : "d-none"
      }`}
    >
      Data has been changed by Admin. Please refresh to stay updated.
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    socketDetails: state.websocketData,
  };
};

export default connect(mapStateToProps)(DataChangedMessage);
