import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  getWhatsappAccountDetailService,
  whatsappAccountDetailService,
  whatsappSendMessageService,
  whatsappTemplateDataService,
  whatsappTemplateRegisterService,
} from "./whatsappService";
import { wpInitialValueData } from "app/components/pages/settings/templateRegister/TemplateRegister";
import {
  GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE,
  GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST,
  GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS,
  WHATSAPP_ACCOUNT_DETAIL_FAILURE,
  WHATSAPP_ACCOUNT_DETAIL_REQUEST,
  WHATSAPP_ACCOUNT_DETAIL_SUCCESS,
  WHATSAPP_SEND_MESSAGE_FAILURE,
  WHATSAPP_SEND_MESSAGE_REQUEST,
  WHATSAPP_SEND_MESSAGE_SUCCESS,
  WHATSAPP_TEMPLATE_DATA_FAILURE,
  WHATSAPP_TEMPLATE_DATA_REQUEST,
  WHATSAPP_TEMPLATE_DATA_SUCCESS,
  WHATSAPP_TEMPLATE_REGISTER_FAILURE,
  WHATSAPP_TEMPLATE_REGISTER_REQUEST,
  WHATSAPP_TEMPLATE_REGISTER_SUCCESS,
} from "../actionTypes";
import { searchParams } from "app/helpers/commonInterface";
import { whatsappDataValue } from "app/components/pages/settings/whatsappDetails/WhatsappDetailsForm";
import { GetErrorMessage } from "../stateHelperFunction";

export const whatsappTemplateRegister = (
  data: wpInitialValueData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(WHATSAPP_TEMPLATE_REGISTER_REQUEST));

    await whatsappTemplateRegisterService(data).then(
      (result: any) => {
        dispatch(success(WHATSAPP_TEMPLATE_REGISTER_SUCCESS, result));
        showToastMessageSuccess("Send data for registration");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            WHATSAPP_TEMPLATE_REGISTER_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const whatsappSendMessage = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(WHATSAPP_SEND_MESSAGE_REQUEST));

    await whatsappSendMessageService(data).then(
      (result: any) => {
        dispatch(success(WHATSAPP_SEND_MESSAGE_SUCCESS, result));
        successCallback();
        showToastMessageSuccess("Message sent");
      },
      (error: any) => {
        dispatch(
          failure(
            WHATSAPP_SEND_MESSAGE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const whatsappTemplateData = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(WHATSAPP_TEMPLATE_DATA_REQUEST));

    whatsappTemplateDataService(searchObj).then(
      (result: any) => {
        dispatch(success(WHATSAPP_TEMPLATE_DATA_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            WHATSAPP_TEMPLATE_DATA_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const whatsappAccountDetail = (wpData: whatsappDataValue) => {
  return async (dispatch: any) => {
    dispatch(request(WHATSAPP_ACCOUNT_DETAIL_REQUEST));

    await whatsappAccountDetailService(wpData).then(
      (result: any) => {
        dispatch(success(WHATSAPP_ACCOUNT_DETAIL_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            WHATSAPP_ACCOUNT_DETAIL_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};

export const getWhatsappAccountDetail = (companyId: number) => {
  return async (dispatch: any) => {
    dispatch(request(GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST));

    await getWhatsappAccountDetailService(companyId).then(
      (result: any) => {
        dispatch(success(GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
