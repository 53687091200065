import { axiosInstance } from "../axiosInstance"
import { editProfileData } from "../../helpers/commonInterface"
import { PROFILE_API_ENDPOINT, UPDATE_PROFILE_API_ENDPOINT } from "../../helpers/config"

export const getProfileDataService = async (userId:string|null) => {
    if(userId != null){
        return await axiosInstance.get(`${PROFILE_API_ENDPOINT}?userId=${userId}`)
    }
    else{
        return await axiosInstance.get(PROFILE_API_ENDPOINT)
    }
}

export const editProfileService = async (data: editProfileData) => {
    return await axiosInstance.put(UPDATE_PROFILE_API_ENDPOINT, data)
}