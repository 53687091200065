import { GET_ASSIGNEE_SUCCESS, GET_CREATER_SUCCESS } from "../actionTypes"


export interface assigneeAndCreateState {
    loading: boolean,
    assignee: any,
    creater: any,
    error: string
}

interface action {
    type: string
    payload: any
}

const initialState: assigneeAndCreateState = {
    loading: false,
    assignee: [],
    creater: [],
    error: ""
}

export const getAssigneeAndCreaterReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case GET_ASSIGNEE_SUCCESS:
            return {
                ...state,
                assignee: action.payload.data.records,
            }
        case GET_CREATER_SUCCESS:
            return {
                ...state,
                creater: action.payload.data.records,
            }
        default: return state
    }
}