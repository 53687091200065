import { adminHomePage } from "../main/admin/AdminMain"
import { absolutePath } from "../../../helpers/relativePath"
import { ADD_DOCUMENT_TYPE, DOCUMENT_TYPE_LIST } from "../../../helpers/routes"
import DocumentTypeTable from "./component/DocumentTypeTable"
import { emptyObject } from "../../../helpers/helperFunction"
import { KTCard } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar"
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"

export const documentTypeListPage = {
    title: 'Document Types',
    path: absolutePath(DOCUMENT_TYPE_LIST),
    isSeparator: true,
    isActive: true,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

const DocumentTypeList = () => {
  return (
    <>
    <PageTitle breadcrumbs={accountBreadCrumbs}>Document Types</PageTitle>
    <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
    <UsersListSearchComponent />
        <UsersListToolbar buttons={[{ title: "Add Document Type", to: ADD_DOCUMENT_TYPE }]} />
    </div>
    <KTCard>
        <DocumentTypeTable />
    </KTCard>
</>
  )
}

export default DocumentTypeList