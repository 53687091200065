import { GET_TASK_TYPE_BY_ID_FAILURE, GET_TASK_TYPE_BY_ID_REQUEST, GET_TASK_TYPE_BY_ID_SUCCESS } from "../actionTypes"

export interface taskTypeDataState {
    loading  : boolean,
    taskType : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:taskTypeDataState = {
    loading  : false,
    taskType : {},
    error : ""
}

export const getTaskTypeByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_TASK_TYPE_BY_ID_REQUEST : return {
            loading : true
        }
        case GET_TASK_TYPE_BY_ID_SUCCESS : 
        return {
            loading : false,
            taskType : action.payload,
            error : ""
        }
        case GET_TASK_TYPE_BY_ID_FAILURE : return {
            loading : false,
            taskType : {},
            error : action.payload
        }
        default : return state
    }
}