import { GET_EXPENSE_FAILURE, GET_EXPENSE_REQUEST, GET_EXPENSE_SUCCESS } from "../actionTypes"

export interface expenseDataState {
    loading  : boolean,
    expense : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:expenseDataState = {
    loading  : false,
    expense : {},
    error : ""
}

export const getExpenseByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_EXPENSE_REQUEST : return {
            loading : true
        }
        case GET_EXPENSE_SUCCESS : 
        return {
            loading : false,
            expense : action.payload,
            error : ""
        }
        case GET_EXPENSE_FAILURE : return {
            loading : false,
            expense : {},
            error : action.payload
        }
        default : return state
    }
}