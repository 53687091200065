import { connect } from "react-redux";
import { FC, useEffect } from "react";

import { getTaskTypeList } from "../../../../reducers/taskType/taskTypeAction";
import { searchParams } from "../../../../helpers/commonInterface";
import TaskTypeTableRow from "./TaskTypeTableRow";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

const HEADERS = [
  {
    columnName: "Type Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Category Name",
    id: "CategoryName",
    className: "min-w-125px",
  },
  {
    columnName: "Time Period",
    id: "IsRepeatative",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "Actions",
    className: "min-w-125px text-end pe-5",
  },
];

interface props {
  taskTypeDetails: any;
  getTaskTypeList: Function;
}

const TaskTypeTable: FC<props> = ({ taskTypeDetails, getTaskTypeList }) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getTaskTypeList(searchObj);
  }, [getTaskTypeList, searchObj]);

  return (
    <>
      
      <Table
        checkBoxColumn={{ show: false }}
        error={taskTypeDetails.error}
        headers={HEADERS}
        success={taskTypeDetails.taskTypeList?.success}
        loading={taskTypeDetails.loading}
        paginate={{
          show: true,
          pagination: taskTypeDetails.taskTypeList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={taskTypeDetails.taskTypeList?.data?.records}
        tableRow={(row: any, index: number) => (
          <TaskTypeTableRow key={`row-${index}-${row.id}`} row={row} />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    taskTypeDetails: state.taskTypeList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTaskTypeList: (searchObj: searchParams) =>
      dispatch(getTaskTypeList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypeTable);
