import {
  downloadZipFileService,
  getFileListService,
  getFileReportListService,
} from "./clientDocumentsService";
import { failure, request, success } from "../dispatchFunctions";
import {
  ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST,
  DOWNLOAD_ZIP_FILE_FAILURE,
  DOWNLOAD_ZIP_FILE_REQUEST,
  DOWNLOAD_ZIP_FILE_SUCCESS,
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_REQUEST,
  GET_FILE_LIST_SUCCESS,
  GET_FILE_REPORT_LIST_FAILURE,
  GET_FILE_REPORT_LIST_REQUEST,
  GET_FILE_REPORT_LIST_SUCCESS,
  SELECT_ALL_DOCUMENT_CHECKBOX,
  SELECT_DOCUMENT_CHECKBOX,
  UNSELECT_ALL_DOCUMENT_CHECKBOX,
  UNSELECT_DOCUMENT_CHECKBOX,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";
import { searchParams } from "app/helpers/commonInterface";

export const getFileList = (fileFilterObject: {
  year?: string;
  clientId: number;
}) => {
  return async (dispatch: any) => {
    dispatch(request(GET_FILE_LIST_REQUEST));

    await getFileListService(fileFilterObject).then(
      (result: any) => {
        dispatch(success(GET_FILE_LIST_SUCCESS, result.data));
        dispatch(
          success(ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST, result.data)
        );
      },
      (error: any) => {
        dispatch(
          failure(GET_FILE_LIST_FAILURE, GetErrorMessage(error?.response?.data))
        );
      }
    );
  };
};

export const downloadZipFile = (
  fileId: Array<number>,
  successCallback?: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(DOWNLOAD_ZIP_FILE_REQUEST));

    await downloadZipFileService(fileId).then(
      (result: any) => {
        dispatch(success(DOWNLOAD_ZIP_FILE_SUCCESS, result));
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            DOWNLOAD_ZIP_FILE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};

export const getDocumentReportList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_FILE_REPORT_LIST_REQUEST));

    getFileReportListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_FILE_REPORT_LIST_SUCCESS, result.data));
        dispatch(
          success(ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST, result.data)
        );
      },
      (error: any) =>
        dispatch(
          failure(
            GET_FILE_REPORT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const selectAllDocument = () => {
  return (dispatch: any) => {
    dispatch(success(SELECT_ALL_DOCUMENT_CHECKBOX, ""));
  };
};
export const unSelectAllDocument = () => {
  return (dispatch: any) => {
    dispatch(success(UNSELECT_ALL_DOCUMENT_CHECKBOX, ""));
  };
};
export const selectDocument = (documentId: number) => {
  return (dispatch: any) => {
    dispatch(success(SELECT_DOCUMENT_CHECKBOX, documentId));
  };
};
export const unSelectDocument = (documentId: number) => {
  return (dispatch: any) => {
    dispatch(success(UNSELECT_DOCUMENT_CHECKBOX, documentId));
  };
};
