import { ErrorMessage, Field } from 'formik'
import { FC } from "react";

import { formikData } from '../TemplateRegister';

interface props {
    formikData: formikData
}
const TemplateForm: FC<props> = ({ formikData }) => {
    const { values, touched, errors } = formikData
    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='category'
                        value='utility'
                        id='utility-btn'
                    />
                    <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 
                            ${values.category === "utility" ? "btn-outline-primary" : ""}`}
                        htmlFor='utility-btn'
                    >
                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4'>Utility</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                                Send message about an exsiting order or account
                            </span>
                        </span>
                    </label>
                </div>
                <div className='col-lg-6'>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='category'
                        value='authentication'
                        id='authentication-btn'
                    />
                    <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10
                    ${values.category === "authentication" ? "btn-outline-primary" : ""}`}
                        htmlFor='authentication-btn'
                    >
                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4'>Authentication</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                                Send codes to verify transaction or login
                            </span>
                        </span>
                    </label>
                </div>
            </div>
            <div className='row'>
                <label className="col-lg-3 col-form-label fw-semibold fs-6 required">Template Name</label>

                <div className="col-lg-8 row pe-0">
                    <div className="">
                        <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                                    ${touched.name && errors.name && "is-invalid inValidBorder"}`}
                            placeholder="Template Name"
                            id="name"
                            name="name"
                        />
                        <ErrorMessage name="name" component="div" className="errorMsg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TemplateForm