import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string, date, number } from "yup";

import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { editProfileData } from "app/helpers/commonInterface";
import { editProfile } from "app/reducers/profile/profileAction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { profileState } from "app/reducers/profile/profileReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { PHONE_NUMBER_REGX } from "app/helpers/constants";

interface props {
  editProfileDispatch: Function;
  setShowForm: Function;
  profileDetails: profileState;
  component: string;
}

interface profileData {
  FirstName: string;
  LastName: string;
  RegistrationDate: string;
  JoiningDate: string;
  IFSCCode: string;
  BankName: string;
  AccountNumber: string;
  AccountType: string;
  PhoneNumber: string;
  BranchName: string;
}

interface initialValue {
  firstName: string;
  lastName: string;
  registrationDate: string;
  joiningDate: string;
  ifscCode: string;
  bankName: string;
  accountNumber: string;
  accountType: string;
  phoneNumber: string;
  branchName: string;
}

const getInitialValue = (profileData: profileData): initialValue => {
  return {
    firstName: profileData.FirstName,
    lastName: profileData.LastName,
    registrationDate: profileData.RegistrationDate,
    joiningDate: profileData.JoiningDate,
    ifscCode: profileData.IFSCCode,
    bankName: profileData.BankName,
    accountNumber: profileData.AccountNumber,
    accountType: profileData.AccountType,
    phoneNumber: profileData.PhoneNumber,
    branchName: profileData.BranchName,
  };
};

const validateFun = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  bankName: string().required("Bank name is required"),
  branchName: string().required("Branch name is required"),
  accountNumber: string().required("Account number is required"),
  accountType: string().required("Account type is required"),
  ifscCode: string().required("IFSC code is required"),
  registrationDate: date().required("Registration date is required"),
  joiningDate: date().required("Joining date is required"),
  phoneNumber: string()
    .matches(PHONE_NUMBER_REGX, "Phone number is not valid")
    .required("Phone number is required"),
});

const OPTIONS = [
  { value: "saving", name: "Saving" },
  { value: "current", name: "Current" },
];

const EditProfile: FC<props> = ({
  setShowForm,
  editProfileDispatch,
  profileDetails,
  component,
}) => {
  return (
    <ErrorBoundary>
      <Formik
        enableReinitialize
        initialValues={getInitialValue(profileDetails?.profileData)}
        validationSchema={validateFun}
        onSubmit={async (values) => {
          await editProfileDispatch(values, true, () => {
            setShowForm({ profileForm: false });
          });
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => {
          return (
            <Form noValidate className="form w-100">
              {component === "user" && (
                <>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-4">
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="form-label fw-semibold fs-6 required">
                        First Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                                                ${
                                                  touched.firstName &&
                                                  errors.firstName &&
                                                  "is-invalid inValidBorder"
                                                }`}
                          placeholder="Enter first name"
                          id="firstName"
                          name="firstName"
                          defaultValue={profileDetails?.profileData?.FirstName}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="form-label fw-semibold fs-6 required">
                        Last Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                                                ${
                                                  touched.lastName &&
                                                  errors.lastName &&
                                                  "is-invalid inValidBorder"
                                                }`}
                          placeholder="Enter last name"
                          id="lastName"
                          name="lastName"
                          defaultValue={profileDetails?.profileData?.LastName}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-4">
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="form-label fw-semibold fs-6 ">
                        Registration Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          disabled
                          name="registrationDate"
                          placeholder="Registration Date"
                          setFieldValue={setFieldValue}
                          currentValue={
                            values.registrationDate
                              ? new Date(values.registrationDate)
                              : null
                          }
                        />
                        <ErrorMessage
                          name="registrationDate"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="form-label fw-semibold fs-6">
                        Joining Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          disabled
                          name="joiningDate"
                          placeholder="Joining Date"
                          setFieldValue={setFieldValue}
                          currentValue={
                            values.joiningDate
                              ? new Date(values.joiningDate)
                              : null
                          }
                        />
                        <ErrorMessage
                          name="joiningDate"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-4">
                    <div className="mb-4 mb-lg-0 w-100 w-sm-50">
                      <label className="form-label fw-semibold fs-6 required">
                        Phone Number
                      </label>

                      <div className="">
                        <Field
                          type="number"
                          className={`form-control form-control-lg form-control-solid 
                                                ${
                                                  touched.phoneNumber &&
                                                  errors.phoneNumber &&
                                                  "is-invalid inValidBorder"
                                                }`}
                          placeholder="Phone Number"
                          id="phoneNumber"
                          name="phoneNumber"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {component === "bank" && (
                <>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-4">
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Bank Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                                            ${
                                              touched.bankName &&
                                              errors.bankName &&
                                              "is-invalid inValidBorder"
                                            }`}
                          placeholder="Bank Name"
                          id="bankName"
                          name="bankName"
                        />
                        <ErrorMessage
                          name="bankName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className=" mb-4 mb-lg-0 w-100">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Branch Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                                            ${
                                              touched.branchName &&
                                              errors.branchName &&
                                              "is-invalid inValidBorder"
                                            }`}
                          placeholder="Branch Name"
                          id="branchName"
                          name="branchName"
                        />
                        <ErrorMessage
                          name="branchName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-6">
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Account Number
                      </label>

                      <div className="">
                        <Field
                          type="number"
                          className={`form-control form-control-lg form-control-solid 
                                            ${
                                              touched.accountNumber &&
                                              errors.accountNumber &&
                                              "is-invalid inValidBorder"
                                            }`}
                          placeholder="Account Number"
                          id="accountNumber"
                          name="accountNumber"
                        />
                        <ErrorMessage
                          name="accountNumber"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 w-100">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Account Type
                      </label>

                      <div className="">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Account Type"
                          options={OPTIONS}
                          setFieldValue={setFieldValue}
                          name="accountType"
                          defaultValue={{
                            value: values.accountType,
                            name:
                              values.accountType.charAt(0).toUpperCase() +
                              values.accountType.slice(1),
                          }}
                        />
                        <ErrorMessage
                          name="accountType"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row gap-5 mb-4">
                    <div className="mb-4 mb-lg-0 w-100 w-sm-50">
                      <label className="form-label fw-semibold fs-6 required">
                        IFSC Code
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid
                                             ${
                                               touched.ifscCode &&
                                               errors.ifscCode &&
                                               "is-invalid inValidBorder"
                                             }`}
                          placeholder="IFSC Code"
                          id="ifscCode"
                          name="ifscCode"
                        />
                        <ErrorMessage
                          name="ifscCode"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex justify-content-end pt-6 gap-3">
                <ResetButton
                  name="Cancel"
                  className="btn btn-light btn-active-light-primary"
                  onClickCallback={() => setShowForm({ profileForm: false })}
                />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  name="Submit"
                  className="btn btn-primary"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profileDetails: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editProfileDispatch: (
      data: editProfileData,
      isEditProfileDetails: boolean,
      successCallback: Function
    ) => dispatch(editProfile({ data, isEditProfileDetails, successCallback })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
