import { absolutePath } from "../../../helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { ADD_CLIENT, CLIENT_LIST } from "../../../helpers/routes";
import ClientTable from "./component/ClientTable";
import ClientFilter from "./component/ClientFilter";
import { emptyObject } from "../../../helpers/helperFunction";
import { KTCard } from "../../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import UsersListSearchComponent from "../../commonComponent/table/UsersListSearchComponent";
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar";
import { useState } from "react";
import ImportModel from "./component/ImportModel";

export const clientListPage = {
  title: "Clients",
  path: absolutePath(CLIENT_LIST),
  isSeparator: true,
  isActive: true,
};

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ClientList = () => {
  const [showModel, setShowModel] = useState(false);

  const closeModal = () => {
    setShowModel(false);
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Clients</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
        <UsersListSearchComponent />
        <ClientFilter />
        <UsersListToolbar buttons={[{ title: "Add Client", to: ADD_CLIENT }]} />
        <button
          className="btn btn-sm btn-primary"
          onClick={() => setShowModel(true)}
        >
          Import Data
        </button>
      </div>
      <KTCard>
        <ClientTable />
      </KTCard>

      {showModel && (
        <ImportModel closeModal={closeModal} showModel={showModel} />
      )}
    </>
  );
};

export default ClientList;
