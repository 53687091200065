//signUp
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

//login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

//forgot-password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

//add user
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

//edit profile
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

//edit user
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

//get user list
export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

//get user by reporting authoritylist
export const GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST = "GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST";
export const GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS = "GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS";
export const GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE = "GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE";

//get profile data
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

//get user by id data
export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

//update user status
export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

//import client
export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

//import client Data
export const IMPORT_CLIENT_REQUEST = "IMPORT_CLIENT_REQUEST";
export const IMPORT_CLIENT_SUCCESS = "IMPORT_CLIENT_SUCCESS";
export const IMPORT_CLIENT_FAILURE = "IMPORT_CLIENT_FAILURE";

//get client list
export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILURE = "GET_CLIENT_LIST_FAILURE";

//get legal status list
export const GET_LEGAL_STATUS_LIST_REQUEST = "GET_LEGAL_STATUS_LIST_REQUEST";
export const GET_LEGAL_STATUS_LIST_SUCCESS = "GET_LEGAL_STATUS_LIST_SUCCESS";
export const GET_LEGAL_STATUS_LIST_FAILURE = "GET_LEGAL_STATUS_LIST_FAILURE";

//get client by id
export const GET_CLIENT_BY_ID_REQUEST = "GET_CLIENT_BY_ID_REQUEST";
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
export const GET_CLIENT_BY_ID_FAILURE = "GET_CLIENT_BY_ID_FAILURE";

//get client by id
export const UPDATE_CLIENT_BY_ID_REQUEST = "UPDATE_CLIENT_BY_ID_REQUEST";
export const UPDATE_CLIENT_BY_ID_SUCCESS = "UPDATE_CLIENT_BY_ID_SUCCESS";
export const UPDATE_CLIENT_BY_ID_FAILURE = "UPDATE_CLIENT_BY_ID_FAILURE";

//get client status
export const UPDATE_CLIENT_STATUS_REQUEST = "UPDATE_CLIENT_STATUS_REQUEST";
export const UPDATE_CLIENT_STATUS_SUCCESS = "UPDATE_CLIENT_STATUS_SUCCESS";
export const UPDATE_CLIENT_STATUS_FAILURE = "UPDATE_CLIENT_STATUS_FAILURE";

//get client report details
export const GET_CLIENT_REPORT_DETAILS_REQUEST =
  "GET_CLIENT_REPORT_DETAILS_REQUEST";
export const GET_CLIENT_REPORT_DETAILS_SUCCESS =
  "GET_CLIENT_REPORT_DETAILS_SUCCESS";
export const GET_CLIENT_REPORT_DETAILS_FAILURE =
  "GET_CLIENT_REPORT_DETAILS_FAILURE";

//add task type
export const ADD_TASK_TYPE_REQUEST = "ADD_TASK_TYPE_REQUEST";
export const ADD_TASK_TYPE_SUCCESS = "ADD_TASK_TYPE_SUCCESS";
export const ADD_TASK_TYPE_FAILURE = "ADD_TASK_TYPE_FAILURE";

//get task type
export const GET_TASK_TYPE_LIST_REQUEST = "GET_TASK_TYPE_LIST_REQUEST";
export const GET_TASK_TYPE_LIST_SUCCESS = "GET_TASK_TYPE_LIST_SUCCESS";
export const GET_TASK_TYPE_LIST_FAILURE = "GET_TASK_TYPE_LIST_FAILURE";

//get task type by id
export const GET_TASK_TYPE_BY_ID_REQUEST = "GET_TASK_TYPE_BY_ID_REQUEST";
export const GET_TASK_TYPE_BY_ID_SUCCESS = "GET_TASK_TYPE_BY_ID_SUCCESS";
export const GET_TASK_TYPE_BY_ID_FAILURE = "GET_TASK_TYPE_BY_ID_FAILURE";

//update task type
export const UPDATE_TASK_TYPE_REQUEST = "UPDATE_TASK_TYPE_REQUEST";
export const UPDATE_TASK_TYPE_SUCCESS = "UPDATE_TASK_TYPE_SUCCESS";
export const UPDATE_TASK_TYPE_FAILURE = "UPDATE_TASK_TYPE_FAILURE";

//update task type status
export const UPDATE_TASK_TYPE_STATUS_REQUEST =
  "UPDATE_TASK_TYPE_STATUS_REQUEST";
export const UPDATE_TASK_TYPE_STATUS_SUCCESS =
  "UPDATE_TASK_TYPE_STATUS_SUCCESS";
export const UPDATE_TASK_TYPE_STATUS_FAILURE =
  "UPDATE_TASK_TYPE_STATUS_FAILURE";

export const GET_TASK_REPORT_LIST_REQUEST = "GET_TASK_REPORT_LIST_REQUEST";
export const GET_TASK_REPORT_LIST_SUCCESS = "GET_TASK_REPORT_LIST_SUCCESS";
export const GET_TASK_REPORT_LIST_FAILURE = "GET_TASK_REPORT_LIST_FAILURE";

export const SET_URGENT_STATUS_REQUEST = "SET_URGENT_STATUS_REQUEST";
export const SET_URGENT_STATUS_SUCCESS = "SET_URGENT_STATUS_SUCCESS";
export const SET_URGENT_STATUS_FAILURE = "SET_URGENT_STATUS_FAILURE";

//add category
export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

//get category list
export const GET_CATEGORY_LIST_REQUEST = "GET_CATEGORY_LIST_REQUEST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "GET_CATEGORY_LIST_FAILURE";

//get category by id
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

//update category
export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

//add task
export const ADD_TASK_REQUEST = "ADD_TASK_REQUEST";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAILURE = "ADD_TASK_FAILURE";

//get task
export const GET_TASK_LIST_REQUEST = "GET_TASK_LIST_REQUEST";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_FAILURE = "GET_TASK_LIST_FAILURE";

//get task by id
export const GET_TASK_BY_ID_REQUEST = "GET_TASK_BY_ID_REQUEST";
export const GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS";
export const GET_TASK_BY_ID_FAILURE = "GET_TASK_BY_ID_FAILURE";

//get ChatNotificationTasks
export const GET_CHATNOTIFICTION_TASK_LIST_REQUEST = "GET_CHATNOTIFICTION_TASK_LIST_REQUEST";
export const GET_CHATNOTIFICTION_TASK_LIST_SUCCESS = "GET_CHATNOTIFICTION_TASK_LIST_SUCCESS";
export const GET_CHATNOTIFICTION_TASK_LIST_FAILURE = "GET_CHATNOTIFICTION_TASK_LIST_FAILURE";
//update task
export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";
//get assignee
export const GET_ASSIGNEE_REQUEST = "GET_ASSIGNEE_REQUEST";
export const GET_ASSIGNEE_SUCCESS = "GET_ASSIGNEE_SUCCESS";
export const GET_ASSIGNEE_FAILURE = "GET_ASSIGNEE_FAILURE";
//get creater
export const GET_CREATER_REQUEST = "GET_CREATER_REQUEST";
export const GET_CREATER_SUCCESS = "GET_CREATER_SUCCESS";
export const GET_CREATER_FAILURE = "GET_CREATER_FAILURE";

//get assigned task to user
export const GET_ASSIGNED_TASK_REQUEST = "GET_ASSIGNED_TASK_REQUEST";
export const GET_ASSIGNED_TASK_SUCCESS = "GET_ASSIGNED_TASK_SUCCESS";
export const GET_ASSIGNED_TASK_FAILURE = "GET_ASSIGNED_TASK_FAILURE";

//get morning planner task to user
export const GET_PLANNER_TASK_REQUEST = "GET_PLANNER_TASK_REQUEST";
export const GET_PLANNER_TASK_SUCCESS = "GET_PLANNER_TASK_SUCCESS";
export const GET_PLANNER_TASK_FAILURE = "GET_PLANNER_TASK_FAILURE";

//get urgent  task to user
export const GET_URGENT_TASK_REQUEST = "GET_URGENT_TASK_REQUEST";
export const GET_URGENT_TASK_SUCCESS = "GET_URGENT_TASK_SUCCESS";
export const GET_URGENT_TASK_FAILURE = "GET_URGENT_TASK_FAILURE";

//get assigned task to check
export const GET_CHECKING_TASK_REQUEST = "GET_CHECKING_TASK_REQUEST";
export const GET_CHECKING_TASK_SUCCESS = "GET_CHECKING_TASK_SUCCESS";
export const GET_CHECKING_TASK_FAILURE = "GET_CHECKING_TASK_FAILURE";

//get assigned task to follow up
export const GET_FOLLOWUP_TASK_REQUEST = "GET_FOLLOWUP_TASK_REQUEST";
export const GET_FOLLOWUP_TASK_SUCCESS = "GET_FOLLOWUP_TASK_SUCCESS";
export const GET_FOLLOWUP_TASK_FAILURE = "GET_FOLLOWUP_TASK_FAILURE";

//change task assignee
export const CHANGE_TASK_ASSIGNEE_REQUEST = "CHANGE_TASK_ASSIGNEE_REQUEST";
export const CHANGE_TASK_ASSIGNEE_SUCCESS = "CHANGE_TASK_ASSIGNEE_SUCCESS";
export const CHANGE_TASK_ASSIGNEE_FAILURE = "CHANGE_TASK_ASSIGNEE_FAILURE";

export const ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE =
  "ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE";

//change task status
export const CHANGE_TASK_STATUS_REQUEST = "CHANGE_TASK_STATUS_REQUEST";
export const CHANGE_TASK_STATUS_SUCCESS = "CHANGE_TASK_STATUS_SUCCESS";
export const CHANGE_TASK_STATUS_FAILURE = "CHANGE_TASK_STATUS_FAILURE";

//upload file
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

//upload file to remarks
export const UPLOAD_FILE_TO_REMARKS_REQUEST = "UPLOAD_FILE_TO_REMARKS_REQUEST";
export const UPLOAD_FILE_TO_REMARKS_SUCCESS = "UPLOAD_FILE_TO_REMARKS_SUCCESS";
export const UPLOAD_FILE_TO_REMARKS_FAILURE = "UPLOAD_FILE_TO_REMARKS_FAILURE";

//download file
export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

//delete file
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const GET_FILE_REPORT_LIST_REQUEST = "GET_FILE_REPORT_LIST_REQUEST";
export const GET_FILE_REPORT_LIST_SUCCESS = "GET_FILE_REPORT_LIST_SUCCESS";
export const GET_FILE_REPORT_LIST_FAILURE = "GET_FILE_REPORT_LIST_FAILURE";

//change Activity Status
export const CHANGE_TASK_ACTIVITY_STATUS_REQUEST =
  "CHANGE_TASK_ACTIVITY_STATUS_REQUEST";
export const CHANGE_TASK_ACTIVITY_STATUS_SUCCESS =
  "CHANGE_TASK_ACTIVITY_STATUS_SUCCESS";
export const CHANGE_TASK_ACTIVITY_STATUS_FAILURE =
  "CHANGE_TASK_ACTIVITY_STATUS_FAILURE";

//task add for checking
export const TASK_ADD_FOR_CHECKING_REQUEST = "TASK_ADD_FOR_CHECKING_REQUEST";
export const TASK_ADD_FOR_CHECKING_SUCCESS = "TASK_ADD_FOR_CHECKING_SUCCESS";
export const TASK_ADD_FOR_CHECKING_FAILURE = "TASK_ADD_FOR_CHECKING_FAILURE";

//get year wise file
export const GET_FILE_LIST_REQUEST = "GET_FILE_LIST_REQUEST";
export const GET_FILE_LIST_SUCCESS = "GET_FILE_LIST_SUCCESS";
export const GET_FILE_LIST_FAILURE = "GET_FILE_LIST_FAILURE";

//add remarks
export const ADD_REMARKS_REQUEST = "ADD_REMARKS_REQUEST";
export const ADD_REMARKS_SUCCESS = "ADD_REMARKS_SUCCESS";
export const ADD_REMARKS_FAILURE = "ADD_REMARKS_FAILURE";

//edit remarks
export const EDIT_REMARKS_REQUEST = "EDIT_REMARKS_REQUEST";
export const EDIT_REMARKS_SUCCESS = "EDIT_REMARKS_SUCCESS";
export const EDIT_REMARKS_FAILURE = "EDIT_REMARKS_FAILURE";

//delete remarks
export const DELETE_REMARKS_REQUEST = "DELETE_REMARKS_REQUEST";
export const DELETE_REMARKS_SUCCESS = "DELETE_REMARKS_SUCCESS";
export const DELETE_REMARKS_FAILURE = "DELETE_REMARKS_FAILURE";

//add document type
export const ADD_DOCUMENT_TYPE_REQUEST = "ADD_DOCUMENT_TYPE_REQUEST";
export const ADD_DOCUMENT_TYPE_SUCCESS = "ADD_DOCUMENT_TYPE_SUCCESS";
export const ADD_DOCUMENT_TYPE_FAILURE = "ADD_DOCUMENT_TYPE_FAILURE";

//edit document type
export const EDIT_DOCUMENT_TYPE_REQUEST = "EDIT_DOCUMENT_TYPE_REQUEST";
export const EDIT_DOCUMENT_TYPE_SUCCESS = "EDIT_DOCUMENT_TYPE_SUCCESS";
export const EDIT_DOCUMENT_TYPE_FAILURE = "EDIT_DOCUMENT_TYPE_FAILURE";

//delete document type
export const DELETE_DOCUMENT_TYPE_REQUEST = "DELETE_DOCUMENT_TYPE_REQUEST";
export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT_TYPE_FAILURE = "DELETE_DOCUMENT_TYPE_FAILURE";

//get document type by id
export const GET_DOCUMENT_TYPE_REQUEST = "GET_DOCUMENT_TYPE_REQUEST";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

//get all document type
export const GET_DOCUMENT_TYPE_LIST_REQUEST = "GET_DOCUMENT_TYPE_LIST_REQUEST";
export const GET_DOCUMENT_TYPE_LIST_SUCCESS = "GET_DOCUMENT_TYPE_LIST_SUCCESS";
export const GET_DOCUMENT_TYPE_LIST_FAILURE = "GET_DOCUMENT_TYPE_LIST_FAILURE";

//client message handler
export const CLIENT_MESSAGE_HANDLER = "CLIENT_MESSAGE_HANDLER";

//client file handler
export const CLIENT_FILE_HANDLER = "CLIENT_FILE_HANDLER";

//add group
export const ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

//edit group
export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";

//get group by id
export const GET_GROUP_BY_ID_REQUEST = "GET_GROUP_BY_ID_REQUEST";
export const GET_GROUP_BY_ID_SUCCESS = "GET_GROUP_BY_ID_SUCCESS";
export const GET_GROUP_BY_ID_FAILURE = "GET_GROUP_BY_ID_FAILURE";

//get group list
export const GET_GROUP_LIST_REQUEST = "GET_GROUP_LIST_REQUEST";
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS";
export const GET_GROUP_LIST_FAILURE = "GET_GROUP_LIST_FAILURE";

//update group status
export const UPDATE_GROUP_STATUS_REQUEST = "UPDATE_GROUP_STATUS_REQUEST";
export const UPDATE_GROUP_STATUS_SUCCESS = "UPDATE_GROUP_STATUS_SUCCESS";
export const UPDATE_GROUP_STATUS_FAILURE = "UPDATE_GROUP_STATUS_FAILURE";

//task selection types
export const SELECT_TASK_CHECKBOX = "SELECT_TASK_CHECKBOX";
export const SELECT_ALL_TASK_CHECKBOX = "SELECT_ALL_TASK_CHECKBOX";
export const UNSELECT_ALL_TASK_CHECKBOX = "UNSELECT_ALL_TASK_CHECKBOX";
export const UNSELECT_TASK_CHECKBOX = "UNSELECT_TASK_CHECKBOX";
export const ADD_PARENT_DATA_FOR_SELECTED_LIST =
  "ADD_PARENT_DATA_FOR_SELECTED_LIST";

// add daily task
export const ADD_DAILY_TASK_REQUEST = "ADD_DAILY_TASK_REQUEST";
export const ADD_DAILY_TASK_SUCCESS = "ADD_DAILY_TASK_SUCCESS";
export const ADD_DAILY_TASK_FAILURE = "ADD_DAILY_TASK_FAILURE";

// edit daily task
export const EDIT_DAILY_TASK_REQUEST = "EDIT_DAILY_TASK_REQUEST";
export const EDIT_DAILY_TASK_SUCCESS = "EDIT_DAILY_TASK_SUCCESS";
export const EDIT_DAILY_TASK_FAILURE = "EDIT_DAILY_TASK_FAILURE";

// get by id daily task
export const GET_BY_ID_DAILY_TASK_REQUEST = "GET_BY_ID_DAILY_TASK_REQUEST";
export const GET_BY_ID_DAILY_TASK_SUCCESS = "GET_BY_ID_DAILY_TASK_SUCCESS";
export const GET_BY_ID_DAILY_TASK_FAILURE = "GET_BY_ID_DAILY_TASK_FAILURE";

// get all daily task
export const GET_DAILY_TASK_LIST_REQUEST = "GET_DAILY_TASK_LIST_REQUEST";
export const GET_DAILY_TASK_LIST_SUCCESS = "GET_DAILY_TASK_LIST_SUCCESS";
export const GET_DAILY_TASK_LIST_FAILURE = "GET_DAILY_TASK_LIST_FAILURE";

//get morning planners
export const GET_MORNING_PLANNER_REQUEST="GET_MORNING_PLANNER_REQUEST";
export const GET_MORNING_PLANNER_SUCCESS="GET_MORNING_PLANNER_SUCCESS";
export const GET_MORNING_PLANNER_FAILURE="GET_MORNING_PLANNER_FAILURE";


//get morning planners
export const GET_ALL_DAILY_MORNING_PLANNER_REQUEST="GET_ALL_DAILY_MORNING_PLANNER_REQUEST";
export const GET_ALL_DAILY_MORNING_PLANNER_SUCCESS="GET_ALL_DAILY_MORNING_PLANNER_SUCCESS";
export const GET_ALL_DAILY_MORNING_PLANNER_FAILURE="GET_ALL_DAILY_MORNING_PLANNER_FAILURE";

//get morning planners
export const GET_DAILY_MORNING_PLANNER_REQUEST="GET_DAILY_MORNING_PLANNER_REQUEST";
export const GET_DAILY_MORNING_PLANNER_SUCCESS="GET_DAILY_MORNING_PLANNER_SUCCESS";
export const GET_DAILY_MORNING_PLANNER_FAILURE="GET_DAILY_MORNING_PLANNER_FAILURE";

//get morning planners for evening reporting
export const SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST="SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST";
export const SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS="SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS";
export const SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE="SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE";


// view morning action
export const SET_VIEW_MORNING_ACTION = "SET_VIEW_MORNING_ACTION";

//view evening action
export const SET_VIEW_EVENING_ACTION = "SET_VIEW_EVENING_ACTION";


//delete morning planners
export const DELETE_MORNING_PLANNER_REQUEST="DELETE_MORNING_PLANNER_REQUEST";
export const DELETE_MORNING_PLANNER_SUCCESS="DELETE_MORNING_PLANNER_SUCCESS";
export const DELETE_MORNING_PLANNER_FAILURE="DELETE_MORNING_PLANNER_FAILURE";

//delete from all morning planners
export const DELETE_USERS_MORNING_PLANNER_REQUEST="DELETE_USERS_MORNING_PLANNER_REQUEST";
export const DELETE_USERS_MORNING_PLANNER_SUCCESS="DELETE_USERS_MORNING_PLANNER_SUCCESS";
export const DELETE_USERS_MORNING_PLANNER_FAILURE="DELETE_USERS_MORNING_PLANNER_FAILURE";

//delete evening reporting
export const DELETE_EVENING_REPORTING_REQUEST="DELETE_EVENING_REPORTING_REQUEST";
export const DELETE_EVENING_REPORTING_SUCCESS="DELETE_EVENING_REPORTING_SUCCESS";
export const DELETE_EVENING_REPORTING_FAILURE="DELETE_EVENING_REPORTING_FAILURE";

//delete from evening reporting
export const DELETE_USERS_EVENING_REPORTING_REQUEST="DELETE_USERS_EVENING_REPORTING_REQUEST";
export const DELETE_USERS_EVENING_REPORTING_SUCCESS="DELETE_USERS_EVENING_REPORTING_SUCCESS";
export const DELETE_USERS_EVENING_REPORTING_FAILURE="DELETE_USERS_EVENING_REPORTING_FAILURE";


//get morning planners by id
export const GET_MORNING_PLANNER_BY_ID_REQUEST="GET_MORNING_PLANNER_BY_ID_REQUEST";
export const GET_MORNING_PLANNER_BY_ID_SUCCESS="GET_MORNING_PLANNER_BY_ID_SUCCESS";
export const GET_MORNING_PLANNER_BY_ID_FAILURE="GET_MORNING_PLANNER_BY_ID_FAILURE";

//get morning planners by id
export const GET_EVENING_REPORTING_BY_ID_REQUEST="GET_EVENING_REPORTING_BY_ID_REQUEST";
export const GET_EVENING_REPORTING_BY_ID_SUCCESS="GET_EVENING_REPORTING_BY_ID_SUCCESS";
export const GET_EVENING_REPORTING_BY_ID_FAILURE="GET_EVENING_REPORTING_BY_ID_FAILURE";

// get all morning planner 
export const GET_USERS_MORNING_PLANNER_REQUEST="GET_USERS_MORNING_PLANNER_REQUEST";
export const GET_USERS_MORNING_PLANNER_SUCCESS="GET_USERS_MORNING_PLANNER_SUCCESS";
export const GET_USERS_MORNING_PLANNER_FAILURE="GET_USERS_MORNING_PLANNER_FAILURE";

// get all evening reporting 
export const GET_USERS_EVENING_REPORTING_REQUEST="GET_USERS_EVENING_REPORTING_REQUEST";
export const GET_USERS_EVENING_REPORTING_SUCCESS="GET_USERS_EVENING_REPORTING_SUCCESS";
export const GET_USERS_EVENING_REPORTING_FAILURE="GET_USERS_EVENING_REPORTING_FAILURE";

//add morning planner
export const ADD_MORNING_PLANNER_REQUEST="ADD_MORNING_PLANNER_REQUEST";
export const ADD_MORNING_PLANNER_SUCCESS="ADD_MORNING_PLANNER_SUCCESS";
export const ADD_MORNING_PLANNER_FAILURE="ADD_MORNING_PLANNER_FAILURE";

//add evening planner
export const ADD_EVENING_REPORTING_REQUEST="ADD_EVENING_REPORTING_REQUEST";
export const ADD_EVENING_REPORTING_SUCCESS="ADD_EVENING_REPORTING_SUCCESS";
export const ADD_EVENING_REPORTING_FAILURE="ADD_EVENING_REPORTING_FAILURE";

//edit morning planner
export const EDIT_MORNING_PLANNER_REQUEST="EDIT_MORNING_PLANNER_REQUEST";
export const EDIT_MORNING_PLANNER_SUCCESS="EDIT_MORNING_PLANNER_SUCCESS";
export const EDIT_MORNING_PLANNER_FAILURE="EDIT_MORNING_PLANNER_FAILURE";

//edit evening reporting
export const EDIT_EVENING_REPORTING_REQUEST="EDIT_EVENING_REPORTING_REQUEST";
export const EDIT_EVENING_REPORTING_SUCCESS="EDIT_EVENING_REPORTING_SUCCESS";
export const EDIT_EVENING_REPORTING_FAILURE="EDIT_EVENING_REPORTING_FAILURE";

//get evening planners
export const GET_EVENING_REPORTING_REQUEST="GET_EVENING_REPORTING_REQUEST";
export const GET_EVENING_REPORTING_SUCCESS="GET_EVENING_REPORTING_SUCCESS";
export const GET_EVENING_REPORTING_FAILURE="GET_EVENING_REPORTING_FAILURE";

// change daily taks status
export const CHANGE_DAILY_TASK_STATUS_REQUEST =
  "CHANGE_DAILY_TASK_STATUS_REQUEST";
export const CHANGE_DAILY_TASK_STATUS_SUCCESS =
  "CHANGE_DAILY_TASK_STATUS_SUCCESS";
export const CHANGE_DAILY_TASK_STATUS_FAILURE =
  "CHANGE_DAILY_TASK_STATUS_FAILURE";

// whatsapp template register
export const WHATSAPP_TEMPLATE_REGISTER_REQUEST =
  "WHATSAPP_TEMPLATE_REGISTER_REQUEST";
export const WHATSAPP_TEMPLATE_REGISTER_SUCCESS =
  "WHATSAPP_TEMPLATE_REGISTER_SUCCESS";
export const WHATSAPP_TEMPLATE_REGISTER_FAILURE =
  "WHATSAPP_TEMPLATE_REGISTER_FAILURE";

// whatsapp send message
export const WHATSAPP_SEND_MESSAGE_REQUEST = "WHATSAPP_SEND_MESSAGE_REQUEST";
export const WHATSAPP_SEND_MESSAGE_SUCCESS = "WHATSAPP_SEND_MESSAGE_SUCCESS";
export const WHATSAPP_SEND_MESSAGE_FAILURE = "WHATSAPP_SEND_MESSAGE_FAILURE";

// whatsapp data
export const WHATSAPP_TEMPLATE_DATA_REQUEST = "WHATSAPP_TEMPLATE_DATA_REQUEST";
export const WHATSAPP_TEMPLATE_DATA_SUCCESS = "WHATSAPP_TEMPLATE_DATA_SUCCESS";
export const WHATSAPP_TEMPLATE_DATA_FAILURE = "WHATSAPP_TEMPLATE_DATA_FAILURE";

// general file upload
export const GENERAL_FILE_UPLOAD_REQUEST = "GENERAL_FILE_UPLOAD_REQUEST";
export const GENERAL_FILE_UPLOAD_SUCCESS = "GENERAL_FILE_UPLOAD_SUCCESS";
export const GENERAL_FILE_UPLOAD_FAILURE = "GENERAL_FILE_UPLOAD_FAILURE";

//send otp request
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

//whatsapp detail data
export const WHATSAPP_ACCOUNT_DETAIL_REQUEST =
  "WHATSAPP_ACCOUNT_DETAIL_REQUEST";
export const WHATSAPP_ACCOUNT_DETAIL_SUCCESS =
  "WHATSAPP_ACCOUNT_DETAIL_SUCCESS";
export const WHATSAPP_ACCOUNT_DETAIL_FAILURE =
  "WHATSAPP_ACCOUNT_DETAIL_FAILURE";

//whatsapp detail data
export const GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST =
  "GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST";
export const GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS =
  "GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS";
export const GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE =
  "GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE";

//document selection types
export const SELECT_ALL_DOCUMENT_CHECKBOX = "SELECT_ALL_DOCUMENT_CHECKBOX";
export const UNSELECT_ALL_DOCUMENT_CHECKBOX = "UNSELECT_ALL_DOCUMENT_CHECKBOX";
export const SELECT_DOCUMENT_CHECKBOX = "SELECT_DOCUMENT_CHECKBOX";
export const UNSELECT_DOCUMENT_CHECKBOX = "UNSELECT_DOCUMENT_CHECKBOX";
export const ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST =
  "ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST";

//download zip file
export const DOWNLOAD_ZIP_FILE_REQUEST = "DOWNLOAD_ZIP_FILE_REQUEST";
export const DOWNLOAD_ZIP_FILE_SUCCESS = "DOWNLOAD_ZIP_FILE_SUCCESS";
export const DOWNLOAD_ZIP_FILE_FAILURE = "DOWNLOAD_ZIP_FILE_FAILURE";

//account add
export const ADD_FIRM_REQUEST = "ADD_FIRM_REQUEST";
export const ADD_FIRM_SUCCESS = "ADD_FIRM_SUCCESS";
export const ADD_FIRM_FAILURE = "ADD_FIRM_FAILURE";

//account edit
export const UPDATE_FIRM_REQUEST = "UPDATE_FIRM_REQUEST";
export const UPDATE_FIRM_SUCCESS = "UPDATE_FIRM_SUCCESS";
export const UPDATE_FIRM_FAILURE = "UPDATE_FIRM_FAILURE";

//get account
export const GET_FIRM_REQUEST = "GET_FIRM_REQUEST";
export const GET_FIRM_SUCCESS = "GET_FIRM_SUCCESS";
export const GET_FIRM_FAILURE = "GET_FIRM_FAILURE";

//get account list
export const GET_FIRM_LIST_REQUEST = "GET_FIRM_LIST_REQUEST";
export const GET_FIRM_LIST_SUCCESS = "GET_FIRM_LIST_SUCCESS";
export const GET_FIRM_LIST_FAILURE = "GET_FIRM_LIST_FAILURE";

//update firm status
export const UPDATE_FIRM_STATUS_REQUEST = "UPDATE_FIRM_STATUS_REQUEST";
export const UPDATE_FIRM_STATUS_SUCCESS = "UPDATE_FIRM_STATUS_SUCCESS";
export const UPDATE_FIRM_STATUS_FAILURE = "UPDATE_FIRM_STATUS_FAILURE";

//account cash book add
export const ADD_RECORD_IN_CASH_BOOK_REQUEST =
  "ADD_RECORD_IN_CASH_BOOK_REQUEST";
export const ADD_RECORD_IN_CASH_BOOK_SUCCESS =
  "ADD_RECORD_IN_CASH_BOOK_SUCCESS";
export const ADD_RECORD_IN_CASH_BOOK_FAILURE =
  "ADD_RECORD_IN_CASH_BOOK_FAILURE";

//account cash book edit
export const UPDATE_RECORD_IN_CASH_BOOK_REQUEST =
  "UPDATE_RECORD_IN_CASH_BOOK_REQUEST";
export const UPDATE_RECORD_IN_CASH_BOOK_SUCCESS =
  "UPDATE_RECORD_IN_CASH_BOOK_SUCCESS";
export const UPDATE_RECORD_IN_CASH_BOOK_FAILURE =
  "UPDATE_RECORD_IN_CASH_BOOK_FAILURE";

// cashbook update status
export const UPDATE_CASH_BOOK_STATUS_REQUEST =
  "UPDATE_CASH_BOOK_STATUS_REQUEST";
export const UPDATE_CASH_BOOK_STATUS_SUCCESS =
  "UPDATE_CASH_BOOK_STATUS_SUCCESS";
export const UPDATE_CASH_BOOK_STATUS_FAILURE =
  "UPDATE_CASH_BOOK_STATUS_FAILURE";

//get account cash book
export const GET_RECORD_IN_CASH_BOOK_REQUEST =
  "GET_RECORD_IN_CASH_BOOK_REQUEST";
export const GET_RECORD_IN_CASH_BOOK_SUCCESS =
  "GET_RECORD_IN_CASH_BOOK_SUCCESS";
export const GET_RECORD_IN_CASH_BOOK_FAILURE =
  "GET_RECORD_IN_CASH_BOOK_FAILURE";

//get account cash book list
export const GET_RECORD_LIST_IN_CASH_BOOK_REQUEST =
  "GET_RECORD_LIST_IN_CASH_BOOK_REQUEST";
export const GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS =
  "GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS";
export const GET_RECORD_LIST_IN_CASH_BOOK_FAILURE =
  "GET_RECORD_LIST_IN_CASH_BOOK_FAILURE";

//account bank book add
export const ADD_RECORD_IN_BANK_BOOK_REQUEST =
  "ADD_RECORD_IN_BANK_BOOK_REQUEST";
export const ADD_RECORD_IN_BANK_BOOK_SUCCESS =
  "ADD_RECORD_IN_BANK_BOOK_SUCCESS";
export const ADD_RECORD_IN_BANK_BOOK_FAILURE =
  "ADD_RECORD_IN_BANK_BOOK_FAILURE";

//account bank book edit
export const UPDATE_RECORD_IN_BANK_BOOK_REQUEST =
  "UPDATE_RECORD_IN_BANK_BOOK_REQUEST";
export const UPDATE_RECORD_IN_BANK_BOOK_SUCCESS =
  "UPDATE_RECORD_IN_BANK_BOOK_SUCCESS";
export const UPDATE_RECORD_IN_BANK_BOOK_FAILURE =
  "UPDATE_RECORD_IN_BANK_BOOK_FAILURE";

//account bank book status update
export const UPDATE_BANK_BOOK_STATUS_REQUEST =
  "UPDATE_BANK_BOOK_STATUS_REQUEST";
export const UPDATE_BANK_BOOK_STATUS_SUCCESS =
  "UPDATE_BANK_BOOK_STATUS_SUCCESS";
export const UPDATE_BANK_BOOK_STATUS_FAILURE =
  "UPDATE_BANK_BOOK_STATUS_FAILURE";

//get account bank book
export const GET_RECORD_IN_BANK_BOOK_REQUEST =
  "GET_RECORD_IN_BANK_BOOK_REQUEST";
export const GET_RECORD_IN_BANK_BOOK_SUCCESS =
  "GET_RECORD_IN_BANK_BOOK_SUCCESS";
export const GET_RECORD_IN_BANK_BOOK_FAILURE =
  "GET_RECORD_IN_BANK_BOOK_FAILURE";

//get account bank book list
export const GET_RECORD_LIST_IN_BANK_BOOK_REQUEST =
  "GET_RECORD_LIST_IN_BANK_BOOK_REQUEST";
export const GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS =
  "GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS";
export const GET_RECORD_LIST_IN_BANK_BOOK_FAILURE =
  "GET_RECORD_LIST_IN_BANK_BOOK_FAILURE";

// add expense
export const ADD_EXPENSE_REQUEST = "ADD_EXPENSE_REQUEST";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_FAILURE = "ADD_EXPENSE_FAILURE";

// get expense
export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST";
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS";
export const GET_EXPENSE_FAILURE = "GET_EXPENSE_FAILURE";

// get expense list
export const GET_EXPENSE_LIST_REQUEST = "GET_EXPENSE_LIST_REQUEST";
export const GET_EXPENSE_LIST_SUCCESS = "GET_EXPENSE_LIST_SUCCESS";
export const GET_EXPENSE_LIST_FAILURE = "GET_EXPENSE_LIST_FAILURE";

// update expense
export const UPDATE_EXPENSE_REQUEST = "UPDATE_EXPENSE_REQUEST";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAILURE = "UPDATE_EXPENSE_FAILURE";

// update expense status
export const UPDATE_EXPENSE_STATUS_REQUEST = "UPDATE_EXPENSE_STATUS_REQUEST";
export const UPDATE_EXPENSE_STATUS_SUCCESS = "UPDATE_EXPENSE_STATUS_SUCCESS";
export const UPDATE_EXPENSE_STATUS_FAILURE = "UPDATE_EXPENSE_STATUS_FAILURE";

// add transaction
export const ADD_TRANSACTION_REQUEST = "ADD_TRANSACTION_REQUEST";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAILURE = "ADD_TRANSACTION_FAILURE";

// update transaction
export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAILURE = "UPDATE_TRANSACTION_FAILURE";

// get transaction
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";

// get transaction list
export const GET_TRANSACTION_LIST_REQUEST = "GET_TRANSACTION_LIST_REQUEST";
export const GET_TRANSACTION_LIST_SUCCESS = "GET_TRANSACTION_LIST_SUCCESS";
export const GET_TRANSACTION_LIST_FAILURE = "GET_TRANSACTION_LIST_FAILURE";

export const GET_TRANSACTION_REPORT_LIST_REQUEST =
  "GET_TRANSACTION_REPORT_LIST_REQUEST";
export const GET_TRANSACTION_REPORT_LIST_SUCCESS =
  "GET_TRANSACTION_REPORT_LIST_SUCCESS";
export const GET_TRANSACTION_REPORT_LIST_FAILURE =
  "GET_TRANSACTION_REPORT_LIST_FAILURE";
// add bill
export const ADD_BILL_REQUEST = "ADD_BILL_REQUEST";
export const ADD_BILL_SUCCESS = "ADD_BILL_SUCCESS";
export const ADD_BILL_FAILURE = "ADD_BILL_FAILURE";

//update bill
export const UPDATE_BILL_REQUEST = "UPDATE_BILL_REQUEST";
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS";
export const UPDATE_BILL_FAILURE = "UPDATE_BILL_FAILURE";

// get bill
export const GET_BILL_REQUEST = "GET_BILL_REQUEST";
export const GET_BILL_SUCCESS = "GET_BILL_SUCCESS";
export const GET_BILL_FAILURE = "GET_BILL_FAILURE";

// get bill list
export const GET_BILL_LIST_REQUEST = "GET_BILL_LIST_REQUEST";
export const GET_BILL_LIST_SUCCESS = "GET_BILL_LIST_SUCCESS";
export const GET_BILL_LIST_FAILURE = "GET_BILL_LIST_FAILURE";

export const GET_BILL_REPORT_LIST_REQUEST = "GET_BILL_REPORT_LIST_REQUEST";
export const GET_BILL_REPORT_LIST_SUCCESS = "GET_BILL_REPORT_LIST_SUCCESS";
export const GET_BILL_REPORT_LIST_FAILURE = "GET_BILL_REPORT_LIST_FAILURE";

export const SAVE_BILL_PDF_REQUEST = "SAVE_BILL_PDF_REQUEST";
export const SAVE_BILL_PDF_SUCCESS = "SAVE_BILL_PDF_SUCCESS";
export const SAVE_BILL_PDF_FAILURE = "SAVE_BILL_PDF_FAILURE";

// master data api
export const MASTER_DATA_REQUEST = "MASTER_DATA_REQUEST";
export const MASTER_DATA_SUCCESS = "MASTER_DATA_SUCCESS";
export const MASTER_DATA_FAILURE = "MASTER_DATA_FAILURE";

// add activity
export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILURE = "ADD_ACTIVITY_FAILURE";

//update activity
export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";

//get activity list
export const GET_ACTIVITY_LIST_REQUEST = "GET_ACTIVITY_LIST_REQUEST";
export const GET_ACTIVITY_LIST_SUCCESS = "GET_ACTIVITY_LIST_SUCCESS";
export const GET_ACTIVITY_LIST_FAILURE = "GET_ACTIVITY_LIST_FAILURE";

// get activity
export const GET_ACTIVITY_REQUEST = "GET_ACTIVITY_REQUEST";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAILURE = "GET_ACTIVITY_FAILURE";

// get planner activity
export const GET_PLANNER_ACTIVITY_REQUEST = "GET_planner_ACTIVITY_REQUEST";
export const GET_PLANNER_ACTIVITY_SUCCESS = "GET_planner_ACTIVITY_SUCCESS";
export const GET_PLANNER_ACTIVITY_FAILURE = "GET_planner_ACTIVITY_FAILURE";


//get activity by article
export const GET_ACTIVITY_BY_ARTICLE_REQUEST =
  "GET_ACTIVITY_BY_ARTICLE_REQUEST";
export const GET_ACTIVITY_BY_ARTICLE_SUCCESS =
  "GET_ACTIVITY_BY_ARTICLE_SUCCESS";
export const GET_ACTIVITY_BY_ARTICLE_FAILURE =
  "GET_ACTIVITY_BY_ARTICLE_FAILURE";

//change bill status
export const CHANGE_BILL_STATUS_REQUEST = "CHANGE_BILL_STATUS_REQUEST";
export const CHANGE_BILL_STATUS_SUCCESS = "CHANGE_BILL_STATUS_SUCCESS";
export const CHANGE_BILL_STATUS_FAILURE = "CHANGE_BILL_STATUS_FAILURE";

//add chat
export const ADD_CHAT_MSG_REQUEST = "ADD_CHAT_MSG_REQUEST";
export const ADD_CHAT_MSG_SUCCESS = "ADD_CHAT_MSG_SUCCESS";
export const ADD_CHAT_MSG_FAILURE = "ADD_CHAT_MSG_FAILURE";

//get chat list
export const GET_CHAT_MSG_LIST_REQUEST = "GET_CHAT_MSG_LIST_REQUEST";
export const GET_CHAT_MSG_LIST_SUCCESS = "GET_CHAT_MSG_LIST_SUCCESS";
export const GET_CHAT_MSG_LIST_FAILURE = "GET_CHAT_MSG_LIST_FAILURE";

//get open chat id
export const GET_OPEN_CHAT_ID_REQUEST = "GET_OPEN_CHAT_ID_REQUEST";
export const GET_OPEN_CHAT_ID_SUCCESS = "GET_OPEN_CHAT_ID_SUCCESS";
export const GET_OPEN_CHAT_ID_FAILURE = "GET_OPEN_CHAT_ID_FAILURE";

//websocket
export const ADD_DATA_SOCKET_STATE = "ADD_DATA_SOCKET_STATE";
export const ADD_CHAT_LIST_DATA_SOCKET_STATE =
  "ADD_CHAT_LIST_DATA_SOCKET_STATE";
export const ADD_SOCKET_INSTANCE = "ADD_SOCKET_INSTANCE";

//add proforma invoice
export const ADD_PROFORMA_INVOICE_REQUEST = "ADD_PROFORMA_INVOICE_REQUEST";
export const ADD_PROFORMA_INVOICE_SUCCESS = "ADD_PROFORMA_INVOICE_SUCCESS";
export const ADD_PROFORMA_INVOICE_FAILURE = "ADD_PROFORMA_INVOICE_FAILURE";

//update proforma invoice
export const UPDATE_PROFORMA_INVOICE_REQUEST =
  "UPDATE_PROFORMA_INVOICE_REQUEST";
export const UPDATE_PROFORMA_INVOICE_SUCCESS =
  "UPDATE_PROFORMA_INVOICE_SUCCESS";
export const UPDATE_PROFORMA_INVOICE_FAILURE =
  "UPDATE_PROFORMA_INVOICE_FAILURE";

//get proforma invoice
export const GET_PROFORMA_INVOICE_REQUEST = "GET_PROFORMA_INVOICE_REQUEST";
export const GET_PROFORMA_INVOICE_SUCCESS = "GET_PROFORMA_INVOICE_SUCCESS";
export const GET_PROFORMA_INVOICE_FAILURE = "GET_PROFORMA_INVOICE_FAILURE";

//get proforma invoice list
export const GET_PROFORMA_INVOICE_LIST_REQUEST =
  "GET_PROFORMA_INVOICE_LIST_REQUEST";
export const GET_PROFORMA_INVOICE_LIST_SUCCESS =
  "GET_PROFORMA_INVOICE_LIST_SUCCESS";
export const GET_PROFORMA_INVOICE_LIST_FAILURE =
  "GET_PROFORMA_INVOICE_LIST_FAILURE";

//convert to final invoice
export const FINAL_PROFORMA_INVOICE_REQUEST = "FINAL_PROFORMA_INVOICE_REQUEST";
export const FINAL_PROFORMA_INVOICE_SUCCESS = "FINAL_PROFORMA_INVOICE_SUCCESS";
export const FINAL_PROFORMA_INVOICE_FAILURE = "FINAL_PROFORMA_INVOICE_FAILURE";
