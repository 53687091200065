import { dailyTaskData } from "app/components/pages/dailyTask/DailyTaskForm";
import { failure, request, success } from "../dispatchFunctions";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addDailyTaskService,
  changeDailyTaskIdService,
  getDailyTaskListService,
  getDailyTaskService,
  updateDailyTaskService,
} from "./dailyTaskService";
import {
  ADD_DAILY_TASK_FAILURE,
  ADD_DAILY_TASK_REQUEST,
  ADD_DAILY_TASK_SUCCESS,
  CHANGE_DAILY_TASK_STATUS_FAILURE,
  CHANGE_DAILY_TASK_STATUS_REQUEST,
  CHANGE_DAILY_TASK_STATUS_SUCCESS,
  EDIT_DAILY_TASK_FAILURE,
  EDIT_DAILY_TASK_REQUEST,
  EDIT_DAILY_TASK_SUCCESS,
  GET_BY_ID_DAILY_TASK_FAILURE,
  GET_BY_ID_DAILY_TASK_REQUEST,
  GET_BY_ID_DAILY_TASK_SUCCESS,
  GET_DAILY_TASK_LIST_FAILURE,
  GET_DAILY_TASK_LIST_REQUEST,
  GET_DAILY_TASK_LIST_SUCCESS,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";

export const addDailyTask = (
  data: dailyTaskData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_DAILY_TASK_REQUEST));

    await addDailyTaskService(data).then(
      (result: any) => {
        dispatch(success(ADD_DAILY_TASK_SUCCESS, result));
        showToastMessageSuccess("Task added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_DAILY_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const updateDailyTask = (
  data: dailyTaskData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_DAILY_TASK_REQUEST));

    await updateDailyTaskService(data).then(
      (result: any) => {
        dispatch(success(EDIT_DAILY_TASK_SUCCESS, result));
        showToastMessageSuccess("Task updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            EDIT_DAILY_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const getDailyTask = (userNumber: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_BY_ID_DAILY_TASK_REQUEST));

    getDailyTaskService(userNumber).then(
      (result: any) => {
        dispatch(success(GET_BY_ID_DAILY_TASK_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_BY_ID_DAILY_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const getDailyTaskList = () => {
  return (dispatch: any) => {
    dispatch(request(GET_DAILY_TASK_LIST_REQUEST));

    getDailyTaskListService().then(
      (result: any) => {
        dispatch(success(GET_DAILY_TASK_LIST_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_DAILY_TASK_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const changeDailyTaskStatus = (
  dailyTaskId: number,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(CHANGE_DAILY_TASK_STATUS_REQUEST));

    changeDailyTaskIdService(dailyTaskId).then(
      (result: any) => {
        dispatch(success(CHANGE_DAILY_TASK_STATUS_SUCCESS, dailyTaskId));
        callback();
      },
      (error: any) => {
        dispatch(
          failure(
            CHANGE_DAILY_TASK_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        callback();
      }
    );
  };
};
