import { FC } from 'react'

import Avatar from '../../../commonComponent/Avatar'
import { getColor } from '../../../../helpers/helperFunction'

interface props {
  email: string
  lastName: string
  firstName: string
}



const UserInfoCell: FC<props> = ({ email, lastName, firstName }) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-40px overflow-hidden me-4'>
        <div>
          <div
            className="w-35px h-35px"
          // className={clsx(
          //   'symbol-label fs-3',
          //   `bg-light-primary`,
          //   `text-primary`
          // )}
          >
            <Avatar
              firstName={firstName}
              lastName={lastName}
              size={50} 
              color={getColor(lastName).color}
              background={getColor(lastName).background}/>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 text-hover-primary mb-1 fw-bold'>
          {firstName} {lastName}
        </div>
        <span>{email}</span>
      </div>
    </div>
  )
}

export { UserInfoCell }
