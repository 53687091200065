import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import MultiSelectDropDown from "app/components/commonComponent/dropDown/MultiSelectDropDown";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { proformaInvoiceInitialValue, task } from "../ProformaInvoiceForm";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getProformaInvoiceList } from "app/reducers/proformaInvoice/proformaInvoiceAction";
import { searchParams } from "app/helpers/commonInterface";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  initialValue: proformaInvoiceInitialValue;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  closeModel: Function;
  updateInvoiceDispatch: Function;
  proformaInvoiceListDispatch: Function;
  proformaInvoiceData: any;
}

const getTaskBySelectedClient = (
  taskList: task[],
  ClientIds: { value: number; name: string }[]
) => {
  if (taskList && taskList?.length && ClientIds.length) {
    return taskList.filter((task: task) =>
      ClientIds.some(
        (client: any) => String(client.value) === String(task.ClientId)
      )
    );
  }
  return [];
};

const EditProformaInvoice: FC<props> = ({
  initialValue,
  masterDataList,
  closeModel,
  updateInvoiceDispatch,
  proformaInvoiceListDispatch,
  proformaInvoiceData,
}) => {
  const params = useParams();
  const { searchObj } = useGetSearchObject();
  const [showAddNewTaskForm, setShowAddNewTaskForm] = useState(false);
  const [selectedClients, setSelectedClients] = useState<
    { value: string | undefined; name: string | null }[]
  >([]);
  const getClientByAddedTask = useMemo(() => {
    if (
      masterDataList.masterData?.data?.records?.Clients?.length &&
      proformaInvoiceData?.data?.records?.Tasks?.length
    ) {
      return masterDataList.masterData.data.records.Clients.filter(
        (client: any) =>
          proformaInvoiceData?.data?.records?.Tasks.some(
            (task: any) => String(task.ClientId) === String(client.Id)
          )
      );
    }
    return [];
  }, [proformaInvoiceData]);
  const billAddedTask = useMemo(() => {
    if (proformaInvoiceData?.data?.records?.Tasks?.length) {
      return proformaInvoiceData?.data?.records?.Tasks?.filter(
        (task: any) =>
          String(proformaInvoiceData?.data?.records?.ClientId) !==
          String(task.ClientId)
      );
    }
    return [];
  }, [proformaInvoiceData]);
  const taskBySelectedClients = useMemo(() => {
    if (
      masterDataList.masterData?.data?.records?.Tasks &&
      masterDataList.masterData?.data?.records?.Tasks?.length &&
      selectedClients.length
    ) {
      return masterDataList.masterData?.data?.records?.Tasks?.filter(
        (task: task) =>
          selectedClients.some(
            (client: any) => String(client.value) === String(task.ClientId)
          )
      );
    }
    return [];
  }, [selectedClients]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValue}
        // validationSchema={billValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          const getTaskIds = [
            ...values.TasksIds.map((task: { value: number; name: string }) =>
              Number(task.value)
            ),
            ...values.NewTaskIds.map(
              (newTask: { value: number; name: string }) =>
                Number(newTask.value)
            ),
          ];
          await updateInvoiceDispatch(
            { ...values, TasksIds: getTaskIds },
            () => {
              closeModel();
              !params.id && proformaInvoiceListDispatch(searchObj);
            }
          );
          resetForm();
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => {
          return (
            <Form noValidate className="form">
              <Modal.Body>
                <div className="">
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Firm
                    </label>

                    <div className="col-lg-9 fv-row">
                      <DropDown
                        className={`text-start form-control form-control-lg form-control-solid form-select ${
                          masterDataList.loading
                            ? "display-dropdown-loader"
                            : ""
                        }`}
                        placeholder="Select Firm"
                        options={masterDataList.masterData?.data?.records?.Firms?.map(
                          (firm: { Id: number; Name: string }) => {
                            return { value: Number(firm.Id), name: firm.Name };
                          }
                        )}
                        displayLoader={masterDataList.loading}
                        setFieldValue={setFieldValue}
                        name="FirmId"
                        defaultValue={{
                          value: values.FirmId,
                          name: values.FirmName,
                        }}
                        disabled={isSubmitting}
                        showSearch={true}
                      />
                      <ErrorMessage
                        name="FirmId"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Discount
                    </label>

                    <div className="col-lg-9">
                      <Field
                        type="number"
                        placeholder="Discount"
                        className={`form-control form-control-lg form-control-solid
                        ${
                          touched.Discount &&
                          errors.Discount &&
                          "is-invalid inValidBorder"
                        }`}
                        name="Discount"
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name="Discount"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Client
                    </label>

                    <div className="col-lg-9">
                      <DropDown
                        className={`text-start form-control form-control-lg form-control-solid form-select ${
                          masterDataList.loading
                            ? "display-dropdown-loader"
                            : ""
                        }`}
                        placeholder="Select Client"
                        displayLoader={masterDataList.loading}
                        options={getClientByAddedTask.map((client: any) => {
                          return {
                            value: Number(client.Id),
                            name: client.Name,
                          };
                        })}
                        setFieldValue={setFieldValue}
                        name="ClientId"
                        currentValue={{
                          value: values.ClientId,
                        }}
                        defaultValue={{
                          value: values.ClientId,
                          name: values.ClientName,
                        }}
                        showSearch={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                      Tasks
                    </label>

                    <div className="col-lg-9 fv-row">
                      <MultiSelectDropDown
                        className={`text-start form-control form-control-lg form-control-solid form-select ${
                          masterDataList.loading
                            ? "display-dropdown-loader"
                            : ""
                        }`}
                        placeholder="Select Tasks"
                        options={[
                          ...billAddedTask,
                          ...getTaskBySelectedClient(
                            masterDataList.masterData?.data?.records?.Tasks,
                            [
                              {
                                value: values.ClientId,
                                name: values.ClientName,
                              },
                            ]
                          ),
                        ].map((task: task) => {
                          return {
                            value: Number(task.Id),
                            name: task.TaskCode,
                          };
                        })}
                        currentValue={values.TasksIds.map(
                          (task: { value: number; name: string }) => ({
                            value: Number(task.value),
                            name: task.name,
                          })
                        )}
                        setFieldValue={setFieldValue}
                        name="TasksIds"
                        defaultValue={values.TasksIds}
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name="TasksIds"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>

                  <div className="row mb-6">
                    {!showAddNewTaskForm && (
                      <div>
                        <button
                          className="btn btn-light-primary btn-sm"
                          onClick={() => setShowAddNewTaskForm(true)}
                          disabled={isSubmitting}
                        >
                          <i className="fa-solid fa-plus"></i>
                          Add New Task
                        </button>
                      </div>
                    )}
                  </div>
                  {showAddNewTaskForm && (
                    <>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Client
                        </label>

                        <div className="col-lg-9 fv-row">
                          <MultiSelectDropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${
                              masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                            }`}
                            placeholder="Select Clients"
                            options={masterDataList.masterData?.data?.records?.Clients?.map(
                              (client: { Id: number; Name: string }) => {
                                return {
                                  value: Number(client.Id),
                                  name: client.Name,
                                };
                              }
                            )}
                            currentValue={values.ClientIds.map(
                              (client: { value: number; name: string }) => ({
                                value: Number(client.value),
                                name: client.name,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            name="ClientIds"
                            disabled={isSubmitting}
                            apiCallDispatch={(e: any) =>
                              setSelectedClients([
                                ...selectedClients,
                                {
                                  value: (e.target as HTMLLIElement).dataset.id,
                                  name: (e.target as HTMLLIElement).textContent,
                                },
                              ])
                            }
                          />
                          <ErrorMessage
                            name="ClientIds"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          New Tasks
                        </label>

                        <div className="col-lg-9 fv-row">
                          <MultiSelectDropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${
                              masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                            }`}
                            placeholder="Select Tasks"
                            options={taskBySelectedClients.map(
                              (task: task) => ({
                                value: task.Id,
                                name: task.TaskCode,
                              })
                            )}
                            currentValue={values.NewTaskIds.map(
                              (task: { value: number; name: string }) => ({
                                value: Number(task.value),
                                name: task.name,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            name="NewTaskIds"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="NewTaskIds"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-end pt-6 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => closeModel()}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    className="btn btn-primary"
                    name="Submit"
                  />
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    proformaInvoiceData: state.proformaInvoice.proformaInvoice,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    proformaInvoiceListDispatch: (searchObj: searchParams) =>
      dispatch(getProformaInvoiceList(searchObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProformaInvoice);
