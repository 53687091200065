import React, { FC, ReactNode } from 'react';

interface Props {
  id: string;
  header: string;
  body: string;
  data: ReactNode;
}

const SingleAccordion: FC<Props> = ({ id, header, body ,data}) => {
  return (
    <div className='accordion mt-6' id={id}>
      <div className='accordion-item'>
        <h2 className='accordion-header' id={`${id}_header`}>
          <button
            className='accordion-button collapsed fs-3'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${id}_body`}
            aria-expanded='false'
            aria-controls={`${id}_body`}
            style={{fontWeight:'500'}}
          >
            {header}
          </button>
        </h2>
        <div
          id={`${id}_body`}
          className='accordion-collapse collapse'
          aria-labelledby={`${id}_header`}
          data-bs-parent={`#${id}`}
        >
          <div className='accordion-body'>
            <>{data}</>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAccordion;
