import ErrorBoundary from "app/helpers/ErrorBoundary";
import { convert } from "app/helpers/helperFunction";
import { FC, useState } from "react";
import { GeneratePdfByClick } from "../../billing/component/GetUrlForPdf";
import { KTSVG } from "_metronic/helpers";
import { downloadFile } from "app/reducers/task/taskAction";
import { connect } from "react-redux";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";

interface props {
  billDetails: any;
  editBillClickFun?: Function;
  addToFinalClickFun?: Function;
  downloadFileDispatch: Function;
}

const ProformaInvoiceBillDetail: FC<props> = ({
  billDetails,
  editBillClickFun,
  addToFinalClickFun,
  downloadFileDispatch,
}) => {
  const [loading, setLoading] = useState(false);
  // console.log(billDetails);

  return (
    <ErrorBoundary>
      <div className="card h-100">
        <div className="card-header pt-5 align-items-center border-0">
          <div className="card-title flex-column">
            <div className="fw-bold">Invoice Details</div>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            {editBillClickFun && (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => editBillClickFun()}
              >
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />
                Edit Invoice
              </button>
            )}
            {PAYMENT_STATUS["Proforma Generated"] ===
            billDetails.PaymentStatus ? (
              <GeneratePdfByClick
                billData={billDetails}
                title="PROFORMA INVOICE"
                className="btn btn-sm btn-primary"
              />
            ) : (
              <button
                type="button"
                className="btn btn-sm btn-primary"
                title="Download"
                onClick={() => {
                  setLoading(true);
                  downloadFileDispatch(
                    { id: billDetails.Id, destination: "bill" },
                    `${billDetails.BillNumber}.${"pdf"}`,
                    () => {
                      setLoading(false);
                    }
                  );
                }}
              >
                {loading ? (
                  <>
                    <div className="text-gray-600">
                      <SmallLoader />
                    </div>
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-download"></i> Download Pdf
                  </>
                )}
              </button>
            )}
            {addToFinalClickFun && (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => addToFinalClickFun()}
              >
                Generate Final Invoice
              </button>
            )}
          </div>
        </div>
        <div className="card-body pt-6">
          <div className="align-items-center d-flex flex-column flex-sm-row gap-5 h-100 justify-content-between">
            <div className="w-100">
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-xxl-4">
                  Invoice No
                </div>
                <div className="col-7">{billDetails?.BillNumber || "-"}</div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-xxl-4">
                  Date
                </div>
                <div className="col-7">
                  {billDetails?.Date ? convert(billDetails.Date) : "-"}
                </div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-xxl-4">
                  Client Name
                </div>
                <div className="col-7">{billDetails?.ClientName || "-"}</div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-xxl-4">
                  PAN No
                </div>
                <div className="col-7">
                  {billDetails?.Firm?.PANNumber || "-"}
                </div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-xxl-4">
                  Address
                </div>
                <div className="col-7">{billDetails?.Firm?.Address || "-"}</div>
              </div>
            </div>
            <div className="border border-1 border-dashed border-gray-300 d-none d-sm-block h-100 me-md-5 me-xl-10 w-1px"></div>
            <div className="w-100">
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-sm-6">
                  SubTotal
                </div>
                <div className="col-6">
                  {billDetails?.TotalAmountWithoutTax || "0"}
                </div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-sm-6">
                  Discount
                </div>
                <div className="col-6">{billDetails?.Discount || "0"}</div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="fw-semibold text-gray-600 col-5 col-sm-6">
                  Tax Amount
                </div>

                <div className="col-6">
                  {billDetails?.TotalTaxAmount || "0"}
                </div>
              </div>
              <div className="row mb-4 align-items-center fw-bold fs-6">
                <div className="text-gray-600 col-5 col-sm-6">Total Amount</div>

                <div className="col-6">{billDetails?.TotalAmount || "0"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      callback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, callback)),
  };
};

export default connect(null, mapDispatchToProps)(ProformaInvoiceBillDetail);
