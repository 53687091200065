import { WithChildren } from "_metronic/helpers";
import { FC, createContext, useContext, useState } from "react";

interface socketChatState {
  companyId: number;
  taskId: string;
  CreatedAt: Date;
  UserId: string;
  FirstName: string;
  LastName: string;
}

interface socketDataChangeState {
  companyId: number;
  UserId: string;
  IsMasterDataChange: boolean;
}

interface socketData {
  chatMessage: Map<string, socketChatState[]>;
  dataChangeMessage: socketDataChangeState;
  socket: WebSocket | null;
}

interface initialState extends socketData {
  addChatListData: (chatList: socketChatState[], taskId: string) => void;
  addReceivedMessageData: (
    receivedData: socketChatState | socketDataChangeState,
    taskId: string
  ) => void;
  addSocketInstance: (socketInstance: WebSocket) => void;
}

const initialState: initialState = {
  chatMessage: new Map<string, []>(),
  dataChangeMessage: { companyId: 0, UserId: "", IsMasterDataChange: false },
  socket: null,
  addChatListData: (chatList: socketChatState[], taskId: string) => {},
  addReceivedMessageData: (
    receivedData: socketChatState | socketDataChangeState,
    taskId: string
  ) => {},
  addSocketInstance: (socketInstance: WebSocket) => {},
};

const websocketContext = createContext<initialState>(initialState);

const useWebSocketContext = () => {
  return useContext(websocketContext);
};

const WebSocketProvider: FC<WithChildren> = ({ children }) => {
  const [socketData, setSocketData] = useState<socketData>({
    chatMessage: new Map<string, []>(),
    dataChangeMessage: { companyId: 0, UserId: "", IsMasterDataChange: false },
    socket: null,
  });
  const addSocketInstance = (socketInstance: WebSocket) => {
    setSocketData({ ...socketData, socket: socketInstance });
  };

  const addReceivedMessageData = (
    receivedData: socketChatState | socketDataChangeState,
    taskId: string
  ) => {
    if ("IsMasterDataChange" in receivedData) {
      setSocketData({ ...socketData, dataChangeMessage: receivedData });
    } else if ("taskId" in receivedData) {
      let temp = socketData;
      if (temp.chatMessage.has(taskId)) {
        let chatData = temp.chatMessage.get(taskId) || [];
        temp.chatMessage.set(taskId, [...chatData, receivedData]);
      } else {
        temp.chatMessage.set(taskId, [receivedData]);
      }
      setSocketData(temp);
    }
  };

  const addChatListData = (chatList: socketChatState[], taskId: string) => {
    let temp = socketData;
    temp.chatMessage.set(taskId, chatList);
    setSocketData(temp);
  };

  return (
    <websocketContext.Provider
      value={{
        addSocketInstance,
        addReceivedMessageData,
        addChatListData,
        ...socketData,
      }}
    >
      {children}
    </websocketContext.Provider>
  );
};

export { WebSocketProvider, useWebSocketContext };
