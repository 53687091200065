import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  FINAL_PROFORMA_INVOCE_API_ENDPOINT,
  GET_ALL_PROFORMA_INVOCE_API_ENDPOINT,
  PROFORMA_INVOCE_API_ENDPOINT,
} from "app/helpers/config";

export const addProformaInvoiceService = async (data: any) => {
  return await axiosInstance.post(PROFORMA_INVOCE_API_ENDPOINT, data);
};

export const getProformaInvoiceListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ALL_PROFORMA_INVOCE_API_ENDPOINT}?${queryString}`
  );
};

export const getProformaInvoiceService = (invoiceId: number) => {
  return axiosInstance.get(`${PROFORMA_INVOCE_API_ENDPOINT}?id=${invoiceId}`);
};

export const updateProformaInvoiceService = async (data: any) => {
  return await axiosInstance.put(PROFORMA_INVOCE_API_ENDPOINT, data);
};

export const convertFinalProformaInvoiceService = async (billId: number) => {
  return await axiosInstance.get(
    `${FINAL_PROFORMA_INVOCE_API_ENDPOINT}?id=${billId}`
  );
};
