import { response } from "app/helpers/commonInterface";
import {
  GET_ASSIGNED_TASK_FAILURE,
  GET_ASSIGNED_TASK_REQUEST,
  GET_ASSIGNED_TASK_SUCCESS,
  GET_PLANNER_TASK_FAILURE,
  GET_PLANNER_TASK_REQUEST,
  GET_PLANNER_TASK_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface plannerTaskDataState {
  loading: boolean;
  plannerTask: response<taskData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: plannerTaskDataState = {
  loading: false,
  plannerTask: {},
  error: "",
};

export const getplannerTaskReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_PLANNER_TASK_REQUEST:
      return {
        loading: true,
      };
    case GET_PLANNER_TASK_SUCCESS:
      return {
        loading: false,
        plannerTask: action.payload,
        error: "",
      };
    case GET_PLANNER_TASK_FAILURE:
      return {
        loading: false,
        plannerTask: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
