import {
  MASTER_DATA_FAILURE,
  MASTER_DATA_REQUEST,
  MASTER_DATA_SUCCESS,
} from "../actionTypes";
import { failure, request, success } from "../dispatchFunctions";
import { GetErrorMessage } from "../stateHelperFunction";
import { getMasterDataService } from "./masterDataService";

export const getMasterData = () => {
  return (dispatch: any) => {
    dispatch(request(MASTER_DATA_REQUEST));

    getMasterDataService().then(
      (result: any) => dispatch(success(MASTER_DATA_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(MASTER_DATA_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const updateMasterData = (
  type: string,
  payloadData: { masterType: string; data: any }
) => {
  return (dispatch: any) => {
    dispatch(success(type, payloadData));
  };
};
