import { connect } from "react-redux";
import { Form, Formik, FormikValues } from "formik";
import { FC, useRef, useState } from "react";

import DropDown from "app/components/commonComponent/dropDown/DropDown";
import FilterButton from "app/components/commonComponent/tableFilter/FilterButton";
import { getFilterQueryString } from "app/helpers/helperFunction";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const OPTIONS = [
  { value: "true", name: "Active" },
  { value: "false", name: "Deactive" },
];

const ClientFilter: FC<props> = ({ masterDataList }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`card shadow-sm w-300px w-md-325px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <Formik
            enableReinitialize
            initialValues={filterObj}
            onSubmit={async (values: any) => {
              setSearchParam({
                ...searchObj,
                PageNumber: "1",
                Filters: getFilterQueryString(values),
              });
            }}
          >
            {({ isSubmitting, setFieldValue, values, resetForm }) => {
              return (
                <Form noValidate className="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Group</label>
                    <DropDown
                      className={`text-start form-control form-control-lg form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder="Select Group"
                      displayLoader={masterDataList.loading}
                      options={masterDataList.masterData?.data?.records?.Groups?.map(
                        (group: { Id: number; Name: string }) => {
                          return { value: group.Id, name: group.Name };
                        }
                      )}
                      setFieldValue={setFieldValue}
                      name="GroupId"
                      currentValue={{
                        value: (values as FormikValues).GroupId,
                        name: masterDataList.masterData?.data?.records?.Groups?.find(
                          (group: { Id: number; Name: string }) =>
                            String(group.Id) === String(values.GroupId)
                        )?.Name,
                      }}
                      defaultValue={{
                        value: (values as FormikValues).GroupId,
                        name: masterDataList.masterData?.data?.records?.Groups?.find(
                          (group: { Id: number; Name: string }) =>
                            String(group.Id) === String(values.GroupId)
                        )?.Name,
                      }}
                      showSearch={true}
                    />
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Status</label>
                    <DropDown
                      className={`text-start form-control form-control-lg form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder="Select Status"
                      displayLoader={masterDataList.loading}
                      options={OPTIONS}
                      setFieldValue={setFieldValue}
                      name="IsActive"
                      currentValue={{
                        value: values.IsActive,
                      }}
                      defaultValue={{
                        value: values.IsActive,
                        name:
                          values.IsActive === "true" ? "Active" : "Deactive",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <ResetButton
                      name="Reset"
                      className="btn btn-light btn-active-light-primary btn-sm"
                      onClickCallback={() => {
                        resetHandler(resetForm);
                        setShowDropDown(false);
                      }}
                    />
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      name="Apply"
                      className="btn btn-primary btn-sm"
                      onClickCallback={() => setShowDropDown(false)}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

export default connect(mapStateToProps)(ClientFilter);
