
import {
    GET_CHATNOTIFICTION_TASK_LIST_FAILURE,
    GET_CHATNOTIFICTION_TASK_LIST_REQUEST,
  GET_CHATNOTIFICTION_TASK_LIST_SUCCESS,
  GET_TASK_BY_ID_FAILURE,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";
import { response } from "app/helpers/commonInterface";

export interface chatNotificationTasksDataState {
  loading: boolean;
  chatNotificationTasks: response<taskData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: chatNotificationTasksDataState = {
  loading: false,
  chatNotificationTasks: {},
  error: "",
};

export const getChatNotificationReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_CHATNOTIFICTION_TASK_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_CHATNOTIFICTION_TASK_LIST_SUCCESS:
      return {
        loading: false,
        chatNotificationTasks: action.payload,
        error: "",
      };
    case GET_CHATNOTIFICTION_TASK_LIST_FAILURE:
      return {
        loading: false,
        chatNotificationTasks: {},
        error: action.payload,
      };

    default:
      return state;
  }
};
