import {
  ErrorMessage,
  Field,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import { FC } from "react";

import { addUserInitialValue } from "../AddUser";
import DropDown from "../../../commonComponent/dropDown/DropDown";

interface props {
  touched: FormikTouched<addUserInitialValue>;
  errors: FormikErrors<addUserInitialValue>;
  setFieldValue: any;
  values: FormikValues;
  isSubmitting: boolean;
}

const OPTIONS = [
  { value: "saving", name: "Saving" },
  { value: "current", name: "Current" },
];

const BankDetailsForm: FC<props> = ({
  isSubmitting,
  values,
  touched,
  errors,
  setFieldValue,
}) => {
  return (
    <div className="d-flex flex-column gap-5 gap-md-7">
      <div className="fs-3 fw-semibold mb-n2">Bank Details</div>
      <div className="row">
      <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Account Number
          </label>
          <div className="">
            <Field
              type="number"
              className={`form-control form-control-lg form-control-solid ${
                touched.accountNumber &&
                errors.accountNumber &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Account Number"
              id="accountNumber"
              name="accountNumber"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="accountNumber"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Bank Name
          </label>
          <div className="">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.bankName &&
                errors.bankName &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Bank Name"
              id="bankName"
              name="bankName"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="bankName"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            IFSC Code
          </label>
          <div className="">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.ifscCode &&
                errors.ifscCode &&
                "is-invalid inValidBorder"
              }`}
              placeholder="IFSC Code"
              id="ifscCode"
              name="ifscCode"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="ifscCode"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Branch Name
          </label>
          <div className="">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.branchName &&
                errors.branchName &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Branch Name"
              id="branchName"
              name="branchName"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="branchName"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Account Type
          </label>
          <div className="">
            <DropDown
              className="text-start form-control form-control-lg form-control-solid form-select"
              placeholder="Select Account Type"
              options={OPTIONS}
              setFieldValue={setFieldValue}
              name="accountType"
              currentValue={{ value: values.accountType}}
              defaultValue={{value: values.accountType,name:OPTIONS.filter((x:any)=>x.value == values.accountType)[0] != null ? OPTIONS.filter((x:any)=>x.value == values.accountType)[0].name:'Saving'}}
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="accountType"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BankDetailsForm;
