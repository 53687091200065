import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { adminHomePage } from "../../main/admin/AdminMain";
import { clientListPage } from "../ClientList";
import { emptyObject } from "../../../../helpers/helperFunction";
import { getClientById } from "../../../../reducers/client/clientAction";
import { KTCard } from "../../../../../_metronic/helpers";
import { PageLink, PageSubTitle, PageTitle } from "../../../../../_metronic/layout/core";
import Table from "app/components/commonComponent/table/Table";
import { TASK_CONTACT_PERSON_DETAIL_PAGE } from "app/helpers/routes";
import { taskListPage } from "../../task/TaskList";

const HEADERS = [
  {
    columnName: "Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Email",
    id: "Email",
    className: "min-w-125px",
  },
  {
    columnName: "Phone Number",
    id: "Phone Number",
    className: "min-w-125px",
  },
];

interface props {
  getClientData: Function;
  clientDetail: any;
}

interface clientContact {
  row: {
    Name: string;
    Email: string;
    MobileNumber: string;
  };
}

const ContactPersonTable: FC<props> = ({ getClientData, clientDetail }) => {
  const params = useParams();
  const location = useLocation();

  const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    location.pathname.includes(TASK_CONTACT_PERSON_DETAIL_PAGE)
      ? taskListPage
      : clientListPage,
    emptyObject,
  ];

  useEffect(() => {
    if (params.id) {
      getClientData(params.id);
    }
  }, [getClientData, params.id]);
  const ClientContactTableRow: FC<clientContact> = ({ row }) => {
    return (
      <tr>
        <td className="text-dark fw-bold ps-2">{row.Name}</td>
        <td>
          <a href={`mailto:${row.Email}`}>{row.Email}</a>
        </td>
        <td>
          <a href={`tel:${row.MobileNumber}`}>{row.MobileNumber}</a>
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Contact Persons</PageTitle>
      <PageSubTitle>{clientDetail?.client?.data?.records?.Name|| ""}</PageSubTitle>

      <KTCard>
        <Table
          checkBoxColumn={{ show: false }}
          error={clientDetail.error}
          headers={HEADERS}
          success={clientDetail.client?.success}
          loading={clientDetail.loading}
          paginate={{ show: false }}
          tableData={clientDetail.client?.data?.records?.ContactPersons}
          tableRow={(row: any, index: number) => (
            <ClientContactTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    clientDetail: state.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientData: (clientId: number) => dispatch(getClientById(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonTable);
