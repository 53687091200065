import { whatsappDataValue } from "app/components/pages/settings/whatsappDetails/WhatsappDetailsForm";
import { axiosInstance } from "../axiosInstance";
import { wpInitialValueData } from "app/components/pages/settings/templateRegister/TemplateRegister";
import { searchParams } from "app/helpers/commonInterface";
import {
  USER_LIST_API_ENDPOINT,
  WHATSAPP_SEND_MESSAGE_API_ENDPOINT,
  WHATSAPP_TEMPLATE_LIST_API_ENDPOINT,
  WHATSAPP_TEMPLATE_REGISTER_API_ENDPOINT,
} from "app/helpers/config";
import { getSearchQueryString } from "app/helpers/helperFunction";

export const whatsappTemplateRegisterService = async (
  data: wpInitialValueData
) => {
  return await axiosInstance.post(
    WHATSAPP_TEMPLATE_REGISTER_API_ENDPOINT,
    data
  );
};

export const whatsappSendMessageService = async (data: any) => {
  return await axiosInstance.post(WHATSAPP_SEND_MESSAGE_API_ENDPOINT, data);
};

export const whatsappTemplateDataService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${WHATSAPP_TEMPLATE_LIST_API_ENDPOINT}?${queryString}`
  );
};

export const whatsappAccountDetailService = (wpData: whatsappDataValue) => {
  return axiosInstance.put(USER_LIST_API_ENDPOINT, wpData);
};

export const getWhatsappAccountDetailService = (companyId: number) => {
  return axiosInstance.get(`${USER_LIST_API_ENDPOINT}/${companyId}`);
};
