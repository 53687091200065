import { ChangeEvent, FC } from "react";
import { initialActivityModal } from "../ActivityForm";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { absolutePath } from "app/helpers/relativePath";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { adminHomePage } from "../../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import { object, string } from "yup";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { activityListPage } from "../ActivityList";
import { ACTIVITY_LIST } from "app/helpers/routes";
import { ACTIVITY_STATUS } from "app/helpers/constants";

interface props {
  title: string;
  activityDispatch: Function;
  initialValue: initialActivityModal;
  masterDataList: any;
  activityDetails: {
    AssigneeId: string;
    CreatorId: string;
    Id: number;
    IsActive: boolean;
    Name: string;
    PersonAssigned: string;
  };
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  activityListPage,
  emptyObject,
];

const validateFun = object().shape({
  name: string().required("Activity name is required"),
  assigneeId: string().required("Article is required"),
});

const ACTIVITY_STATUS_OPTION = [
  { value: ACTIVITY_STATUS.Assigned, name: "Assigned" },
  { value: ACTIVITY_STATUS.Completed, name: "Completed" },
];

const AddActivity: FC<props> = ({
  masterDataList,
  title,
  activityDispatch,
  initialValue,
  activityDetails,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            await activityDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(ACTIVITY_LIST));
                  }
                : () => {
                    resetForm();
                  }
            );
          }}
        >
          {({ touched, errors, isSubmitting, setFieldValue, values }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Activity Name
                      </label>
                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid ${
                            touched.name &&
                            errors.name &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Activity Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Article
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Article"
                          displayLoader={masterDataList.loading}
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.Article?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name="assigneeId"
                          currentValue={{
                            value: (values as FormikValues).assigneeId,
                          }}
                          defaultValue={{
                            value: activityDetails
                              ? activityDetails.AssigneeId
                              : "",
                            name: activityDetails
                              ? activityDetails.PersonAssigned
                              : "",
                          }}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="assigneeId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    {params.id && (
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6">
                          Status
                        </label>

                        <div className="fv-row">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Status"
                            options={ACTIVITY_STATUS_OPTION}
                            setFieldValue={setFieldValue}
                            name="status"
                            defaultValue={{
                              value: (values as FormikValues).status,
                              name: ACTIVITY_STATUS[
                                (values as FormikValues).status
                              ],
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6">
                        Description
                      </label>
                      <div className="">
                        <textarea
                          className={`form-control form-control-lg form-control-solid ${
                            touched.description &&
                            errors.description &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Description"
                          id="description"
                          name="description"
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setFieldValue("description", e.target.value)
                          }
                          value={values.description}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    {params.id && (
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6">
                          Remark
                        </label>
                        <div className="">
                          <textarea
                            className={`form-control form-control-lg form-control-solid ${
                              touched.remark &&
                              errors.remark &&
                              "is-invalid inValidBorder"
                            }`}
                            placeholder="Remark"
                            id="remark"
                            name="remark"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              setFieldValue("remark", e.target.value)
                            }
                            value={values.remark}
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(absolutePath(ACTIVITY_LIST))
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddActivity;
