import { TRANSACTION_TYPE } from "app/helpers/constants";
import {
  convert,
  getDetailsAgainstTransaction,
} from "app/helpers/helperFunction";
import { FC } from "react";

interface props {
  row: any;
}

const TransactionReportTableRow: FC<props> = ({ row }) => {
  return (
    <tr>
      <td className="text-dark fw-bold ps-2">
        {row?.Type ? TRANSACTION_TYPE[Number(row.Type)] : "-"}
      </td>
      <td>
        <div className="overflow-auto mh-75px vertical-scroll mw-375px d-flex flex-column">
          {row.Type !== TRANSACTION_TYPE.Other && (
            <span className="text-dark fw-semibold">
              {getDetailsAgainstTransaction(row).value}
            </span>
          )}
          <span className="text-gray-500 fw-semibold">
            {row?.Narration || "-"}
          </span>
        </div>
      </td>
      <td>{row?.FirmName || "-"}</td>
      <td>{row?.BookName || "-"}</td>
      <td>{row?.ClientName || "-"}</td>
      <td>{row?.TransactionDate ? convert(row.TransactionDate) : "-"}</td>
      <td>
        {!row?.IsPayment ? (
          <span className="fw-semibold text-success">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `+${row.Amount}`
              : "-"}
          </span>
        ) : (
          <span className="fw-semibold text-danger">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `-${row.Amount}`
              : "-"}
          </span>
        )}
      </td>
    </tr>
  );
};

export default TransactionReportTableRow;
