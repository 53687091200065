import { axiosInstance } from "../axiosInstance";
import { dailyTaskData } from "app/components/pages/dailyTask/DailyTaskForm";
import {
  DAILY_TASK_API_ENDPOINT,
  DAILY_TASK_LIST_API_ENDPOINT,
  GET_DAILY_TASK_BY_ASSIGNEE_API_ENDPOINT,
  MARK_DAILY_TASK_COMPLETED,
} from "../../helpers/config";

export const addDailyTaskService = async (data: dailyTaskData) => {
  return await axiosInstance.post(DAILY_TASK_API_ENDPOINT, data);
};
export const updateDailyTaskService = async (data: dailyTaskData) => {
  return await axiosInstance.put(DAILY_TASK_API_ENDPOINT, data);
};
export const getDailyTaskService = (userNumber: number) => {
  return axiosInstance.get(
    `${GET_DAILY_TASK_BY_ASSIGNEE_API_ENDPOINT}?userNumber=${userNumber}`
  );
};
export const getDailyTaskListService = () => {
  return axiosInstance.get(DAILY_TASK_LIST_API_ENDPOINT);
};
export const changeDailyTaskIdService = (dailyTaskId: number) => {
  return axiosInstance.put(`${MARK_DAILY_TASK_COMPLETED}?id=${dailyTaskId}`);
};
