import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { convert } from "app/helpers/helperFunction";
import { BILL_REPORT_DETAILS } from "app/helpers/routes";
import { billData } from "app/reducers/billing/billingDataInterface";
import { downloadFile } from "app/reducers/task/taskAction";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GeneratePdfByClick } from "./GetUrlForPdf";

interface props {
  row: billData;
  downloadFileDispatch: Function;
}

const BillingReportTableRow: FC<props> = ({ downloadFileDispatch, row }) => {
  const [loading, setLoading] = useState(false);
  return (
    <tr>
      <td className="text-dark fw-bold ps-2">
        <Link
          to={`${BILL_REPORT_DETAILS}/${row.Id}`}
          className="text-dark text-hover-primary"
        >
          {row.BillNumber}
        </Link>
      </td>
      <td className="">{row.ClientName}</td>
      <td className="">
        {row.Tasks.map((task: { TaskCode: string }) => task.TaskCode).join(
          ","
        ) || "-"}
      </td>
      <td className="">{row?.Firm?.Name || "-"}</td>
      <td className="">{row.TotalAmount}</td>
      <td className="">{row.PaidAmount}</td>
      <td>{row?.Date ? convert(row.Date) : "-"}</td>
      <td>
        <div className={`badge badge-sm bill-status-${row.PaymentStatus}`}>
          {PAYMENT_STATUS[row.PaymentStatus]}
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-end pe-5">
          {PAYMENT_STATUS["Proforma Generated"] === row.PaymentStatus ? (
            <GeneratePdfByClick
              billData={row}
              showIcon={true}
              title="PROFORMA INVOICE"
              className="btn btn-bg-light btn-sm btn-icon btn-active-light-primary"
            />
          ) : (
            <button
              type="button"
              className="btn btn-bg-light btn-sm btn-icon btn-active-light-primary"
              title="Download"
              onClick={() => {
                setLoading(true);
                downloadFileDispatch(
                  { id: row.Id, destination: "bill" },
                  `${row.BillNumber}.${"pdf"}`,
                  () => {
                    setLoading(false);
                  }
                );
              }}
            >
              {loading ? (
                <>
                  <div className="text-gray-600">
                    <SmallLoader />
                  </div>
                </>
              ) : (
                <i className="fa-solid fa-download"></i>
                // <ImageDisplay
                //   path="media/task/download-arrow.png"
                //   altText="download-arrow"
                //   className="w-20px"
                //   errorPath="/media/task/download-arrow.png"
                // />
              )}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      callback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, callback)),
  };
};

export default connect(null, mapDispatchToProps)(BillingReportTableRow);
