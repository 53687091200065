import { connect } from "react-redux";
import { FC } from "react";

import { convert, getRole } from "../../../../helpers/helperFunction";
import { updateUserStatus } from "../../../../reducers/user/userAction";
import StatusCell from "../../../commonComponent/table/StatusCell";
import UserActionsCell from "../../../commonComponent/table/UserActionsCell";
import { UserInfoCell } from "./UserInfoCell";
import { KTSVG } from "_metronic/helpers";
import { EDIT_USER } from "app/helpers/routes";
import { absolutePath } from "../../../../helpers/relativePath";
import { useNavigate } from "react-router-dom";

interface row {
  Roles: Array<string>;
  JoiningDate: string;
  FirstName: string;
  Email: string;
  LastName: string;
  IsActive: boolean;
  Id: number;
  RegistrationDate: string;
  Work: Array<string>;
  ReportingAuthorityId:string;
}

type Props = {
  row: row;
  updateListStatus: Function;
};

const CustomRow: FC<Props> = ({ row, updateListStatus }) => {
  const updateList = (id: number, callBack: Function) => {
    updateListStatus(id, row.IsActive, callBack);
  };

  const navigate = useNavigate();
 
const editTaskHandler = (id:any) => {
  navigate(absolutePath(EDIT_USER), {
    state: { id: id }
  });
};

  return (
    <tr>
      <td className="ps-2">
        <UserInfoCell
          firstName={row.FirstName}
          lastName={row.LastName}
          email={row.Email}
        />
      </td>
      <td>{getRole(row.Roles)}</td>
      <td>{convert(row.RegistrationDate)}</td>
      <td>{convert(row.JoiningDate)}</td>
      <td>{row.Work.length ? row.Work.join("  ,  ") : "-"}</td>
      <td>{row.ReportingAuthorityId ? row.ReportingAuthorityId : "-"}</td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td className="text-end pe-2">
      <button
          className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
          title="Edit"
          onClick={()=>editTaskHandler(row.Id)}
          style={{marginRight:'10px'}}
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </button>
        <UserActionsCell
          updateStatusDispatch={updateList}
          isActive={row?.IsActive}
          id={row?.Id}
        />
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateListStatus: (
      userId: number,
      activeStatus: boolean,
      successCallback: Function
    ) => dispatch(updateUserStatus(userId, activeStatus, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(CustomRow);
