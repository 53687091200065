import { GET_DOCUMENT_TYPE_FAILURE, GET_DOCUMENT_TYPE_REQUEST, GET_DOCUMENT_TYPE_SUCCESS } from "../actionTypes"

export interface taskState {
    loading  : boolean,
    documentType : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:taskState = {
    loading  : false,
    documentType : {},
    error : ""
}

export const getDocumentTypeByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_DOCUMENT_TYPE_REQUEST : return {
            loading : true
        }
        case GET_DOCUMENT_TYPE_SUCCESS : 
        return {
            loading : false,
            documentType : action.payload,
            error : ""
        }
        case GET_DOCUMENT_TYPE_FAILURE : return {
            loading : false,
            documentType : {},
            error : action.payload
        }
        default : return state
    }
}