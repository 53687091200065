import { connect } from 'react-redux'
import { FC } from 'react'

import { absolutePath } from '../../../../../helpers/relativePath'
import { EDIT_CATEGORY_TYPES } from '../../../../../helpers/routes'
import { getCategory } from '../../../../../reducers/category/categoryAction'
import { KTSVG } from '../../../../../../_metronic/helpers'
import StatusCell from '../../../../commonComponent/table/StatusCell'
import { useNavigate, useParams } from 'react-router-dom'
import UserActionsCell from '../../../../commonComponent/table/UserActionsCell'
import { updateTaskTypeStatus } from '../../../../../reducers/taskType/taskTypeAction'

interface props {
    row: {Id : number, IsActive : boolean, Name : string}
    updateTaskTypeStatusDispatch: Function
    getCategoryData : Function
}

const TypeTableRow: FC<props> = ({ row, updateTaskTypeStatusDispatch, getCategoryData }) => {
    const params = useParams();
    const navigate = useNavigate()

    const editRecord = () => {
        navigate(absolutePath(`${EDIT_CATEGORY_TYPES}/${row.Id}`))
    }

    const updateList = (id: number) => {
        updateTaskTypeStatusDispatch(row.Id, () => { getCategoryData(params.id)})
    }

    return (
        <tr>
            <td className='text-dark fw-bold ps-2'>{row?.Name || "-"}</td>
            <td><StatusCell isActive={row.IsActive} /></td>
            <td className='d-flex gap-3 justify-content-end pe-5'>
                <button
                    className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
                    title="Edit"
                    onClick={editRecord}
                >
                    <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-3" />
                </button>
                <UserActionsCell updateStatusDispatch={updateList} isActive={row?.IsActive} id={row?.Id} />
            </td>
        </tr>
    )
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTaskTypeStatusDispatch: (typeId: number, successCallback : Function) => dispatch(updateTaskTypeStatus(typeId, successCallback)),
        getCategoryData: (categoryId: number) => dispatch(getCategory(categoryId)),
    }
}

export default connect(null, mapDispatchToProps)(TypeTableRow)