import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { FC, Fragment } from "react";

import {
  selectedTemplateComponent,
  selectedTemplateData,
  selectedTemplateInitailValue,
} from "../ClientMessagePopup";
import { selectedContactPerson } from "app/components/pages/task/component/TaskGrouping";
import { whatsappSendMessage } from "app/reducers/whatsapp/whatsappAction";
import WPTemplateBody from "./WPTemplateBody";
import WPTemplateFooter from "./WPTemplateFooter";
import WPTemplateHeader from "./WPTemplateHeader";
import { ResetButton, SubmitButton } from "../../buttons/Buttons";

interface props {
  whatsappSendMessageDispatch: Function;
  selectedData: selectedTemplateData | undefined;
  contactsArr: Array<selectedContactPerson>;
  closeModal: Function;
}

const getInitailValue = (
  selectedTemplate: selectedTemplateData | undefined
): selectedTemplateInitailValue => {
  if (selectedTemplate) {
    return {
      name: selectedTemplate.name,
      components: [
        {
          contactNumber: "string",
          headerParameter: {
            type: selectedTemplate.components
              .find(
                (component: selectedTemplateComponent) =>
                  component.type === "HEADER"
              )
              ?.format?.toLowerCase(),
            value: "",
          },
          bodyParameters: [],
        },
      ],
      contactsArr: [],
    };
  }
  return {
    name: "",
    components: [
      {
        contactNumber: "string",
        headerParameter: {
          type: "",
          value: "",
        },
        bodyParameters: [],
      },
    ],
    contactsArr: [],
  };
};

// Array.from(document.getElementsByClassName("form-text"))
//     .reduce((acc: any, element: any) => {
//         acc[element.name] = Yup.string().required("This field is required")
//         return acc;
//     }, {}),
// const validateFun = Yup.object().shape({
//     contactsArr: Yup.array().min(1, "Contact Person is required")
// })

const WPTemplateForm: FC<props> = ({
  whatsappSendMessageDispatch,
  selectedData,
  closeModal,
  contactsArr,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={getInitailValue(selectedData)}
      // validationSchema={validateFun}
      onSubmit={async (values, { resetForm }) => {
        let updatedData = values;
        const { header, body } = Array.from(
          document.getElementsByClassName("form-text")
        ).reduce(
          (acc: any, element: any) => {
            if (element.classList.contains("header-text")) {
              return { ...acc, header: element.value };
            }
            return { ...acc, body: [...acc.body, element.value] };
          },
          { header: "", body: [] }
        );
        if (header) {
          updatedData = {
            ...updatedData,
            components: [
              {
                ...updatedData.components[0],
                headerParameter: {
                  ...updatedData.components[0].headerParameter,
                  value: header,
                },
              },
            ],
          };
        }
        if (body.length) {
          updatedData = {
            ...updatedData,
            components: [
              {
                ...updatedData.components[0],
                bodyParameters: body,
              },
            ],
          };
        }
        const updatedComponents = contactsArr?.map(
          (contact: selectedContactPerson) => {
            return {
              ...updatedData.components[0],
              contactNumber: contact.MobileNumber,
            };
          }
        );

        await whatsappSendMessageDispatch(
          { ...updatedData, components: updatedComponents },
          () => {
            resetForm();
            closeModal();
          }
        );
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form noValidate className="form">
            <div className="">
              {selectedData?.components.map(
                (component: selectedTemplateComponent, index: number) => (
                  <Fragment key={index}>
                    {component.type === "HEADER" && (
                      <WPTemplateHeader component={component} />
                    )}
                    {component.type === "BODY" && (
                      <WPTemplateBody component={component} />
                    )}
                    {component.type === "FOOTER" && (
                      <WPTemplateFooter component={component} />
                    )}
                  </Fragment>
                )
              )}
            </div>
            <div className="d-flex justify-content-end gap-3 pt-4">
              <ResetButton
                name="Cancel"
                className="btn btn-light btn-active-light-primary"
                onClickCallback={() => closeModal()}
              />
              <SubmitButton
                isSubmitting={isSubmitting}
                name="Submit"
                className="btn btn-primary"
                disabled={selectedData === undefined}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    whatsappSendMessageDispatch: (
      data: selectedTemplateInitailValue,
      successCallback: Function
    ) => dispatch(whatsappSendMessage(data, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(WPTemplateForm);
