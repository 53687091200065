import { getSearchQueryString } from "app/helpers/helperFunction"
import { axiosInstance } from "../axiosInstance"
import { searchParams } from "app/helpers/commonInterface"
import { addFirmValue } from "app/components/pages/firmMaster/FirmMasterForm"
import { FIRM_API_ENDPOINT, GET_ALL_FIRM_API_ENDPOINT } from "app/helpers/config"

export const addFirmService = async (data: addFirmValue) => {
    return await axiosInstance.post(FIRM_API_ENDPOINT, data)
}

export const getFirmListService = (searchObj: searchParams) => {
    const queryString = getSearchQueryString(searchObj)
    return axiosInstance.get(`${GET_ALL_FIRM_API_ENDPOINT}?${queryString}`)
}

export const getFirmService = (firmId: number) => {
    return axiosInstance.get(`${FIRM_API_ENDPOINT}?id=${firmId}`)
}

export const updateFirmService = async (data: addFirmValue) => {
    return await axiosInstance.put(FIRM_API_ENDPOINT, data)
}

export const updateFirmStatusService = (firmId: number) => {
    return axiosInstance.delete(`${FIRM_API_ENDPOINT}?id=${firmId}`)
}