import { connect } from "react-redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import { EDIT_EXPENSE } from "app/helpers/routes";
import { updateExpenseStatus } from "app/reducers/expense/expenseAction";
import { KTSVG } from "_metronic/helpers";
import StatusCell from "app/components/commonComponent/table/StatusCell";
import UserActionsCell from "app/components/commonComponent/table/UserActionsCell";

interface props {
  row: { Name: string; Id: number; IsActive: boolean };
  updateStatusDispatch: Function;
}

const ExpenseTableRow: FC<props> = ({ row, updateStatusDispatch }) => {
  const navigate = useNavigate();

  const editButtonHandler = () => {
    navigate(absolutePath(`${EDIT_EXPENSE}/${row.Id}`));
  };

  const handleUpdateStatus = (id: number, callBack: Function) => {
    updateStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.Name || "-"}</td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td className="text-end pe-5">
        <button
          className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary me-5`}
          title="Edit"
          onClick={editButtonHandler}
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </button>
        <UserActionsCell
          updateStatusDispatch={handleUpdateStatus}
          isActive={row?.IsActive}
          id={row?.Id}
        />
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateStatusDispatch: (expenseId: number, successCallback: Function) =>
      dispatch(updateExpenseStatus(expenseId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(ExpenseTableRow);
