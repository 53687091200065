import { response } from "app/helpers/commonInterface";
import {
  GET_FILE_REPORT_LIST_FAILURE,
  GET_FILE_REPORT_LIST_REQUEST,
  GET_FILE_REPORT_LIST_SUCCESS,
} from "../actionTypes";

export interface clientDocument {
  Id: number;
  TypeId: number;
  DocumentType: string;
  Path: string;
  TaskId: number;
  DateAdded: string;
  StartDate: string;
  EndDate: string;
  MimeType: string;
  UploadedBy: string;
  DocumentTime: string;
  ClientName: string;
  TaskCode: string;
  Changes: [
    {
      Id: number;
      Message: string;
      ChangedBy: string;
      Date: string;
      Review: {};
    }
  ];
}

export interface documentReportState {
  loading: boolean;
  documentReportDetails: response<clientDocument[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: documentReportState = {
  loading: false,
  documentReportDetails: {},
  error: "",
};

export const clientDocumentsReportListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_FILE_REPORT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FILE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documentReportDetails: action.payload,
        error: "",
      };
    case GET_FILE_REPORT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        documentReportDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
