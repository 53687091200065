import { GET_RECORD_IN_CASH_BOOK_FAILURE, GET_RECORD_IN_CASH_BOOK_REQUEST, GET_RECORD_IN_CASH_BOOK_SUCCESS } from "../actionTypes"

export interface cashBookDataState {
    loading  : boolean,
    cashBook : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:cashBookDataState = {
    loading  : false,
    cashBook : {},
    error : ""
}

export const getByIdCashBookReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_RECORD_IN_CASH_BOOK_REQUEST : return {
            loading : true
        }
        case GET_RECORD_IN_CASH_BOOK_SUCCESS : 
        return {
            loading : false,
            cashBook : action.payload,
            error : ""
        }
        case GET_RECORD_IN_CASH_BOOK_FAILURE : return {
            loading : false,
            cashBook : {},
            error : action.payload
        }
        default : return state
    }
}