import { object, string } from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";

import { addCategoryValue } from "../CategoryForm";
import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import { categoryListPage } from "../CategoryList";
import { CATEGORY_LIST } from "../../../../helpers/routes";
import { emptyObject } from "../../../../helpers/helperFunction";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

interface props {
  initialValue: addCategoryValue;
  categoryDispatch: Function;
  title: string;
  isUpdate: string | null | undefined;
}

const validateFun = object().shape({
  name: string().required("Category name is required"),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  categoryListPage,
  emptyObject,
];

const AddCategory: FC<props> = ({
  categoryDispatch,
  initialValue,
  title,
  isUpdate,
}) => {
  const navigate = useNavigate();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            await categoryDispatch(
              values,
              isUpdate
                ? () => {
                    navigate(absolutePath(CATEGORY_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="d-flex flex-column col-lg-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Category Name
                      </label>

                      <div className="fv-row">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid ${
                            touched.name &&
                            errors.name &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Category Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(absolutePath(CATEGORY_LIST))
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddCategory;
