import { GET_GROUP_BY_ID_FAILURE, GET_GROUP_BY_ID_REQUEST, GET_GROUP_BY_ID_SUCCESS } from "../actionTypes"


export interface groupDataState {
    loading  : boolean,
    group : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:groupDataState = {
    loading  : false,
    group : {},
    error : ""
}

export const getGroupByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_GROUP_BY_ID_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_GROUP_BY_ID_SUCCESS : 
        return {
            loading : false,
            group : action.payload,
            error : ""
        }
        case GET_GROUP_BY_ID_FAILURE : return {
            loading : false,
            group : {},
            error : action.payload
        }
        default : return state
    }
}