import { KTSVG } from "_metronic/helpers";
import { FC, MouseEventHandler } from "react";

interface resetButtonProps {
  onClickCallback: MouseEventHandler<HTMLButtonElement>;
  name: string;
  className: string;
  disabled?: boolean;
  svgicon?: { path: string; class?: string };
  fonticon?: string;
}

interface submitButtonProps {
  onClickCallback?: MouseEventHandler<HTMLButtonElement>;
  isSubmitting: boolean;
  name: string;
  className: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  svgicon?: { path: string; class?: string };
  fonticon?: string;
}

export const ResetButton: FC<resetButtonProps> = ({
  disabled,
  className,
  onClickCallback,
  name,
  svgicon,
  fonticon,
}) => {
  return (
    <button
      type="button"
      className={className}
      onClick={onClickCallback}
      disabled={disabled}
    >
      {svgicon && (
        <span>
          <KTSVG
            path={svgicon.path}
            className={svgicon.class || "svg-icon svg-icon-3"}
          />
        </span>
      )}
      {fonticon && (
        <span>
          <i className={fonticon}></i>
        </span>
      )}
      {name}
    </button>
  );
};

export const SubmitButton: FC<submitButtonProps> = ({
  type,
  onClickCallback,
  className,
  isSubmitting,
  name,
  disabled,
  svgicon,
  fonticon,
}) => {
  return (
    <button
      type={type || "submit"}
      className={className}
      disabled={isSubmitting || disabled}
      onClick={onClickCallback}
    >
      {!isSubmitting && (
        <div>
          {svgicon && (
            <span>
              <KTSVG
                path={svgicon.path}
                className={svgicon.class || "svg-icon svg-icon-3"}
              />
            </span>
          )}
          {fonticon && (
            <span>
              <i className={fonticon}></i>
            </span>
          )}
          {name}
        </div>
      )}
      {isSubmitting && (
        <span className="indicator-progress" style={{ display: "block" }}>
          Please wait...{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
};

export const SmallLoader = () => {
  return (
    <span className="indicator-progress" style={{ display: "block" }}>
      <span className="spinner-border spinner-border-sm align-middle"></span>
    </span>
  );
};
