import { GET_DAILY_MORNING_PLANNER_REQUEST, GET_DAILY_MORNING_PLANNER_SUCCESS, GET_DAILY_MORNING_PLANNER_FAILURE, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS, SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE } from "../actionTypes"

interface dailyMorningPlannerListState {
    sendLoading:boolean,
    loading  : boolean,
    dailyMorningPlannerList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:dailyMorningPlannerListState = {
    sendLoading:false,
    loading  : false,
    dailyMorningPlannerList : {},
    error : ""
}

export const dailyMorningPlannerReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_DAILY_MORNING_PLANNER_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_DAILY_MORNING_PLANNER_SUCCESS : return {
            ...state,
            loading : false,
            dailyMorningPlannerList : action.payload,
            error : ""
        }
        case GET_DAILY_MORNING_PLANNER_FAILURE : return {
            ...state,
            loading : false,
            dailyMorningPlannerList : {},
            error : action.payload
        }
        case SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_REQUEST : return {
            ...state,
            sendLoading : true
        }
        case SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_SUCCESS : 
        return {
            ...state,
            sendLoading : false,
            dailyMorningPlannerList : action.payload,
            error : ""
        }
        case SENT_EVENING_REPORTING_FOR_MORNING_PLANNER_FAILURE : return {
            ...state,
            sendLoading : false,
            dailyMorningPlannerList : {},
            error : action.payload
        }
      
       
        default : return state
    }
}