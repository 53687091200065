import { adminHomePage } from '../main/admin/AdminMain'
import { absolutePath } from '../../../helpers/relativePath'
import { ADD_TASK_TYPE, TASK_TYPE_LIST } from '../../../helpers/routes'
import { emptyObject } from '../../../helpers/helperFunction'
import { KTCard } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import TaskTypeFilter from './component/TaskTypeFilter'
import TaskTypeTable from './component/TaskTypeTable'
import UsersListSearchComponent from '../../commonComponent/table/UsersListSearchComponent'
import UsersListToolbar from '../../commonComponent/table/UsersListToolbar'

export const taskTypeListPage = {
    title: 'Task Types',
    path: absolutePath(TASK_TYPE_LIST),
    isSeparator: true,
    isActive: false,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

function TaskTypeList() {
    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Task Types</PageTitle>
            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListSearchComponent />
                <TaskTypeFilter/>
                <UsersListToolbar buttons={[{ title: "Add Task Type", to: ADD_TASK_TYPE }]} />
            </div>
            <KTCard>
                <TaskTypeTable />
            </KTCard>
        </>
    )
}

export default TaskTypeList