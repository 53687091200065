import { ErrorMessage, Field, FormikState, FormikValues, useFormikContext } from "formik"

const TemplateFooter = () => {
    const { touched, errors }: FormikState<FormikValues> = useFormikContext();

    return (
        <div className="row mt-3">
            <label className="col-form-label fw-semibold fs-6">Footer</label>

            <div className="col-12 col-xl-9  fv-row">
                <Field
                    type="text"
                    className={`form-control form-control-lg form-control-solid
                    ${touched.footer && errors.footer && "is-invalid inValidBorder"}`}
                    placeholder="Footer"
                    id="footer"
                    name="footer"
                />
                <ErrorMessage name="footer" component="div" className="errorMsg" />
            </div>
        </div>
    )
}

export default TemplateFooter