import { connect } from "react-redux";
import { Children, FC, ReactElement, ReactNode, useMemo, useState } from "react"
import { Form, Formik, FormikConfig, FormikHelpers, FormikState, FormikValues, useFormikContext } from "formik"
import { object, string } from "yup";

import { ResetButton, SubmitButton } from "app/components/commonComponent/buttons/Buttons";
import { SETTINGS, TEMPLATE } from "app/helpers/routes";
import { TemplateRegisterValidateFun } from "./FormComponent/TemplateRegisterValidation";
import TemplateForm from "./FormComponent/TemplateForm"
import TemplateDetailForm from "./FormComponent/TemplateDetailForm"
import { useNavigate } from "react-router-dom";
import { WithChildren } from "_metronic/helpers"
import { whatsappTemplateRegister } from "app/reducers/whatsapp/whatsappAction";

export interface formikData extends FormikState<FormikValues>, FormikHelpers<FormikValues> { }

interface FormikStepProps extends Pick<FormikConfig<FormikValues>, 'validationSchema'> {
    children: ((props: formikData) => ReactNode)
}

// extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'>

const FormikComponent = ({ children, initialValues, onSubmit }: FormikConfig<FormikValues> & WithChildren) => {
    const childrenArr = Children.toArray(children)
    const [step, setStep] = useState<number>(0);
    const currentChild = useMemo(() => childrenArr[step], [step]) as ReactElement<FormikStepProps>

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values, helpers) => {
                if (childrenArr.length - 1 === step) {
                    // add api call
                    onSubmit(values, helpers)
                }
                else {
                    setStep((step: number) => step + 1)
                    helpers.setTouched({});
                }
            }}
            validationSchema={currentChild.props.validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <>{currentChild}</>
                        <div className="d-flex align-items-center justify-content-end gap-3 py-6">
                            <ResetButton name="Back"
                                className="btn btn-light btn-active-light-primary"
                                onClickCallback={() => setStep((step: number) => step - 1)}
                                disabled={step === 0} />
                            <SubmitButton className='btn btn-primary' isSubmitting={isSubmitting}
                                name={childrenArr.length - 1 === step ? "Submit" : "Next"} />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export const FormStep = ({ children }: FormikStepProps) => {
    const formikData: formikData = useFormikContext()
    return <>{children(formikData)}</>
}

export interface buttonObjInterface {
    id: number,
    type: string,
    text: string,
    phone_number: string,
    url: string
}
export const buttonObjData: buttonObjInterface = {
    id: 0,
    type: "",
    text: "",
    phone_number: "",
    url: ""
}

export interface wpInitialValueData {
    category: string,
    name: string,
    // language: string,
    header: {
        format: string,
        text: string,
        example: Array<string>,
        mediaFormat: string,
        file: string
    },
    body: {
        text: string,
        example: Array<string>
    },
    footer: string,
    buttonType: string,
    buttons: Array<buttonObjInterface>
}

const initialValue = {
    category: "utility",
    name: "",
    // language: "en-US",
    header: {
        format: "none",
        text: "",
        example: [],
        mediaFormat: "",
        // file: ""
    },
    body: {
        text: "",
        example: []
    },
    footer: "",
    buttonType: "none",
    buttons: []
}

interface props {
    whatsappTemplateRegisterDispatch: Function
}

const TemplateRegister: FC<props> = ({ whatsappTemplateRegisterDispatch }) => {
    const navigate = useNavigate();

    return (
        <div className="card p-7">
            <FormikComponent initialValues={initialValue}
                onSubmit={async (values, helpers) => {
                    const data = { ...values, header: { ...values.header, format: values.header.mediaFormat || "text" } }
                    await whatsappTemplateRegisterDispatch(data, () => { helpers.resetForm(); navigate(`${SETTINGS}/${TEMPLATE}`) })
                }}
            >
                <FormStep
                    validationSchema={object().shape({
                        name: string().required("Template Name is required")
                    })}>
                    {(formikData: formikData) => <TemplateForm formikData={formikData} />}
                </FormStep>
                <FormStep
                    validationSchema={TemplateRegisterValidateFun}>
                    {(formikData: formikData) => <TemplateDetailForm formikData={formikData} />}
                </FormStep>
            </FormikComponent>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        whatsappTemplateRegisterDispatch: (data: wpInitialValueData, successCallback: Function) =>
            dispatch(whatsappTemplateRegister(data, successCallback))
    }
}

export default connect(null, mapDispatchToProps)(TemplateRegister)