import DOMPurify from "dompurify";
import { Document, Page, pdfjs } from 'react-pdf';
import { FormikState, FormikValues, useFormikContext } from "formik";
import { FC, ReactNode, useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import { addFileObject } from "app/helpers/commonInterface";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface props {
    pdfFile: { file: File, details: addFileObject } | null
}

const TemplatePreview: FC<props> = ({ pdfFile }) => {
    const { values }: FormikState<FormikValues> = useFormikContext()
    const [pdfPreview, setPdfPreview] = useState<ReactNode>(null)

    useEffect(() => {
        setPdfPreview(
            (values.header.format === "media" && values.header.mediaFormat === "document") ?
                <Document file={pdfFile?.file ? URL.createObjectURL(pdfFile.file) : ""}>
                    <Page pageNumber={1} renderTextLayer={false} scale={10} />
                </Document> :
                <></>
        )
    }, [pdfFile])

    return (
        <div className="">
            <div className="rounded-1 card shadow-sm w-250px min-h-20px p-3">

                {/* Header Start*/}
                <div className="template-preview-wp-header fw-bold">
                    {values.header.format == "text" &&
                        <div>
                            {values.header.text}
                        </div>
                    }
                    {(values.header.format === "media" && values.header.mediaFormat === "image") &&
                        <div className="">
                            <ImageDisplay
                                path={pdfFile?.file ? URL.createObjectURL(pdfFile.file) : ""}
                                altText="uploadedImage"
                                errorPath="/media/svg/avatars/blank.svg"
                                className="img-fit-to-div"
                            />
                        </div>
                    }
                    {(values.header.format === "media" && values.header.mediaFormat === "document") &&
                        <div className="">
                            {pdfPreview}
                        </div>
                    }
                    {values.header.format == "none" && <></>}
                </div>
                {/* Header End*/}


                {/* Body Start*/}
                <div className="body-preview-wp"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(values.body.text) }}></div>
                {/* Body End*/}


                {/* Footer Start*/}
                <div className="d-flex justify-content-between align-items-center text-gray-400 py-2">
                    {values.footer && <div className="footer">{values.footer}</div>}
                    <div>14:57</div>
                </div>
                {/* Footer End*/}


                {/* Buttons Start*/}
                {values.buttonType === "callToAction" &&
                    <div className="border-top border-top-1 pt-3 mt-1 d-flex flex-column gap-2">
                        {values.buttons.map((button: { type: string, text: string }, index: number) => {
                            if (button.type === "callPhoneNumber") {
                                return <div className="btn text-primary py-0 cursor-default" key={index}>
                                    <i className="fa-solid fa-phone pe-3 text-primary"></i>
                                    <span>{button.text}</span>
                                </div>
                            }
                            if (button.type === "visitWebsite") {
                                return <div className="btn text-primary py-0 cursor-default" key={index}>
                                    <i className="fa-solid fa-right-from-bracket pe-3 text-primary"></i>
                                    <span>{button.text}</span>
                                </div>
                            }
                            return "";
                        })}
                    </div>
                }
                {/* Buttons End*/}
            </div>
        </div>
    )
}

export default TemplatePreview