import { connect } from "react-redux";
import { FC, useEffect, useState } from "react";

import {
  changeDailyTaskStatus,
  getDailyTask,
} from "app/reducers/dailyTask/dailyTaskAction";
import { useAuth } from "app/components/pages/auth";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";

interface task {
  AssigneeId: string;
  Assignee: string;
  Id: number;
  Description: string;
  Date: Date;
  IsCompleted: boolean;
}

interface props {
  getDailyTaskDispatch: Function;
  dailyTaskDetail: any;
  changeDailyTaskStatusDispatch: Function;
}

const DisplayDailyTask: FC<props> = ({
  changeDailyTaskStatusDispatch,
  getDailyTaskDispatch,
  dailyTaskDetail,
}) => {
  const { currentUser } = useAuth();
  const [displayLoader, setDisplayLoader] = useState({ loading: false, id: 0 });

  useEffect(() => {
    if (currentUser?.UserNumber) {
      getDailyTaskDispatch(currentUser?.UserNumber);
    }
  }, [currentUser, getDailyTaskDispatch]);

  const countCompletedDailyTask = () => {
    const count = dailyTaskDetail.dailyTask?.data.records[0].Tasks.reduce(
      (acc: any, task: task) => {
        if (task.IsCompleted) return (acc = acc + 1);
        return acc;
      },
      0
    );
    return count ===
      Number(dailyTaskDetail.dailyTask?.data.records[0].Tasks.length)
      ? "All Task Completed"
      : `${count} Task Completed`;
  };

  return (
    <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 col-xxl-4 mb-8">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">Today's Task</span>
          </h3>
        </div>
        {dailyTaskDetail.loading && (
          <div className="p-5">
            <Loader />
          </div>
        )}
        {!dailyTaskDetail.loading && dailyTaskDetail.error && (
          <ErrorDisplay error={dailyTaskDetail.error} />
        )}
        {!dailyTaskDetail.loading &&
          dailyTaskDetail.dailyTask?.success &&
          !dailyTaskDetail.dailyTask?.data?.records[0]?.Tasks?.length && (
            <div className="fs-5 fw-semibold p-5 text-center">
              No Tasks Found
            </div>
          )}
        {!dailyTaskDetail.loading &&
          dailyTaskDetail.dailyTask?.success &&
          dailyTaskDetail.dailyTask?.data?.records[0]?.Tasks?.length && (
            <>
              <div className="card-body pt-5 pb-6">
                <div className="mh-200px overflow-auto vertical-scroll">
                  {dailyTaskDetail.dailyTask?.data.records[0].Tasks.map(
                    (task: task, index: number) => {
                      return (
                        <div key={index} className="d-flex gap-3 mb-4">
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            {displayLoader.loading &&
                            displayLoader.id == task.Id ? (
                              <div className="me-2">
                                <SmallLoader />
                              </div>
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`checkList.${index}.isDefault`}
                                id={`checkList.${index}.isDefault`}
                                checked={task.IsCompleted}
                                onChange={() => {
                                  setDisplayLoader({
                                    loading: true,
                                    id: task.Id,
                                  });
                                  changeDailyTaskStatusDispatch(task.Id, () => {
                                    setDisplayLoader({ loading: false, id: 0 });
                                  });
                                }}
                              />
                            )}
                          </div>
                          <div>{task.Description}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="card-footer p-5">
                <div className="fs-5 fw-semibold text-center text-success">
                  {countCompletedDailyTask()}
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dailyTaskDetail: state.dailyTask,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDailyTaskDispatch: (userNumber: number) =>
      dispatch(getDailyTask(userNumber)),
    changeDailyTaskStatusDispatch: (dailyTaskId: number, callback: Function) =>
      dispatch(changeDailyTaskStatus(dailyTaskId, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayDailyTask);
