import { connect } from "react-redux";
import { FC, useEffect } from "react";

import DailyTaskTableRow from "./DailyTaskTableRow";
import { getDailyTaskList } from "../../../../reducers/dailyTask/dailyTaskAction";
import Table from "app/components/commonComponent/table/Table";
import { getAllDailyMorningPlanner } from "app/reducers/morningPlanner/plannerAction";
import { searchParams } from "app/helpers/commonInterface";
import AllDailyTaskTableRow from "./AllDailyTaskTableRow";

const HEADERS = [
  {
    columnName: "Article",
    id: "NULL",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Client Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Task Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "NULL",
    className: "min-w-sm-125px min-w-175px pe-5",
  },
];

interface props {
    getAllDailyMorningPlannerDispatch: Function;
    getAllDailyMorningPlanner: any;
}

const AllDailyTaskTable: FC<props> = ({
    getAllDailyMorningPlanner,
  getAllDailyMorningPlannerDispatch,
}) => {
  useEffect(() => {
    getAllDailyMorningPlannerDispatch({paginate : false});
  }, [getAllDailyMorningPlannerDispatch]);

  return (
    <div>
      <div className="m-6 fs-3 fw-bold">
       Daily Morning Task
      </div>
      <Table
        checkBoxColumn={{ show: false }}
        error={getAllDailyMorningPlanner.error}
        headers={HEADERS}
        success={getAllDailyMorningPlanner.getAllDailyMorningPlanner?.success}
        loading={getAllDailyMorningPlanner.loading}
        paginate={{ show: false }}
        tableData={getAllDailyMorningPlanner.getAllDailyMorningPlanner?.data?.records}
        tableRow={(row: any, index: number) => (
          <AllDailyTaskTableRow
           row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAllDailyMorningPlanner: state.getAllDailyMorningPlanner,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllDailyMorningPlannerDispatch: (searchObj:searchParams) => dispatch(getAllDailyMorningPlanner(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDailyTaskTable);
