import {EDIT_MORNING_PLANNER_REQUEST, EDIT_MORNING_PLANNER_SUCCESS, EDIT_MORNING_PLANNER_FAILURE } from "../actionTypes"

interface editMorningPlannerState {
    loading  : boolean,
    editMorningPlanner : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:editMorningPlannerState = {
    loading  : false,
    editMorningPlanner: {},
    error : ""
}

export const editMorningPlannerReducer = (state = initialState, action : action) => {
    switch(action.type){
        case EDIT_MORNING_PLANNER_REQUEST : return {
            ...state,
            loading : true
        }
        case EDIT_MORNING_PLANNER_SUCCESS : return {
            ...state,
            loading : false,
            editMorningPlanner : 'Task updated successfully',
            error : ""
        }
        case EDIT_MORNING_PLANNER_FAILURE : return {
            ...state,
            loading : false,
            editMorningPlanner : 'Task not updated',
            error : action.payload
        }
        default : return state
    }
}