import ErrorBoundary from "app/helpers/ErrorBoundary";
import { firmData } from "app/reducers/billing/billingDataInterface";
import { FC } from "react";

interface props {
  firmDetails: firmData | undefined;
}

const ProformaInvoiceBankDetail: FC<props> = ({ firmDetails }) => {
  return (
    <ErrorBoundary>
      <div className="card h-100">
        <div className="card-header pt-5 align-items-center border-0">
          <div className="card-title flex-column">
            <div className="fw-bold">Bank Details</div>
          </div>
        </div>
        <div className="card-body pt-6">
          <div className="row mb-4 align-items-center">
            <div className="col-5 col-md-5 col-lg-3 col-xl-5 col-sm-3 col-xxl-4 fw-semibold text-gray-600">
              Firm
            </div>
            <div className="col-7">{firmDetails?.Name || "-"}</div>
          </div>
          <div className="row mb-4 align-items-center">
            <div className="col-5 col-md-5 col-lg-3 col-xl-5 col-sm-3 col-xxl-4 fw-semibold text-gray-600">
              Bank Name
            </div>
            <div className="col-7">{firmDetails?.BankName || "-"}</div>
          </div>
          <div className="row mb-4 align-items-center">
            <div className="col-5 col-md-5 col-lg-3 col-xl-5 col-sm-3 col-xxl-4 fw-semibold text-gray-600">
              IFSC Code
            </div>
            <div className="col-7">{firmDetails?.IFSCCode || "-"}</div>
          </div>
          <div className="row mb-4 align-items-center">
            <div className="col-5 col-md-5 col-lg-3 col-xl-5 col-sm-3 col-xxl-4 fw-semibold text-gray-600">
              Current A/c. No.
            </div>
            <div className="col-7">{firmDetails?.AccountNumber || "-"}</div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ProformaInvoiceBankDetail;
