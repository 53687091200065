import { connect } from "react-redux";
import { Form, Formik, FormikValues } from "formik";
import { FC, useRef, useState } from "react";

import DropDown from "app/components/commonComponent/dropDown/DropDown";
import FilterButton from "app/components/commonComponent/tableFilter/FilterButton";
import {
  getFilterQueryString,
} from "app/helpers/helperFunction";
import { PAYMENT_STATUS } from "app/helpers/constants";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  BILL_STATUS_OPTIONS: {
    value: string | number | undefined;
    name: string | undefined;
  }[];
}

const BillFilter: FC<props> = ({ masterDataList, BILL_STATUS_OPTIONS }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`shadow-sm card w-300px w-sm-400px w-md-500px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="p-7">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <Formik
          enableReinitialize
          initialValues={filterObj}
          onSubmit={async (values: any) => {
            setSearchParam({
              ...searchObj,
              PageNumber: "1",
              Filters: getFilterQueryString(values),
            });
          }}
        >
          {({ values, isSubmitting, setFieldValue, resetForm }) => {
            return (
              <Form noValidate className="form">
                <div className="mb-10 px-9 pt-3">
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Client
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Client"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Clients.map(
                            (client: { Id: number; Name: string }) => {
                              return { value: client.Id, name: client.Name };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="ClientId"
                          currentValue={{
                            value: (values as FormikValues).ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: (values as FormikValues).ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Firm
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Firm"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Firms?.map(
                            (firm: { Id: number; Name: string }) => {
                              return {
                                value: Number(firm.Id),
                                name: firm.Name,
                              };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="Firm"
                          currentValue={{
                            value: (values as FormikValues).Firm,
                            name: masterDataList.masterData?.data?.records?.Firms?.find(
                              (firm: { Id: number; Name: string }) =>
                                String(firm.Id) === String(values.Firm)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: (values as FormikValues).Firm,
                            name: masterDataList.masterData?.data?.records?.Firms?.find(
                              (firm: { Id: number; Name: string }) =>
                                String(firm.Id) === String(values.Firm)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Start Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="startDate"
                          placeholder="Start Date"
                          currentValue={
                            values.startDate ? new Date(values.startDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        End Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="endDate"
                          placeholder="End Date"
                          currentValue={
                            values.endDate ? new Date(values.endDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Status
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Status"
                          options={BILL_STATUS_OPTIONS}
                          setFieldValue={setFieldValue}
                          name="PaymentStatus"
                          currentValue={{
                            value: values.PaymentStatus,
                          }}
                          defaultValue={{
                            value: values.PaymentStatus,
                            name: values.PaymentStatus
                              ? PAYMENT_STATUS[Number(values.PaymentStatus)]
                              : "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-6 px-6 gap-3">
                  <ResetButton
                    name="Reset"
                    className="btn btn-light btn-active-light-primary btn-sm"
                    onClickCallback={() => {
                      resetHandler(resetForm);
                      setShowDropDown(false);
                    }}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Apply"
                    className="btn btn-primary btn-sm"
                    onClickCallback={() => setShowDropDown(false)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

export default connect(mapStateToProps)(BillFilter);
