import { Link, Outlet, useLocation } from "react-router-dom"

import { absolutePath } from "app/helpers/relativePath"
import { HOME_PAGE, ASSIGNED_TASK_LIST, CHECKING_TASK_LIST, FOLLOWUP_TASK_LIST } from "app/helpers/routes";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import TaskFilter from "app/components/pages/task/component/TaskFilter";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"
import hasPermission, { actionsRole } from "app/components/pages/auth/core/permission";

const AdminTaskListing = () => {
    const location = useLocation();
    return (
        <ErrorBoundary>
            <div className='d-flex justify-content-between align-items-center gap-3 mb-4 flex-wrap'>
                <div className="d-flex overflow-auto h-55px tabs-container">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                        <li className="nav-item profile-tab-width">
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 fw-semibold ` +
                                    ((location.pathname === absolutePath(`${HOME_PAGE}`) ||
                                        location.pathname === absolutePath(`${HOME_PAGE}/${ASSIGNED_TASK_LIST}`)) && "active")
                                }
                                to={absolutePath(`${HOME_PAGE}/${ASSIGNED_TASK_LIST}`)}
                            >   
                                Assigned
                            </Link>
                        </li>
                        {/* {(hasPermission(actionsRole.ADMIN) || hasPermission(actionsRole.TEAM_LEAD)) &&
                            <> */}
                                <li className="nav-item profile-tab-width">
                                    <Link
                                        className={
                                            `nav-link text-active-primary me-6 fw-semibold ` +
                                            (location.pathname === absolutePath(`${HOME_PAGE}/${CHECKING_TASK_LIST}`) && "active")
                                        }
                                        to={absolutePath(`${HOME_PAGE}/${CHECKING_TASK_LIST}`)}
                                    >
                                        Checking
                                    </Link>
                                </li>
                                <li className="nav-item profile-tab-width">
                                    <Link
                                        className={
                                            `nav-link text-active-primary me-6 fw-semibold ` +
                                            (location.pathname === absolutePath(`${HOME_PAGE}/${FOLLOWUP_TASK_LIST}`) && "active")
                                        }
                                        to={absolutePath(`${HOME_PAGE}/${FOLLOWUP_TASK_LIST}`)}
                                    >
                                        Followup
                                    </Link>
                                </li>
                            {/* </>
                        } */}
                    </ul>
                </div>
                <div className='d-flex align-items-center gap-3'>
                    <UsersListSearchComponent />
                    <TaskFilter />
                </div>
            </div>
            <div className="card">
                <Outlet />
            </div>
        </ErrorBoundary>
    )
}

export default AdminTaskListing