import {
  GET_ALL_TRANSACTION_API_ENDPOINT,
  TRANSACTION_API_ENDPOINT,
  TRANSACTION_REPORT_API_ENDPOINT,
} from "app/helpers/config";
import { axiosInstance } from "../axiosInstance";
import { searchParams } from "app/helpers/commonInterface";
import { getSearchQueryString } from "app/helpers/helperFunction";
import { addTransactionValue } from "app/components/pages/transaction/TransactionForm";

export const addTransactionService = async (data: addTransactionValue) => {
  return await axiosInstance.post(TRANSACTION_API_ENDPOINT, data);
};

export const getTransactionListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ALL_TRANSACTION_API_ENDPOINT}?${queryString}`
  );
};

export const getTransactionService = (
  transactionId: number,
  isPayment: boolean
) => {
  return axiosInstance.get(
    `${TRANSACTION_API_ENDPOINT}?id=${transactionId}&isPayment=${isPayment}`
  );
};

export const updateTransactionService = async (data: addTransactionValue) => {
  return await axiosInstance.put(TRANSACTION_API_ENDPOINT, data);
};

export const getTransactionReportListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${TRANSACTION_REPORT_API_ENDPOINT}?${queryString}`);
};
