import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import { FIRM_BANK_BOOK_LIST } from "app/helpers/routes";
import { KTSVG } from "_metronic/helpers";
import StatusCell from "app/components/commonComponent/table/StatusCell";
import UserActionsCell from "app/components/commonComponent/table/UserActionsCell";
import { updateBankBookStatus } from "app/reducers/bankBook/bankBookAction";
import { connect } from "react-redux";

interface props {
  row: {
    AccountNumber: string;
    AccountType: string;
    BankName: string;
    BranchName: string;
    FirmId: number;
    FirmName: string;
    IFSCCode: string;
    Id: number;
    IsActive: boolean;
    IsCashBook: boolean;
    Name: string;
  };
  updateStatusDispatch: Function;
}

const BankBookTableRow: FC<props> = ({ row, updateStatusDispatch }) => {
  const navigate = useNavigate();
  const params = useParams();

  const handleEditData = () => {
    navigate(
      absolutePath(`${FIRM_BANK_BOOK_LIST}/${params.id}/edit/${row.Id}`)
    );
  };

  const handleViewTransaction = () => {
    navigate(
      absolutePath(`${FIRM_BANK_BOOK_LIST}/${params.id}/transaction/${row.Id}`)
    );
  };

  const updateList = (id: number, callBack: Function) => {
    updateStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.Name}</td>
      <td className="">{row.BankName}</td>
      <td className="">{row.AccountNumber}</td>
      <td className="">{row.IFSCCode}</td>
      <td className="">{row.BranchName}</td>
      <td className="">{row.AccountType}</td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={handleEditData}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateList}
            isActive={row?.IsActive}
            id={row?.Id}
          />
          <button
            className="w-150px btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary"
            title="View Transaction"
            onClick={handleViewTransaction}
          >
            View Transaction
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateStatusDispatch: (bankBookId: number, successCallback: Function) =>
      dispatch(updateBankBookStatus(bankBookId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(BankBookTableRow);
