import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addBillService,
  getBillListService,
  getBillReportListService,
  getBillService,
  savePdfService,
  updateBillService,
} from "./billingService";
import { searchParams } from "app/helpers/commonInterface";
import { addBillValue } from "app/components/pages/billing/BillingForm";
import {
  ADD_BILL_FAILURE,
  ADD_BILL_REQUEST,
  ADD_BILL_SUCCESS,
  GET_BILL_FAILURE,
  GET_BILL_LIST_FAILURE,
  GET_BILL_LIST_REQUEST,
  GET_BILL_LIST_SUCCESS,
  GET_BILL_REPORT_LIST_FAILURE,
  GET_BILL_REPORT_LIST_REQUEST,
  GET_BILL_REPORT_LIST_SUCCESS,
  GET_BILL_REQUEST,
  GET_BILL_SUCCESS,
  SAVE_BILL_PDF_FAILURE,
  SAVE_BILL_PDF_REQUEST,
  SAVE_BILL_PDF_SUCCESS,
  UPDATE_BILL_FAILURE,
  UPDATE_BILL_REQUEST,
  UPDATE_BILL_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addBill = (data: addBillValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_BILL_REQUEST));

    await addBillService(data).then(
      (result: any) => {
        dispatch(success(ADD_BILL_SUCCESS, result.data));
        showToastMessageSuccess("Bill added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BILLS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_BILL_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getBillList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_BILL_LIST_REQUEST));

    getBillListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_BILL_LIST_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(GET_BILL_LIST_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const getBill = (billId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_BILL_REQUEST));

    getBillService(billId).then(
      (result: any) => dispatch(success(GET_BILL_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_BILL_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const updateBill = (data: addBillValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_BILL_REQUEST));

    await updateBillService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_BILL_SUCCESS, result.data));
        showToastMessageSuccess("Bill updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BILLS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(UPDATE_BILL_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const saveBillPdf = (pdfData: FormData, billId: number) => {
  return async (dispatch: any) => {
    dispatch(request(SAVE_BILL_PDF_REQUEST));

    await savePdfService(pdfData, billId).then(
      (result: any) => {
        dispatch(success(SAVE_BILL_PDF_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(SAVE_BILL_PDF_FAILURE, GetErrorMessage(error?.response?.data))
        );
      }
    );
  };
};

export const getBillReportList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_BILL_REPORT_LIST_REQUEST));

    getBillReportListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_BILL_REPORT_LIST_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_BILL_REPORT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
