import {
  GET_GROUP_LIST_FAILURE,
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  UPDATE_GROUP_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface groupListState {
  loading: boolean;
  groupList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: groupListState = {
  loading: false,
  groupList: {},
  error: "",
};

export const groupListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_GROUP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUP_LIST_SUCCESS:
      return {
        loading: false,
        groupList: action.payload,
        error: "",
      };
    case GET_GROUP_LIST_FAILURE:
      return {
        loading: false,
        groupList: {},
        error: action.payload,
      };
    case UPDATE_GROUP_STATUS_SUCCESS:
      return updateStatusFun(
        state,
        "groupList",
        action.payload.data.records.Id
      );
    default:
      return state;
  }
};
