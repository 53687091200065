import React from 'react';

interface PinSVGProps {
    fill: string;
}

const PinSVG: React.FC<PinSVGProps> = ({ fill }) => {
    return (
        <svg viewBox="0 0 24 24" style={{ height: '15px', width: '15px' }}>
            <path fill={fill} d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        </svg>
    );
};

export default PinSVG;
