import {
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
} from "../actionTypes";

export interface activityDataState {
  loading: boolean;
  activity: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: activityDataState = {
  loading: false,
  activity: {},
  error: "",
};

export const getActivityReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case GET_ACTIVITY_FAILURE:
      return {
        loading: false,
        activity: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
