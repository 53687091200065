import { GET_USERS_MORNING_PLANNER_FAILURE, GET_USERS_MORNING_PLANNER_SUCCESS, GET_USERS_MORNING_PLANNER_REQUEST, DELETE_USERS_MORNING_PLANNER_REQUEST, DELETE_USERS_MORNING_PLANNER_SUCCESS, DELETE_USERS_MORNING_PLANNER_FAILURE } from "../actionTypes"

interface usersMorningPlannerListState {
    deleteLoading : boolean,
    loading  : boolean,
    usersMorningPlannerList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:usersMorningPlannerListState = {
    deleteLoading:false,
    loading  : false,
    usersMorningPlannerList : {},
    error : ""
}

export const usersMorningPlannerReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_USERS_MORNING_PLANNER_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_USERS_MORNING_PLANNER_SUCCESS : return {
            ...state,
            loading : false,
            usersMorningPlannerList : action.payload,
            error : ""
        }
        case GET_USERS_MORNING_PLANNER_FAILURE : return {
            ...state,
            loading : false,
            usersMorningPlannerList : {},
            error : action.payload
        }

        case  DELETE_USERS_MORNING_PLANNER_REQUEST: return {
            ...state,
            deleteLoading : true
        }
        case DELETE_USERS_MORNING_PLANNER_SUCCESS : return {
            ...state,
            deleteLoading : false,
            usersMorningPlannerList : action.payload,
            error : ""
        }
        case DELETE_USERS_MORNING_PLANNER_FAILURE : return {
            ...state,
            deleteLoading : false,
            usersMorningPlannerList : {},
            error : action.payload
        }
        default : return state
    }
}