import { axiosInstance } from "../axiosInstance";
import {
  GROUP_AP_ENDPOINT,
  GROUP_LIST_AP_ENDPOINT,
} from "../../helpers/config";
import { getSearchQueryString } from "../../helpers/helperFunction";
import { groupData } from "../../components/pages/groups/GroupForm";
import { searchParams } from "../../helpers/commonInterface";

export const addGroupService = async (data: groupData) => {
  console.log(data);
  return await axiosInstance.post(GROUP_AP_ENDPOINT, data);
};

export const editGroupService = async (data: groupData) => {
  return await axiosInstance.put(GROUP_AP_ENDPOINT, data);
};

export const getGroupService = (groupId: number) => {
  return axiosInstance.get(`${GROUP_AP_ENDPOINT}?id=${groupId}`);
};

export const getGroupListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GROUP_LIST_AP_ENDPOINT}?${queryString}`);
};

export const updateGroupStatusService = (groupId: number) => {
  return axiosInstance.delete(`${GROUP_AP_ENDPOINT}?id=${groupId}`);
};
