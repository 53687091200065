import { taskActivities } from "app/reducers/task/taskDataInterface";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useState } from "react";
import { object, mixed, addMethod } from "yup";

interface props {
  task: taskActivities;
  onSubmitHandler: Function;
}

addMethod(mixed, "checkMinimumValue", function (task: taskActivities) {
  return this.test(
    "check-minimum-value",
    `${
      task.CompletionFieldName.charAt(0).toUpperCase() +
      task.CompletionFieldName.slice(1)
    } is Required`,
    function (value) {
      const { path, createError } = this;

      if (value) {
        if (
          task.CompletionFieldType == "number" &&
          !(Number(value?.toString().length) >= Number(task.MinValue))
        ) {
          return createError({
            path,
            message: `Minimum ${task.MinValue} number is required`,
          });
        }
      }
      return true;
    }
  );
});

const CompletionField: FC<props> = ({ task, onSubmitHandler }) => {
  const [showCompletionField, setShowCompletionField] = useState(false);

  const validateFun = object().shape({
    [task.CompletionFieldName]: (mixed() as any)
      .checkMinimumValue(task)
      .required(
        `${
          task.CompletionFieldName.charAt(0).toUpperCase() +
          task.CompletionFieldName.slice(1)
        } is Required`
      ),
  });

  return (
    <div className="completion-field-container position-relative">
      <div>
        <button
          type="button"
          className="btn-active-light-primary gap-2 align-items-center btn btn-light-primary btn-light-primary btn-sm d-flex justify-content-center p-2"
          onClick={() => setShowCompletionField(!showCompletionField)}
        >
          {task.CompletionFieldValue ? (
            <div className="d-flex gap-3">
              <span>{task.CompletionFieldName}</span>
              <span>{task.CompletionFieldValue}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center gap-2">
              <i className="fa fa-solid fa-plus p-0"></i>
              <span>{task.CompletionFieldName}</span>
            </div>
          )}
        </button>
      </div>
      <div
        className={`card shadow-sm w-300px w-md-325px task-filter ${
          showCompletionField ? "" : "hidden"
        }`}
        data-kt-menu="true"
      >
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <Formik
            enableReinitialize
            initialValues={{
              [task.CompletionFieldName]: task.CompletionFieldValue,
            }}
            validationSchema={validateFun}
            onSubmit={async (values) => {
              await onSubmitHandler(
                { id: task.Id, fieldValue: values[task.CompletionFieldName] },
                () => {
                  setShowCompletionField(false);
                }
              );
            }}
          >
            {({ isSubmitting, errors, touched }) => {
              return (
                <Form noValidate className="form">
                  <div className="mb-10">
                    <label className="col-form-label fw-semibold fs-6 required">
                      {task.CompletionFieldName}
                    </label>
                    <Field
                      type={task.CompletionFieldType}
                      className={`form-control form-control-solid 
                      ${
                                              touched[
                                                task.CompletionFieldName
                                              ] &&
                                              errors[
                                                task.CompletionFieldName
                                              ] &&
                                              "is-invalid inValidBorder"
                                            }`}
                      placeholder={`${
                        task.CompletionFieldName.charAt(0).toUpperCase() +
                        task.CompletionFieldName.slice(1)
                      }`}
                      id={task.CompletionFieldName}
                      name={task.CompletionFieldName}
                    />
                    <ErrorMessage
                      name={task.CompletionFieldName}
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      type="button"
                      className="btn btn-light btn-active-light-primary btn-sm"
                      onClick={() => setShowCompletionField(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={isSubmitting}
                      // onClick={() => !errors[task.CompletionFieldName] && setShowCompletionField(false)}
                    >
                      {!isSubmitting && <div>Submit</div>}
                      {isSubmitting && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CompletionField;
