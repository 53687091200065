import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addExpenseService,
  getExpenseListService,
  getExpenseService,
  updateExpenseService,
  updateExpenseStatusService,
} from "./expenseService";
import {
  ADD_EXPENSE_FAILURE,
  ADD_EXPENSE_REQUEST,
  ADD_EXPENSE_SUCCESS,
  GET_EXPENSE_FAILURE,
  GET_EXPENSE_LIST_FAILURE,
  GET_EXPENSE_LIST_REQUEST,
  GET_EXPENSE_LIST_SUCCESS,
  GET_EXPENSE_REQUEST,
  GET_EXPENSE_SUCCESS,
  UPDATE_EXPENSE_FAILURE,
  UPDATE_EXPENSE_REQUEST,
  UPDATE_EXPENSE_STATUS_FAILURE,
  UPDATE_EXPENSE_STATUS_REQUEST,
  UPDATE_EXPENSE_STATUS_SUCCESS,
  UPDATE_EXPENSE_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { searchParams } from "app/helpers/commonInterface";
import { GetErrorMessage } from "../stateHelperFunction";

export const addExpense = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_EXPENSE_REQUEST));

    await addExpenseService(data).then(
      (result: any) => {
        dispatch(success(ADD_EXPENSE_SUCCESS, result));
        showToastMessageSuccess("Expense added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.EXPENSES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_EXPENSE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getExpenseList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_EXPENSE_LIST_REQUEST));

    getExpenseListService(searchObj).then(
      (result: any) => dispatch(success(GET_EXPENSE_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_EXPENSE_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getExpense = (expenseId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_EXPENSE_REQUEST));

    getExpenseService(expenseId).then(
      (result: any) => dispatch(success(GET_EXPENSE_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_EXPENSE_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const updateExpense = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_EXPENSE_REQUEST));

    await updateExpenseService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_EXPENSE_SUCCESS, result.data));
        showToastMessageSuccess("Expense updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.EXPENSES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_EXPENSE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const updateExpenseStatus = (
  expenseId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_EXPENSE_STATUS_REQUEST));

    updateExpenseStatusService(expenseId).then(
      (result: any) => {
        dispatch(success(UPDATE_EXPENSE_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Expense status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.EXPENSES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_EXPENSE_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
