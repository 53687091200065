import { absolutePath } from "../../../helpers/relativePath"
import { adminHomePage } from "../main/admin/AdminMain"
import { ADD_DAILY_TASK, DAILY_TASK_LIST } from "../../../helpers/routes"
import DailyTaskTable from "./component/DailyTaskTable"
import { emptyObject } from "../../../helpers/helperFunction"
import { KTCard } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar"
import AllDailyTaskTable from "./component/AllDailyTaskTable"

export const dailyTaskListPage = {
    title: 'Daily Tasks',
    path: absolutePath(DAILY_TASK_LIST),
    isSeparator: true,
    isActive: true,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

const DailyTaskList = () => {
    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Daily Tasks</PageTitle>
            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListToolbar buttons={[{ title: "Add Task", to: ADD_DAILY_TASK }]} />
            </div>
            <KTCard>
                <DailyTaskTable />
            </KTCard>
            <div className="mt-6">
            <KTCard>
                <AllDailyTaskTable
                 />
            </KTCard>
            </div>
        </>
    )
}

export default DailyTaskList