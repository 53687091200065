import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddExpense from "./component/AddExpense";
import {
  addExpense,
  getExpense,
  updateExpense,
} from "app/reducers/expense/expenseAction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "app/components/commonComponent/loader/Loader";

export interface addExpenseValue {
  id?: number;
  name: string;
}

interface editExpenseData {
  Id: number;
  Name: string;
}

interface props {
  updateExpenseDispatch: Function;
  addExpenseDispatch: Function;
  getExpenseDetailsDispatch: Function;
  expenseDetails: any;
}

const initialValue = {
  name: "",
};
const getValueForEdit = (expenseData: editExpenseData): addExpenseValue => {
  return {
    id: expenseData.Id,
    name: expenseData.Name,
  };
};

const ExpenseForm: FC<props> = ({
  expenseDetails,
  updateExpenseDispatch,
  addExpenseDispatch,
  getExpenseDetailsDispatch,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getExpenseDetailsDispatch(params.id);
    }
  }, [getExpenseDetailsDispatch, params.id]);

  return (
    <ErrorBoundary>
      {params.id && expenseDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && expenseDetails.error && (
        <ErrorDisplay error={expenseDetails.error} />
      )}
      {(params.id ? expenseDetails?.expense?.success : true) && (
        <>
          {params.id && (
            <AddExpense
              initialValue={getValueForEdit(
                expenseDetails.expense.data.records
              )}
              expenseDispatch={updateExpenseDispatch}
              title="Edit Expense"
            />
          )}
          {!params.id && (
            <AddExpense
              initialValue={initialValue}
              expenseDispatch={addExpenseDispatch}
              title="Add Expense"
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    expenseDetails: state.expense,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getExpenseDetailsDispatch: (expenseId: number) =>
      dispatch(getExpense(expenseId)),
    addExpenseDispatch: (data: addExpenseValue, successCallback: Function) =>
      dispatch(addExpense(data, successCallback)),
    updateExpenseDispatch: (data: addExpenseValue, successCallback: Function) =>
      dispatch(updateExpense(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseForm);
