import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import { FC } from "react";

import { groupContactPerson, groupData } from "../GroupForm";

interface props {
  values: FormikValues;
  touched: FormikTouched<groupData>;
  errors: FormikErrors<groupData>;
  newGroupContactPersonObject: groupContactPerson;
  isSubmitting: boolean;
}

const AddGroupContactPerson: FC<props> = ({
  isSubmitting,
  newGroupContactPersonObject,
  values,
  touched,
  errors,
}) => {
  return (
    <div className="">
      <FieldArray
        name="contactPersons"
        render={(arrayHelpers) => (
          <div className="">
            {values.contactPersons.length === 0 && (
              <div>
                <button
                  className="btn btn-light-primary btn-sm"
                  onClick={() => arrayHelpers.push(newGroupContactPersonObject)}
                  disabled={isSubmitting}
                >
                  <i className="fa-solid fa-plus"></i>
                  Add Contact Person
                </button>
              </div>
            )}
            {values.contactPersons.map(
              (person: groupContactPerson, index: number) => (
                <div
                  key={index}
                  className="border border-2 rounded-2 row px-2 mb-4"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="align-items-center d-flex gap-4 fs-4 fw-semibold min-h-50px w-100"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#group-contact-person-${index}`}
                    >
                      <div>Contact Person {index + 1}</div>
                    </div>
                    <div className="align-items-center d-flex justify-content-start gap-3 w-auto">
                      {
                        <button
                          type="button"
                          style={{ color: "#ff5959" }}
                          className="btn btn-active-light-danger btn-light px-4 py-2 rounded-circle text-center"
                          onClick={() => arrayHelpers.remove(index)}
                          disabled={isSubmitting}
                        >
                          <i
                            style={{ color: "#ff5959" }}
                            className="fa-solid fa-close p-0"
                          ></i>
                        </button>
                      }
                      {values.contactPersons.length - 1 === index && (
                        <button
                          type="button"
                          className="btn btn-active-light-primary btn-light px-4 py-2 rounded-circle text-center"
                          onClick={() =>
                            arrayHelpers.insert(
                              index + 1,
                              newGroupContactPersonObject
                            )
                          }
                          disabled={isSubmitting}
                        >
                          <i
                            style={{ color: "#1da9f8" }}
                            className="fa-solid fa-plus p-0"
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    id={`group-contact-person-${index}`}
                    className={`border-top collapse pt-5 ${
                      values.contactPersons.length - 1 === index ? "show" : ""
                    }`}
                  >
                    {newGroupContactPersonObject?.isDefault && (
                      <div className="d-flex gap-1">
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name={`contactPersons.${index}.isDefault`}
                            id={`contactPersons.${index}.isDefault`}
                            disabled={isSubmitting}
                          />
                        </div>
                        <label className="col-form-label fw-semibold fs-6">
                          Set as Default
                        </label>
                      </div>
                    )}
                    <div className="row mb-6">
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Name
                        </label>

                        <div className="">
                          <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                           ${
                             touched.contactPersons &&
                             touched.contactPersons[index]?.name &&
                             errors.contactPersons &&
                             (
                               errors.contactPersons[
                                 index
                               ] as FormikErrors<groupContactPerson>
                             )?.name &&
                             "is-invalid inValidBorder"
                           }`}
                            placeholder="Person Name"
                            id={`contactPersons.${index}.name`}
                            name={`contactPersons.${index}.name`}
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name={`contactPersons.${index}.name`}
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6">
                          Email
                        </label>

                        <div className="">
                          <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.contactPersons &&
                            touched.contactPersons[index]?.email
                             &&
                            errors.contactPersons &&
                            (
                              errors.contactPersons[
                                index
                              ] as FormikErrors<groupContactPerson>
                            )?.email &&
                            "is-invalid inValidBorder"
                          }`}
                            placeholder="Person Email"
                            id={`contactPersons.${index}.email`}
                            name={`contactPersons.${index}.email`}
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name={`contactPersons.${index}.email`}
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Mobile Number
                        </label>

                        <div className="">
                          <Field
                            type="number"
                            className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.contactPersons &&
                            touched.contactPersons[index]?.phoneNumber &&
                            errors.contactPersons &&
                            (
                              errors.contactPersons[
                                index
                              ] as FormikErrors<groupContactPerson>
                            )?.phoneNumber &&
                            "is-invalid inValidBorder"
                          }`}
                            placeholder="Person Mobile Number"
                            id={`contactPersons.${index}.phoneNumber`}
                            name={`contactPersons.${index}.phoneNumber`}
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name={`contactPersons.${index}.phoneNumber`}
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      />
    </div>
  );
};

export default AddGroupContactPerson;
