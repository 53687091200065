import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import DocumentTableRow from "./DocumentTableRow";
import {
  getFileList,
  selectAllDocument,
  unSelectAllDocument,
} from "app/reducers/clientDocuments/clientDocumentsAction";
import { selctedDocumentList } from "../DocumentsList";
import Table from "app/components/commonComponent/table/Table";
import { clientDocumentsState } from "app/reducers/clientDocuments/clientDocumentsReducer";
import { useGetSearchObject } from "app/helpers/customHooks";

const HEADERS = [
  {
    columnName: "Document Name",
    id: "DocumentType",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Document Year",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Upload Date",
    id: "DateAdded",
    className: "min-w-125px",
  },
  {
    columnName: "Upload By",
    id: "UploadedBy",
    className: "min-w-125px",
  },
];

interface props {
  getallDocuments: Function;
  documentsListDetails: clientDocumentsState;
  selectedDocumentDetails: selctedDocumentList;
  unSelectAllDocumentDispatch: Function;
  selectAllDocumentDispatch: Function;
}

const DocumentsTable: FC<props> = ({
  getallDocuments,
  documentsListDetails,
  selectedDocumentDetails,
  unSelectAllDocumentDispatch,
  selectAllDocumentDispatch,
}) => {
  const params = useParams();
  const {searchObj} = useGetSearchObject();
  useEffect(() => {
    getallDocuments({ ...searchObj, clientId: params.id });
  }, [getallDocuments, searchObj]);

  useEffect(() => {
    return () => unSelectAllDocumentDispatch();
  }, []);

  const onChangeSelectAllHandler = (e: any) => {
    if (e.target.checked) {
      selectAllDocumentDispatch();
    } else {
      unSelectAllDocumentDispatch();
    }
  };

  return (
    <>
      
      <Table
        checkBoxColumn={{
          show: true,
          checked: selectedDocumentDetails.isCheckedAll,
          onChangeFun: onChangeSelectAllHandler,
        }}
        error={documentsListDetails.error}
        headers={HEADERS}
        success={documentsListDetails.documentList?.success}
        loading={documentsListDetails.loading}
        paginate={{ show: false }}
        tableData={documentsListDetails.documentList?.data?.records}
        tableRow={(row: any, index: number) => (
          <DocumentTableRow
            row={row}
            key={`row-${index}-${row.id}`}
            selectedDocumentDetails={selectedDocumentDetails}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    documentsListDetails: state.clientDocumentList,
    selectedDocumentDetails: state.selectedDocumentList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getallDocuments: (fileFilterObject: { year?: string; clientId: number }) =>
      dispatch(getFileList(fileFilterObject)),
    unSelectAllDocumentDispatch: () => dispatch(unSelectAllDocument()),
    selectAllDocumentDispatch: () => dispatch(selectAllDocument()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
