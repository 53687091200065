import { FC } from 'react'

import ImageDisplay from './ImageDisplay'
import { PROFILE_PICTURE_API_ENDPOINT } from '../../helpers/config'

interface props {
    firstName : string
    lastName : string
    size? : number
    color? : string | undefined
    background ?: string | undefined
    length ?: number | undefined
    title ?: string|undefined
    rounded ?: boolean
}

const Avatar:FC<props> = ({firstName, lastName, size, color, background, length, title, rounded}) => {
    return (
        <ImageDisplay
            path={`${PROFILE_PICTURE_API_ENDPOINT}?name=${firstName} ${lastName}&size=${size}&background=${background}&bold=true&color=${color}&length=${length ? length : 2}&rounded=${rounded || false}`}
            altText='profilePicture'
            className='img-fit-to-div'
            errorPath='/media/svg/avatars/blank.svg'
            title={title}
        />
    )
}

export default Avatar