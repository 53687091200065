import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddGroup from "./components/AddGroup";
import {
  addGroup,
  editGroup,
  getGroup,
} from "../../../reducers/groups/groupAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";
import { useAuth } from "../auth";

interface props {
  groupDetails: any;
  editGroupDispatch: Function;
  addGroupDispatch: Function;
  getGroupDetails: Function;
}

export interface groupContactPerson {
  name: string;
  email: string;
  phoneNumber: string;
  companyId: number | undefined;
  clientId?: number;
  id?: number;
  isDefault: boolean;
  groupId?: number;
}

export interface groupData {
  id?: number;
  name: string;
  contactPersons: Array<groupContactPerson>;
}

interface contactObject {
  Email: string;
  GroupId: 9;
  Id: 30;
  IsActive: true;
  IsDefault: true;
  Name: string;
  PhoneNumber: string;
  CompanyId: number;
}

interface editGropData {
  Id?: number;
  Name: string;
  Contacts: Array<contactObject>;
}

const initailvalue: groupData = {
  name: "",
  contactPersons: [],
};

const getNewContactPersonObject = (
  companyId: number | undefined
): groupContactPerson => {
  return {
    name: "",
    email: "",
    phoneNumber: "",
    companyId: companyId,
    isDefault: true,
  };
};

const getInitialValue = (groupData: editGropData): groupData => {
  return {
    id: groupData.Id,
    name: groupData.Name,
    contactPersons: groupData.Contacts.map((data: contactObject) => {
      return {
        name: data.Name,
        email: data.Email,
        phoneNumber: data.PhoneNumber,
        id: data.Id,
        isDefault: data.IsDefault,
        groupId: data.GroupId,
        companyId: data.CompanyId,
      };
    }),
  };
};

const GroupForm: FC<props> = ({
  groupDetails,
  editGroupDispatch,
  addGroupDispatch,
  getGroupDetails,
}) => {
  const params = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (params.id) {
      getGroupDetails(params.id);
    }
  }, [params.id, getGroupDetails]);

  return (
    <ErrorBoundary>
      {params.id && groupDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && groupDetails.error && (
        <ErrorDisplay error={groupDetails.error} />
      )}
      {(params.id
        ? !groupDetails.loading && groupDetails.group?.success
        : true) && (
        <>
          {params.id && (
            <AddGroup
              initialValue={getInitialValue(groupDetails?.group?.data?.records)}
              groupDataDispatch={editGroupDispatch}
              title="Edit Group"
              newGroupContactPersonObject={getNewContactPersonObject(
                currentUser?.CompanyId
              )}
            />
          )}
          {!params.id && (
            <AddGroup
              initialValue={initailvalue}
              groupDataDispatch={addGroupDispatch}
              title="Add Group"
              newGroupContactPersonObject={getNewContactPersonObject(
                currentUser?.CompanyId
              )}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    groupDetails: state.group,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addGroupDispatch: (data: groupData, successCallback: Function) =>
      dispatch(addGroup(data, successCallback)),
    editGroupDispatch: (data: groupData, successCallback: Function) =>
      dispatch(editGroup(data, successCallback)),
    getGroupDetails: (groupId: number) => dispatch(getGroup(groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);
