import { adminHomePage } from "../main/admin/AdminMain"
import { absolutePath } from "../../../helpers/relativePath"
import { ADD_GROUP, GROUP_LIST } from "../../../helpers/routes"
import { emptyObject } from "../../../helpers/helperFunction"
import GroupTable from "./components/GroupTable"
import { KTCard } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import UsersListSearchComponent from "../../commonComponent/table/UsersListSearchComponent"
import UsersListToolbar from "../../commonComponent/table/UsersListToolbar"

export const groupListPage = {
    title: 'Groups',
    path: absolutePath(GROUP_LIST),
    isSeparator: true,
    isActive: true,
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]


const GroupList = () => {
    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Groups</PageTitle>
            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListSearchComponent />
                <UsersListToolbar buttons={[{ title: "Add Group", to: ADD_GROUP }]} />
            </div>
            <KTCard>
                <GroupTable />
            </KTCard>
        </>
    )
}

export default GroupList