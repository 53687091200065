import * as Yup from "yup";

const VARAIBLE_FORMAT_REWGEX = /{{(\d?)+\W(\d?)+}}/g;
const IDENTIFY_PARAGRAPH_TAG = /<\/?p>/g;
const IDENTIFY_OTHER_HTML_TAGS = /<\/?\w+>/g;
const URL_VALIDATE_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

Yup.addMethod(Yup.mixed, 'checkFileType', function (mediaFormat) {
    return this.test("check-file-type", "File Upload of proper format", async function (value: any) {
        const { path, createError } = this;

        const resolvedMediaFormat = this.resolve(mediaFormat)
        if (value) {
            if (resolvedMediaFormat === "image" && !['image/jpg', 'image/jpeg', 'image/png'].includes(value?.type)) {
                return createError({
                    path,
                    message: `Invalid file format only jpg, jpeg, png file allowed`
                });
            }

            if (resolvedMediaFormat === "document" && value?.type !== 'application/pdf') {
                return createError({
                    path,
                    message: `Invalid file format only pdf file allowed`
                });
            }
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'validateBodyText', function (example) {
    return this.test("validate-body-text", "Body text format is incorrect", async function (value: any) {
        const { path, createError } = this;

        if (value) {
            if (VARAIBLE_FORMAT_REWGEX.test(value)) {
                return createError({
                    path,
                    message: `This template contains variable parameters with incorrect formatting. Variable parameters must be whole numbers with two sets of curly brackets`
                });
            }
            const dataLength = value.replace(IDENTIFY_PARAGRAPH_TAG, "")
                .replace(IDENTIFY_OTHER_HTML_TAGS, "*").length
            if (!(dataLength <= 1024)) {
                return createError({
                    path,
                    message: `Maximum length exceeded`
                });
            }
            const exampleArr = this.resolve(example)
            if (!(value.split(" ").length - exampleArr.length >= (2 * exampleArr.length) + 1)) {
                return createError({
                    path,
                    message: `This template contains too many variable parameters relative to the message length. You need to decrease the number of variable parameters or increase the message length.`
                });
            }
        }
        return true;
    });
});

export const TemplateRegisterValidateFun = Yup.object().shape({
    header: Yup.object().shape({
        format: Yup.string().required("Header format is required"),
        text: Yup.string().when("format", {
            is: (format: string) => format == "text",
            then: Yup.string().required("Header text is required")
                .max(60, "Maximum length exceeded")
        }),
        example: Yup.array().of(Yup.string().required("Variable is required")),
        mediaFormat: Yup.string().when("format", {
            is: (format: string) => format === "media",
            then: Yup.string().required("Media format is required")
        }),
        // file: mixed().when("format", {
        //     is: (format: string) => format === "media",
        //     then: (mixed() as any)
        //         .checkFileType(ref("mediaFormat"))
        //         .required("Header file is required")
        // })
    }),
    body: Yup.object().shape({
        example: Yup.array().of(Yup.string().required("Variable text is required")),
        text: (Yup as any).string()
        .validateBodyText(Yup.ref("example"))
        .required("Body text is required"),
    }),
    footer: Yup.string().test("length", "Maximum length exceeded", (value) => {
        if (value) return value.length <= 60
        return true
    }),
    buttonType: Yup.string().required("Button type is required"),
    buttons: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required("Button action type is required"),
            // .when("buttonType", {
            //     is: (buttonType: string) => buttonType == "callToAction",
            //     then: string().required("Button action type is required")
            // }),
            text: Yup.string().required("Button text is required")
            .max(25, "Maximum length exceeded"),
            // .when("buttonType", {
            //     is: (buttonType: string) => buttonType == "callToAction",
            //     then: string().required("Button text is required")
            //         .max(25, "Maximum length exceeded"),
            // }),
            phone_number: Yup.number().when("type", {
                is: (type: string) => type === "callPhoneNumber",
                then: Yup.number().required("Phone number is required")
                    .test("length", "Maximum length exceeded", (value) => {
                        if (value) return value.toString().length <= 20
                        return true
                    }),
            }),
            url: Yup.string().when("type", {
                is: (type: string) => type === "visitWebsite",
                then: Yup.string()
                    .matches(
                        URL_VALIDATE_REGEX,
                        'Incorrect url format')
                    .max(2000, "Maximum length exceeded")
                    .required("URL is requiqred"),
            }),
        })
    )
})