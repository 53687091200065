import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import CashBookTableRow from "./CashBookTableRow";
import { getCashBookList } from "app/reducers/cashBook/cashBookAction";
import { getFilterQueryString } from "app/helpers/helperFunction";
import { searchParams } from "app/helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  cashBookDetails: any;
  getCashBookListDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end pe-5",
  },
];

const CashBookTable: FC<props> = ({
  cashBookDetails,
  getCashBookListDispatch,
}) => {
  const params = useParams();
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getCashBookListDispatch(
      { ...searchObj, Filters: getFilterQueryString({ IsCashBook: true }) },
      params.id
    );
  }, [getCashBookListDispatch, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={cashBookDetails.error}
        headers={HEADERS}
        success={cashBookDetails.cashBookList?.success}
        loading={cashBookDetails.loading}
        paginate={{
          show: true,
          pagination: cashBookDetails.cashBookList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={cashBookDetails.cashBookList?.data?.records?.BookRecords}
        tableRow={(row: any, index: number) => (
          <CashBookTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    cashBookDetails: state.cashBookList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCashBookListDispatch: (searchObj: searchParams, firmId: number) =>
      dispatch(getCashBookList(searchObj, firmId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CashBookTable);
