import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { TotalAmountOfTransaction } from "app/reducers/transaction/transactionDataInterface";
import { FC } from "react";

interface props {
  Total: TotalAmountOfTransaction | undefined;
}

const TransactionAmountDetail: FC<props> = ({ Total }) => {
  return (
    <div className="d-flex flex-wrap fs-5 gap-3 mb-3 w-100">
      <div className="d-flex gap-3 w-100 w-md-50 w-xl-550px">
        <div className="w-100 p-4 rounded-2 totalInAmount">
          <div className="fw-semibold me-3">
            <i className="fa-solid fa-arrow-up totalIn text-success me-3"></i>
            Total In
          </div>
          <div className="fw-bolder d-inline-flex align-items-center">
            <ImageDisplay
              altText="rupee"
              className="w-15px"
              errorPath="/media/task/rupee.png"
              path="/media/task/rupee.png"
              title="rupeeSymbol"
            />
            {Total?.In}
          </div>
        </div>
        <div className="w-100 p-4 rounded-2 totalOutAmount">
          <div className="fw-semibold me-3">
            <i className="fa-solid fa-arrow-up totalOut text-danger me-3"></i>
            Total Out
          </div>
          <div className="fw-bolder d-inline-flex align-items-center">
            <ImageDisplay
              altText="rupee"
              className="w-15px"
              errorPath="/media/task/rupee.png"
              path="/media/task/rupee.png"
              title="rupeeSymbol"
            />
            {Total?.Out}
          </div>
        </div>
      </div>
      <div className="closingBalanceAmount p-4 rounded-2 w-100 w-md-25 w-xl-275px">
        <div className="fw-semibold me-3">Closing Balance</div>
        <div className="fw-bolder d-inline-flex align-items-center">
          <ImageDisplay
            altText="rupee"
            className="w-15px"
            errorPath="/media/task/rupee.png"
            path="/media/task/rupee.png"
            title="rupeeSymbol"
          />
          {Total?.ClosingBalance}
        </div>
      </div>
    </div>
  );
};

export default TransactionAmountDetail;
