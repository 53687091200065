import { response } from "app/helpers/commonInterface";
import {
  GET_LEGAL_STATUS_LIST_FAILURE,
  GET_LEGAL_STATUS_LIST_REQUEST,
  GET_LEGAL_STATUS_LIST_SUCCESS,
  UPDATE_CLIENT_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";
import { client } from "./clientDataInterfce";
export interface legalStatusListState {
  loading: boolean;
  legalStatusList: response<client[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: legalStatusListState = {
  loading: false,
  legalStatusList: {},
  error: "",
};

export const getLegalStatusListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_LEGAL_STATUS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_LEGAL_STATUS_LIST_SUCCESS:
      return {
        loading: false,
        legalStatusList: action.payload,
        error: "",
      };
    case GET_LEGAL_STATUS_LIST_FAILURE:
      return {
        loading: false,
        legalStatusList: {},
        error: action.payload,
      };
    case UPDATE_CLIENT_STATUS_SUCCESS:
      return updateStatusFun(
        state,
        "legalStatusList",
        action.payload.data.records.Id
      );
    default:
      return state;
  }
};
