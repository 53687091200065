import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { adminHomePage } from "../../main/admin/AdminMain";
import { emptyObject } from "../../../../helpers/helperFunction";
import { getGroup } from "app/reducers/groups/groupAction";
import { groupListPage } from "../GroupList";
import { KTCard } from "../../../../../_metronic/helpers";
import { PageLink, PageSubTitle, PageTitle } from "../../../../../_metronic/layout/core";
import Table from "app/components/commonComponent/table/Table";

const HEADERS = [
  {
    columnName: "Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Email",
    id: "Email",
    className: "min-w-125px",
  },
  {
    columnName: "Phone Number",
    id: "Phone Number",
    className: "min-w-125px",
  },
  {
    columnName: "Default",
    id: "IsDefault",
    className: "min-w-125px",
  },
];

interface props {
  getGroupDetails: Function;
  groupDetails: any;
}

interface groupContact {
  row: {
    Name: string;
    Email: string;
    PhoneNumber: string;
    IsDefault: boolean;
  };
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  groupListPage,
  emptyObject,
];

const GroupContactPerson: FC<props> = ({
  getGroupDetails,
  groupDetails,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getGroupDetails(params.id);
    }
  }, [getGroupDetails, params.id]);
  
  const GroupContactTableRow: FC<groupContact> = ({ row }) => {
    return (
      <tr>
        <td className="text-dark fw-bold ps-2">{row.Name}</td>
        <td>
          <a href={`mailto:${row.Email}`}>{row.Email}</a>
        </td>
        <td>
          <a href={`tel:${row.PhoneNumber}`}>{row.PhoneNumber}</a>
        </td>
        <td>
          <span
            className={`badge badge-light-${
              row.IsDefault ? "success" : "danger"
            }`}
          >
            {row.IsDefault ? "Yes" : "No"}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Contact Persons</PageTitle>
      <PageSubTitle>{groupDetails?.group?.data?.records?.Name|| ""}</PageSubTitle>

      <KTCard>
        
        <Table
          checkBoxColumn={{ show: false }}
          error={groupDetails.error}
          headers={HEADERS}
          success={groupDetails.group?.success}
          loading={groupDetails.loading}
          paginate={{ show: false }}
          tableData={groupDetails.group?.data?.records?.Contacts}
          tableRow={(row: any, index: number) => (
            <GroupContactTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    groupDetails: state.group,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getGroupDetails: (groupId: number) => dispatch(getGroup(groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupContactPerson);
