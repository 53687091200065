import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addTransactionService,
  getTransactionListService,
  getTransactionReportListService,
  getTransactionService,
  updateTransactionService,
} from "./transactionService";
import {
  ADD_TRANSACTION_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTION_LIST_FAILURE,
  GET_TRANSACTION_LIST_REQUEST,
  GET_TRANSACTION_LIST_SUCCESS,
  GET_TRANSACTION_REPORT_LIST_FAILURE,
  GET_TRANSACTION_REPORT_LIST_REQUEST,
  GET_TRANSACTION_REPORT_LIST_SUCCESS,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
} from "../actionTypes";
import { searchParams } from "app/helpers/commonInterface";
import { addTransactionValue } from "app/components/pages/transaction/TransactionForm";
import { GetErrorMessage } from "../stateHelperFunction";

export const addTransaction = (
  data: addTransactionValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_TRANSACTION_REQUEST));

    await addTransactionService(data).then(
      (result: any) => {
        dispatch(success(ADD_TRANSACTION_SUCCESS, result.data));
        showToastMessageSuccess("Transaction added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_TRANSACTION_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getTransactionList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_TRANSACTION_LIST_REQUEST));

    getTransactionListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_TRANSACTION_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TRANSACTION_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getTransaction = (transactionId: number, isPayment: boolean) => {
  return (dispatch: any) => {
    dispatch(request(GET_TRANSACTION_REQUEST));

    getTransactionService(transactionId, isPayment).then(
      (result: any) => dispatch(success(GET_TRANSACTION_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TRANSACTION_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const updateTransaction = (
  data: addTransactionValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_TRANSACTION_REQUEST));

    await updateTransactionService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_TRANSACTION_SUCCESS, result.data));
        showToastMessageSuccess("Transaction updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_TRANSACTION_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getTransactionReportList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_TRANSACTION_REPORT_LIST_REQUEST));

    getTransactionReportListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_TRANSACTION_REPORT_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TRANSACTION_REPORT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
