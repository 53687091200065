import { connect, useDispatch } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { adminHomePage } from "../AdminMain";
import { emptyObject, nullObject } from "../../../../../helpers/helperFunction";
import ErrorDisplay from "../../../../commonComponent/ErrorDisplay";
import {
  getTask,
  updateTaskStatus,
} from "../../../../../reducers/task/taskAction";
import Loader from "../../../../commonComponent/loader/Loader";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import TaskAttachmentsDetails from "./taskAttachments/TaskAttachmentsDetails";
import TaskClientDetails from "./client/TaskClientDetails";
import TaskDetails from "./taskDetail/TaskDetails";
import TaskLogDetails from "./activity/TaskLogDetails";
import TaskRemarksDetails from "./remarks/TaskRemarksDetails";
import TaskReviewMessage from "./taskDetail/TaskReviewMessage";
import TaskActivityList from "./taskDetail/TaskActivityList";
import { useAuth } from "../../../auth";
import TaskTransactionDetails from "./taskTransaction/TaskTransactionDetails";
import { taskListPage } from "app/components/pages/task/TaskList";
import {
  CLIENT_REPORT_DETAILS,
  TASK_DETAIL_PAGE,
  TASK_REPORT_DETAILS,
} from "app/helpers/routes";
import { clientReportDetailsPage } from "app/components/pages/client/component/ClientReportDetail";
import { clientReportListPage } from "app/components/pages/client/ClientReportList";
import { taskData } from "app/reducers/task/taskDataInterface";
import { taskReportListPage } from "app/components/pages/task/TaskReportList";
import { SENT_FOR_CHECK } from "app/helpers/constants";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";

export interface assigneeChangeData {
  taskId: Array<number>;
  assigneeId: string;
  otp: string;
}
export interface statusChangeData {
  taskIds: Array<number>;
  status?: string;
  startDate?: Date;
}
interface props {
  taskDetails: {
    loading: boolean;
    task: {
      data: {
        records: taskData;
      };
      message: string;
      status: number;
      success: boolean;
    };
    error: string;
  };
  getTaskData: Function;
}

const AdminTaskDetailPage: FC<props> = ({ taskDetails, getTaskData }) => {
  const dispatch: any = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { currentUser } = useAuth();
  const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    ...(location.pathname.includes(CLIENT_REPORT_DETAILS)
      ? [clientReportListPage, clientReportDetailsPage(params.clientId || "")]
      : location.pathname.includes(TASK_DETAIL_PAGE)
      ? [taskListPage]
      : location.pathname.includes(TASK_REPORT_DETAILS)
      ? [taskReportListPage]
      : [nullObject]),
    emptyObject,
  ];

  const [forcedUpdate, setForcedUpdate] = useState(false);

  useEffect(() => {
    getTaskData(params.id);
  }, [params.id, currentUser, getTaskData, forcedUpdate]);

  //sent for check when all checkpoints are checked
  const sentForCheckWhenAllChecked = async (activities: any) => {
    for (let obj of activities) {
      if (!obj.IsCompleted) {
        console.log("all checkpoints are not checked");
        return;
      }
    }
    console.log("all checkpoints are checked");
    dispatch(
      updateTaskStatus({
        data: {
          taskIds: [Number(params.id)],
          status: String(SENT_FOR_CHECK.id), // Convert to string explicitly
        },
      })
    );
    getTaskData(params.id);
    setForcedUpdate((prev) => !prev);
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Task Details</PageTitle>
      {taskDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {taskDetails.error && <ErrorDisplay error={taskDetails.error} />}
      {taskDetails.task?.success && (
        <div>
          <div className="d-flex flex-column align-items-start flex-xl-row gap-5">
            <div className="d-flex flex-column gap-5 col-12 col-xl-7">
              <TaskDetails taskDetails={taskDetails.task.data.records} />
              <TaskReviewMessage taskDetails={taskDetails.task.data.records} />
              <div className="row">
                <TaskActivityList
                  taskDetails={taskDetails.task.data.records}
                  sentForCheckWhenAllChecked={sentForCheckWhenAllChecked}
                />
              </div>
              <div className="row">
                <TaskRemarksDetails
                  remarksArr={taskDetails.task.data.records.Remarks}
                />
                <TaskAttachmentsDetails
                  filesArr={taskDetails.task?.data?.records?.Files}
                />
              </div>
            </div>
            <div className="d-flex flex-lg-column flex-row flex-wrap gap-5 col-12 col-xl-5">
              <TaskClientDetails taskDetails={taskDetails.task.data.records} />
              {hasPaymentAccess() && (
                <>
                 <TaskTransactionDetails
                transactions={taskDetails.task.data.records.Payments}
                title="Payments"
              />
              <TaskTransactionDetails
                transactions={taskDetails.task.data.records.Receipts}
                title="Receipts"
              />
                </>
              )}
             
              <TaskLogDetails
                logDetails={taskDetails.task.data.records.Changes}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    taskDetails: state.task,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTaskData: (taskId: number) => dispatch(getTask(taskId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTaskDetailPage);
