import { FC } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { addTaskTypeValue, editTaskTypeValue } from "../TaskTypeForm";
import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import { emptyObject } from "../../../../helpers/helperFunction";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { TASK_TYPE_LIST } from "../../../../helpers/routes";
import { taskTypeListPage } from "../TaskTypeList";
import TaskTypeActivityForm from "./TaskTypeActivityForm";
import TaskTypeDetailForm from "./TaskTypeDetailForm";
import { tasktypeValidateSchema } from "./TaskTypeValidation";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

export interface taskTypeCategoryDetails {
  Id: number;
  Name: string;
}

interface props {
  initialValue: addTaskTypeValue;
  taskTypeDispatch: Function;
  title: string;
  taskTypeDetails?: editTaskTypeValue;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  taskTypeListPage,
  emptyObject,
];

const options: any = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const AddTaskType: FC<props> = ({
  masterDataList,
  initialValue,
  taskTypeDispatch,
  title,
  taskTypeDetails,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={tasktypeValidateSchema}
          onSubmit={async (values, { resetForm }) => {
            const updatedValue = {
              ...values,
              governmentFixDate: values.governmentFixDate
                ? new Intl.DateTimeFormat("en-US", options).format(
                    new Date(values.governmentFixDate)
                  )
                : values.governmentFixDate,
            };
            await taskTypeDispatch(
              updatedValue,
              params.id
                ? () => {
                    navigate(absolutePath(TASK_TYPE_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ values, setFieldValue, touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <TaskTypeDetailForm
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    taskTypeDetails={taskTypeDetails}
                    isSubmitting={isSubmitting}
                    values={values}
                    masterDataList={masterDataList}
                  />
                  <TaskTypeActivityForm
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="d-flex justify-content-end py-6 px-6 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(absolutePath(TASK_TYPE_LIST))
                    }
                  />
                  <SubmitButton
                    className="btn btn-primary"
                    isSubmitting={isSubmitting}
                    name="Submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddTaskType;
