import { response } from "app/helpers/commonInterface";
import {
  GET_TASK_REPORT_LIST_FAILURE,
  GET_TASK_REPORT_LIST_REQUEST,
  GET_TASK_REPORT_LIST_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface taskReportListState {
  loading: boolean;
  taskDetails: response<taskData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: taskReportListState = {
  loading: false,
  taskDetails: {},
  error: "",
};

export const getTaskReportListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_TASK_REPORT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_REPORT_LIST_SUCCESS:
      return {
        loading: false,
        taskDetails: action.payload,
        error: "",
      };
    case GET_TASK_REPORT_LIST_FAILURE:
      return {
        loading: false,
        taskDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
