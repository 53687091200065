import { connect } from "react-redux"
import { FC, useEffect } from "react"
import { useParams } from "react-router-dom"

import AddBankBook from "./component/AddBankBook"
import { addBankBook, getBankBook, updateBankBook } from "app/reducers/bankBook/bankBookAction"
import ErrorBoundary from "app/helpers/ErrorBoundary"
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay"
import Loader from "app/components/commonComponent/loader/Loader"

interface props {
    firmName: string
    bankBookDetails: any
    updateBankBookDispatch: Function
    addBankBookDispatch: Function
    getBankBookDetailsDispatch: Function
}

export interface addBankBookData {
    id?: number,
    isActive?: boolean,
    name: string,
    isCashBook: boolean,
    bankName: string,
    branchName: string,
    accountNumber: string,
    accountType: string,
    ifscCode: string,
    firmId: number
}

interface editBankBookData {
    Id: number,
    IsActive: boolean,
    Name: string,
    IsCashBook: boolean,
    BankName: string,
    BranchName: string,
    AccountNumber: string,
    AccountType: string,
    IFSCCode: string,
    FirmId: number
}

const getInitialValue = (firmId: number): addBankBookData => {
    return {
        name: "",
        isCashBook: false,
        bankName: "",
        branchName: "",
        accountNumber: "",
        accountType: "",
        ifscCode: "",
        firmId: firmId
    }
}

const getValueForEdit = (bankBookData: editBankBookData): addBankBookData => {
    return {
        id: bankBookData.Id,
        isActive: bankBookData.IsActive,
        name: bankBookData.Name,
        isCashBook: bankBookData.IsCashBook,
        bankName: bankBookData.BankName,
        branchName: bankBookData.BranchName,
        accountNumber: bankBookData.AccountNumber,
        accountType: bankBookData.AccountType,
        ifscCode: bankBookData.IFSCCode,
        firmId: bankBookData.FirmId
    }
}


const BankBookForm: FC<props> = ({ updateBankBookDispatch, addBankBookDispatch, getBankBookDetailsDispatch, bankBookDetails, firmName }) => {
    const params = useParams();

    useEffect(() => {
        if (params.bankBookId) {
            getBankBookDetailsDispatch(params.bankBookId)
        }
    }, [getBankBookDetailsDispatch, params.bankBookId])

    return (
        <ErrorBoundary>
            {(params.bankBookId && bankBookDetails.loading) && <div className='loader-container'><Loader /></div>}
            {(params.bankBookId && bankBookDetails.error) && <ErrorDisplay error={bankBookDetails.error} />}
            {(params.bankBookId ? bankBookDetails?.bankBook?.success : true) &&
                <>
                    {params.bankBookId && <AddBankBook
                        initialValue={getValueForEdit(bankBookDetails.bankBook.data.records)}
                        firmDispatch={updateBankBookDispatch}
                        title="Edit Bank Book"
                        firmName={firmName}
                    />}
                    {!params.bankBookId && <AddBankBook
                        firmName={firmName}
                        initialValue={getInitialValue(Number(params.id))}
                        firmDispatch={addBankBookDispatch}
                        title="Add Bank Book"
                    />}
                </>
            }
        </ErrorBoundary>
    )
}

const mapStateToProps = (state: any) => {
    return {
        bankBookDetails: state.bankBook,
        firmName: state?.bankBookList?.bankBookList?.data?.records?.FirmName || ""
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getBankBookDetailsDispatch: (bankBookId: number) => dispatch(getBankBook(bankBookId)),
        addBankBookDispatch: (data: addBankBookData, successCallback : Function) => dispatch(addBankBook(data, successCallback)),
        updateBankBookDispatch: (data: addBankBookData, successCallback : Function) => dispatch(updateBankBook(data, successCallback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankBookForm)