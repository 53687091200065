import {
  GET_RECORD_LIST_IN_BANK_BOOK_FAILURE,
  GET_RECORD_LIST_IN_BANK_BOOK_REQUEST,
  GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS,
  UPDATE_BANK_BOOK_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface bankBookListState {
  loading: boolean;
  bankBookList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: bankBookListState = {
  loading: false,
  bankBookList: {},
  error: "",
};

export const getBankBookListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_RECORD_LIST_IN_BANK_BOOK_REQUEST:
      return {
        loading: true,
      };
    case GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS:
      return {
        loading: false,
        bankBookList: action.payload,
        error: "",
      };
    case GET_RECORD_LIST_IN_BANK_BOOK_FAILURE:
      return {
        loading: false,
        bankBookList: {},
        error: action.payload,
      };
    case UPDATE_BANK_BOOK_STATUS_SUCCESS:
      return {
        ...state,
        bankBookList: {
          ...state["bankBookList"],
          data: {
            ...state["bankBookList"].data,
            records: {
              ...state["bankBookList"].data.records,
              BookRecords: state["bankBookList"].data.records.BookRecords.map(
                (item: any) => {
                  if (
                    String(item.Id) === String(action.payload.data.records.Id)
                  ) {
                    return { ...item, IsActive: !item.IsActive };
                  }
                  return item;
                }
              ),
            },
          },
        },
      };
    default:
      return state;
  }
};
