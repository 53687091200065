import { GET_CATEGORY_FAILURE, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS } from "../actionTypes"

export interface categoryDataState {
    loading  : boolean,
    category : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:categoryDataState = {
    loading  : false,
    category : {},
    error : ""
}

export const getCategoryByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_CATEGORY_REQUEST : return {
            loading : true
        }
        case GET_CATEGORY_SUCCESS : 
        return {
            loading : false,
            category : action.payload,
            error : ""
        }
        case GET_CATEGORY_FAILURE : return {
            loading : false,
            category : {},
            error : action.payload
        }
        default : return state
    }
}