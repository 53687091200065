import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  BOOK_API_ENDPOINT,
  GETALL_BOOK_API_ENDPOINT,
} from "app/helpers/config";

export const addCashBookService = async (data: any) => {
  return await axiosInstance.post(BOOK_API_ENDPOINT, data);
};

export const getCashBookListService = (
  searchObj: searchParams,
  firmId: number
) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GETALL_BOOK_API_ENDPOINT}/${firmId}?${queryString}`
  );
};

export const getCashBookService = (cashBookId: number) => {
  return axiosInstance.get(`${BOOK_API_ENDPOINT}?id=${cashBookId}`);
};

export const updateCashBookService = async (data: any) => {
  return await axiosInstance.put(BOOK_API_ENDPOINT, data);
};

export const updateCashBookStatusService = async (cashBookId: number) => {
  return await axiosInstance.delete(`${BOOK_API_ENDPOINT}?id=${cashBookId}`);
};
