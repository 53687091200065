import { FC } from "react";
import { object, string, array } from "yup";

import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import { DAILY_TASK_LIST } from "../../../../helpers/routes";
import { dailyTaskListPage } from "../DailyTaskList";
import {
  dailyTaskData,
  dailyTaskDataObject,
  editDailyTaskData,
  newDailyDataObject,
} from "../DailyTaskForm";
import { emptyObject } from "../../../../helpers/helperFunction";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
} from "formik";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  initialValue: dailyTaskData;
  title: string;
  newDailyTaskObject: newDailyDataObject;
  dailyTaskDataDispatch: Function;
  dailyTaskDetail?: editDailyTaskData;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}
const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  dailyTaskListPage,
  emptyObject,
];

const validateFun = object().shape({
  assigneeId: string().required("Article is required"),
  tasks: array().of(
    object().shape({
      description: string().required("Task is required"),
    })
  ),
});

const AddDailyTask: FC<props> = ({
  masterDataList,
  dailyTaskDetail,
  dailyTaskDataDispatch,
  newDailyTaskObject,
  title,
  initialValue,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="container-fluid px-0 client-form-container">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            await dailyTaskDataDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(DAILY_TASK_LIST));
                  }
                : () => {
                    resetForm();
                  }
            );
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Article
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select
                        ${
                          masterDataList.loading
                            ? "display-dropdown-loader"
                            : ""
                        }
                        ${
                          touched.assigneeId &&
                          errors.assigneeId &&
                          "is-invalid inValidBorder"
                        }`}
                          placeholder="Select Article"
                          displayLoader={masterDataList.loading}
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.Article?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          currentValue={{ value: values.assigneeId }}
                          name="assigneeId"
                          defaultValue={{
                            value: dailyTaskDetail
                              ? dailyTaskDetail.AssigneeId
                              : "",
                            name: dailyTaskDetail
                              ? dailyTaskDetail.Assignee
                              : "",
                          }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="assigneeId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-5 gap-md-7">
                    <FieldArray
                      name="tasks"
                      render={(arrayHelpers) => (
                        <div className="">
                          {values.tasks.map(
                            (tesk: dailyTaskDataObject, index: number) => (
                              <div
                                key={index}
                                className="border border-2 rounded-2 row px-2 mb-4"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div
                                    className="align-items-center d-flex gap-4 fs-4 fw-semibold min-h-50px w-100"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#task-${index}`}
                                  >
                                    <div>Task {index + 1}</div>
                                  </div>
                                  <div className="align-items-center d-flex justify-content-start gap-3 w-auto">
                                    {index !== 0 && (
                                      <button
                                        type="button"
                                        style={{ color: "#ff5959" }}
                                        className="btn btn-active-light-danger btn-light px-4 py-2 rounded-circle text-center"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        disabled={isSubmitting}
                                      >
                                        <i
                                          style={{ color: "#ff5959" }}
                                          className="fa-solid fa-close p-0"
                                        ></i>
                                      </button>
                                    )}
                                    {values.tasks.length - 1 == index && (
                                      <button
                                        type="button"
                                        className="btn btn-active-light-primary btn-light px-4 py-2 rounded-circle text-center"
                                        onClick={() =>
                                          arrayHelpers.insert(
                                            index + 1,
                                            newDailyTaskObject
                                          )
                                        }
                                        disabled={isSubmitting}
                                      >
                                        <i
                                          style={{ color: "#1da9f8" }}
                                          className="fa-solid fa-plus p-0"
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div
                                  id={`task-${index}`}
                                  className={`border-top collapse pt-5 ${
                                    values.tasks.length - 1 === index
                                      ? "show"
                                      : ""
                                  }`}
                                >
                                  <div className="row mb-6">
                                    <div className="col-sm-6 col-lg-6">
                                      <label className="col-form-label fw-semibold fs-6 required">
                                        Task
                                      </label>

                                      <div className="">
                                        <Field
                                          as="textarea"
                                          className={`form-control form-control-lg form-control-solid 
                                        ${
                                          touched.tasks &&
                                          (
                                            touched.tasks[
                                              index
                                            ] as FormikErrors<dailyTaskDataObject>
                                          )?.description &&
                                          errors.tasks &&
                                          (
                                            errors.tasks[
                                              index
                                            ] as FormikErrors<dailyTaskDataObject>
                                          )?.description &&
                                          "is-invalid inValidBorder"
                                        }`}
                                          placeholder="Task"
                                          id={`tasks.${index}.description`}
                                          name={`tasks.${index}.description`}
                                          disabled={isSubmitting}
                                        />
                                        <ErrorMessage
                                          name={`tasks.${index}.description`}
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(absolutePath(DAILY_TASK_LIST))
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddDailyTask;
