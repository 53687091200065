import { connect } from "react-redux";
import { FC, MouseEvent, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  assigneeChangeData,
  statusChangeData,
} from "../../main/admin/taskDetail/AdminTaskDetailPage";
import ClientMessagePopup from "../../../commonComponent/clientMessagePopup/ClientMessagePopup";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import {
  getSearchParameter,
  getStatusArr,
  getUrgent,
} from "../../../../helpers/helperFunction";
import {
  getTaskList,
  unSelectAllTask,
  updateBillStatus,
  updateTaskAssignee,
  updateTaskStatus,
  updateUrgentStatus,
} from "../../../../reducers/task/taskAction";
import hasPermission, { actionsRole } from "../../auth/core/permission";
import OTPPopup from "app/components/commonComponent/otpPopup/OTPPopup";
import { ResetButton } from "app/components/commonComponent/buttons/Buttons";
import { selectedState } from "../../../../reducers/task/taskSelectionReducer";
import { searchParams } from "../../../../helpers/commonInterface";
import { PAUSED, PAYMENT_STATUS } from "app/helpers/constants";
import PausedStatusModal from "./PausedStatusModal";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  selectedNumber: number;
  updateTaskStatusDispatch: Function;
  updateTaskAssigneeDispatch: Function;
  selectedTaskDetails: selectedState;
  getAllTaskList: Function;
  unSelectAllTaskDispatch: Function;
  setBill: Function;
  updateBillStatusDispatch: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  urgentStatus:any;
  updateUrgentStatusDispatch:Function
}

export interface files {
  id: number;
  file: any;
}

export interface clientMessage {
  show: boolean;
}
export interface selectedContactPerson {
  Id?: number;
  Email: string;
  MobileNumber: string;
  Name: string;
  ContactPersonId: number;
}
interface selectedTaskData {
  Id: number;
  ContactPersons: Array<selectedContactPerson>;
}

const BILL_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
  { value: PAYMENT_STATUS["Free Of Cost"], name: "Free Of Cost" },
];

const LOADER_STATUS = {
  TASK_STATUS: "taskStatus",
  BILL_STATUS: "billStatus",
  UPDATE_ASSIGNEE: "updateAssignee",
  URGENT_STATUS: 'Urgnt'
};

const TaskGrouping: FC<props> = ({
  setBill,
  getAllTaskList,
  selectedTaskDetails,
  selectedNumber,
  updateTaskStatusDispatch,
  updateTaskAssigneeDispatch,
  unSelectAllTaskDispatch,
  updateBillStatusDispatch,
  masterDataList,
  urgentStatus,
  updateUrgentStatusDispatch
}) => {

  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const [loading, setLoading] = useState({ show: false, source: "" });
  const [clientMessage, setClientMessage] = useState<clientMessage>({
    show: false,
  });
  const [otpPopup, setOtpPopup] = useState({
    show: false,
    apiData: "",
    article: "",
  });
  const [dropDownValue, setDropDownValue] = useState({ value: "", name: "" });
  const [showPausedPopup, setShowPausedPopup] = useState({
    show: false,
    apiData: "",
  });
  const contactsArr = useMemo(
    () =>
      selectedTaskDetails.taskListData.reduce(
        (acc: any, task: selectedTaskData) => {
          if (selectedTaskDetails.selected.includes(task.Id)) {
            const notAvailableContacts = task.ContactPersons.filter(
              (contact: selectedContactPerson) =>
                !acc.some(
                  (accContact: selectedContactPerson) =>
                    accContact.ContactPersonId === contact.ContactPersonId
                )
            );
            return [...acc, ...notAvailableContacts];
          }
          return acc;
        },
        []
      ),
    [selectedTaskDetails]
  );

  const closeModal = () => {
    setClientMessage({ show: false });
  };

  const statusChangeApiDispatch = async (e: any, startDate?: Date) => {
    setLoading({ show: true, source: LOADER_STATUS.TASK_STATUS });
    await updateTaskStatusDispatch(
      {
        taskIds: selectedTaskDetails.selected,
        status: e.target.dataset.id,
        startDate: startDate == undefined ? null : startDate,
      },
      () => {
        getAllTaskList(searchObj);
        unSelectAllTaskDispatch();
        setLoading({ show: false, source: "" });
      },
      () => {
        setLoading({ show: false, source: "" });
      }
    );
  };

  const onChangeStatusHandler = async (e: any) => {
    if (Number(e.target.dataset.id) === PAUSED.id) {
      setShowPausedPopup({ show: true, apiData: e });
    } else {
      statusChangeApiDispatch(e);
    }
  };

  const updateAssigneeApiCall = async (
    otpValue: string,
    successCallback: Function,
    failureCallback: Function
  ) => {
    await updateTaskAssigneeDispatch(
      {
        taskId: selectedTaskDetails.selected,
        assigneeId: otpPopup.apiData,
        otp: otpValue,
      },
      () => {
        getAllTaskList(searchObj);
        unSelectAllTaskDispatch();
        setDropDownValue({ value: otpPopup.apiData, name: otpPopup.article });
        successCallback();
      },
      () => {
        failureCallback();
      }
    );
  };

  const onChangeAssigneeHandler = async (e: any) => {
    if (hasPermission(actionsRole.ADMIN)) {
      setLoading({ show: true, source: LOADER_STATUS.UPDATE_ASSIGNEE });
      await updateTaskAssigneeDispatch(
        {
          taskId: selectedTaskDetails.selected,
          assigneeId: e.target.dataset.id,
          otp: "",
        },
        () => {
          getAllTaskList(searchObj);
          unSelectAllTaskDispatch();
          setDropDownValue({
            value: e.target.dataset.id,
            name: e.target.textContent,
          });
          setLoading({ show: false, source: "" });
        },
        () => {
          setLoading({ show: false, source: "" });
        }
      );
    } else {
      setOtpPopup({
        show: true,
        apiData: e.target.dataset.id,
        article: e.target.textContent,
      });
    }
  };

  const onChangeBillStatus = (e: MouseEvent<HTMLElement>) => {
    setLoading({ show: true, source: LOADER_STATUS.BILL_STATUS });
    updateBillStatusDispatch(
      selectedTaskDetails.selected,
      (e.target as HTMLLIElement).dataset.id,
      () => {
        getAllTaskList(searchObj);
        setLoading({ show: false, source: "" });
      },
      () => {
        setLoading({ show: false, source: "" });
      }
    );
  };

  const onChangeUrgentStatusHandler = (e: MouseEvent<HTMLElement>) => {
    setLoading({ show: true, source: LOADER_STATUS.URGENT_STATUS });
    var data = {
      taskIds : selectedTaskDetails.selected,
      isUrgent: (e.target as HTMLLIElement).dataset.id == '1' ? true : false
    }
    updateUrgentStatusDispatch(
      data,
      () => {
        getAllTaskList(searchObj);
        setLoading({ show: false, source: "" });
      },
      () => {
        setLoading({ show: false, source: "" });
      }
    );
  };

  return (
    <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-1">
      <div className="fw-bolder">
        <span className="me-2">{selectedNumber}</span> Selected
      </div>
      <div className="d-flex gap-3 align-items-center flex-wrap justify-content-end">
      <div>
          <DropDown
            placeholder="Select Urgent "
            options={getUrgent()}            className={`form-select form-select-sm form-select-white show text-start w-175px ${
              urgentStatus.loading 
                ? "display-dropdown-loader"
                : ""
            }`}
           apiCallDispatch={onChangeUrgentStatusHandler}
            displayLoader={
              loading.source == LOADER_STATUS.URGENT_STATUS  && loading.show
            }
          />
        </div>

        <div>
          <DropDown
            placeholder="Status"
            options={getStatusArr(10)}
            className={`form-select form-select-sm form-select-white show text-start w-175px ${
              loading.source === LOADER_STATUS.TASK_STATUS && loading.show
                ? "display-dropdown-loader"
                : ""
            }`}
            apiCallDispatch={onChangeStatusHandler}
            displayLoader={
              loading.source === LOADER_STATUS.TASK_STATUS && loading.show
            }
          />
        </div>
        <div>
          <DropDown
            placeholder="Select Bill Status"
            className={`form-select form-select-sm form-select-white show text-start w-175px ${
              loading.source === LOADER_STATUS.BILL_STATUS && loading.show
                ? "display-dropdown-loader"
                : ""
            }`}
            options={BILL_STATUS_OPTIONS}
            apiCallDispatch={onChangeBillStatus}
            displayLoader={
              loading.source === LOADER_STATUS.BILL_STATUS && loading.show
            }
          />
        </div>
        <div>
          <DropDown
            name="AssigneeId"
            placeholder="Select Article"
            className={`form-select form-select-sm form-select-white show text-start w-175px ${
              masterDataList.loading ||
              (loading.source === LOADER_STATUS.UPDATE_ASSIGNEE && loading.show)
                ? "display-dropdown-loader"
                : ""
            }`}
            displayLoader={
              masterDataList.loading ||
              (loading.source === LOADER_STATUS.UPDATE_ASSIGNEE && loading.show)
            }
            options={
              masterDataList.masterData?.data?.records?.Admin &&
              masterDataList.masterData?.data?.records?.Article &&
              masterDataList.masterData?.data?.records?.TeamLeader
                ? [
                    ...masterDataList.masterData?.data?.records?.Admin?.map(
                      (assignee: any) => {
                        return {
                          value: assignee.Id,
                          name: `${assignee.FirstName} ${assignee.LastName}`,
                        };
                      }
                    ),
                    ...masterDataList.masterData?.data?.records?.Article?.map(
                      (assignee: any) => {
                        return {
                          value: assignee.Id,
                          name: `${assignee.FirstName} ${assignee.LastName}`,
                        };
                      }
                    ),
                    ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                      (assignee: any) => {
                        return {
                          value: assignee.Id,
                          name: `${assignee.FirstName} ${assignee.LastName}`,
                        };
                      }
                    ),
                  ]
                : []
            }
            showSearch={true}
            currentValue={{
              value: dropDownValue.value,
              name: dropDownValue.name,
            }}
            apiCallDispatch={onChangeAssigneeHandler}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3">
          <div className="position-relative">
            <ResetButton
              name="Send Message"
              className="btn btn-primary btn-sm w-125px"
              onClickCallback={() =>
                setClientMessage({ ...clientMessage, show: true })
              }
            />
          </div>
          <div>
            <ResetButton
              name="Generate Bill"
              className="btn btn-linkedin btn-sm w-125px"
              onClickCallback={() =>
                setBill({ show: true, edit: false, billId: null })
              }
            />
          </div>
        </div>
      </div>
      {otpPopup.show && (
        <OTPPopup
          taskId={1}
          updateArticleFun={updateAssigneeApiCall}
          showOtpPopup={otpPopup}
          setShowOtpPopup={setOtpPopup}
        />
      )}
      <div className={`pop-up-overlay ${otpPopup.show ? "" : "hidden"}`}></div>
      <ClientMessagePopup
        contactsArr={contactsArr}
        clientMessage={clientMessage}
        closeModal={closeModal}
      />
      <PausedStatusModal
        setShowPausedPopup={setShowPausedPopup}
        showPausedPopup={showPausedPopup}
        updateFun={statusChangeApiDispatch}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedTaskDetails: state.selectedTask,
    masterDataList: state.masterData,
    urgentStatus : state.urgentStatus
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTaskAssigneeDispatch: (
      data: assigneeChangeData,
      successCallback: Function,
      failureCallback?: Function
    ) => dispatch(updateTaskAssignee(data, successCallback, failureCallback)),
    updateTaskStatusDispatch: (
      data: statusChangeData,
      successCallback: Function,
      failureCallback: Function
    ) => dispatch(updateTaskStatus({ data, successCallback, failureCallback })),
    getAllTaskList: (searchObj: searchParams) =>
      dispatch(getTaskList(searchObj)),
    updateUrgentStatusDispatch:(data:any, successCallback: Function,
      failureCallback: Function)=> dispatch (updateUrgentStatus(data,successCallback, failureCallback)),
    unSelectAllTaskDispatch: () => dispatch(unSelectAllTask()),
    updateBillStatusDispatch: (
      taskId: Array<number>,
      billStatus: number,
      successCallback: Function,
      failureCallback: Function
    ) =>
      dispatch(
        updateBillStatus(taskId, billStatus, successCallback, failureCallback)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskGrouping);
