import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { FC, useState } from "react"

interface props {
    src: string | undefined
    isRecordingAudio: boolean
    messageState?: string
    index: number
}

const AudioControls: FC<props> = ({ index, src, isRecordingAudio, messageState }) => {
    const [audioIsPlaying, setAudioIsPlaying] = useState({ play: false, id: 0 });
    const [audioIsMute, setAudioIsMute] = useState({ mute: false, id: 0 });

    const toggleAudioIcon = () => {
        const audio = document.getElementById(`player-${index}`) as HTMLAudioElement;
        // const controlBtn = document.getElementById('audio-control-icon') as HTMLButtonElement
        if (audio.paused) {
            audio.play();
            // controlBtn.innerHTML = 'pause'
            setAudioIsPlaying({ play: true, id: index })
        } else {
            audio.pause();
            // controlBtn.innerHTML = 'play'
            setAudioIsPlaying({ play: false, id: index })

        }
    }

    const audioSlider = () => {
        const audio = document.getElementById(`player-${index}`) as HTMLAudioElement;
        const timeline = document.getElementById(`time-slider-${index}`) as HTMLInputElement;
        if (audio.duration !== Infinity) {
            const time = (Number(timeline.value) * audio.duration) / 100;
            audio.currentTime = time;
        }
    }

    const changeTimelinePosition = () => {
        const timeline = document.getElementById(`time-slider-${index}`) as HTMLInputElement;
        const audio = document.getElementById(`player-${index}`) as HTMLAudioElement;
        if (audio.duration !== Infinity) {
            const percentagePosition = (100 * audio.currentTime) / audio.duration;
            timeline.style.backgroundSize = `${percentagePosition}% 100%`;
            timeline.value = `${percentagePosition}`;
        }
    }

    const audioEnded = () => {
        // const controlBtn = document.getElementById('audio-control-icon') as HTMLButtonElement
        // controlBtn.innerHTML = "play";
        setAudioIsPlaying({ play: false, id: index })
        // controlBtn.innerHTML = `${<i className="fa-solid fa-play"></i>}`;
    }

    const toggleSound = () => {
        // const volumeBtn = document.getElementById("audio-volume-icon") as HTMLButtonElement;
        const audio = document.getElementById(`player-${index}`) as HTMLAudioElement;
        audio.muted = !audio.muted;
        setAudioIsMute({ mute: !audio.muted, id: index })
        // volumeBtn.innerHTML = audio.muted ? "mute" : "sound";
    }

    return (
        <div>
            <audio id={`player-${index}`} onEnded={audioEnded} onTimeUpdate={changeTimelinePosition}>
                <source src={src} type="audio/webm" />
                Your browser does not support the audio element.
            </audio>
            <div className={`align-items-center d-flex mb-5 w-300px 
            ${isRecordingAudio ? "bg-light px-7 py-4 rounded-pill" : `bg-light-${messageState} p-5 rounded`}`}>
                {/* {` bg-light-${state}   p-5 rounded`}bg-light px-7 py-4 rounded-pill */}
                {!isRecordingAudio && <div className='bg-light-primary rounded-2'>
                    <ImageDisplay
                        altText='audio'
                        className='w-20px me-4'
                        errorPath='/media/task/icons8-musical-notes-90.png'
                        path='/media/task/icons8-musical-notes-90.png'
                        title='audio'
                    />
                </div>}
                <button id="audio-control-icon" className="p-0 btn btn-sm" onClick={toggleAudioIcon}>
                    {
                        (audioIsPlaying.play && audioIsPlaying.id === index) ?
                            <i className="fa-solid fa-pause text-hover-primary fs-4 p-0"></i> :
                            <i className="fa-solid fa-play text-hover-primary fs-4 p-0"></i>
                    }
                </button>
                <span className='audio-duration'>
                    {/* {calculateTime()} */}
                </span>
                <input type="range" id={`time-slider-${index}`} max="100" defaultValue="0" onChange={audioSlider} />
                <button id="audio-volume-icon" className="p-0 btn btn-sm" onClick={toggleSound}>
                    {
                        (audioIsMute.mute && audioIsMute.id === index) ?
                            <i className="fa-solid fa-volume-xmark text-hover-primary fs-4 p-0"></i> :
                            <i className="fa-solid fa-volume-high text-hover-primary fs-4 p-0"></i>
                    }
                </button>
            </div>
        </div>
    )
}

export default AudioControls