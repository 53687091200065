import { activityData } from "app/components/pages/main/admin/taskDetail/taskDetail/TaskDetails";
import { axiosInstance } from "../axiosInstance";
import {
  assigneeChangeData,
  statusChangeData,
} from "../../components/pages/main/admin/taskDetail/AdminTaskDetailPage";
import { addTaskValue } from "../../components/pages/task/TaskForm";
import { getSearchQueryString } from "../../helpers/helperFunction";
import { searchParams } from "../../helpers/commonInterface";
import {
  ASSIGNED_TASK_API_ENDPOINT,
  CHANGE_TASK_ASSIGNEE_API_ENDPOINT,
  CHANGE_TASK_STATUS_API_ENDPOINT,
  CHAT_NOTIFICATION_TASK_API_ENDPOINT,
  DOWNLOAD_FILE_API_ENDPOINT,
  FILE_API_ENDPOINT,
  GENERAL_FILE_UPLOAD_API_ENDPOINT,
  GET_ALL_TASK_API_ENDPOINT,
  GET_URGENT_TASK,
  REMARKS_API_ENDPOINT,
  SEND_OTP_REQUEST_API_ENDPOINT,
  SET_URGENT_STATUS,
  TASK_API_ENDPOINT,
  TASK_FOR_CHECKING_API_ENDPOINT,
  TASK_REPORT_DETAILS_API_ENDPOINT,
  UPDATE_BILL_STATUS_API_ENDPOINT,
  USER_LIST_API_ENDPOINT,
  WORKTASK_ACTIVITY_STATUS_API_ENDPOINT,
} from "../../helpers/config";
import { uploadFileData } from "app/components/pages/main/admin/taskDetail/taskAttachments/TaskAttachmentsDetails";
import { remarkData } from "app/components/pages/main/admin/taskDetail/remarks/TaskRemarksForm";
import { sentForCheckDataModal } from "app/components/pages/main/admin/taskDetail/taskDetail/TaskDetailHeader";

export const addTaskService = async (data: addTaskValue) => {
  return await axiosInstance.post(TASK_API_ENDPOINT, data);
};

export const getTaskListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GET_ALL_TASK_API_ENDPOINT}?${queryString}`);
};

export const getChatNotificationTasksService = (ids: number[]) => {
  return axiosInstance.get(`${CHAT_NOTIFICATION_TASK_API_ENDPOINT}`);
};

export const getTaskService = (taskId: number) => {
  return axiosInstance.get(`${TASK_API_ENDPOINT}?id=${taskId}`);
};

export const updateTaskService = async (data: addTaskValue) => {
  return await axiosInstance.put(TASK_API_ENDPOINT, data);
};

export const getAssigneeService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${USER_LIST_API_ENDPOINT}?${queryString}`);
};

export const getCreaterService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${USER_LIST_API_ENDPOINT}?${queryString}`);
};

export const getAssigneedTaskToUserService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${ASSIGNED_TASK_API_ENDPOINT}?${queryString}`);
};

export const getPlannerTaskService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${ASSIGNED_TASK_API_ENDPOINT}?${queryString}`);
};
export const getUrgentPlannerTaskService = (userId: string | null) => {
  let param = userId != null ? `?userId=${userId}` : '';
  return axiosInstance.get(`${GET_URGENT_TASK}${param}`);
};

export const updateTaskAssigneeService = (data: assigneeChangeData) => {
  return axiosInstance.put(CHANGE_TASK_ASSIGNEE_API_ENDPOINT, {
    assigneeId: data.assigneeId,
    taskIds: data.taskId,
    otp: data.otp,
  });
};

export const updateTaskStatusService = async (data: statusChangeData) => {
  return await axiosInstance.put(CHANGE_TASK_STATUS_API_ENDPOINT, data);
};

export const uploadFileService = async (data: uploadFileData) => {
  return await axiosInstance.post(FILE_API_ENDPOINT, data);
};

export const deleteFileService = (id: number) => {
  return axiosInstance.delete(`${FILE_API_ENDPOINT}?Id=${id}`);
};

export const addRemarksService = async (data: remarkData) => {
  return await axiosInstance.post(REMARKS_API_ENDPOINT, data);
};

export const editRemarksService = async (data: remarkData) => {
  return await axiosInstance.put(REMARKS_API_ENDPOINT, data);
};

export const deleteRemarksService = (id: number) => {
  return axiosInstance.delete(`${REMARKS_API_ENDPOINT}?Id=${id}`);
};

export const uploadFileToRemarks = async (fileData: any) => {
  return await axiosInstance.post(DOWNLOAD_FILE_API_ENDPOINT, fileData);
};

export const downloadFileService = async (
  downloadDataObj: { id: number; destination?: string },
  fileName: string
) => {
  const queryString = getSearchQueryString(downloadDataObj);
  return axiosInstance
    .get(`${DOWNLOAD_FILE_API_ENDPOINT}?${queryString}`, {
      responseType: "blob",
    })
    .then((res) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(res.data);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
};

export const changeStatusOfActivityService = async (
  activityData: activityData
) => {
  const queryString = getSearchQueryString(activityData);
  return await axiosInstance.put(
    `${WORKTASK_ACTIVITY_STATUS_API_ENDPOINT}?${queryString}`
  );
};

export const taskForCheckingService = async (
  sentData: sentForCheckDataModal
) => {
  return await axiosInstance.put(
    `${TASK_FOR_CHECKING_API_ENDPOINT}`,
    sentData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const generalfileUploadService = async (file: any) => {
  return await axiosInstance.post(GENERAL_FILE_UPLOAD_API_ENDPOINT, file);
};

export const sendOTPRequestService = async (taskId: number) => {
  return await axiosInstance.post(
    `${SEND_OTP_REQUEST_API_ENDPOINT}?taskId=${taskId}`
  );
};

export const updateBillStatusService = async (
  taskId: Array<number>,
  billStatus: number
) => {
  return await axiosInstance.post(
    `${UPDATE_BILL_STATUS_API_ENDPOINT}?billingStatus=${billStatus}`,
    taskId
  );
};

export const updateUrgentStatusService = async (
 data:any
) => {
  return await axiosInstance.put(
    `${SET_URGENT_STATUS}`,
    data
  );
};

export const getTaskReportListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${TASK_REPORT_DETAILS_API_ENDPOINT}?${queryString}`
  );
};
