import { response } from "app/helpers/commonInterface";
import {
  GET_TASK_REPORT_LIST_FAILURE,
  GET_TASK_REPORT_LIST_REQUEST,
  GET_TASK_REPORT_LIST_SUCCESS,
  SET_URGENT_STATUS_FAILURE,
  SET_URGENT_STATUS_REQUEST,
  SET_URGENT_STATUS_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface urgentStatusState {
  loading: boolean;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: urgentStatusState = {
  loading: false,
  error: "",
};

export const urgentStatusReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case SET_URGENT_STATUS_REQUEST:
      return {
        loading: true,
      };
    case SET_URGENT_STATUS_SUCCESS:
      return {
        loading: false,
        error: "",
      };
    case SET_URGENT_STATUS_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
