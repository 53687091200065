import { axiosInstance } from "../axiosInstance"
import { DOCUMENT_TYPE_API_ENDPOINT, GET_ALL_DOCUMENT_TYPE_API_ENDPOINT } from "../../helpers/config"
import { getSearchQueryString } from "app/helpers/helperFunction"

export const addDocumentTypeService = async (data : any) => {
    return await axiosInstance.post(DOCUMENT_TYPE_API_ENDPOINT, data)
}

export const editDocumentTypeService = async (data : any) => {
    return await axiosInstance.put(DOCUMENT_TYPE_API_ENDPOINT, data)
}

export const getDocumentTypeService = (documentId : number) => {
    return axiosInstance.get(`${DOCUMENT_TYPE_API_ENDPOINT}?id=${documentId}`)
}

export const updateDocumentTypeStatusService = (documentId : number) => {
    return axiosInstance.delete(`${DOCUMENT_TYPE_API_ENDPOINT}?id=${documentId}`)
}

export const getDocumentTypeListService = (filterData : any) => {
    const queryString = getSearchQueryString(filterData)
    return axiosInstance.get(`${GET_ALL_DOCUMENT_TYPE_API_ENDPOINT}?${queryString}`)
}