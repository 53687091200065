import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { FC, useRef, useState } from "react";

import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import FilterButton from "app/components/commonComponent/tableFilter/FilterButton";
import { getFilterQueryString } from "app/helpers/helperFunction";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

interface tasks {
  Id: number;
  TaskCode: string;
  ClientId: number;
  ClientName: string;
}

const getTaskDataBySelectedClient = (tasks: tasks[], clientId: string) => {
  return tasks?.reduce(
    (acc: { value: number; name: string }[], task: tasks) => {
      if (String(task.ClientId) === String(clientId))
        acc.push({ value: task.Id, name: task.TaskCode });
      return acc;
    },
    []
  );
};

const DocumentFilter: FC<props> = ({ masterDataList }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`shadow-sm card w-300px w-sm-400px w-md-500px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="p-7">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <Formik
          enableReinitialize
          initialValues={filterObj}
          onSubmit={async (values: any) => {
            setSearchParam({
              ...searchObj,
              Filters: getFilterQueryString(values),
            });
          }}
        >
          {({ values, isSubmitting, setFieldValue, resetForm }) => {
            return (
              <Form noValidate className="form">
                <div className="mb-10 px-9 pt-3">
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Document Name
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Document Name"
                          options={masterDataList.masterData?.data?.records?.DocumentTypes.map(
                            (document: { Id: Number; Name: string }) => {
                              return {
                                value: Number(document.Id),
                                name: document.Name,
                              };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="TypeId"
                          currentValue={{
                            value: values.TypeId,
                            name: masterDataList.masterData?.data?.records?.DocumentTypes.find(
                              (document: { Id: Number; Name: string }) =>
                                String(document.Id) === String(values.TypeId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.TypeId,
                            name: masterDataList.masterData?.data?.records?.DocumentTypes.find(
                              (document: { Id: Number; Name: string }) =>
                                String(document.Id) === String(values.TypeId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Upload By
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Article"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.Article?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name="UploadedBy"
                          currentValue={{
                            value: values.UploadedBy,
                            name: values.UploadedBy,
                          }}
                          defaultValue={{
                            value: values.UploadedBy,
                            name: values.UploadedBy,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Client
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Client"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Clients?.map(
                            (client: { Id: number; Name: string }) => {
                              return { value: client.Id, name: client.Name };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="ClientId"
                          currentValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Task Code
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Task Code"
                          options={getTaskDataBySelectedClient(
                            masterDataList.masterData?.data?.records?.Tasks,
                            values.ClientId
                          )}
                          setFieldValue={setFieldValue}
                          name="TaskId"
                          currentValue={{
                            value: values.TaskId,
                            name: masterDataList.masterData?.data?.records?.Tasks?.find(
                              (task: { Id: number; TaskCode: string }) =>
                                String(task.Id) === String(values.TaskId)
                            )?.TaskCode,
                          }}
                          defaultValue={{
                            value: values.TaskId,
                            name: masterDataList.masterData?.data?.records?.Tasks?.find(
                              (task: { Id: number; TaskCode: string }) =>
                                String(task.Id) === String(values.TaskId)
                            )?.TaskCode,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Start Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="startDate"
                          placeholder="Start Date"
                          currentValue={
                            values.startDate ? new Date(values.startDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        End Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="endDate"
                          placeholder="End Date"
                          currentValue={
                            values.endDate ? new Date(values.endDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-6 px-6 gap-3">
                  <ResetButton
                    name="Reset"
                    className="btn btn-light btn-active-light-primary btn-sm"
                    onClickCallback={() => {
                      resetHandler(resetForm);
                      setShowDropDown(false);
                    }}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary btn-sm"
                    onClickCallback={() => setShowDropDown(false)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

export default connect(mapStateToProps)(DocumentFilter);
