import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import { ErrorMessage, Form, Formik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import { string, object } from "yup";

interface props {
  showPausedPopup: { show: boolean; apiData: any };
  updateFun: Function;
  setShowPausedPopup: Function;
}

const PausedStatusModal: FC<props> = ({
  showPausedPopup,
  updateFun,
  setShowPausedPopup,
}) => {
  return (
    <Modal
      show={showPausedPopup.show}
      aria-labelledby="contained-modal-otp"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Update Status</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5 fw-semibold">
        <Formik
          enableReinitialize
          initialValues={{ startDate: "" }}
          validationSchema={object().shape({
            startDate: string().required("Start date is required"),
          })}
          onSubmit={async (values) => {
            const changedDateFormat = new Date(values.startDate).toDateString();
            await updateFun(showPausedPopup.apiData, changedDateFormat);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div> Add start date to continue task from that day</div>
                <label className="col-form-label fw-semibold fs-6 required">
                  Start Date
                </label>
                <div className="col-6 fv-row">
                  <CustomDatePicker
                    name="startDate"
                    placeholder="Start Date"
                    setFieldValue={setFieldValue}
                    currentValue={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    disabled={isSubmitting}
                  />
                  <ErrorMessage
                    name="startDate"
                    component="div"
                    className="errorMsg"
                  />
                </div>
                <div className="d-flex gap-3 justify-content-end mt-4">
                  <ResetButton
                    className="btn btn-light btn-sm btn-active-light-primary"
                    name="Cancel"
                    onClickCallback={() =>
                      setShowPausedPopup({ show: false, apiData: "" })
                    }
                  />
                  <SubmitButton
                    className="btn btn-primary btn-sm"
                    isSubmitting={isSubmitting}
                    name="Submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PausedStatusModal;
