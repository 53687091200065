import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { FC, useState } from "react";

import {
  changeWpTemplateTextFormat,
  changeWpTemplateVariable,
} from "../../../helpers/helperFunction";
import {
  clientMessage,
  selectedContactPerson,
} from "../../pages/task/component/TaskGrouping";
import WPTemplateForm from "./component/WPTemplateForm";
import WPTemplateList from "./component/WPTemplateList";
import { Modal } from "react-bootstrap";

interface props {
  clientMessage: clientMessage;
  contactsArr: Array<selectedContactPerson>;
  closeModal: Function;
}

export interface selectedTemplateComponent {
  type: string;
  format?: string;
  text?: string;
}

export interface selectedTemplateData {
  name: string;
  previous_category: string;
  components: Array<selectedTemplateComponent>;
  language: string;
  status: string;
  category: string;
  id: string;
}

export interface selectedTemplateInitailValue {
  name: string;
  components: [
    {
      contactNumber: string;
      headerParameter: {
        type: string | undefined;
        value: string;
      };
      bodyParameters: [];
    }
  ];
  contactsArr?: Array<{ selectedValue: string; selectedName: string }>;
}

export const getReplacedStr = (
  str: string | undefined,
  className: string,
  errors?: FormikErrors<FormikValues>,
  touched?: FormikTouched<FormikValues>
) => {
  const data = changeWpTemplateVariable(
    changeWpTemplateTextFormat(str || ""),
    className,
    errors,
    touched
  );
  return `<p>${data}</p>`;
};

const ClientMessagePopup: FC<props> = ({
  closeModal,
  clientMessage,
  contactsArr,
}) => {
  const [selectedData, setSelectedData] = useState<selectedTemplateData>();

  return (
    // <div className='card client-pop-up col-11 col-md-8 col-xl-7 rounded-4 shadow-sm'>
    <Modal
      show={clientMessage.show}
      aria-labelledby="contained-modal-session"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      {/* <div className='card-header'> */}
      <Modal.Header>
        {/* <div className='align-items-center d-flex fs-2 fw-bold gap-3'> */}
        <Modal.Title>
          {/* <i className="fa-solid fa-envelope fs-3"></i> */}
          Send Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className='p-5 pb-7 py-5 overflow-auto mh-500px mh-xl-600px vertical-scroll'> */}
        <WPTemplateList
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
        <WPTemplateForm
          closeModal={closeModal}
          selectedData={selectedData}
          contactsArr={contactsArr}
        />
        {/* </div> */}
      </Modal.Body>
    </Modal>
    /* </div> */
  );
};

export default ClientMessagePopup;
