import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import { searchParams } from "app/helpers/commonInterface";
import {
  addCashBookService,
  getCashBookService,
  getCashBookListService,
  updateCashBookService,
  updateCashBookStatusService,
} from "./cashBookService";
import {
  ADD_RECORD_IN_CASH_BOOK_FAILURE,
  ADD_RECORD_IN_CASH_BOOK_REQUEST,
  ADD_RECORD_IN_CASH_BOOK_SUCCESS,
  GET_RECORD_IN_CASH_BOOK_FAILURE,
  GET_RECORD_IN_CASH_BOOK_REQUEST,
  GET_RECORD_IN_CASH_BOOK_SUCCESS,
  GET_RECORD_LIST_IN_CASH_BOOK_FAILURE,
  GET_RECORD_LIST_IN_CASH_BOOK_REQUEST,
  GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS,
  UPDATE_CASH_BOOK_STATUS_FAILURE,
  UPDATE_CASH_BOOK_STATUS_REQUEST,
  UPDATE_CASH_BOOK_STATUS_SUCCESS,
  UPDATE_RECORD_IN_CASH_BOOK_FAILURE,
  UPDATE_RECORD_IN_CASH_BOOK_REQUEST,
  UPDATE_RECORD_IN_CASH_BOOK_SUCCESS,
} from "../actionTypes";
import { addCashBookData } from "app/components/pages/cashBook/CashBookForm";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addCashBook = (
  data: addCashBookData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_RECORD_IN_CASH_BOOK_REQUEST));

    await addCashBookService(data).then(
      (result: any) => {
        dispatch(success(ADD_RECORD_IN_CASH_BOOK_SUCCESS, result));
        showToastMessageSuccess("Cash Book added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BOOKS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_RECORD_IN_CASH_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getCashBookList = (searchObj: searchParams, firmId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_RECORD_LIST_IN_CASH_BOOK_REQUEST));

    getCashBookListService(searchObj, firmId).then(
      (result: any) =>
        dispatch(success(GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_RECORD_LIST_IN_CASH_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getCashBook = (cashBookId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_RECORD_IN_CASH_BOOK_REQUEST));

    getCashBookService(cashBookId).then(
      (result: any) =>
        dispatch(success(GET_RECORD_IN_CASH_BOOK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_RECORD_IN_CASH_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const updateCashBook = (
  data: addCashBookData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_RECORD_IN_CASH_BOOK_REQUEST));

    await updateCashBookService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_RECORD_IN_CASH_BOOK_SUCCESS, result.data));
        showToastMessageSuccess("Cash Book updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BOOKS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_RECORD_IN_CASH_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const updateCashBookStatus = (
  bankBookId: number,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_CASH_BOOK_STATUS_REQUEST));

    await updateCashBookStatusService(bankBookId).then(
      (result: any) => {
        dispatch(success(UPDATE_CASH_BOOK_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Cash book status updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CASH_BOOK_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
