import { connect } from "react-redux"
import { FC } from "react"
import { Link } from "react-router-dom"

import { absolutePath } from "../../helpers/relativePath"
import { HOME_PAGE } from "../../helpers/routes"

interface props {
    profileDetails: any
}

const HeaderLogo: FC<props> = ({ profileDetails }) => {
    return (
        <Link to={absolutePath(HOME_PAGE)}
            className="d-flex gap-2 align-items-center">
            {/* <div className="w-55px">
                <ImageDisplay path="/media/auth/logo1.png" altText="companyLogo" className="img-fit-to-div" errorPath="" />
            </div> */}
            <div className="fs-2 fw-bold">
                {profileDetails?.profileData?.CompanyName || "Company Name"}
            </div>
        </Link>
    )
}
const mapStateToProps = (state: any) => {
    return {
        profileDetails: state.profile
    }
}

export default connect(mapStateToProps)(HeaderLogo)