import {
  GET_CATEGORY_LIST_FAILURE,
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface categoryListState {
  loading: boolean;
  categoryList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: categoryListState = {
  loading: false,
  categoryList: {},
  error: "",
};

export const categoryListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categoryList: action.payload,
        error: "",
      };
    case GET_CATEGORY_LIST_FAILURE:
      return {
        loading: false,
        categoryList: {},
        error: action.payload,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return updateStatusFun(
        state,
        "categoryList",
        action.payload.data.records.Id
      );
    default:
      return state;
  }
};
