import { response } from "app/helpers/commonInterface";
import {
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
} from "../actionTypes";
import { client } from "./clientDataInterfce";

export interface clientDataState {
  loading: boolean;
  client: response<client>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: clientDataState = {
  loading: false,
  client: {},
  error: "",
};

export const getClientByIdReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_CLIENT_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_CLIENT_BY_ID_SUCCESS:
      return {
        loading: false,
        client: action.payload,
        error: "",
      };
    case GET_CLIENT_BY_ID_FAILURE:
      return {
        loading: false,
        client: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
