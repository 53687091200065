import { axiosInstance } from "../axiosInstance"
import { addUserData, searchParams } from "../../helpers/commonInterface"
import { ADD_USER_API_ENDPOINT, UPDATE_USER_STATUS_API_ENDPOINT, USER_LIST_API_ENDPOINT, USER_LIST_REPORTED_AUTHORITY_API_ENDPOINT } from "../../helpers/config"
import { getSearchQueryString } from "../../helpers/helperFunction"

export const addUserService = async (data : addUserData) => {
    return await axiosInstance.post(ADD_USER_API_ENDPOINT, data)
}

export const getUserListService = async (searchObj : searchParams) => {
    const queryString = getSearchQueryString(searchObj)
    return await axiosInstance.get(`${USER_LIST_API_ENDPOINT}?${queryString}`)
}

export const updateUserStatusService = (userId : number) => {
    return axiosInstance.put(`${UPDATE_USER_STATUS_API_ENDPOINT}?userId=${userId}`)
}

export const getUsersByReportingAuthority =  (searchObj : searchParams,isActive:boolean) => {
    return axiosInstance.get(`${USER_LIST_REPORTED_AUTHORITY_API_ENDPOINT}?IsActive=${isActive}`)
}