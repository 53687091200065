import { UPDATE_USER_STATUS_FAILURE, UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS } from "../actionTypes"

export interface userUpdateState {
    loading  : boolean,
    userUpdateStatus : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:userUpdateState = {
    loading  : false,
    userUpdateStatus : {},
    error : ""
}

export const updateUserStatusReducer = (state = initialState, action : action) => {
    switch(action.type){
        case UPDATE_USER_STATUS_REQUEST : return {
            loading : true
        }
        case UPDATE_USER_STATUS_SUCCESS : return {
            loading : false,
            userUpdateStatus : action.payload,
            error : ""
        }
        case UPDATE_USER_STATUS_FAILURE : return {
            loading : false,
            userUpdateStatus : {},
            error : action.payload
        }
        default : return state
    }
}