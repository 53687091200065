import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { ADD_EXPENSE, EXPENSE_LIST } from "app/helpers/routes";
import { emptyObject } from "app/helpers/helperFunction";
import ExpenseTable from "./component/ExpenseTable";
import { KTCard } from "_metronic/helpers";
import { PageLink, PageTitle } from "_metronic/layout/core";
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";

export const expenseListPage = {
  title: "Expenses",
  path: absolutePath(EXPENSE_LIST),
  isSeparator: true,
  isActive: true,
};

const expenseBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ExpenseList = () => {
  return (
    <>
      <PageTitle breadcrumbs={expenseBreadCrumbs}>Expense</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
        <UsersListSearchComponent />
        <UsersListToolbar
          buttons={[{ title: "Add Expense", to: ADD_EXPENSE }]}
        />
      </div>
      <KTCard>
        <ExpenseTable />
      </KTCard>
    </>
  );
};

export default ExpenseList;
