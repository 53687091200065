import {
  GET_CHAT_MSG_LIST_FAILURE,
  GET_CHAT_MSG_LIST_REQUEST,
  GET_CHAT_MSG_LIST_SUCCESS,
} from "../actionTypes";

export interface chatListState {
  loading: boolean;
  chatList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: chatListState = {
  loading: false,
  chatList: {},
  error: "",
};

export const chatListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_CHAT_MSG_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_CHAT_MSG_LIST_SUCCESS:
      return {
        loading: false,
        chatList: action.payload,
        error: "",
      };
    case GET_CHAT_MSG_LIST_FAILURE:
      return {
        loading: false,
        chatList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
