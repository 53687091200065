import { connect } from "react-redux";
import { FC, useEffect } from "react";

import DailyTaskTableRow from "./DailyTaskTableRow";
import { getDailyTaskList } from "../../../../reducers/dailyTask/dailyTaskAction";
import Table from "app/components/commonComponent/table/Table";

const HEADERS = [
  {
    columnName: "Article",
    id: "NULL",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Progress",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-sm-125px min-w-175px text-end pe-5",
  },
];

interface props {
  getDailyTaskListDispatch: Function;
  dailyTaskListDetails: any;
}

const DailyTaskTable: FC<props> = ({
  dailyTaskListDetails,
  getDailyTaskListDispatch,
}) => {
  useEffect(() => {
    getDailyTaskListDispatch();
  }, [getDailyTaskListDispatch]);

  return (
    <>
      
      <Table
        checkBoxColumn={{ show: false }}
        error={dailyTaskListDetails.error}
        headers={HEADERS}
        success={dailyTaskListDetails.dailyTaskList?.success}
        loading={dailyTaskListDetails.loading}
        paginate={{ show: false }}
        tableData={dailyTaskListDetails.dailyTaskList?.data?.records}
        tableRow={(row: any, index: number) => (
          <DailyTaskTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dailyTaskListDetails: state.dailyTaskList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDailyTaskListDispatch: () => dispatch(getDailyTaskList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTaskTable);
