import { connect } from "react-redux";
import { FC, useState } from "react";

import { deleteFile, downloadFile } from "app/reducers/task/taskAction";
import { dateConverter, getImage } from "app/helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { taskFiles } from "app/reducers/task/taskDataInterface";

interface props {
  downloadFileDispatch: Function;
  deleteFileDispatch: Function;
  filesArr: taskFiles[];
}

const TaskAttachmentsList: FC<props> = ({
  downloadFileDispatch,
  deleteFileDispatch,
  filesArr,
}) => {
  const [downloadLoading, setDownloadLoading] = useState({
    loading: false,
    id: 0,
    source: "",
  });

  return (
    <div className="card-body px-7 py-4 d-flex flex-wrap gap-4 align-items-center">
      {!filesArr.length ? (
        <div className="fs-5 fw-semibold">No records found</div>
      ) : (
        <div className="mh-300px overflow-auto w-100 vertical-scroll">
          {filesArr.map((file: taskFiles, index: number) => {
            return (
              <div
                key={index}
                className="w-100 d-flex justify-content-between align-items-center gap-3 fw-semibold p-3 rounded-2 border border-gray-300 text-gray-600 mb-4"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="w-35px">
                    <ImageDisplay
                      path={getImage(file.MimeType)}
                      errorPath="/media/task/doc-1.png"
                      className="img-fit-to-div"
                      altText="documentType"
                    />
                  </div>
                  <div className="fw-semibold">
                    <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                      {file.DocumentType}
                    </div>
                    <div className="d-flex gap-2 text-gray-400">
                      <span>{dateConverter(file.DateAdded)}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 pe-3">
                  <button
                    className="btn btn-active-color-primary btn-sm p-0"
                    onClick={() => {
                      setDownloadLoading({
                        loading: true,
                        id: file.Id,
                        source: "download",
                      });
                      downloadFileDispatch(
                        { id: file.Id },
                        `${file.DocumentType}.${file.MimeType}`,
                        () => {
                          setDownloadLoading({
                            loading: false,
                            id: 0,
                            source: "",
                          });
                        }
                      );
                    }}
                  >
                    {downloadLoading.source === "download" &&
                    downloadLoading.loading &&
                    downloadLoading.id === file.Id ? (
                      <div className="w-20px text-gray-600">
                        <SmallLoader />
                      </div>
                    ) : (
                      <i className="fa-solid fa-download"></i>
                      // <ImageDisplay
                      //   path="media/task/download-arrow.png"
                      //   altText="download-arrow"
                      //   className="w-20px"
                      //   errorPath="/media/task/download-arrow.png"
                      // />
                    )}
                  </button>
                  <button
                    className="btn btn-active-color-primary btn-sm p-0"
                    onClick={() => {
                      setDownloadLoading({
                        id: file.Id,
                        loading: true,
                        source: "delete",
                      });
                      deleteFileDispatch(file.Id, () => {
                        setDownloadLoading({
                          id: 0,
                          loading: false,
                          source: "",
                        });
                      });
                    }}
                  >
                    {downloadLoading.source === "delete" &&
                    downloadLoading.loading &&
                    downloadLoading.id === file.Id ? (
                      <div className="text-gray-600">
                        <SmallLoader />
                      </div>
                    ) : (
                      <i className="fa-solid fa-trash p-0"></i>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteFileDispatch: (id: number, successCallback: Function) =>
      dispatch(deleteFile(id, successCallback)),
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TaskAttachmentsList);
