import clsx from "clsx";
import { connect } from "react-redux";
import { FC, useMemo, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";

import { loginAction } from "../../../../reducers/login/loginAction";
import { loginData } from "../../../../helpers/commonInterface";
import { useAuth } from "../core/Auth";
import {
  Link,
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setupAxios } from "../core/AuthHelpers";
import hasPermission, { actionsRole } from "../core/permission";

const loginSchema = object().shape({
  email: string().email("Wrong email format").required("Email is required"),
  password: string()
    .required("Password is required")
    .matches(
      /(?=.*[A-Z])(?=.*\W+)(?=.*\d+).+/,
      "Password must contain one uppercase, one number and one special case character"
    ),
});

const initialValues = {
  email: "",
  password: "",
};

interface props {
  loginDispatch: Function;
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const Login: FC<props> = ({ loginDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const redirectPath = useMemo(() => searchParam.get("redirectTo"), []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values: any, { setStatus, setSubmitting }: any) => {
      setLoading(true);
      await loginDispatch(
        { email: values.email, password: values.password },
        (result: any) => {
          saveAuth(result);
          setCurrentUser(result);
          redirectPath && navigate(redirectPath);

          // state?.redirectTo && map.has(state?.redirectTo).isPrivate && result.Roles.includes("Admin") && navigate(state?.redirectTo);
          // state?.redirectTo && !map.has(state?.redirectTo).isPrivate && !result.Roles.includes("Admin") && navigate(state?.redirectTo);
        },
        (error: string) => {
          saveAuth(undefined);
          setStatus(error);
          setSubmitting(false);
          setLoading(false);
        }
      );
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="mb-11">
        <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
      </div>
      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        ""
      )}

      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email }
            // {
            //   'is-valid': formik.touched.email && !formik.errors.email,
            // }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-3 position-relative">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            }
            // {
            //   'is-valid': formik.touched.password && !formik.errors.password,
            // }
          )}
        />
        {showPassword && (
          <button
            type="button"
            className={`btn p-0 ${
              formik.touched.password && formik.errors.password
                ? "password-eye-with-error"
                : "password-eye"
            }`}
            onClick={() => setShowPassword(false)}
          >
            <i className="fa-solid fa-eye-slash"></i>
          </button>
        )}
        {!showPassword && (
          <button
            type="button"
            className={`btn p-0  ${
              formik.touched.password && formik.errors.password
                ? "password-eye-with-error"
                : "password-eye"
            }`}
            onClick={() => setShowPassword(true)}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
        )}
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='text-gray-600 text-hover-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginDispatch: (
      data: loginData,
      successCallback: Function,
      failureCallback: Function
    ) => dispatch(loginAction(data, successCallback, failureCallback)),
  };
};
export default connect(null, mapDispatchToProps)(Login);
