import { FC } from "react";

import { KTSVG } from "../../../../_metronic/helpers";

interface props {
  showDropDown: boolean;
  setShowDropDown: Function;
}

const FilterButton: FC<props> = ({ showDropDown, setShowDropDown }) => {
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <KTSVG
          path="/media/icons/duotune/general/gen031.svg"
          className="svg-icon-2"
        />
        Filter
      </button>
    </div>
  );
};

export default FilterButton;
