import { connect } from "react-redux";
import { getAuth } from "./AuthHelpers";
import { getProfileData } from "app/reducers/profile/profileAction";
import { FC } from "react";


function hasPaymentAccess ()  {

    const auth = getAuth()
    if (auth) {
        if (auth.PaymentAccess) {
            return true;
        }
        else{
            return false;
        }
    }
    return false;
}
  
export default hasPaymentAccess;
