import { axiosInstance } from "../axiosInstance"
import { addTaskTypeValue } from "../../components/pages/taskType/TaskTypeForm"
import { GET_ALL_TASK_TYPE_API_ENDPOINT, TASK_TYPE_API_ENDPOINT } from "../../helpers/config"
import { getSearchQueryString } from "../../helpers/helperFunction"
import { searchParams } from "../../helpers/commonInterface"

export const addTaskTypeService = async (data : addTaskTypeValue) => {
    return await axiosInstance.post(TASK_TYPE_API_ENDPOINT, data)
}

export const getTaskTypeListService = (searchObj : searchParams) => {
    const queryString = getSearchQueryString(searchObj)
    return axiosInstance.get(`${GET_ALL_TASK_TYPE_API_ENDPOINT}?${queryString}`)
}

export const getTaskTypeService = (typeId : number) => {
    return axiosInstance.get(`${TASK_TYPE_API_ENDPOINT}?id=${typeId}`)
}

export const updateTaskTypeService = async (data : addTaskTypeValue) => {
    return await axiosInstance.put(TASK_TYPE_API_ENDPOINT, data)
}

export const updateTaskTypeStatusService = (typeId : number) => {
    return axiosInstance.delete(`${TASK_TYPE_API_ENDPOINT}?id=${typeId}`)
}