import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  ACTIVITY_API_ENDPOINT,
  GET_ACTIVITY_BY_ARTICLE_API_ENDPOINT,
  GET_ALL_ACTIVITY_API_ENDPOINT,
} from "app/helpers/config";
import { initialActivityModal } from "app/components/pages/activity/ActivityForm";

export const addActivityService = async (data: initialActivityModal) => {
  return await axiosInstance.post(ACTIVITY_API_ENDPOINT, data);
};

export const getActivityListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GET_ALL_ACTIVITY_API_ENDPOINT}?${queryString}`);
};

export const getActivityByArticleService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ACTIVITY_BY_ARTICLE_API_ENDPOINT}?${queryString}`
  );
};

export const getActivityService = (activityId: number) => {
  return axiosInstance.get(`${ACTIVITY_API_ENDPOINT}?id=${activityId}`);
};

export const updateActivityService = async (data: initialActivityModal) => {
  return await axiosInstance.put(ACTIVITY_API_ENDPOINT, data);
};

export const getPlannerActivityService= (searchObj: searchParams)=>{
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ACTIVITY_BY_ARTICLE_API_ENDPOINT}?${queryString}`
  );
}
