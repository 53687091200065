import { FC } from "react";

import { convert } from "app/helpers/helperFunction";
import { KTSVG } from "_metronic/helpers";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import { transactionDetail } from "app/reducers/transaction/transactionDataInterface";
interface props {
  row: transactionDetail;
  setTransactionId: Function;
}

const TransactionTableRow: FC<props> = ({ row, setTransactionId }) => {
  const editTransactionHandler = () => {
    setTransactionId({
      show: true,
      edit: true,
      transactionId: row.Id,
      isPayment: row.IsPayment,
    });
  };

  const GetDataAsPerType = ({ classname }: { classname: string }) => {
    switch (true) {
      case TRANSACTION_TYPE["Advance"] === Number(row.Type):
      case TRANSACTION_TYPE["GovernmentFess/Tax"] === Number(row.Type):
      case TRANSACTION_TYPE.Reimbursement === Number(row.Type):
        return <span className={classname}>{row.TaskCode}</span>;
      case TRANSACTION_TYPE["Against Bill"] === Number(row.Type):
        return <span className={classname}>{row.BillNumber}</span>;
      case TRANSACTION_TYPE.Expense === Number(row.Type):
        return <span className={classname}>{row.ExpenseName}</span>;
      default:
        return <></>;
    }
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">
        {row?.Type ? TRANSACTION_TYPE[Number(row.Type)] : "-"}
      </td>
      <td>
        <div className="overflow-auto mh-75px vertical-scroll mw-375px d-flex flex-column">
          <GetDataAsPerType classname="text-dark fw-semibold" />
          <span className="text-gray-500 fw-semibold">
            {row?.Narration || "-"}
          </span>
        </div>
      </td>
      <td>{row?.TransactionDate ? convert(row.TransactionDate) : "-"}</td>
      <td>
        {!row?.IsPayment ? (
          <span className="fw-semibold text-success">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `+${row.Amount}`
              : "-"}
          </span>
        ) : (
          <span className="fw-semibold text-danger">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `-${row.Amount}`
              : "-"}
          </span>
        )}
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            onClick={editTransactionHandler}
            title="Edit"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TransactionTableRow;
