import {
  EXPENSE_API_ENDPOINT,
  GET_ALL_EXPENSE_API_ENDPOINT,
} from "app/helpers/config";
import { axiosInstance } from "../axiosInstance";
import { searchParams } from "app/helpers/commonInterface";
import { getSearchQueryString } from "app/helpers/helperFunction";

export const addExpenseService = async (data: any) => {
  return await axiosInstance.post(EXPENSE_API_ENDPOINT, data);
};

export const getExpenseListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GET_ALL_EXPENSE_API_ENDPOINT}?${queryString}`);
};

export const getExpenseService = (expenseId: number) => {
  return axiosInstance.get(`${EXPENSE_API_ENDPOINT}?id=${expenseId}`);
};

export const updateExpenseService = async (data: any) => {
  return await axiosInstance.put(EXPENSE_API_ENDPOINT, data);
};

export const updateExpenseStatusService = (expenseId: number) => {
  return axiosInstance.delete(`${EXPENSE_API_ENDPOINT}?id=${expenseId}`);
};
