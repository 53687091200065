import {
    CHANGE_DAILY_TASK_STATUS_SUCCESS, GET_BY_ID_DAILY_TASK_FAILURE, GET_BY_ID_DAILY_TASK_REQUEST,
    GET_BY_ID_DAILY_TASK_SUCCESS
} from "../actionTypes"

export interface dailyTaskDataState {
    loading: boolean,
    dailyTask: any,
    error: string
}

interface action {
    type: string
    payload: any
}

const initialState: dailyTaskDataState = {
    loading: false,
    dailyTask: {},
    error: ""
}

export const getDailyTaskByIdReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case GET_BY_ID_DAILY_TASK_REQUEST: return {
            ...state,
            loading: true
        }
        case GET_BY_ID_DAILY_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                dailyTask: action.payload,
                error: ""
            }
        case GET_BY_ID_DAILY_TASK_FAILURE: return {
            ...state,
            loading: false,
            dailyTask: {},
            error: action.payload
        }
        case CHANGE_DAILY_TASK_STATUS_SUCCESS: return {
            ...state,
            dailyTask: {
                ...state.dailyTask, data: {
                    ...state.dailyTask.data,
                    records: state.dailyTask.data.records.map((record: any) => {
                        return {
                            ...record,
                            Tasks: record.Tasks.map((task: any) => {
                                if (task.Id === action.payload) {
                                    return {
                                        ...task,
                                        IsCompleted: !task.IsCompleted
                                    }
                                }
                                return task
                            })
                        }
                    })
                }
            }
        }
        default: return state
    }
}