import { FC } from "react"

import { selectedTemplateComponent } from "../ClientMessagePopup"

interface props {
    component: selectedTemplateComponent
}

const WPTemplateFooter: FC<props> = ({ component }) => {
    return (
        <div className='footer text-gray-400 mb-3'>
            <div>{component.text}</div>
        </div>
    )
}

export default WPTemplateFooter