import { connect } from "react-redux";
import { FC, useEffect } from "react";

import FirmMasterTableRow from "./FirmMasterTableRow";
import { getFirmList } from "app/reducers/firmMaster/firmMasterAction";
import { searchParams } from "app/helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  firmsDetails: any;
  getFirmListDetails: Function;
}

const HEADERS = [
  {
    columnName: "Firm Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "GST Required",
    id: "HasGST",
    className: "min-w-125px",
  },
  {
    columnName: "Tax Rate",
    id: "TaxRate",
    className: "min-w-125px",
  },
  {
    columnName: "PAN No",
    id: "PANNumber",
    className: "min-w-125px",
  },
  {
    columnName: "Bill Series",
    id: "BillSeries",
    className: "min-w-125px",
  },
  {
    columnName: "Bill Type Series",
    id: "BillTypeSeries",
    className: "min-w-150px",
  },
  {
    columnName: "Qr Code",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end pe-5",
  },
];

const FirmMasterTable: FC<props> = ({ firmsDetails, getFirmListDetails }) => {
  const { searchObj } = useGetSearchObject();
  useEffect(() => {
    getFirmListDetails(searchObj);
  }, [searchObj, getFirmListDetails]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={firmsDetails.error}
        headers={HEADERS}
        success={firmsDetails.firmList?.success}
        loading={firmsDetails.loading}
        paginate={{
          show: true,
          pagination: firmsDetails.firmList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={firmsDetails.firmList?.data?.records}
        tableRow={(row: any, index: number) => (
          <FirmMasterTableRow key={`row-${index}-${row.id}`} row={row} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firmsDetails: state.firmList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFirmListDetails: (searchObj: searchParams) =>
      dispatch(getFirmList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmMasterTable);
