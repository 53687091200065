import { contactPerson } from "app/reducers/client/clientDataInterfce";
import { FC } from "react";

interface props {
  contactPersonArr: contactPerson[];
}

const ClientReportContactDetails: FC<props> = ({ contactPersonArr }) => {
  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-header pt-5 align-items-center border-0">
          <div className="card-title flex-column">
            <div className="fw-bold">Contacts Details</div>
          </div>
        </div>
        <div className="card-body mh-150px overflow-auto pt-0">
          <table
            id="kt_table_users"
            className="align-middle gs-0 gy-4 table table-row-dashed table-row-gray-300"
          >
            <thead>
              <tr className="text-start text-muted fw-bold">
                <th className="min-w-125px ps-3">Name</th>
                <th className="min-w-125px">Email</th>
                <th className="min-w-125px">Phone No.</th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {!contactPersonArr.length ? (
                <tr>
                  <td colSpan={3}>
                    <div className="text-center w-100 text-dark">
                      No Records Found
                    </div>
                  </td>
                </tr>
              ) : (
                contactPersonArr.map((contact) => {
                  return (
                    <tr>
                      <td className="fw-semibold text-gray-600 ps-3">
                        {contact.Name}
                      </td>
                      <td>
                        <a href={`mailto:${contact.Email}`}>{contact.Email}</a>
                      </td>
                      <td>
                        <a href={`tel:${contact.MobileNumber}`}>
                          {contact.MobileNumber}
                        </a>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClientReportContactDetails;
