import {
  ADD_BILL_FAILURE,
  ADD_BILL_REQUEST,
  ADD_BILL_SUCCESS,
  UPDATE_BILL_FAILURE,
  UPDATE_BILL_REQUEST,
  UPDATE_BILL_SUCCESS,
} from "../actionTypes";

interface addUpdateBillState {
  loading: boolean;
  addedBillData: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: addUpdateBillState = {
  loading: false,
  addedBillData: {},
  error: "",
};

export const addUpdateBillReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case UPDATE_BILL_REQUEST:
    case ADD_BILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BILL_SUCCESS:
    case ADD_BILL_SUCCESS:
      return {
        ...state,
        loading: false,
        addedBillData: action.payload,
        error: "",
      };
    case UPDATE_BILL_FAILURE:
    case ADD_BILL_FAILURE:
      return {
        ...state,
        loading: false,
        addedBillData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
