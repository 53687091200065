import { useState } from "react";
// import TransactionForm from "app/components/pages/transaction/TransactionForm";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import AddTransactionShrt from "app/components/pages/transaction/component/AddTransactionShrt";

const SidebarFooter = () => {
  // const [transaction, setTransaction] = useState({
  //   show: false,
  //   edit: false,
  //   transactionId: null,
  //   isPayment: null,
  // });

  // const closeModal = () => {
  //   setTransaction({
  //     show: false,
  //     edit: false,
  //     transactionId: null,
  //     isPayment: null,
  //   });
  // };
  const [showTransaction, setShowTransaction] = useState(false);

  const closeModal = () => setShowTransaction(false);

  return (
    <div
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      id="kt_app_sidebar_footer"
    >
      <div
        className="menu-link without-sub cursor-pointer"
        onClick={
          () => setShowTransaction(true)
          // setTransaction({
          //   show: true,
          //   edit: false,
          //   transactionId: null,
          //   isPayment: null,
          // })
        }
      >
        <span>
          <ImageDisplay
            altText="shortcuts"
            className="w-25px me-4"
            errorPath="/media/task/shortcuts-1.png"
            path="/media/task/transaction-shrtct.png"
            title="shortcuts"
          />
        </span>
        <span className="menu-title fw-semibold text-muted fs-6">
          Add Transaction
        </span>
      </div>
      {/* <TransactionForm transaction={transaction} closeModal={closeModal} /> */}
      <AddTransactionShrt
        showTransaction={showTransaction}
        closeModal={closeModal}
      />
    </div>
  );
};

export { SidebarFooter };
