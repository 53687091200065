import {GET_USER_BY_ID_FAILURE, GET_USER_BY_ID_REQUEST, GET_USER_BY_ID_SUCCESS } from "../actionTypes"

export interface profileState {
    loading : boolean,
    userById : any,
    error : string
}

interface action {
    type  : string,
    payload : any
}

const initialState : profileState = {
    loading : false,
    userById : {},
    error : ""
}

const getUserByIdReducer = (state = initialState, action:action) => {
    switch(action.type){
        case GET_USER_BY_ID_REQUEST : return{
            ...state,
            loading : true
        }
        case GET_USER_BY_ID_SUCCESS : return{
            ...state,
            loading : false,
            userById : action.payload,
            error : "",
        }
        case GET_USER_BY_ID_FAILURE : return{
            ...state,
            loading : false,
            userById : {},
            error : action.payload
        }
        default : return state;
    }
}

export {getUserByIdReducer}