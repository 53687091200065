import { FC, useState } from "react";

import ErrorBoundary from "app/helpers/ErrorBoundary";
import TaskAttachmentForm from "./TaskAttachmentForm";
import TaskAttachmentsList from "./TaskAttachmentsList";
import { taskFiles } from "app/reducers/task/taskDataInterface";

interface props {
  filesArr: taskFiles[];
}

export interface uploadFileData {
  TypeId: string;
  File: undefined;
  startDate: string | null | Date;
  endDate: string | null | Date;
  TaskId: string | undefined;
}

const TaskAttachmentsDetails: FC<props> = ({ filesArr }) => {
  const [showAddForm, setShowAddForm] = useState(false);

  return (
    <ErrorBoundary>
      <div className="col-12 col-lg-12 col-md-6 col-xxl-6 h-lg-100 mt-5 mt-md-0 mt-lg-5 mt-xxl-0 upload-file-container">
        <div className="card">
          <div className="card-header pt-5 align-items-center border-0">
            <div className="card-title flex-column">
              <div className="fw-bold">Latest Files</div>
            </div>
            <div className="d-flex gap-4 align-items-center flex-wrap position-relative">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setShowAddForm(true)}
              >
                <i className="bi bi-upload me-1"></i>
                Upload File
              </button>
            </div>
          </div>
          {!showAddForm && <TaskAttachmentsList filesArr={filesArr} />}
          {showAddForm && (
            <TaskAttachmentForm setShowAddForm={setShowAddForm} />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default TaskAttachmentsDetails;
