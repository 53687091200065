import { connect } from "react-redux";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import { assigneeChangeData } from "../AdminTaskDetailPage";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { updateTaskAssigneeFromTaskDetails } from "../../../../../../reducers/task/taskAction";
import OTPPopup from "app/components/commonComponent/otpPopup/OTPPopup";
import TaskDetailHeader from "./TaskDetailHeader";
import TaskCompletionDetails from "./TaskCompletionDetails";
import { taskData } from "app/reducers/task/taskDataInterface";

export interface activityData {
  id: number;
  fieldValue: string | number | null;
}

interface props {
  taskDetails: taskData;
  updateTaskAssigneeDispatch: Function;
}

const TaskDetails: FC<props> = ({
  updateTaskAssigneeDispatch,
  taskDetails,
}) => {
  const params = useParams();
  const [otpPopup, setOtpPopup] = useState({
    show: false,
    apiData: "",
    article: "",
  });
  const [dropDownValue, setDropDownValue] = useState({
    value: taskDetails.AssigneeId,
    name: taskDetails.PersonAssigned,
  });

  const updateAssigneeApiCall = async (
    otpValue: string,
    successCallback: Function,
    failureCallback: Function
  ) => {
    await updateTaskAssigneeDispatch(
      {
        taskId: [Number(params.id)],
        assigneeId: otpPopup.apiData,
        otp: otpValue,
      },
      () => {
        setDropDownValue({ value: otpPopup.apiData, name: otpPopup.article });
        successCallback();
      },
      () => {
        failureCallback();
      }
    );
  };

  return (
    <ErrorBoundary>
      <div className="task-detail-container card">
        <TaskDetailHeader taskDetails={taskDetails} />
        <div className="card-body pt-0">
          <TaskCompletionDetails
            setDropDownValue={setDropDownValue}
            dropDownValue={dropDownValue}
            setOtpPopup={setOtpPopup}
            taskDetails={taskDetails}
            updateTaskAssigneeDispatch={updateTaskAssigneeDispatch}
          />
         
        </div>
        <OTPPopup
          taskId={params.id}
          updateArticleFun={updateAssigneeApiCall}
          showOtpPopup={otpPopup}
          setShowOtpPopup={setOtpPopup}
        />
      </div>
    </ErrorBoundary>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTaskAssigneeDispatch: (
      data: assigneeChangeData,
      successCallback: Function,
      failureCallback: Function
    ) =>
      dispatch(
        updateTaskAssigneeFromTaskDetails(
          data,
          successCallback,
          failureCallback
        )
      ),
  };
};

export default connect(null, mapDispatchToProps)(TaskDetails);
