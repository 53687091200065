import {
  GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE,
  GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST,
  GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS,
} from "../actionTypes";

export interface whatsappDetail {
  loading: boolean;
  whatsappData: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: whatsappDetail = {
  loading: false,
  whatsappData: {},
  error: "",
};

export const getWhatsappAccountDetailReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_WHATSAPP_ACCOUNT_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case GET_WHATSAPP_ACCOUNT_DETAIL_SUCCESS:
      return {
        loading: false,
        whatsappData: action.payload,
        error: "",
      };
    case GET_WHATSAPP_ACCOUNT_DETAIL_FAILURE:
      return {
        loading: false,
        whatsappData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
