import { connect } from "react-redux";
import { FC, SetStateAction, useState } from "react";

import { downloadFile } from "app/reducers/task/taskAction";
import { KTSVG } from "_metronic/helpers";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { convert } from "app/helpers/helperFunction";
import { billData } from "app/reducers/billing/billingDataInterface";
interface props {
  row: billData;
  setBill: SetStateAction<any>;
  downloadFileDispatch: Function;
}

const BillingTableRow: FC<props> = ({ downloadFileDispatch, row, setBill }) => {
  const [loading, setLoading] = useState(false);

  const handleEditData = () => {
    setBill({ show: true, edit: true, billId: row.Id });
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.BillNumber}</td>
      <td className="">{row.ClientName}</td>
      <td className="">
        {row.Tasks.map((task: { TaskCode: string }) => task.TaskCode).join(
          ","
        ) || "-"}
      </td>
      <td className="">{row?.Firm?.Name || "-"}</td>
      <td className="">{row.TotalAmount}</td>
      <td className="">{row.PaidAmount}</td>
      <td>{row?.Date ? convert(row.Date) : "-"}</td>
      <td>
        <div className={`badge badge-sm bill-status-${row.PaymentStatus}`}>
          {PAYMENT_STATUS[row.PaymentStatus]}
        </div>
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            type="button"
            className="btn btn-bg-light btn-sm btn-icon btn-active-light-primary"
            title="Download"
            onClick={() => {
              setLoading(true);
              downloadFileDispatch(
                { id: row.Id, destination: "bill" },
                `${row.BillNumber}.${"pdf"}`,
                () => {
                  setLoading(false);
                }
              );
            }}
          >
            {loading ? (
              <>
                <div className="text-gray-600">
                  <SmallLoader />
                </div>
              </>
            ) : (
              <i className="fa-solid fa-download"></i>
              // <ImageDisplay
              //   path="media/task/download-arrow.png"
              //   altText="download-arrow"
              //   className="w-20px"
              //   errorPath="/media/task/download-arrow.png"
              // />
            )}
          </button>
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={handleEditData}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      callback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, callback)),
  };
};

export default connect(null, mapDispatchToProps)(BillingTableRow);
