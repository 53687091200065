import { debounce } from "lodash";
import { useState, useMemo, FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { company } from "../../../helpers/commonInterface";
import { getSearchParameter } from "../../../helpers/helperFunction";
import { KTSVG } from "../../../../_metronic/helpers";

interface props {
  getDataDispatchFunction?: Function;
  addCompanyId?: company | undefined;
}

const UsersListSearchComponent: FC<props> = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const [searchTerm, setSearchTerm] = useState<string>(searchObj.Search || "");

  const onChangeHandler = useCallback(
    async (search: string) => {
      setSearchParam({ ...searchObj, Search: search, PageNumber: "1" });
    },
    [setSearchParam, searchObj]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(onChangeHandler, 400),
    [onChangeHandler]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <div className="card-title">
      <div className="d-flex align-items-center position-relative">
        {/* <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        /> */}
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-3 position-absolute ms-3"
        />
        <input
          type="text"
          // data-kt-user-table-filter='search'
          id="kt_filter_search"
          className="form-control form-control-white form-control-sm w-175px ps-9 p-3"
          // className='form-control form-control-solid w-250px ps-14'
          placeholder="Search Here"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedChangeHandler(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default UsersListSearchComponent;
