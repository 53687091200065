import { GET_MORNING_PLANNER_FAILURE, GET_MORNING_PLANNER_SUCCESS, GET_MORNING_PLANNER_REQUEST, DELETE_MORNING_PLANNER_REQUEST, DELETE_MORNING_PLANNER_SUCCESS, DELETE_MORNING_PLANNER_FAILURE, SET_VIEW_MORNING_ACTION } from "../actionTypes"

interface viewMorningActionState {
    deleteLoading:boolean,
    loading  : boolean,
    viewMorningAction : boolean,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:viewMorningActionState = {
    deleteLoading : false,
    loading  : false,
    viewMorningAction : true,
    error : ""
}

export const viewMorningActionReducer = (state = initialState, action : action) => {
    switch(action.type){
        case SET_VIEW_MORNING_ACTION : return {
            ...state,
            viewMorningAction : action.payload
        }
        default : return state
    }
}