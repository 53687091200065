import { FC } from 'react'
import { Link } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import hasPermission, { actionsRole } from '../../auth/core/permission'

const Error404: FC = () => {
  return (
    <div className='card px-7 py-4'>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4 text-center'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7 text-center'>We can't find that page.</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3 text-center'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0 text-center'>
        <Link
          to={hasPermission(actionsRole.ADMIN) ? "/admin/home" : "/article/home"}
          className='btn btn-sm btn-primary'>
          Return Home
        </Link>
      </div>
      {/* end::Link */}
    </div>
  )
}

export default Error404
