import {
  ADD_PROFORMA_INVOICE_FAILURE,
  ADD_PROFORMA_INVOICE_REQUEST,
  ADD_PROFORMA_INVOICE_SUCCESS,
  UPDATE_PROFORMA_INVOICE_FAILURE,
  UPDATE_PROFORMA_INVOICE_REQUEST,
} from "../actionTypes";

interface addUpdateProformaInvoiceState {
  loading: boolean;
  addedProformaInvoiceData: any;
  updatedProformaInvoiceData: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: addUpdateProformaInvoiceState = {
  loading: false,
  addedProformaInvoiceData: {},
  updatedProformaInvoiceData: {},
  error: "",
};

export const addUpdateProformaInvoiceReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case ADD_PROFORMA_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PROFORMA_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        addedProformaInvoiceData: action.payload,
        error: "",
      };
    case ADD_PROFORMA_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        addedProformaInvoiceData: {},
        error: action.payload,
      };
    case UPDATE_PROFORMA_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFORMA_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        updatedProformaInvoiceData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
