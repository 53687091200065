import { connect } from "react-redux";
import { FC, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";

import DropDown from "../../dropDown/DropDown";
import ErrorDisplay from "../../ErrorDisplay";
import Loader from "../../loader/Loader";
import { selectedTemplateData } from "../ClientMessagePopup";
import { whatsappTemplateData } from "app/reducers/whatsapp/whatsappAction";
import { searchParams } from "app/helpers/commonInterface";
import { changeTextToFirstUppercase } from "app/helpers/helperFunction";

interface props {
  wpTemplateListDetails: any;
  whatsappTemplateDataDispatch: Function;
  selectedData: selectedTemplateData | undefined;
  setSelectedData: Function;
}

const WPTemplareList: FC<props> = ({
  whatsappTemplateDataDispatch,
  wpTemplateListDetails,
  setSelectedData,
  selectedData,
}) => {
  // const [selectTemplate, setSelectTemplate] = useState<selectedTemplateData>();

  useEffect(() => {
    if (!wpTemplateListDetails?.wpTemplateList?.data?.records) {
      whatsappTemplateDataDispatch({ Filters: "status=APPROVED" });
    }
  }, []);

  useEffect(() => {
    setSelectedData(wpTemplateListDetails?.wpTemplateList?.data?.records[0]);
    // setSelectTemplate(wpTemplateListDetails?.wpTemplateList?.data?.records[0])
  }, [wpTemplateListDetails]);

  return (
    <>
      {wpTemplateListDetails.loading && (
        // loader-container
        <div className="py-5">
          <Loader />
        </div>
      )}
      {wpTemplateListDetails.error && (
        <ErrorDisplay error={wpTemplateListDetails.error} />
      )}
      {wpTemplateListDetails?.wpTemplateList?.success &&
        !wpTemplateListDetails?.wpTemplateList?.data?.records?.length && (
          <div>No records found</div>
        )}
      {!wpTemplateListDetails.loading &&
        wpTemplateListDetails?.wpTemplateList?.success &&
        wpTemplateListDetails?.wpTemplateList?.data?.records?.length !== 0 && (
          // <div className='client-pop-up-slider'>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div className="fw-semibold fs-4">
              {changeTextToFirstUppercase(selectedData?.name || "")}
            </div>
            <div className="">
              <DropDown
                className="text-start form-control form-control-sm form-control-solid form-select w-200px"
                placeholder="Select Template"
                options={wpTemplateListDetails?.wpTemplateList?.data?.records.map(
                  (data: selectedTemplateData) => {
                    return {
                      value: data.name,
                      name: changeTextToFirstUppercase(data.name),
                    };
                  }
                )}
                name="templateSelectDropDown"
                currentValue={{
                  value: selectedData?.name,
                  name: changeTextToFirstUppercase(selectedData?.name || ""),
                }}
                apiCallDispatch={(e: any) =>
                  setSelectedData(
                    wpTemplateListDetails?.wpTemplateList?.data?.records.find(
                      (data: any) => data.name === e.target.dataset.id
                    )
                  )
                }
              />
            </div>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    wpTemplateListDetails: state.wpTemplateList,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    whatsappTemplateDataDispatch: (searchObj: searchParams) =>
      dispatch(whatsappTemplateData(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WPTemplareList);
