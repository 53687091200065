import { connect } from "react-redux";
import { FC, useEffect } from "react";

import { getGroupList } from "../../../../reducers/groups/groupAction";
import GroupTableRow from "./GroupTableRow";
import { searchParams } from "../../../../helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  groupDetails: any;
  getGroupListDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Group Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-sm-125px min-w-275px text-end pe-5",
  },
];

const GroupTable: FC<props> = ({ groupDetails, getGroupListDispatch }) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getGroupListDispatch(searchObj);
  }, [getGroupListDispatch, searchObj]);

  return (
    <>
      
      <Table
        checkBoxColumn={{ show: false }}
        error={groupDetails.error}
        headers={HEADERS}
        success={groupDetails.groupList?.success}
        loading={groupDetails.loading}
        paginate={{
          show: true,
          pagination: groupDetails.groupList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={groupDetails.groupList?.data?.records}
        tableRow={(row: any, index: number) => (
          <GroupTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    groupDetails: state.groupList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getGroupListDispatch: (searchObj: searchParams) =>
      dispatch(getGroupList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupTable);
