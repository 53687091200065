import { GET_EVENING_REPORTING_BY_ID_REQUEST, GET_EVENING_REPORTING_BY_ID_SUCCESS, GET_EVENING_REPORTING_BY_ID_FAILURE } from "../actionTypes"

interface eveningReportingByIdState {
    loading  : boolean,
    eveningReportingById : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:eveningReportingByIdState = {
    loading  : false,
    eveningReportingById : {},
    error : ""
}

export const eveningReportingByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_EVENING_REPORTING_BY_ID_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_EVENING_REPORTING_BY_ID_SUCCESS : return {
            ...state,
            loading : false,
            eveningReportingById : action.payload,
            error : ""
        }
        case GET_EVENING_REPORTING_BY_ID_FAILURE : return {
            ...state,
            loading : false,
            eveningReportingById : {},
            error : action.payload
        }
        default : return state
    }
}