import {
  GET_EXPENSE_LIST_FAILURE,
  GET_EXPENSE_LIST_REQUEST,
  GET_EXPENSE_LIST_SUCCESS,
  UPDATE_EXPENSE_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface expenseListState {
  loading: boolean;
  expenseList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: expenseListState = {
  loading: false,
  expenseList: {},
  error: "",
};

export const getExpenseListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_EXPENSE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_EXPENSE_LIST_SUCCESS:
      return {
        loading: false,
        expenseList: action.payload,
        error: "",
      };
    case GET_EXPENSE_LIST_FAILURE:
      return {
        loading: false,
        expenseList: {},
        error: action.payload,
      };
    case UPDATE_EXPENSE_STATUS_SUCCESS:
      return updateStatusFun(
        state,
        "expenseList",
        action.payload.data.records.Id
      );
    default:
      return state;
  }
};
