import clsx from "clsx";
import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSearchParameter } from "../../../helpers/helperFunction";

interface header {
  columnName: string;
  id: string;
  className: string;
}

type Props = {
  headers: Array<header>;
};
const UserCustomHeader: FC<Props> = ({ headers }) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );

  const handleSorting = async (e: any) => {
    if (e.target.id !== "NULL") {
      let orderby =
        searchParam.get("OrderByDescending") == null
          ? false
          : searchParam.get("OrderByDescending") == "true"
          ? false
          : true;
      setSearchParam({
        ...searchObj,
        PageNumber: "1",
        SortBy: e.target.id,
        OrderByDescending: String(orderby),
      });
    }
  };

  return (
    <>
      {headers?.map((header: header, index: number) => {
        return (
          <th
            key={index}
            className={clsx(
              header.className,
              searchParam.get("OrderByDescending") &&
                searchParam.get("SortBy") === header.id &&
                `table-sort-${
                  searchParam.get("OrderByDescending") == "true"
                    ? "desc"
                    : "asc"
                }`,
              header.id !== "NULL" && "cursor-pointer"
            )}
            onClick={handleSorting}
            id={header.id}
          >
            {header.columnName}
          </th>
        );
      })}
    </>
  );
};

export { UserCustomHeader };
