import { KTCard } from "_metronic/helpers"
import RegisteredTemplateTable from "./TableComponent/RegisteredTemplateTable"


const RegisteredTemplateList = () => {
    return (
        <>
            {/* <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListToolbar buttons={[{ title: "Add Template", to: TEMPLATE_REGISTER }]} />
            </div> */}
            <KTCard>
                <RegisteredTemplateTable />
            </KTCard>
        </>
    )
}

export default RegisteredTemplateList