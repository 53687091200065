import { connect } from "react-redux";
import { FC, useState } from "react";

import { downloadZipFile } from "app/reducers/clientDocuments/clientDocumentsAction";
import { selctedDocumentList } from "../DocumentsList";
import { SubmitButton } from "app/components/commonComponent/buttons/Buttons";

interface props {
  selectedDocumentDetails: selctedDocumentList;
  downloadZipFileDispatch: Function;
}

const DocumentGrouping: FC<props> = ({
  selectedDocumentDetails,
  downloadZipFileDispatch,
}) => {
  const [loading, setLoading] = useState(false);

  const downloadFileHandler = () => {
    setLoading(true);
    downloadZipFileDispatch(selectedDocumentDetails.selected, () => {
      setLoading(false);
    });
  };

  return (
    <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-1">
      <div className="fw-bolder">
        <span className="me-2">{selectedDocumentDetails.selected.length}</span>{" "}
        Selected
      </div>
      <div className="d-flex gap-3 align-items-center flex-wrap justify-content-end">
        <div className="d-flex justify-content-end align-items-center gap-3">
          <div>
            <SubmitButton
              isSubmitting={loading}
              name="Download"
              type="button"
              className="btn btn-primary btn-sm"
              onClickCallback={downloadFileHandler}
              fonticon="fa-solid fa-download"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedDocumentDetails: state.selectedDocumentList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadZipFileDispatch: (
      fileId: Array<number>,
      successCallback: Function
    ) => dispatch(downloadZipFile(fileId, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentGrouping);
