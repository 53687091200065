import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import ErrorBoundary from "app/helpers/ErrorBoundary";
import TaskRemarksForm from "./TaskRemarksForm";
import TaskRemarksList from "./TaskRemarksList";
import { taskRemarks } from "app/reducers/task/taskDataInterface";

interface props {
  remarksArr: taskRemarks[];
}

export interface remarksInitialValue {
  id?: number;
  taskId: string | undefined;
  remark?: string;
  fileName?: string;
  filePath?: string;
  fileUrl?: string;
  mimeType?: string;
}

const getInitialValue = (
  taskId: string | undefined,
  remarkData?: taskRemarks
): remarksInitialValue => {
  if (remarkData) {
    return {
      taskId: taskId,
      remark: remarkData.Remark,
      fileName: remarkData.UploadedFile?.FileName,
      filePath: remarkData.UploadedFile?.FilePath,
      fileUrl: remarkData.UploadedFile?.FileUrl,
      mimeType: remarkData.UploadedFile?.MimeType,
    };
  } else {
    return {
      taskId: taskId,
      remark: "",
      fileName: "",
      filePath: "",
      fileUrl: "",
      mimeType: "",
    };
  }
};

const TaskRemarksDetails: FC<props> = ({ remarksArr }) => {
  const params = useParams();
  const [showForm, setShowForm] = useState({ form: false, edit: false });
  const [initialValue, setInitialValue] = useState<remarksInitialValue>(
    getInitialValue(params.id)
  );

  return (
    <ErrorBoundary>
      <div className="remarks-container col-12 col-lg-12 col-md-6 col-xxl-6">
        <div className="card">
          <div className="card-header pt-5 align-items-center border-0">
            <div className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Remarks</span>
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setShowForm({ form: true, edit: false });
                  setInitialValue(getInitialValue(params.id));
                }}
              >
                <i className="fa-solid fa-plus"></i>
                Add Remarks
              </button>
            </div>
          </div>
          {!showForm.form && (
            <TaskRemarksList
              getInitialValue={getInitialValue}
              remarksArr={remarksArr}
              setInitialValue={setInitialValue}
              setShowForm={setShowForm}
            />
          )}
          {showForm.form && (
            <TaskRemarksForm
              initialValue={initialValue}
              setShowForm={setShowForm}
              showForm={showForm}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default TaskRemarksDetails;
