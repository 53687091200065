import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSearchParameter } from "../../../helpers/helperFunction";

type props = {
  itemsPerPage: number;
  itemLength: number;
  currentPage: number;
};

const Paginate: FC<props> = ({
  itemLength,
  itemsPerPage,
  currentPage,
}): any => {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );

  const handlePagination = async (page: number) => {
    setSearchParam({ ...searchObj, PageNumber: String(page) });
  };

  const pageCount = Math.ceil(itemLength / itemsPerPage);

  let btn: Array<JSX.Element> = [];
  let pageLimit = pageCount;
  // let pageLimit = 3;
  let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;

  // btn.push(
  //   <button
  //     className={`table-list-pagination-btn ${+currentPage === 1 ? "pagination-btn-disable" : ""}`}
  //     onClick={() => handlePagination(+currentPage - 1)}
  //     disabled={+currentPage === 1}>
  //     <i className="fa-solid fa-chevron-left"></i>
  //   </button>
  // );
  const getPages = () => {
    const from = Math.max(Math.min(currentPage - 3, pageLimit - 5), 0);
    const to = Math.min(from + 4, pageLimit);
    // for (let i = 0; i < pageLimit; i++) {
    for (let i = from; i <= to; i++) {
      let num = start + i + 1;

      if (num > pageCount) break;

      btn.push(
        <button
          key={num}
          className={`table-list-pagination-btn ${
            +currentPage === +num ? "table-list-active" : ""
          }`}
          onClick={() => handlePagination(num)}
          disabled={+currentPage === +num}
        >
          {num}
        </button>
      );
    }
    return btn;
  };
  // btn.push(
  //   <button
  //     className={`table-list-pagination-btn ${+currentPage === pageCount ? "pagination-btn-disable" : ""}`}
  //     onClick={() => handlePagination(+currentPage + 1)}
  //     disabled={+currentPage === pageCount}>
  //     <i className="fa-solid fa-chevron-right"></i>
  //   </button>
  // );

  // return btn;

  return (
    <>
      <button
        className={`table-list-pagination-btn ${
          +currentPage === 1 ? "pagination-btn-disable" : ""
        }`}
        onClick={() => handlePagination(+currentPage - 1)}
        disabled={+currentPage === 1}
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>
      {getPages()}
      <button
        className={`table-list-pagination-btn ${
          +currentPage === pageCount ? "pagination-btn-disable" : ""
        }`}
        onClick={() => handlePagination(+currentPage + 1)}
        disabled={+currentPage === pageCount}
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>
    </>
  );
};

export { Paginate };
