import { failure, request, success } from "../dispatchFunctions";
import { groupData } from "../../components/pages/groups/GroupForm";
import { searchParams } from "../../helpers/commonInterface";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addGroupService,
  editGroupService,
  getGroupListService,
  getGroupService,
  updateGroupStatusService,
} from "./groupService";
import {
  ADD_GROUP_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  GET_GROUP_BY_ID_FAILURE,
  GET_GROUP_BY_ID_REQUEST,
  GET_GROUP_LIST_FAILURE,
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  UPDATE_GROUP_STATUS_FAILURE,
  GET_GROUP_BY_ID_SUCCESS,
  UPDATE_GROUP_STATUS_REQUEST,
  UPDATE_GROUP_STATUS_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addGroup = (data: groupData, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_GROUP_REQUEST));

    await addGroupService(data).then(
      (result: any) => {
        dispatch(success(ADD_GROUP_SUCCESS, result));
        showToastMessageSuccess("Group added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.GROUPS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_GROUP_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const editGroup = (data: groupData, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_GROUP_REQUEST));

    await editGroupService(data).then(
      (result: any) => {
        dispatch(success(EDIT_GROUP_SUCCESS, result));
        showToastMessageSuccess("Group updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.GROUPS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(EDIT_GROUP_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const getGroup = (groupId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_GROUP_BY_ID_REQUEST));

    getGroupService(groupId).then(
      (result: any) => {
        dispatch(success(GET_GROUP_BY_ID_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_GROUP_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const getGroupList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_GROUP_LIST_REQUEST));

    getGroupListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_GROUP_LIST_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_GROUP_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const changeGroupStatus = (
  groupId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_GROUP_STATUS_REQUEST));

    updateGroupStatusService(groupId).then(
      (result: any) => {
        dispatch(success(UPDATE_GROUP_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Group status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.GROUPS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_GROUP_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
