import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { connect } from "react-redux";
import { searchParams } from 'app/helpers/commonInterface';
import { getPlannerActivity } from 'app/reducers/activity/activityAction';
import { getPlannerTask } from 'app/reducers/task/taskAction';
import { addMorningPlanner, editMorningPlanner, getMorningPlanner, getMorningPlannerById, getUsersMorningPlanner } from 'app/reducers/morningPlanner/plannerAction';
import * as Yup from 'yup';
import { capitalizeFirstLetter, convertTo12HourFormat, emptyObject } from 'app/helpers/helperFunction';
import CustomDatePicker from 'app/components/commonComponent/datePicker/CustomDatePicker';
import DropDown from 'app/components/commonComponent/dropDown/DropDown';
import { KTSVG } from '_metronic/helpers';
import Loader from 'app/components/commonComponent/loader/Loader';

// Define the type for the objects in the array
interface ItemType {
    value: number;
    name: string;
}

// Define the array with the specified type
const TaskType: ItemType[] = [
    { value: 1, name: "Task" },
    { value: 2, name: "Activity" }
];

interface props {
    ButtonTitle: string,
    ModalTitle: string,
    userId?: string,
    plannerActivity: any,
    getPlannerActivityDispatch: Function,
    plannerTask: any,
    getPlannerTaskDispatch: Function,
    addMorningPlannerDispatch: Function,
    getMorningPlannerByIdDispatch: Function,
    morningPlannerById: any,
    id: any,
    editMorningPlanner: any,
    editMorningPlannerDispatch: Function,
    getUsersMorningPlannerDispatch: Function,
    getMorningPlannerDispatch: Function
}
const EditMorningPlanner = ({
    ModalTitle,
    userId,
    plannerActivity,
    getPlannerActivityDispatch,
    plannerTask,
    getPlannerTaskDispatch,
    addMorningPlannerDispatch,
    getMorningPlannerByIdDispatch,
    morningPlannerById,
    editMorningPlanner,
    editMorningPlannerDispatch,
    id,
    getUsersMorningPlannerDispatch,
    getMorningPlannerDispatch
}: props
) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        if (userId) {
            getUsersMorningPlannerDispatch({ userId: userId });
        }
        else {
            getMorningPlannerDispatch();
        }
        setShow(false)
    };
    const [isLoading, setIsLoading] = useState(false);

    const handleShow = () => {
        if (userId != null) {
            getPlannerActivityDispatch({ Paginate: false, userId: userId })
            getPlannerTaskDispatch({ Paginate: false, listType: 'assigned', userId: userId })
        }
        else {
            getPlannerActivityDispatch({ Paginate: false })
            getPlannerTaskDispatch({ Paginate: false, listType: 'assigned' })
        }
        getMorningPlannerByIdDispatch(id)
        setShow(true)
    };

    const [activities, setActivities] = useState<any[]>([]);
    const [workTask, setWorkTask] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState({
        name: '',
        TaskTypeId: '', // default value for TaskTypeId
        TaskTypeName: '',
        TaskId: '', // default value for TaskId
        TaskName: '',
        ActivityId: '',
        ActivityName: '', // default value for ActivityId
        startTime: '00:00',
        endTime: '00:00',
        statusId: '', // only added,
        Date: '',
        AssignId: '',
        Id: '',
        isDailyTask : false
    })



    useEffect(() => {
        if (plannerActivity.plannerActivity && plannerActivity.plannerActivity.data && show) {
            //status = 3 (Completed)
            setActivities(plannerActivity.plannerActivity.data.records
                .filter((activity: any) => activity.Status !== 3)
                .map((activity: any) => {
                    return { value: activity.Id, name: activity.Name };
                })
            )
        }
    }, [plannerActivity])

    useEffect(() => {
        if (plannerTask.plannerTask && plannerTask.plannerTask.data && show) {
            //status = 5 (Completed)
            setWorkTask(plannerTask.plannerTask.data.records
                .filter((activity: any) => activity.Status !== 5)
                .map((activity: any) => {
                    return { value: activity.Id, name: `${activity.ClientName} - ${activity.TypeName}` };
                }))
        }
    }, [plannerTask])

    useEffect(() => {

        if (morningPlannerById.morningPlannerById.data != undefined && show) {
            var data = morningPlannerById.morningPlannerById.data.records;
            setInitialValues({
                TaskId: data.TaskId == undefined ? 'NA' : data.TaskId, // default value for TaskId
                ActivityId: data.ActivityId == undefined ? 'NA' : data.ActivityId, // default value for ActivityId
                startTime: data.StartTime,
                endTime: data.EndTime,
                statusId: data.Status,
                TaskName: data.TaskId == undefined ? '' : data.TaskName,
                ActivityName: data.ActivityId == undefined ? '' : data.ActivityName,
                TaskTypeName: data.TaskTypeId == 1 ? 'Task' : 'Activity',
                TaskTypeId: data.TaskTypeId,
                Date: data.Date,
                AssignId: data.AssignId,
                Id: data.Id,
                isDailyTask : data.IsDailyTask,
                name:''
            });
        }
    }, [morningPlannerById.morningPlannerById.data])


    const validateFun = Yup.object({
        TaskTypeId: Yup.string().required('Task Type is required'),
        TaskId: Yup.string().required('Task is required'),
        ActivityId: Yup.string().required('Activity is required'),
        endTime: Yup.string().when('startTime', (startTime, schema) => {
            return startTime && schema.test({
                test: function (endTime: any) {
                    return endTime > startTime;
                },
                message: 'End Time must be greater than Start Time',
            });
        }),
    });


    const convertToTimeOnly = (timeString: any) => {
        // Assuming the backend expects a format like 'HH:mm:ss'
        const [hours, minutes] = timeString.split(':');
        return `${hours}:${minutes}:00`;
    };

    const formRef = useRef<FormikProps<any>>(null);

    const saveInput = (e: any) => {
        e.preventDefault()
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };



    return (
        <>
            <button
                className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
                title="Edit"
                onClick={handleShow}
            >
                <KTSVG
                    path="/media/icons/duotune/art/art005.svg"
                    className="svg-icon-3"
                />
            </button>

            {show && (
                <div className="modal fade show d-block" id="exampleModal" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-3" id="exampleModalLabel">{ModalTitle}</h5>
                                <button type="button" onClick={handleClose} className="btn  btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger"><span className="svg-icon svg-icon-muted svg-icon-2hx "><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px "><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="white"></rect><rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect><rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect></svg></span></button>

                            </div>
                            <div className="modal-body">
                                <div className="container-fluid px-0">
                                    {!morningPlannerById.loading ? (
                                        <div className="card">
                                            <Formik
                                                innerRef={formRef}
                                                enableReinitialize
                                                initialValues={initialValues}
                                                validationSchema={validateFun}
                                                onSubmit={async (values, { resetForm }) => {
                                                
                                                    setIsLoading(true);
                                                    var task: any = {
                                                        ...values,
                                                        TaskId: values.TaskId === "NA" ? null : values.TaskId,
                                                        ActivityId: values.ActivityId === "NA" ? null : values.ActivityId,
                                                        startTime: convertToTimeOnly(values.startTime),
                                                        endTime: convertToTimeOnly(values.endTime),
                                                        status: values.statusId
                                                    }

                                                    editMorningPlannerDispatch([task])
                                                    setInitialValues({
                                                        ...values,
                                                        TaskId: values.TaskId,
                                                        TaskName: values.TaskId != 'NA' ? workTask.find(task => task.value === values.TaskId)[0]?.name : '',
                                                        ActivityId: values.ActivityId,
                                                        ActivityName: values.ActivityId != 'NA' ? activities.find(activity => activity.value === values.ActivityId)[0].name : '',
                                                        startTime: convertToTimeOnly(values.startTime),
                                                        endTime: convertToTimeOnly(values.endTime),
                                                        name:''
                                                    })


                                                    resetForm();
                                                }}
                                            >
                                                {({ isSubmitting, setFieldValue, values }) => {
                                                  
                                                    if (values && !isSubmitting) {
                                                        if (values.ActivityId != 'NA' || values.TaskId != 'NA') {
                                                            if (values.TaskTypeId == 1) {
                                                                values.ActivityId = 'NA'
                                                                if (values.TaskId == 'NA') {
                                                                    values.TaskId = ''
                                                                }

                                                            } else {
                                                                values.TaskId = 'NA'
                                                                if (values.ActivityId == 'NA') {
                                                                    values.ActivityId = ''
                                                                }
                                                            }
                                                        }

                                                    }
                                                    return (
                                                        <Form noValidate className="form">
                                                            <div className="p-7">
                                                                <div className="row mb-6">
                                                                    <div className="col-sm-6 col-lg-6">
                                                                        <label className="col-form-label fw-semibold fs-6 required">
                                                                            Task Type
                                                                        </label>
                                                                        <div className="">
                                                                            <DropDown
                                                                                className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                 `}
                                                                                placeholder="Select Type"
                                                                                options={TaskType}
                                                                                setFieldValue={setFieldValue}
                                                                                name="TaskTypeId"
                                                                                disabled={isSubmitting}
                                                                                showSearch={true}
                                                                                defaultValue={
                                                                                    {
                                                                                        value: morningPlannerById.morningPlannerById.data.records.TaskTypeId,
                                                                                        name: capitalizeFirstLetter(morningPlannerById.morningPlannerById.data.records.TasktypeName)
                                                                                    }
                                                                                }
                                                                            />
                                                                            <ErrorMessage
                                                                                name="TaskTypeId"
                                                                                component="div"
                                                                                className="errorMsg"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {(workTask && values.TaskId != 'NA') && (
                                                                        <div className="col-sm-6 col-lg-6">
                                                                            <label className="col-form-label fw-semibold fs-6 required">
                                                                                Task
                                                                            </label>
                                                                            <div className="">
                                                                                <DropDown
                                                                                    className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                                                                    placeholder="Select Type"
                                                                                    options={workTask}
                                                                                    setFieldValue={setFieldValue}
                                                                                    name="TaskId"
                                                                                    disabled={isSubmitting}
                                                                                    showSearch={true}
                                                                                    defaultValue={{
                                                                                        value: morningPlannerById.morningPlannerById.data.records.TaskId,
                                                                                        name:`${morningPlannerById.morningPlannerById.data.records.ClientName} - ${morningPlannerById.morningPlannerById.data.records.TaskName}`,
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="TaskId"
                                                                                    component="div"
                                                                                    className="errorMsg"
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                    )}

                                                                    {(activities && values.ActivityId != 'NA') && (
                                                                        <div className="col-sm-6 col-lg-6">
                                                                            <label className="col-form-label fw-semibold fs-6 required">
                                                                                Activity
                                                                            </label>
                                                                            <div className="">
                                                                                <DropDown
                                                                                    className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                                                                    placeholder="Select Type"
                                                                                    options={activities}
                                                                                    setFieldValue={setFieldValue}
                                                                                    name="ActivityId"
                                                                                    disabled={isSubmitting}
                                                                                    showSearch={true}
                                                                                    defaultValue={{
                                                                                        value: morningPlannerById.morningPlannerById.data.records.ActivityId,
                                                                                        name: morningPlannerById.morningPlannerById.data.records.ActivityName,
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="ActivityId"
                                                                                    component="div"
                                                                                    className="errorMsg"
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="row mb-6">
                                                                    <div className="col-sm-6 col-lg-6">
                                                                        <div>
                                                                        <label className="col-form-label fw-semibold fs-6 required">
                                                                            Start Time
                                                                        </label>
                                                                        </div>
                                                                        <CustomDatePicker
                                                                            name="startTime"
                                                                            placeholder="Start Date"
                                                                            setFieldValue={setFieldValue}
                                                                            TimeDisable={false}
                                                                            TimeValue={values.startTime}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-6 col-lg-6">
                                                                    <div>
                                                                        <label className="col-form-label fw-semibold fs-6 required">
                                                                            End Time
                                                                        </label>
                                                                        </div>
                                                                        <CustomDatePicker
                                                                            name="endTime"
                                                                            placeholder="end Date"
                                                                            setFieldValue={setFieldValue}
                                                                            TimeDisable={false}
                                                                            TimeValue={values.endTime}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="endTime"
                                                                            component="div"
                                                                            className="errorMsg"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-row align-items-center gap-3 mt-8 col-lg-4">
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    <Field
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="isDailyTask"
                                                                        id="isDailyTask"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="col-form-label fw-semibold fs-6">Daily Task</div>
                                                            </div>
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    ) : (
                                        <Loader />
                                    )}

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light-primary font-weight-bold" onClick={handleClose}>Close</button>
                                <button type="submit"
                                    disabled={(editMorningPlanner && editMorningPlanner.loading)}
                                    className="btn btn-primary font-weight-bold" onClick={(e: any) => saveInput(e)}>
                                    {(editMorningPlanner && editMorningPlanner.loading) ? (
                                        <>Editing... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                                    ) : (
                                        <>Edit Task</>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


const mapStateToProps = (state: any) => {
    return {
        plannerActivity: state.plannerActivity,
        plannerTask: state.plannerTask,
        morningPlannerById: state.morningPlannerById,
        editMorningPlanner: state.editMorningPlanner
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getPlannerActivityDispatch: (searchObj: searchParams) => dispatch(getPlannerActivity(searchObj)),
        getMorningPlannerByIdDispatch: (id: number) => dispatch(getMorningPlannerById(id)),
        getPlannerTaskDispatch: (searchObj: searchParams) => dispatch(getPlannerTask(searchObj)),
        addMorningPlannerDispatch: (data: any) => dispatch(addMorningPlanner(data)),
        editMorningPlannerDispatch: (data: any) => dispatch(editMorningPlanner(data)),
        getUsersMorningPlannerDispatch: (searchObj: searchParams) =>
            dispatch(getUsersMorningPlanner(searchObj)),
        getMorningPlannerDispatch: () =>
            dispatch(getMorningPlanner()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMorningPlanner);

