import Avatar from "app/components/commonComponent/Avatar";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { getColor, getStatus } from "app/helpers/helperFunction";
import { FC } from "react";

interface props {
  taskListDetails: any;
}

const getTotalAmount = (taskList: any) => {
  return taskList.reduce((acc: number, task: any) => acc + task.Fees, 0);
};

const ProformaInvoiceTaskDetail: FC<props> = ({ taskListDetails }) => {
  return (
    <ErrorBoundary>
      <div className="card mb-6">
        <div className="card-header pt-5 align-items-center border-0">
          <div className="card-title flex-column">
            <div className="fw-bold">Tasks</div>
          </div>
          <div className="fw-bold fs-6">
            <span>Total: </span>
            <span>{getTotalAmount(taskListDetails)}</span>
          </div>
        </div>
        <div className="card-body px-7 py-4 fs-6 mh-400px overflow-auto">
          {taskListDetails.map((task: any, index: number) => (
            <div className="m-0" key={index}>
              <div
                className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                data-bs-toggle="collapse"
                data-bs-target={`#task-${index}`}
              >
                <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                  <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        fill="currentColor"
                      />
                      <rect
                        x="6.0104"
                        y="10.9247"
                        width="12"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      />
                    </svg>
                  </span>

                  <span className="svg-icon toggle-off svg-icon-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        fill="currentColor"
                      />
                      <rect
                        x="10.8891"
                        y="17.8033"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(-90 10.8891 17.8033)"
                        fill="currentColor"
                      />
                      <rect
                        x="6.01041"
                        y="10.9247"
                        width="12"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>

                <div className="d-flex justify-content-between w-100">
                  <div className="text-gray-700 fw-semibold cursor-pointer mb-0">
                    {task.TypeName}
                  </div>
                  <div>{task.Fees}</div>
                </div>
              </div>

              <div id={`task-${index}`} className="collapse fs-6 ms-1">
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    TaskCode
                  </div>
                  <div className="col-6">{task?.TaskCode || "-"}</div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    ClientName
                  </div>
                  <div className="col-6">{task?.ClientName || "-"}</div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Article
                  </div>
                  <div className="col-6">
                    {task?.PersonAssigned ? (
                      <div className="d-flex align-items-center">
                        <div className="w-25px me-3">
                          <Avatar
                            firstName={task.PersonAssigned}
                            lastName={""}
                            size={20}
                            color={getColor(task.PersonAssigned).color}
                            background={
                              getColor(task.PersonAssigned).background
                            }
                            length={1}
                            rounded={true}
                          />
                        </div>
                        {task.PersonAssigned}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    CreatedBy
                  </div>
                  <div className="col-6">
                    {task?.CreatedBy ? (
                      <div className="d-flex align-items-center">
                        <div className="w-25px me-3">
                          <Avatar
                            firstName={task.CreatedBy}
                            lastName={""}
                            size={20}
                            color={getColor(task.CreatedBy).color}
                            background={getColor(task.CreatedBy).background}
                            length={1}
                            rounded={true}
                          />
                        </div>
                        {task.CreatedBy}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Status
                  </div>
                  <div className="col-6">
                    <span className={`badge status-${task.StatusValue}`}>
                      {getStatus(task.StatusValue).name}
                    </span>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed"></div>
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ProformaInvoiceTaskDetail;
