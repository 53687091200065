import { connect } from "react-redux";
import { FC, useEffect, useMemo, useState } from "react";

import { adminHomePage } from "../main/admin/AdminMain";
import { cashBookListPage } from "../cashBook/CashBookList";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { emptyObject, getSearchParameter } from "app/helpers/helperFunction";
import { firmListPage } from "../firmMaster/FirmMasterList";
import { getTransactionList } from "app/reducers/transaction/transactionAction";
import Loader from "app/components/commonComponent/loader/Loader";
import { KTCard, KTSVG } from "_metronic/helpers";
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core";
import { searchParams } from "app/helpers/commonInterface";
import TransactionTable from "./component/TransactionTable";
import TransactionForm from "./TransactionForm";
import TransactionFilter from "./component/TransactionFilter";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { bankBookListPage } from "../bankBook/BankBookList";
import { transactionListState } from "app/reducers/transaction/getTransactionListReducer";
import TransactionAmountDetail from "./component/TransactionAmountDetail";

const accountBreadCrumbs = (firmId: number, path: string): Array<PageLink> => {
  return [
    adminHomePage,
    firmListPage,
    path.includes("bank-book")
      ? bankBookListPage(firmId)
      : cashBookListPage(firmId),
    emptyObject,
  ];
};

interface props {
  getTransactionListDetails: Function;
  transactionDetails: transactionListState;
}

const TransactionList: FC<props> = ({
  getTransactionListDetails,
  transactionDetails,
}) => {
  const params = useParams();
  const [searchParam] = useSearchParams();
  const location = useLocation();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const [transaction, setTransaction] = useState({
    show: false,
    edit: false,
    transactionId: null,
    isPayment: null,
  });

  const closeModal = () => {
    setTransaction({
      show: false,
      edit: false,
      transactionId: null,
      isPayment: null,
    });
  };

  useEffect(() => {
    getTransactionListDetails({ ...searchObj, bookId: params.bookId });
  }, [getTransactionListDetails, searchObj, params.bookId]);

  return (
    <>
      <PageTitle
        breadcrumbs={accountBreadCrumbs(Number(params.id), location.pathname)}
      >
        Transactions
      </PageTitle>
      <PageSubTitle>
        {transactionDetails.transactionList?.data?.records?.BookName
          ? `${
              transactionDetails.transactionList?.data?.records?.BookName
            }${" "}-${" "}${
              transactionDetails.transactionList?.data?.records?.FirmName
            }`
          : ""}
      </PageSubTitle>
      <div className="d-flex justify-content-end align-items-end flex-wrap gap-3 mb-3">
        <UsersListSearchComponent />
        <TransactionFilter />
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() =>
            setTransaction({
              show: true,
              edit: false,
              transactionId: null,
              isPayment: null,
            })
          }
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          /> 
          Add Transaction
        </button>
      </div>

      {transactionDetails.loading && <Loader />}
      {transactionDetails.error && (
        <ErrorDisplay error={transactionDetails.error} />
      )}

      {!transactionDetails.loading &&
        transactionDetails.transactionList?.success && (
          <>
            <TransactionAmountDetail
              Total={transactionDetails.transactionList.data?.records.Total}
            />

            <KTCard>
              <TransactionTable setTransactionId={setTransaction} />
            </KTCard>
          </>
        )}
      <TransactionForm transaction={transaction} closeModal={closeModal} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    transactionDetails: state.transactionList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionListDetails: (searchObj: searchParams) =>
      dispatch(getTransactionList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
