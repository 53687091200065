import { response } from "app/helpers/commonInterface";
import {
  GET_TRANSACTION_REPORT_LIST_FAILURE,
  GET_TRANSACTION_REPORT_LIST_REQUEST,
  GET_TRANSACTION_REPORT_LIST_SUCCESS,
} from "../actionTypes";
import { transactionData } from "./transactionDataInterface";

export interface transactionReportListState {
  loading: boolean;
  transactionDetails: response<transactionData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: transactionReportListState = {
  loading: false,
  transactionDetails: {},
  error: "",
};

export const getTransactionReportListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_TRANSACTION_REPORT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTION_REPORT_LIST_SUCCESS:
      return {
        loading: false,
        transactionDetails: action.payload,
        error: "",
      };
    case GET_TRANSACTION_REPORT_LIST_FAILURE:
      return {
        loading: false,
        transactionDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
