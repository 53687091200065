import { failure, request, success } from "../dispatchFunctions";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addDocumentTypeService,
  editDocumentTypeService,
  getDocumentTypeListService,
  getDocumentTypeService,
  updateDocumentTypeStatusService,
} from "./documentTypeService";
import {
  ADD_DOCUMENT_TYPE_FAILURE,
  ADD_DOCUMENT_TYPE_REQUEST,
  ADD_DOCUMENT_TYPE_SUCCESS,
  DELETE_DOCUMENT_TYPE_FAILURE,
  DELETE_DOCUMENT_TYPE_REQUEST,
  DELETE_DOCUMENT_TYPE_SUCCESS,
  EDIT_DOCUMENT_TYPE_FAILURE,
  EDIT_DOCUMENT_TYPE_REQUEST,
  EDIT_DOCUMENT_TYPE_SUCCESS,
  GET_DOCUMENT_TYPE_FAILURE,
  GET_DOCUMENT_TYPE_LIST_FAILURE,
  GET_DOCUMENT_TYPE_LIST_REQUEST,
  GET_DOCUMENT_TYPE_LIST_SUCCESS,
  GET_DOCUMENT_TYPE_REQUEST,
  GET_DOCUMENT_TYPE_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addDocumentType = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_DOCUMENT_TYPE_REQUEST));

    await addDocumentTypeService(data).then(
      (result: any) => {
        dispatch(success(ADD_DOCUMENT_TYPE_SUCCESS, result.data));
        showToastMessageSuccess("Document type added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.DOCUMENT_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_DOCUMENT_TYPE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const editDocumentType = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_DOCUMENT_TYPE_REQUEST));

    await editDocumentTypeService(data).then(
      (result: any) => {
        dispatch(success(EDIT_DOCUMENT_TYPE_SUCCESS, result.data));
        showToastMessageSuccess("Document type updated");
        successCallback(result.data.data.records);
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.DOCUMENT_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            EDIT_DOCUMENT_TYPE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const updateDocumentTypeStatus = (
  id: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(DELETE_DOCUMENT_TYPE_REQUEST));

    updateDocumentTypeStatusService(id).then(
      (result: any) => {
        dispatch(success(DELETE_DOCUMENT_TYPE_SUCCESS, result.data));
        showToastMessageSuccess("Document type status updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.DOCUMENT_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            DELETE_DOCUMENT_TYPE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback();
      }
    );
  };
};
export const getDocumentType = (id: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_DOCUMENT_TYPE_REQUEST));

    getDocumentTypeService(id).then(
      (result: any) => {
        dispatch(success(GET_DOCUMENT_TYPE_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_DOCUMENT_TYPE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const getDocumentTypeList = (filterData: any) => {
  return (dispatch: any) => {
    dispatch(request(GET_DOCUMENT_TYPE_LIST_REQUEST));

    getDocumentTypeListService(filterData).then(
      (result: any) => {
        dispatch(success(GET_DOCUMENT_TYPE_LIST_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            GET_DOCUMENT_TYPE_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
