import {
  GET_ACTIVITY_LIST_FAILURE,
  GET_ACTIVITY_LIST_REQUEST,
  GET_ACTIVITY_LIST_SUCCESS,
} from "../actionTypes";

export interface activityListState {
  loading: boolean;
  activityList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: activityListState = {
  loading: false,
  activityList: {},
  error: "",
};

export const getActivityListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        activityList: action.payload,
        error: "",
      };
    case GET_ACTIVITY_LIST_FAILURE:
      return {
        loading: false,
        activityList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
