import { response } from "app/helpers/commonInterface";
import {
  GET_TRANSACTION_LIST_FAILURE,
  GET_TRANSACTION_LIST_REQUEST,
  GET_TRANSACTION_LIST_SUCCESS,
} from "../actionTypes";
import { transactionData } from "./transactionDataInterface";

export interface transactionListState {
  loading: boolean;
  transactionList: response<transactionData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: transactionListState = {
  loading: false,
  transactionList: {},
  error: "",
};

export const getTransactionListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_TRANSACTION_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTION_LIST_SUCCESS:
      return {
        loading: false,
        transactionList: action.payload,
        error: "",
      };
    case GET_TRANSACTION_LIST_FAILURE:
      return {
        loading: false,
        transactionList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
