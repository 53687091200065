import { FormikValues } from "formik";
import { FC, useState } from "react";

import { addFileObject } from "app/helpers/commonInterface";
import { formikData } from "../TemplateRegister";
import TemplatePreview from "./TemplatePreview";
import TemplateButtons from "./TemplateButtons";
import TemplateHeader from "./TemplateHeader";
import TemplateBody from "./TemplateBody";
import TemplateFooter from "./TemplateFooter";
import { useScreenWidth } from "app/helpers/customHooks";

interface props {
    formikData: formikData
}

const VARAIBLE_REGEX = /{{(\d)+}}/g

export const getNameFromValue = (arr: Array<{ value: string, name: string }>, value: string | number): string => {
    return arr.reduce((acc: any, obj: { value: string, name: string }) => {
        if (obj.value === value) return acc = obj.name
        return acc;
    }, "")
}

export const onChangeBodyDataHandler = (data: string, parentName: string,
    values: FormikValues, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const variableArr: Array<string> | null = data.match(VARAIBLE_REGEX)

    let muted = "";
    if (variableArr?.length) {
        let i = 0;
        muted = data.replaceAll(VARAIBLE_REGEX, `{{a}}`)
        while (i < variableArr.length) {
            muted = muted.replace(`{{a}}`, `{{${i + 1}}}`)
            i++;
        }
    }

    setFieldValue(`${parentName}[text]`, muted ? muted : data)
    setFieldValue(`${parentName}[example]`,
        variableArr?.length ?
            values[parentName].example.length > variableArr.length ?
                values[parentName].example.slice(0, variableArr.length) :
                [].concat(values[parentName].example, ...Array(variableArr.length - values[parentName].example.length).fill("")) :
            []
    )
}

export const addVariableFunction = (objName: string, textName: string, arrName: string,
    values: FormikValues, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const length = values[objName][arrName].length
    setFieldValue(`${objName}[${textName}]`, values[objName][textName] + `{{${length ? length + 1 : 1}}}`)
    if (objName == "header") {
        setFieldValue(`${objName}[${arrName}]`, [...values[objName][arrName], ""])
    }
}

const TemplateDetailForm: FC<props> = ({ formikData }) => {
    // const { values, errors, touched, setFieldValue } = formikData
    const [pdfFile, setPdfFile] = useState<{ file: File, details: addFileObject } | null>(null);
    const screenWidth = useScreenWidth();
    const [showPreview, setShowPreview] = useState(screenWidth <= 1200 ? false : true);

    return (
        <div className="position-relative">
            <div className="w-100 w-xl-75">
                <TemplateHeader setPdfFile={setPdfFile} pdfFile={pdfFile} />
                <TemplateBody />
                <TemplateFooter />
                <TemplateButtons />
            </div>
            <div className="wp-template-preview-parent">

                <div className="position-relative">
                    {screenWidth <= 1200 && <div>
                        <button type="button" className="btn btn-sm btn-light btn-active-light-primary"
                            onClick={() => setShowPreview(!showPreview)}>
                            <i className="fa-solid fa-eye"></i>
                            <span>Preview</span>
                        </button>
                    </div>}
                    {(screenWidth > 1200 || showPreview) &&
                        <div className="wp-template-preview">
                            <TemplatePreview pdfFile={pdfFile} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TemplateDetailForm