import { ErrorMessage, Form, Formik } from "formik";
import { FC } from "react";
import { object, number, array } from "yup";
import { useNavigate } from "react-router-dom";

import { addTaskValue } from "../TaskForm";
import { absolutePath } from "../../../../helpers/relativePath";
import { adminHomePage } from "../../main/admin/AdminMain";
import CustomDropdown, { workCategoryIdDataObject } from "./CustomDropdown";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import { emptyObject } from "../../../../helpers/helperFunction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import SelectedTaskList from "./SelectedTaskList";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { TASK_LIST } from "../../../../helpers/routes";
import { taskListPage } from "../TaskList";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

export interface addTaskActivityObject {
  CompletionFieldName: string;
  CompletionFieldType: string;
  Id: number;
  IsMandatory: boolean;
  MinValue: number;
  Name: string;
  RequireChecking: boolean;
  RequireCompletionField: boolean;
}
export interface taskTypes {
  Id: number;
  Name: string;
  DefaultAssigneeId: string;
  DefaultAssigneeName: string;
  DefaultExpertId: string;
  DefaultExpertName: string;
  DefaultManagerId: string;
  DefaultManagerName: string;
  RequireConfirmation: true;
  StandardFees: number;
  IsRepeatative: boolean;
  IdealDaysToComplete: number;
  GovernmentFixDate: string;
  Activities: Array<addTaskActivityObject>;
  OnboardBefore: number;
}
export interface categoryDetails {
  Id: number;
  Name: string;
  TaskTypes: Array<taskTypes>;
}

interface props {
  taskDispatch: Function;
  initialValue: addTaskValue;
  title: string;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const validateFun = object().shape({
  clientId: number()
    .positive("Client name is required")
    .required("Client name is required"),
  workCategoryId: array().min(1, "Category is required"),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  taskListPage,
  emptyObject,
];

const AddTask: FC<props> = ({
  initialValue,
  taskDispatch,
  title,
  masterDataList,
}) => {
  const navigate = useNavigate();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            const subTasks = values.workCategoryId.reduce(
              (acc: any, workCategory: workCategoryIdDataObject) => [
                ...acc,
                ...workCategory.subKey,
              ],
              []
            );
            const { clientId } = values;
            await taskDispatch({ clientId, subTasks }, () => {
              resetForm();
              sendDataChangeWarning();
            });
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Client Name
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Client Name"
                          setFieldValue={setFieldValue}
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Clients?.map(
                            (client) => ({
                              value: client.Id,
                              name: client.Name,
                            })
                          )}
                          name="clientId"
                          currentValue={{ value: values.clientId }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="clientId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Task
                      </label>

                      <div className="">
                        <CustomDropdown
                          categoryDetails={
                            masterDataList.masterData?.data?.records?.Categories
                          }
                          loader={masterDataList.loading}
                        />
                        <ErrorMessage
                          name="workCategoryId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <SelectedTaskList taskArr={values.workCategoryId} />
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(TASK_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddTask;