import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import BankBookTableRow from "./BankBookTableRow";
import { getBankBookList } from "app/reducers/bankBook/bankBookAction";
import { getFilterQueryString } from "app/helpers/helperFunction";
import { searchParams } from "app/helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  bankBookDetails: any;
  getBankBookListDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Bank Name",
    id: "BankName",
    className: "min-w-125px",
  },
  {
    columnName: "Account Number",
    id: "AccountNumber",
    className: "min-w-125px",
  },
  {
    columnName: "IFSC Code",
    id: "IFSCCode",
    className: "min-w-125px",
  },
  {
    columnName: "Branch Name",
    id: "BranchName",
    className: "min-w-125px",
  },
  {
    columnName: "Account Type",
    id: "AccountType",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end pe-5",
  },
];

const BankBookTable: FC<props> = ({
  bankBookDetails,
  getBankBookListDispatch,
}) => {
  const params = useParams();
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getBankBookListDispatch(
      { ...searchObj, Filters: getFilterQueryString({ IsCashBook: false }) },
      params.id
    );
  }, [getBankBookListDispatch, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={bankBookDetails.error}
        headers={HEADERS}
        success={bankBookDetails.bankBookList?.success}
        loading={bankBookDetails.loading}
        paginate={{
          show: true,
          pagination: bankBookDetails.bankBookList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={bankBookDetails.bankBookList?.data?.records?.BookRecords}
        tableRow={(row: any, index: number) => (
          <BankBookTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    bankBookDetails: state.bankBookList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBankBookListDispatch: (searchObj: searchParams, firmId: number) =>
      dispatch(getBankBookList(searchObj, firmId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankBookTable);
