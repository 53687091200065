import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  BOOK_API_ENDPOINT,
  GETALL_BOOK_API_ENDPOINT,
} from "app/helpers/config";
import { addBankBookData } from "app/components/pages/bankBook/BankBookForm";

export const addBankBookService = async (data: addBankBookData) => {
  return await axiosInstance.post(BOOK_API_ENDPOINT, data);
};

export const getBankBookListService = (
  searchObj: searchParams,
  firmId: number
) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GETALL_BOOK_API_ENDPOINT}/${firmId}?${queryString}`
  );
};

export const getBankBookService = (bankBookId: number) => {
  return axiosInstance.get(`${BOOK_API_ENDPOINT}?id=${bankBookId}`);
};

export const updateBankBookService = async (data: addBankBookData) => {
  return await axiosInstance.put(BOOK_API_ENDPOINT, data);
};

export const updateBankBookStatusService = async (bankBookId: number) => {
  return await axiosInstance.delete(`${BOOK_API_ENDPOINT}?id=${bankBookId}`);
};
