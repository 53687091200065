import { response } from "app/helpers/commonInterface";
import {
  GET_BILL_LIST_FAILURE,
  GET_BILL_LIST_REQUEST,
  GET_BILL_LIST_SUCCESS,
} from "../actionTypes";
import { billData } from "./billingDataInterface";

export interface billListState {
  loading: boolean;
  billList: response<billData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: billListState = {
  loading: false,
  billList: {},
  error: "",
};

export const getBillListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_BILL_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_BILL_LIST_SUCCESS:
      return {
        loading: false,
        billList: action.payload,
        error: "",
      };
    case GET_BILL_LIST_FAILURE:
      return {
        loading: false,
        billList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
