import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string } from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../../main/admin/AdminMain";
import { addCashBookData } from "../CashBookForm";
import { cashBookListPage } from "../CashBookList";
import { emptyObject } from "app/helpers/helperFunction";
import { FIRM_CASH_BOOK_LIST } from "app/helpers/routes";
import { firmListPage } from "../../firmMaster/FirmMasterList";
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";

interface props {
  firmName:string;
  title: string;
  cashBookDispatch: Function;
  initialValue: addCashBookData;
}

const firmBreadCrumbs = (firmId: number): Array<PageLink> => {
  return [adminHomePage, firmListPage, cashBookListPage(firmId), emptyObject];
};

const firmValidationSchema = object().shape({
  name: string().required("Cash book name is required"),
});

const AddCashBook: FC<props> = ({ title, cashBookDispatch, initialValue, firmName }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={firmBreadCrumbs(Number(params.id))}>
        {title}
      </PageTitle>
      <PageSubTitle>{ firmName || ""}</PageSubTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={firmValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await cashBookDispatch(
              values,
              params.id
                ? () => {
                    navigate(
                      absolutePath(`${FIRM_CASH_BOOK_LIST}/${params.id}`)
                    );
                  }
                : () => {
                    resetForm();
                  }
            );
          }}
        >
          {({ touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Cash Book Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.name &&
                              errors.name &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Cash Book Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(
                        absolutePath(`${FIRM_CASH_BOOK_LIST}/${params.id}`)
                      )
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddCashBook;
