import { connect } from "react-redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import {
  CLIENT_DOCUMENT_LIST,
  CONTACT_PERSON_DETAIL_PAGE,
  EDIT_CLIENT,
} from "../../../../helpers/routes";
import { updateClientStatus } from "../../../../reducers/client/clientAction";
import { KTSVG } from "../../../../../_metronic/helpers";
import StatusCell from "../../../commonComponent/table/StatusCell";
import UserActionsCell from "../../../commonComponent/table/UserActionsCell";
import { client } from "app/reducers/client/clientDataInterfce";

interface props {
  row: client;
  updateClientStatusDispatch: Function;
}

const ClientTableRow: FC<props> = ({ row, updateClientStatusDispatch }) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_CLIENT}/${row.Id}`));
  };

  const viewContactperson = () => {
    navigate(absolutePath(`${CONTACT_PERSON_DETAIL_PAGE}/${row.Id}`));
  };

  const viewDocumentHandler = () => {
    navigate(absolutePath(`${CLIENT_DOCUMENT_LIST}/${row.Id}`));
  };

  const updateList = (id: number, callBack: Function) => {
    updateClientStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row?.Name || "-"}</td>
      <td className="">{row?.GroupName || "-"}</td>
      <td className="">{row?.FirmName || "-"}</td>
      <td className="Bedge"> <span className="badge rounded-pill badge-light-primary">
    {row?.LegalStatusName || "-"}
  </span></td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateList}
            isActive={row?.IsActive}
            id={row?.Id}
          />
          <button
            className={`p-2 btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary`}
            title="View Contact Person"
            onClick={viewContactperson}
          >
            View Contact Person
          </button>
          <button
            className={`btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary`}
            title="View Documents"
            onClick={viewDocumentHandler}
          >
            View Documents
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateClientStatusDispatch: (clientId: number, successCallback: Function) =>
      dispatch(updateClientStatus(clientId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(ClientTableRow);
