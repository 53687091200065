import { connect } from "react-redux"
import { useParams } from "react-router-dom"

import { absolutePath } from "app/helpers/relativePath"
import { adminHomePage } from "../main/admin/AdminMain"
import CashBookTable from "./component/CashBookTable"
import { emptyObject } from "app/helpers/helperFunction"
import { firmListPage } from "../firmMaster/FirmMasterList"
import { FIRM_CASH_BOOK_LIST } from "app/helpers/routes"
import { KTCard } from "_metronic/helpers"
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core"
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar"
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"
import { FC } from "react"

interface props {
    cashBookDetails : any
}

export const cashBookListPage = (firmId : number) => {
    return {
        title: 'Cash Book',
        path: absolutePath(`${FIRM_CASH_BOOK_LIST}/${firmId}`),
        isSeparator: true,
        isActive: true,
    }
}

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    firmListPage,
    emptyObject
]

const CashBookList:FC<props> = ({cashBookDetails}) => {
    const params = useParams();

    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Cash Books</PageTitle>
            <PageSubTitle>{cashBookDetails.cashBookList?.data?.records?.FirmName}</PageSubTitle>
            <div className='d-flex justify-content-end align-items-end flex-wrap gap-3 mb-4'>
                <UsersListSearchComponent />
                <UsersListToolbar
                    buttons={[{ title: "Add Cash Book", to: `${FIRM_CASH_BOOK_LIST}/${params.id}/add` }]} />
            </div>
            <KTCard>
                <CashBookTable />
            </KTCard>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
      cashBookDetails: state.cashBookList
    }
  }

export default connect(mapStateToProps)(CashBookList)