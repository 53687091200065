import { Link, Outlet, useLocation } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import {
  ADD_WHATSAPP_DATA,
  SETTINGS,
  TEMPLATE,
  TEMPLATE_REGISTER,
  WHATSAPP,
} from "app/helpers/routes";
import TemplateRegisterFilter from "./templateRegister/TableComponent/TemplateRegisterFilter";
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";

const Settings = () => {
  const location = useLocation();

  return (
    <ErrorBoundary>
      <div className="mb-5 mb-xl-10">
        <div className="d-flex gap-7 flex-column">
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
            <div className="d-flex overflow-auto h-55px tabs-container">
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                <li className="nav-item profile-tab-width">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 fw-semibold ` +
                      ((location.pathname === absolutePath(SETTINGS) ||
                        location.pathname ===
                          absolutePath(`${SETTINGS}/${TEMPLATE_REGISTER}`) ||
                        location.pathname ===
                          absolutePath(`${SETTINGS}/${TEMPLATE}`)) &&
                        "active")
                    }
                    to={absolutePath(`${SETTINGS}/${TEMPLATE}`)}
                  >
                    Template
                  </Link>
                </li>
                <li className="nav-item profile-tab-width">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 fw-semibold ` +
                      (location.pathname ===
                        absolutePath(`${SETTINGS}/${WHATSAPP}`) && "active")
                    }
                    to={absolutePath(`${SETTINGS}/${WHATSAPP}`)}
                  >
                    Whatsapp
                  </Link>
                </li>
              </ul>
            </div>
            {(location.pathname === absolutePath(SETTINGS) ||
              location.pathname ===
                absolutePath(`${SETTINGS}/${TEMPLATE}`)) && (
              <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 position-relative">
                <UsersListSearchComponent />
                <TemplateRegisterFilter />
                <UsersListToolbar
                  buttons={[{ title: "Add Template", to: TEMPLATE_REGISTER }]}
                />
              </div>
            )}
            {location.pathname === absolutePath(`${SETTINGS}/${WHATSAPP}`) && (
              <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 position-relative">
                <UsersListToolbar
                  buttons={[{ title: "Add Whatsapp", to: ADD_WHATSAPP_DATA }]}
                />
              </div>
            )}
          </div>
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Settings;
