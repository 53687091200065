import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

import AddTransaction from "./component/AddTransaction";
import {
  addTransaction,
  getTransaction,
  updateTransaction,
} from "app/reducers/transaction/transactionAction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "app/components/commonComponent/loader/Loader";
import { transactionDataState } from "app/reducers/transaction/getByIdTransactionReducer";
import { transactionDetail } from "app/reducers/transaction/transactionDataInterface";

export interface addTransactionValue {
  id?: number;
  isActive?: boolean;
  transactionDate?: string;
  amount: number | string;
  narration: string;
  type: number | undefined;
  bookId: number;
  taskId?: number;
  billId?: number;
  expenseId?: number;
  isPayment: string;
  clientId: number | undefined;
  firmId?: number;
  isCashBook?: string;
  clientName?: string;
}


interface props {
  closeModal: Function;
  transaction: {
    show: boolean;
    edit: boolean;
    transactionId: number | null;
    isPayment: boolean | null;
  };
  updateTransactionDispatch: Function;
  addTransactionDispatch: Function;
  getTransactionDetailsDispatch: Function;
  transactionDetails: transactionDataState;
}

const getInitialValue = (bookId: number): addTransactionValue => {
  return {
    bookId: bookId,
    amount: "",
    narration: "",
    type: undefined,
    taskId: undefined,
    billId: undefined,
    expenseId: undefined,
    isPayment: "false",
    clientId: undefined,
    isCashBook: "true",
  };
};
const getValueForEdit = (
  transactionData: transactionDetail | undefined
): addTransactionValue => {
  return {
    id: transactionData?.Id,
    isActive: transactionData?.IsActive,
    transactionDate: transactionData?.TransactionDate,
    amount: transactionData?.Amount || "",
    narration: transactionData?.Narration || "",
    type: transactionData?.Type,
    bookId: transactionData?.BookId || 0,
    taskId: transactionData?.TaskId,
    billId: transactionData?.BillId,
    expenseId: transactionData?.ExpenseId,
    isPayment: String(transactionData?.IsPayment),
    clientId: transactionData?.ClientId,
    clientName: transactionData?.ClientName,
  };
};

const TransactionForm: FC<props> = ({
  closeModal,
  transaction,
  updateTransactionDispatch,
  addTransactionDispatch,
  getTransactionDetailsDispatch,
  transactionDetails,
}) => {
  const params = useParams();

  useEffect(() => {
    if (transaction.edit) {
      getTransactionDetailsDispatch(
        transaction.transactionId,
        transaction.isPayment
      );
    }
  }, [getTransactionDetailsDispatch, transaction]);

  return (
    <ErrorBoundary>
      <Modal
        show={transaction.show}
        aria-labelledby="contained-modal-transaction"
        centered
        data-toggle="modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="">
            {transaction.edit ? "Update Transaction" : "Add Transaction"}
          </Modal.Title>
        </Modal.Header>
        {transaction.edit && transactionDetails.loading && (
          <div className="py-5">
            <Loader />
          </div>
        )}
        {transaction.edit && transactionDetails.error && (
          <ErrorDisplay error={transactionDetails.error} />
        )}
        {(transaction.edit
          ? transactionDetails?.transaction?.success
          : true) && (
          <>
            {transaction.edit && (
              <AddTransaction
                initialValue={getValueForEdit(
                  transactionDetails?.transaction?.data?.records
                )}
                transactionDispatch={updateTransactionDispatch}
                transactionDetails={
                  transactionDetails?.transaction?.data?.records
                }
                closeModal={closeModal}
                transaction={transaction}
              />
            )}
            {!transaction.edit && (
              <AddTransaction
                initialValue={getInitialValue(Number(params?.bookId || 0))}
                transactionDispatch={addTransactionDispatch}
                closeModal={closeModal}
                transaction={transaction}
              />
            )}
          </>
        )}
      </Modal>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    transactionDetails: state.transaction,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionDetailsDispatch: (
      transactionId: number,
      isPayment: boolean
    ) => dispatch(getTransaction(transactionId, isPayment)),
    addTransactionDispatch: (
      data: addTransactionValue,
      successCallback: Function
    ) => dispatch(addTransaction(data, successCallback)),
    updateTransactionDispatch: (
      data: addTransactionValue,
      successCallback: Function
    ) => dispatch(updateTransaction(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);
