import { connect } from "react-redux";
import { FC, useState } from "react";

import { sendOTPRequest } from "app/reducers/task/taskAction";
import { ResetButton, SubmitButton } from "../buttons/Buttons";
import { Modal } from "react-bootstrap";

interface props {
  setShowOtpPopup: Function;
  showOtpPopup: {
    show: boolean;
    apiData: string | number | null;
    article: string;
  };
  updateArticleFun: Function;
  sendOTPRequestDispatch: Function;
  taskId: number | string | undefined;
}

const OTPPopup: FC<props> = ({
  taskId,
  updateArticleFun,
  showOtpPopup,
  setShowOtpPopup,
  sendOTPRequestDispatch,
}) => {
  const [showOtpBox, setShowOtpBox] = useState(false);
  // const [otpMessage, setOtpMessage] = useState("");
  const [otp, setOtp] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  const getGeneratedOtp = () => {
    setLoading(true);
    sendOTPRequestDispatch(
      taskId,
      () => {
        setLoading(false);
        setShowOtpBox(true);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateArticleButtonCallback = () => {
    setLoading(true);
    updateArticleFun(
      otp.join(""),
      () => {
        setLoading(false);
        setOtp([]);
        setShowOtpBox(false);
        setShowOtpPopup({ ...showOtpPopup, show: false });
      },
      () => {
        setLoading(false);
      }
    );
  };

  const cancelButtonCallback = () => {
    setLoading(false);
    setOtp([]);
    setShowOtpBox(false);
    setShowOtpPopup({ ...showOtpPopup, show: false });
  };

  const handleChange = (value: string, index: number) => {
    let otpArr = otp;
    otpArr[index] = value;
    setOtp([...otpArr]);
  };

  const inputfocus = (elmnt: any) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 1;
      if (next > -1) {
        // elmnt.target.form.elements[next].focus()
        elmnt.target.previousElementSibling.focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        // elmnt.target.form.elements[next].focus()
        elmnt.target.nextElementSibling.focus();
      }
    }
  };

  return (
    <Modal
      show={showOtpPopup.show}
      aria-labelledby="contained-modal-otp"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Update Article</Modal.Title>
      </Modal.Header>
      {/* <div className="card card-body w-300px w-sm-400px otp-pop-up px-7 py-5"> */}
      <Modal.Body className="fs-5 fw-semibold">
        {/* <div className="fs-5 fw-semibold mb-5 mt-1"> */}
        {showOtpBox ? (
          "Enter OTP "
        ) : (
          // <><span>Task article has been updated to <b>{showOtpPopup.article}</b></span></>
          <>
            <div className="mb-2">
              <b>{showOtpPopup.article}</b> is now assigned as the new article
              for the task.
            </div>
            <div>
              {" "}
              In order to validate and complete this assignee alteration, an OTP
              (One-Time Password) has been sent to the admin.
            </div>
          </>
        )}
        {/* </div> */}

        <div className="align-items-center d-flex gap-5 mb-6 mt-2">
          {/* <input type="text"
            placeholder="OTP"
            className={`form-control form-control-solid w-300px ${showOtpBox ? "" : "hidden"}`}
            onChange={(e: any) => setOtpMessage(e.target.value)} /> */}
          {Array(6)
            .fill(0)
            .map((data: any, index: number) => {
              return (
                <input
                  key={index}
                  name="otpBox"
                  type="text"
                  className={`otpInput text-center w-30px border border-gray-500 rounded-2 lh-xl ${
                    showOtpBox ? "" : "hidden"
                  }`}
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  value={otp[index]}
                  onChange={(e: any) => handleChange(e.target.value, index)}
                  tabIndex={index}
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 pt-2">
        <div className="align-items-center d-flex gap-3 justify-content-end mt-4">
          <ResetButton
            name="Cancel"
            className="btn btn-light btn-sm btn-active-light-primary"
            onClickCallback={cancelButtonCallback}
            disabled={loading}
          />
          <SubmitButton
            name="Send OTP"
            isSubmitting={loading}
            className={`btn btn-primary btn-sm ${showOtpBox ? "hidden" : ""}`}
            onClickCallback={getGeneratedOtp}
            disabled={loading}
          />
          <SubmitButton
            name="Submit"
            isSubmitting={loading}
            className={`btn btn-primary btn-sm ${showOtpBox ? "" : "hidden"}`}
            onClickCallback={updateArticleButtonCallback}
            disabled={
              !otp.length || otp.some((num: string) => num == "") || loading
            }
          />
        </div>
      </Modal.Footer>
      {/* </div> */}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendOTPRequestDispatch: (
      taskId: number,
      successCallback: Function,
      failureCallback: Function
    ) => dispatch(sendOTPRequest(taskId, successCallback, failureCallback)),
  };
};

export default connect(null, mapDispatchToProps)(OTPPopup);
