import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { ChangeEvent, FC, useState } from "react";
import { object, string, number, bool } from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { addFirmValue } from "../FirmMasterForm";
import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../../main/admin/AdminMain";
import { emptyObject, getFormData, getImage } from "app/helpers/helperFunction";
import { firmListPage } from "../FirmMasterList";
import { FIRMS_LIST } from "app/helpers/routes";
import { PageLink, PageTitle } from "_metronic/layout/core";
import {
  ResetButton,
  SmallLoader,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { addFileObject } from "app/helpers/commonInterface";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

interface props {
  title: string;
  firmDispatch: Function;
  initialValue: addFirmValue;
  uploadFileDispatch: Function;
}

const firmBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  firmListPage,
  emptyObject,
];

const firmValidationSchema = object().shape({
  name: string().required("Account name is required"),
  hasGST: bool(),
  gstin: string().max(15, "GSTIN must be at most 15 characters"),
  taxRate: number().when("hasGST", {
    is: true,
    then: number().required("Tax rate is required"),
  }),
  billSeries: string().required("BillSeries is required"),
  billTypeSeries: string().required("BillTypeSeries is required"),
});

const AddFirm: FC<props> = ({
  title,
  firmDispatch,
  initialValue,
  uploadFileDispatch,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const sendDataChangeWarning = useSendDataChangeWarning();

  const onChangeFileHandler = (
    file: any,
    setValues: any,
    values: FormikValues
  ) => {
    setLoading(true);
    uploadFileDispatch(
      getFormData({ file: file }),
      (fileObject: addFileObject) => {
        setValues({
          ...values,
          qrCodeFile: {
            FileName: fileObject.FileName,
            FilePath: fileObject.FilePath,
            FileUrl: fileObject.FileUrl,
            MimeType: fileObject.MimeType,
          },
        });
        setLoading(false);
      }
    );
  };

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={firmBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={firmValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await firmDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(FIRMS_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Firm Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.name &&
                            errors.name &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Firm Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        PAN No
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                        ${
                          touched.panNumber &&
                          errors.panNumber &&
                          "is-invalid inValidBorder"
                        }`}
                          placeholder="PAN No."
                          id="panNumber"
                          name="panNumber"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="panNumber"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                     {/* GSTIN FIELD */}
                     <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        GSTIN
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.gstin &&
                              errors.gstin &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="GSTIN"
                          id="gstin"
                          name="gstin"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="gstin"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4 d-flex gap-4">
                      <div>
                        <label className="col-form-label fw-semibold fs-6 required">
                          BillSeries
                        </label>

                        <div className="">
                          <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.billSeries &&
                              errors.billSeries &&
                              "is-invalid inValidBorder"
                            }`}
                            placeholder="BillSeries"
                            id="billSeries"
                            name="billSeries"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                "billSeries",
                                e.target.value.toUpperCase()
                              )
                            }
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="billSeries"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div>
                        <label className="col-form-label fw-semibold fs-6 required">
                          BillTypeSeries
                        </label>

                        <div className="">
                          <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.billTypeSeries &&
                              errors.billTypeSeries &&
                              "is-invalid inValidBorder"
                            }`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                "billTypeSeries",
                                e.target.value.toUpperCase()
                              )
                            }
                            placeholder="BillTypeSeries"
                            id="billTypeSeries"
                            name="billTypeSeries"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="billTypeSeries"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        Address
                      </label>

                      <div className="">
                        <textarea
                          className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.address &&
                            errors.address &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Address"
                          id="address"
                          name="address"
                          value={values.address}
                          onChange={(e: any) =>
                            setFieldValue("address", e.target.value)
                          }
                          disabled={isSubmitting}
                        ></textarea>
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                  <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        Account Number
                      </label>

                      <div className="">
                        <Field
                          type="number"
                          className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.accountNumber &&
                            errors.accountNumber &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Account Number"
                          id="accountNumber"
                          name="accountNumber"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="accountNumber"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        Bank Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.bankName &&
                            errors.bankName &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Bank Name"
                          id="bankName"
                          name="bankName"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="bankName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                   
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        IFSC Code
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                          ${
                            touched.ifscCode &&
                            errors.ifscCode &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="IFSC Code"
                          id="ifscCode"
                          name="ifscCode"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="ifscCode"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        QR Code
                      </label>

                      <div className="">
                        {!values.qrCodeFile?.FilePath && (
                          <>
                            <label htmlFor="filePath" className="col-lg-9">
                              <div className="d-inline-flex gap-3 btn btn-light-primary btn-active-light-primary btn-sm">
                                {loading ? (
                                  <SmallLoader />
                                ) : (
                                  <i className="bi bi-upload me-1"></i>
                                )}
                                Upload File
                              </div>
                              <Field
                                type="file"
                                value={undefined}
                                className="d-none"
                                id="filePath"
                                name="filePath"
                                onChange={(e: any) =>
                                  onChangeFileHandler(
                                    e.target.files[0],
                                    setValues,
                                    values
                                  )
                                }
                                accept=".jpg, .jpeg, .png"
                              />
                              <ErrorMessage
                                name="filePath"
                                component="div"
                                className="errorMsg"
                              />
                            </label>
                          </>
                        )}
                        {values.qrCodeFile?.FilePath && (
                          <div className="col-lg-9 ms-3 upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                            <div className="d-flex gap-3 align-items-center">
                              <div className="w-35px">
                                <ImageDisplay
                                  path={getImage(
                                    values.qrCodeFile?.MimeType || "pdf"
                                  )}
                                  errorPath="/media/task/doc-1.png"
                                  className="img-fit-to-div"
                                  altText="documentType"
                                />
                              </div>
                              <div className="fw-semibold">
                                <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                                  {values.qrCodeFile?.FileName || "QrCode"}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <button
                                className="btn p-0"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    qrCodeFile: null,
                                  })
                                }
                              >
                                <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 d-flex flex-wrap gap-3 align-items-start pt-3 pt-sm-0">
                      <div className="d-flex gap-3">
                        <div className="form-check form-check-custom form-check-solid">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="hasGST"
                            name="hasGST"
                            checked={values.hasGST}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setFieldValue("hasGST", true);
                              } else {
                                setFieldValue("hasGST", false);
                                setFieldValue("taxRate", 0);
                              }
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                        <label className="col-form-label fw-semibold fs-6 ">
                          GST Required
                        </label>
                      </div>
                      {values.hasGST && (
                        <div className="d-flex align-items-start gap-4 mb-6">
                          <div className="w-50">
                            <Field
                              type="number"
                              className={`form-control form-control-lg form-control-solid 
                              ${
                                touched.taxRate &&
                                errors.taxRate &&
                                "is-invalid inValidBorder"
                              }`}
                              placeholder="Tax Rate"
                              id="taxRate"
                              name="taxRate"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="taxRate"
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                          <label className="col-form-label fw-semibold fs-6 required">
                            Tax Rate
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(FIRMS_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddFirm;
