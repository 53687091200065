import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string, ref } from "yup";
import { useNavigate } from "react-router-dom";

import { editProfileData } from "../../../helpers/commonInterface";
import { editProfile } from "../../../reducers/profile/profileAction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";

interface props {
  editProfileDispatch: Function;
}

interface resetPasswordInitialValue {
  password: string;
  confirmPassword?: string;
  newPassword: string;
}

const initialValue: resetPasswordInitialValue = {
  password: "",
  confirmPassword: "",
  newPassword: "",
};

const validateFun = object().shape({
  password: string().required("Current password is required"),
  newPassword: string()
    .required("New password is required")
    .matches(
      /(?=.*[A-Z])(?=.*\W+)(?=.*\d+).+/,
      "Password must contain one uppercase, one number and one special case character"
    ),
  confirmPassword: string()
    .when("newPassword", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: string().oneOf(
        [ref("newPassword")],
        "New password and Confirm password didn't match"
      ),
    })
    .required("Password confirmation is required"),
});

const ChangePassword: FC<props> = ({ editProfileDispatch }) => {
  const navigate = useNavigate();
  return (
    <ErrorBoundary>
      <div className="card reset-password-container py-4">
        <div className="text-center py-8">
          <h1 className="text-dark fw-bolder mb-3">Change Password</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Please enter your new password
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { setStatus }) => {
            delete values.confirmPassword;
            await editProfileDispatch(
              values,
              false,
              () => {
                setStatus("");
                navigate(-1);
              },
              (errorMessage: string) => {
                setStatus(errorMessage);
              }
            );
          }}
        >
          {({ status, touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  {status && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        There was an error updating password
                      </div>
                    </div>
                  )}
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark required">
                      Current Password
                    </label>
                    <div className="">
                      <Field
                        type="text"
                        className={`form-control form-control-lg form-control-solid ${
                          touched.password &&
                          errors.password &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="Current Password"
                        id="password"
                        name="password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark required">
                      New Password
                    </label>

                    <div className="">
                      <Field
                        type="password"
                        className={`form-control form-control-lg form-control-solid ${
                          touched.newPassword &&
                          errors.newPassword &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="New Password"
                        id="newPassword"
                        name="newPassword"
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark required">
                      Confirm New Password
                    </label>

                    <div className="">
                      <Field
                        type="password"
                        className={`form-control form-control-lg form-control-solid ${
                          touched.confirmPassword &&
                          errors.confirmPassword &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="Confirm New Password"
                        id="confirmPassword"
                        name="confirmPassword"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-8 px-6 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(-1)}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editProfileDispatch: (
      data: editProfileData,
      isEditProfileDetails: boolean,
      successCallback: Function,
      errorCallback: Function
    ) =>
      dispatch(
        editProfile({
          data,
          isEditProfileDetails,
          successCallback,
          errorCallback,
        })
      ),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
