import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { absolutePath } from '../../../helpers/relativePath';
import { KTSVG } from '../../../../_metronic/helpers'

interface buttonDetails {
  to: string
  title: string
}
interface props {
  buttons: Array<buttonDetails>
}

const UsersListToolbar: FC<props> = ({ buttons }) => {
  const navigate = useNavigate();
  const openAddUserModal = (to: string) => {
    navigate(absolutePath(to))
  }

  return (
    <div className='d-flex justify-content-end gap-3' data-kt-user-table-toolbar='base'>
      {buttons.map((button: buttonDetails, index: number) => (
        <button key={index} type='button' className='btn btn-primary btn-sm' onClick={() => openAddUserModal(button.to)}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {button.title}
        </button>))}
    </div>
  )
}

export default UsersListToolbar
