import { connect } from "react-redux";
import { FC, useEffect } from "react";

import Table from "app/components/commonComponent/table/Table";
import { userListState } from "../../../../reducers/user/userListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import MPCustomRow from "./MPCustomRow";
import AMPCustomRow from "./AMPCustomRow";
import AERCustomRow from "./AERCustomRow";

const HEADERS = [
 
  {
    columnName: "Task Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "StartTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "EndTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Duration",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Assignee",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Discription",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Status Name",
    id: "NULL",
    className: "min-w-125px",
  }
];

interface props {
    usersEveningReportingList:any
}

const AllEveningReportingTable: FC<props> = ({usersEveningReportingList}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
  }, [searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={usersEveningReportingList.error}
        headers={HEADERS}
        success={usersEveningReportingList.usersEveningReportingList?.success}
        loading={usersEveningReportingList.loading}
        paginate={{
          show: true,
          pagination: usersEveningReportingList.usersEveningReportingList?.pagination,
          PageNumber: Number(searchObj.PageNumber) || 1,
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={usersEveningReportingList.usersEveningReportingList?.data?.records}
        tableRow={(row: any, index: number) => (
          <AERCustomRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    usersEveningReportingList: state.usersEveningReportingList,
  };
};


export default connect(mapStateToProps, null)(AllEveningReportingTable);
