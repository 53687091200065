import {GET_USERS_EVENING_REPORTING_REQUEST, GET_USERS_EVENING_REPORTING_SUCCESS, GET_USERS_EVENING_REPORTING_FAILURE, DELETE_USERS_EVENING_REPORTING_REQUEST, DELETE_USERS_EVENING_REPORTING_SUCCESS, DELETE_USERS_EVENING_REPORTING_FAILURE } from "../actionTypes"

interface usersEveningReportingListState {
    deleteLoading:boolean,
    loading  : boolean,
    usersEveningReportingList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:usersEveningReportingListState = {
    deleteLoading:false,
    loading  : false,
    usersEveningReportingList : {},
    error : ""
}

export const usersEveningReportingReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_USERS_EVENING_REPORTING_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_USERS_EVENING_REPORTING_SUCCESS : return {
            ...state,
            loading : false,
            usersEveningReportingList : action.payload,
            error : ""
        }
        case GET_USERS_EVENING_REPORTING_FAILURE : return {
            ...state,
            loading : false,
            usersEveningReportingList : {},
            error : action.payload
        }

        case DELETE_USERS_EVENING_REPORTING_REQUEST : return {
            ...state,
            deleteLoading : true
        }
        case DELETE_USERS_EVENING_REPORTING_SUCCESS : return {
            ...state,
            deleteLoading : false,
            usersEveningReportingList : action.payload,
            error : ""
        }
        case DELETE_USERS_EVENING_REPORTING_FAILURE : return {
            ...state,
            deleteLoading : false,
            usersEveningReportingList : {},
            error : action.payload
        }
        default : return state
    }
}