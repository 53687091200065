import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/components/pages/auth";
import { connect } from "react-redux";
import { profileState } from "../../../../app/reducers/profile/profileReducer";
import { absolutePath } from "../../../../app/helpers/relativePath";
import {
  CHANGE_PASSWORD_PAGE,
  PROFILE_PAGE,
  SETTINGS,
} from "../../../../app/helpers/routes";
import Avatar from "../../../../app/components/commonComponent/Avatar";
import { getColor } from "../../../../app/helpers/helperFunction";
import hasPermission, {
  actionsRole,
} from "app/components/pages/auth/core/permission";

interface props {
  profileDetails: profileState;
}

const HeaderUserMenuCom: FC<props> = ({ profileDetails }) => {
  const { logout } = useAuth();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <Avatar
              firstName={profileDetails.profileData.FirstName}
              lastName={profileDetails.profileData.LastName}
              size={100}
              color={getColor(profileDetails?.profileData?.LastName).color}
              background={
                getColor(profileDetails?.profileData?.LastName).background
              }
            />
          </div>

          <div className="d-flex flex-column w-100">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {profileDetails?.profileData?.FirstName ? (
                <>
                  {profileDetails.profileData.FirstName}{" "}
                  {profileDetails.profileData.LastName}
                </>
              ) : (
                "User Name"
              )}
            </div>
            <div className="fw-bold text-muted fs-7 word-break-90">
              {profileDetails.profileData.Email || "abc@gmail.com"}
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={absolutePath(PROFILE_PAGE)} className="menu-link px-5">
          My Profile
        </Link>
      </div>
      <div className="menu-item px-5">
        <Link
          to={absolutePath(CHANGE_PASSWORD_PAGE)}
          className="menu-link px-5"
        >
          Change Password
        </Link>
      </div>
      {/* {hasPermission(actionsRole.ADMIN) && ( */}
      <div className="menu-item px-5">
        <Link to={absolutePath(SETTINGS)} className="menu-link px-5">
          Settings
        </Link>
      </div>
      {/* )} */}
      <div className="menu-item px-5">
        <div onClick={logout} className="menu-link px-5">
          Sign Out
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    profileDetails: state.profile,
  };
};
const HeaderUserMenu = connect(mapStateToProps)(HeaderUserMenuCom);

export { HeaderUserMenu };
