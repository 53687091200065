import { connect } from "react-redux";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "app/helpers/relativePath";
import {
  EDIT_FIRM,
  FIRM_BANK_BOOK_LIST,
  FIRM_CASH_BOOK_LIST,
} from "app/helpers/routes";
import { KTSVG } from "_metronic/helpers";
import { updateFirmStatus } from "app/reducers/firmMaster/firmMasterAction";
import StatusCell from "app/components/commonComponent/table/StatusCell";
import UserActionsCell from "app/components/commonComponent/table/UserActionsCell";
import { downloadFile } from "app/reducers/task/taskAction";
import { addFileObject } from "app/helpers/commonInterface";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";

interface props {
  row: {
    Name: string;
    Id: number;
    IsActive: boolean;
    HasGST: boolean;
    TaxRate: number;
    PANNumber: string;
    QRCodeFile: addFileObject;
    BillSeries: string;
    BillTypeSeries: string;
  };
  updateFirmStatusDispatch: Function;
  downloadFileDispatch: Function;
}

const FirmMasterTableRow: FC<props> = ({
  downloadFileDispatch,
  row,
  updateFirmStatusDispatch,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const editButtonHandler = () => {
    navigate(absolutePath(`${EDIT_FIRM}/${row.Id}`));
  };

  const updateStatus = (id: number, callBack: Function) => {
    updateFirmStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.Name || "-"}</td>
      <td>
        <span
          className={`badge badge-light-${row.HasGST ? "danger" : "warning"}`}
        >
          {`${row.HasGST && row.HasGST ? "Required" : "Not Required"}` || "-"}
        </span>
      </td>
      <td>{`${row.TaxRate}%` || "-"}</td>
      <td>{row?.PANNumber || "-"}</td>
      <td>{row?.BillSeries || "-"}</td>
      <td>{row?.BillTypeSeries || "-"}</td>
      <td>
        {row?.QRCodeFile ? (
          <button
            type="button"
            className="btn btn-sm btn-bg-light btn-icon btn-active-light-primary"
            title="Download"
            onClick={() => {
              setLoading(true);
              downloadFileDispatch(
                { id: row.Id, destination: "firm" },
                `${row.QRCodeFile.FileName}.${row.QRCodeFile.MimeType}`,
                () => {
                  setLoading(false);
                }
              );
            }}
          >
            {loading ? (
              <>
                <div className="w-20px text-gray-600">
                  <SmallLoader />
                </div>
              </>
            ) : (
              <i className="fa-solid fa-download"></i>
              // <ImageDisplay
              //   path="media/task/download-arrow.png"
              //   altText="download-arrow"
              //   className="w-20px"
              //   errorPath="/media/task/download-arrow.png"
              // />
            )}
          </button>
        ) : (
          <span className="badge badge-secondary">Not Available</span>
        )}
      </td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-end pe-5">
          <button
            className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary me-5`}
            title="Edit"
            onClick={editButtonHandler}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateStatus}
            isActive={row?.IsActive}
            id={row?.Id}
          />
          <button
            className={`w-100px p-2 btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary ms-5 me-5`}
            title="Bank Book"
            onClick={() =>
              navigate(absolutePath(`${FIRM_BANK_BOOK_LIST}/${row.Id}`))
            }
          >
            Bank Book
          </button>
          <button
            className={`w-100px p-2 btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary`}
            title="Cash Book"
            onClick={() =>
              navigate(absolutePath(`${FIRM_CASH_BOOK_LIST}/${row.Id}`))
            }
          >
            Cash Book
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
    updateFirmStatusDispatch: (firmId: number, successCallback: Function) =>
      dispatch(updateFirmStatus(firmId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(FirmMasterTableRow);
