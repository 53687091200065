import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AddActivity from "./component/AddActivity";
import {
  addActivity,
  getActivity,
  updateActivity,
} from "app/reducers/activity/activityAction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface activityDetails {
  Id: number;
  IsActive: boolean;
  Name: string;
  AssigneeId: string;
  PersonAssigned: string;
  CreatorId: string;
  CreatedBy: string;
  Description: string;
  Remark: string;
  CreatedDate: string;
  Status: number;
}

interface props {
  activityDetails: {
    loading: boolean;
    error: string;
    activity: {
      data: { records: activityDetails };
      success: boolean;
      status: number;
      message: string;
    };
  };
  addActivityDispatch: Function;
  updateActivityDispatch: Function;
  getActivityDispatch: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

export interface initialActivityModal {
  id?: number;
  name: string;
  assigneeId: string;
  description?: string;
  remark?: string;
  createdDate?: string;
  status?: number;
  creatorId?: string;
  isActive?: boolean;
}

const initialValue: initialActivityModal = {
  name: "",
  assigneeId: "",
  description: "",
};

const getValueForEdit = (
  activityData: activityDetails
): initialActivityModal => {
  return {
    id: activityData.Id,
    name: activityData.Name,
    assigneeId: activityData.AssigneeId,
    description: activityData.Description,
    remark: activityData.Remark,
    createdDate: activityData.CreatedDate,
    status: activityData.Status,
    isActive: activityData.IsActive,
    creatorId: activityData.CreatorId,
  };
};

const ActivityForm: FC<props> = ({
  masterDataList,
  activityDetails,
  addActivityDispatch,
  updateActivityDispatch,
  getActivityDispatch,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getActivityDispatch(params.id);
    }
  }, [params.id]);

  return (
    <ErrorBoundary>
      {params.id && activityDetails.loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : params.id && activityDetails.error && !activityDetails.loading ? (
        <ErrorDisplay error={activityDetails.error} />
      ) : (
        (params.id
          ? activityDetails.activity?.success && !activityDetails.loading
          : true) && (
          <>
            {params.id && (
              <AddActivity
                initialValue={getValueForEdit(
                  activityDetails.activity?.data?.records
                )}
                activityDispatch={updateActivityDispatch}
                title="Edit Activity"
                masterDataList={masterDataList}
                activityDetails={activityDetails.activity?.data?.records}
              />
            )}
            {!params.id && (
              <AddActivity
                initialValue={initialValue}
                activityDispatch={addActivityDispatch}
                title="Add Activity"
                masterDataList={masterDataList}
                activityDetails={activityDetails.activity?.data?.records}
              />
            )}
          </>
        )
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activityDetails: state.activity,
    masterDataList: state.masterData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addActivityDispatch: (
      data: initialActivityModal,
      successCallback: Function
    ) => dispatch(addActivity(data, successCallback)),
    updateActivityDispatch: (
      data: initialActivityModal,
      successCallback: Function
    ) => dispatch(updateActivity(data, successCallback)),
    getActivityDispatch: (activityId: number) =>
      dispatch(getActivity(activityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityForm);
