import { connect } from "react-redux"
import { FC, useEffect } from "react"
import { useParams } from "react-router-dom"

import AddCashBook from "./component/AddCashBook"
import { addCashBook, getCashBook, updateCashBook } from "app/reducers/cashBook/cashBookAction"
import ErrorBoundary from "app/helpers/ErrorBoundary"
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay"
import Loader from "app/components/commonComponent/loader/Loader"

interface props {
    firmName: string
    cashBookDetails: any
    updateCashBookDispatch: Function
    addCahsBookDispatch: Function
    getCashBookDetailsDispatch: Function
}

export interface addCashBookData {
    id?: number,
    isActive?: boolean,
    name: string,
    isCashBook: boolean,
    firmId: number
}

interface editCashBookData {
    Id: number,
    IsActive: boolean,
    Name: string,
    FirmId: number,
    IsCashBookData: boolean
}

const getInitialValue = (firmId: number): addCashBookData => {
    return {
        name: "",
        isCashBook: true,
        firmId: firmId
    }
}

const getValueForEdit = (cashBookData: editCashBookData): addCashBookData => {
    return {
        id: cashBookData.Id,
        isActive: cashBookData.IsActive,
        name: cashBookData.Name,
        firmId: cashBookData.FirmId,
        isCashBook: cashBookData.IsCashBookData
    }
}

const CashBookForm: FC<props> = ({ cashBookDetails, updateCashBookDispatch, addCahsBookDispatch, getCashBookDetailsDispatch, firmName }) => {
    const params = useParams();

    useEffect(() => {
        if (params.cashBookId) {
            getCashBookDetailsDispatch(params.cashBookId)
        }
    }, [getCashBookDetailsDispatch, params.cashBookId])

    return (
        <ErrorBoundary>
            {(params.cashBookId && cashBookDetails.loading) && <div className='loader-container'><Loader /></div>}
            {(params.cashBookId && cashBookDetails.error) && <ErrorDisplay error={cashBookDetails.error} />}
            {(params.cashBookId ? cashBookDetails?.cashBook?.success : true) &&
                <>
                    {params.cashBookId && <AddCashBook
                        initialValue={getValueForEdit(cashBookDetails?.cashBook?.data?.records)}
                        cashBookDispatch={updateCashBookDispatch}
                        title="Edit Cash Book"
                        firmName={firmName}
                    />}
                    {!params.cashBookId && <AddCashBook
                        initialValue={getInitialValue(Number(params.id))}
                        cashBookDispatch={addCahsBookDispatch}
                        title="Add Cash Book"
                        firmName={firmName}
                    />}
                </>
            }
        </ErrorBoundary>
    )
}

const mapStateToProps = (state: any) => {
    return {
        cashBookDetails: state.cashBook,
        firmName: state?.cashBookList?.cashBookList?.data?.records?.FirmName || ""
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getCashBookDetailsDispatch: (cashBookId: number) => dispatch(getCashBook(cashBookId)),
        addCahsBookDispatch: (data: addCashBookData, successCallback : Function) => dispatch(addCashBook(data, successCallback)),
        updateCashBookDispatch: (data: addCashBookData, successCallback : Function) => dispatch(updateCashBook(data, successCallback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBookForm)