import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, number, string, mixed } from "yup";
import { useParams } from "react-router-dom";

import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "../../../../../commonComponent/dropDown/DropDown";
import {
  getFormData,
  getImage,
} from "../../../../../../helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { uploadFileData } from "./TaskAttachmentsDetails";
import { uploadFile } from "app/reducers/task/taskAction";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  uploadFileDispatch: Function;
  setShowAddForm: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const validateFun = object().shape({
  TypeId: number().required("Document type is required"),
  startDate: string()
    .required("Start date is required")
    .typeError("Start date is required"),
  endDate: string()
    .required("End year is required")
    .typeError("End date is required"),
  File: mixed().required("File is required"),
});

const TaskAttachmentForm: FC<props> = ({
  masterDataList,
  uploadFileDispatch,
  setShowAddForm,
}) => {
  const params = useParams();

  const initailvalue: uploadFileData = {
    TypeId: "",
    File: undefined,
    startDate: null,
    endDate: null,
    TaskId: params.id,
  };

  return (
    <div className="card-body px-7 py-4">
      <Formik
        enableReinitialize
        initialValues={initailvalue}
        validationSchema={validateFun}
        onSubmit={async (values) => {
          await uploadFileDispatch(getFormData(values), () => {
            setShowAddForm(false);
          });
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => {
          return (
            <Form noValidate className="form w-100">
              <div className="card-body p-0">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                    Document Name
                  </label>

                  <div className="col-lg-8 fv-row">
                    <DropDown
                      className={`text-start form-control form-control-lg form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder="Select Document Type Name"
                      displayLoader={masterDataList.loading}
                      options={masterDataList.masterData?.data?.records?.DocumentTypes?.map(
                        (document: { Id: Number; Name: string }) => {
                          return {
                            value: Number(document.Id),
                            name: document.Name,
                          };
                        }
                      )}
                      setFieldValue={setFieldValue}
                      name="TypeId"
                    />
                    <ErrorMessage
                      name="TypeId"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                    Start Date
                  </label>

                  <div className="col-lg-8 fv-row">
                    <CustomDatePicker
                      name="startDate"
                      placeholder="Start Date"
                      setFieldValue={setFieldValue}
                      timePeriod={
                        masterDataList.masterData?.data?.records?.DocumentTypes?.find(
                          (type: { Id: number }) =>
                            type.Id === Number(values.TypeId)
                        )?.Repeatation
                      }
                      currentValue={
                        values.startDate ? new Date(values.startDate) : null
                      }
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                    End Date
                  </label>

                  <div className="col-lg-8 fv-row">
                    <CustomDatePicker
                      name="endDate"
                      placeholder="End Date"
                      setFieldValue={setFieldValue}
                      timePeriod={
                        masterDataList.masterData?.data?.records?.DocumentTypes?.find(
                          (type: { Id: number }) =>
                            type.Id === Number(values.TypeId)
                        )?.Repeatation
                      }
                      currentValue={
                        values.endDate ? new Date(values.endDate) : null
                      }
                    />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="errorMsg"
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label fw-semibold fs-6 required">
                    File
                  </div>

                  {!values.File && (
                    <label htmlFor="File" className="col-lg-8">
                      <div className="btn btn-light-primary btn-active-light-primary btn-sm">
                        <i className="bi bi-upload me-1"></i>
                        Upload File
                      </div>
                      <Field
                        type="file"
                        value={undefined}
                        className="d-none"
                        id="File"
                        name="File"
                        onChange={(e: any) =>
                          setFieldValue("File", e.target.files[0])
                        }
                        accept=".pdf"
                      />
                      <ErrorMessage
                        name="File"
                        component="div"
                        className="errorMsg"
                      />
                    </label>
                  )}
                  {values.File && (
                    <div className="col-lg-8 ms-3 upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="w-35px">
                          <ImageDisplay
                            path={getImage("pdf")}
                            errorPath="/media/task/doc-1.png"
                            className="img-fit-to-div"
                            altText="documentType"
                          />
                        </div>
                        <div className="fw-semibold">
                          <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                            {(values.File as File).name}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          className="btn p-0"
                          onClick={() => setFieldValue("File", "")}
                        >
                          <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end py-6 gap-3">
                <ResetButton
                  name="Cancel"
                  className="btn btn-light btn-active-light-primary"
                  onClickCallback={() => {
                    setShowAddForm(false);
                  }}
                />
                <SubmitButton
                  className="btn btn-primary"
                  isSubmitting={isSubmitting}
                  name="Submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadFileDispatch: (data: uploadFileData, successCallback: Function) =>
      dispatch(uploadFile(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAttachmentForm);
