import { GET_FIRM_FAILURE, GET_FIRM_REQUEST, GET_FIRM_SUCCESS } from "../actionTypes"

export interface firmDataState {
    loading  : boolean,
    firm : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:firmDataState = {
    loading  : false,
    firm : {},
    error : ""
}

export const getFirmByIdReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_FIRM_REQUEST : return {
            loading : true
        }
        case GET_FIRM_SUCCESS : 
        return {
            loading : false,
            firm : action.payload,
            error : ""
        }
        case GET_FIRM_FAILURE : return {
            loading : false,
            firm : {},
            error : action.payload
        }
        default : return state
    }
}