import { KTSVG } from "_metronic/helpers";
import Avatar from "app/components/commonComponent/Avatar";
import { ACTIVITY_STATUS } from "app/helpers/constants";
import { convert, getColor } from "app/helpers/helperFunction";
import { absolutePath } from "app/helpers/relativePath";
import { EDIT_ACTIVITY } from "app/helpers/routes";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface props {
  row: {
    Id: number;
    IsActive: boolean;
    Name: string;
    AssigneeId: string;
    PersonAssigned: string;
    CreatorId: string;
    CreatedBy: string;
    Description: string;
    Remark: string;
    CreatedDate: string;
    Status: number;
  };
}

const ActivityTableRow: FC<props> = ({ row }) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_ACTIVITY}/${row.Id}`));
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row?.Name || "-"}</td>
      <td>
        <div className="overflow-auto mh-75px mw-375px vertical-scroll">
          {row?.Description || "-"}
        </div>
      </td>
      <td>
        <div className="overflow-auto mh-75px mw-375px vertical-scroll">
          {row?.Remark || "-"}
        </div>
      </td>
      <td>
        {row.PersonAssigned ? (
          <div className="d-flex">
            <div className="min-w-150px align-items-center bg-light border d-flex fs-7 fw-semibold py-1 px-2 rounded-pill text-gray-600 min-w-125px">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.PersonAssigned}
                  lastName={""}
                  size={20}
                  color={getColor(row.PersonAssigned).color}
                  background={getColor(row.PersonAssigned).background}
                  length={1}
                  rounded={true}
                />
              </div>
              {row.PersonAssigned}
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>
        {row.CreatedBy ? (
          <div className="d-flex">
            <div className="min-w-150px d-flex align-items-center bg-light border fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.CreatedBy}
                  lastName={""}
                  size={20}
                  color={getColor(row.CreatedBy).color}
                  background={getColor(row.CreatedBy).background}
                  length={1}
                  rounded={true}
                />
              </div>
              <span className="w-auto">{row.CreatedBy}</span>
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>{row?.CreatedDate ? convert(row.CreatedDate) : "-"}</td>
      <td>
        <span
          className={`badge badge-sm badge-light-${
            ACTIVITY_STATUS[row.Status] === "Assigned" ? "primary" : "success"
          }`}
        >
          {ACTIVITY_STATUS[row.Status]}
        </span>
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ActivityTableRow;
