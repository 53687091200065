import { GET_MORNING_PLANNER_BY_ID_REQUEST, GET_MORNING_PLANNER_BY_ID_SUCCESS, GET_MORNING_PLANNER_BY_ID_FAILURE, ADD_MORNING_PLANNER_REQUEST, ADD_MORNING_PLANNER_SUCCESS, ADD_MORNING_PLANNER_FAILURE } from "../actionTypes"

interface addMorningPlannerState {
    loading  : boolean,
    addMorningPlanner : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:addMorningPlannerState = {
    loading  : false,
    addMorningPlanner: {},
    error : ""
}

export const addMorningPlannerReducer = (state = initialState, action : action) => {
    switch(action.type){
        case ADD_MORNING_PLANNER_REQUEST : return {
            ...state,
            loading : true
        }
        case ADD_MORNING_PLANNER_SUCCESS : return {
            ...state,
            loading : false,
            addMorningPlanner : 'Task added successfully',
            error : ""
        }
        case ADD_MORNING_PLANNER_FAILURE : return {
            ...state,
            loading : false,
            addMorningPlanner : 'Task not added',
            error : action.payload
        }
        default : return state
    }
}