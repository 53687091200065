import { addCategoryValue } from "../../components/pages/categoryWork/CategoryForm";
import { axiosInstance } from "../axiosInstance";
import {
  GET_ALL_CATEGORY_WORK_API_ENDPOINT,
  CATEGORY_WORK_API_ENDPOINT,
} from "../../helpers/config";
import { getSearchQueryString } from "../../helpers/helperFunction";
import { searchParams } from "../../helpers/commonInterface";

export const addCategoryService = async (data: addCategoryValue) => {
  return await axiosInstance.post(CATEGORY_WORK_API_ENDPOINT, data);
};

export const getCategoryListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(
    `${GET_ALL_CATEGORY_WORK_API_ENDPOINT}?${queryString}`
  );
};

export const getCategoryService = (categoryId: number) => {
  return axiosInstance.get(`${CATEGORY_WORK_API_ENDPOINT}?id=${categoryId}`);
};

export const updateCategoryService = async (data: any) => {
  return await axiosInstance.put(CATEGORY_WORK_API_ENDPOINT, data);
};

export const updateCategoryStatusService = (categoryId: number) => {
  return axiosInstance.delete(`${CATEGORY_WORK_API_ENDPOINT}?id=${categoryId}`);
};
