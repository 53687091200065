import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1d1d1d',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
    },
    // Add more customization if needed
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#90caf9',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#90caf9',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d'
        },
      },
    },
    // Add other component customizations if needed
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
    // Add more customization if needed
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: 'black',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'black',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#1976d2',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#1976d2',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    // Add other component customizations if needed
  },
});
