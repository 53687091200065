import { FC, useState } from "react";
import { connect } from "react-redux";
import Avatar from "app/components/commonComponent/Avatar";
import { convert, getColor, getImage } from "app/helpers/helperFunction";
import { deleteRemarks, downloadFile } from "app/reducers/task/taskAction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { useParams } from "react-router-dom";
import { taskRemarks } from "app/reducers/task/taskDataInterface";

interface props {
  remarksArr: Array<taskRemarks>;
  downloadFileDispatch: Function;
  deleteRemarkDispatch: Function;
  getInitialValue: Function;
  setInitialValue: Function;
  setShowForm: Function;
}

const TaskRemarksList: FC<props> = ({
  getInitialValue,
  setInitialValue,
  setShowForm,
  remarksArr,
  downloadFileDispatch,
  deleteRemarkDispatch,
}) => {
  const params = useParams();
  const [downloadLoading, setDownloadLoading] = useState({
    loading: false,
    id: 0,
  });
  const [deleteLoading, setDeleteLoading] = useState({ loading: false, id: 0 });

  return (
    <div className="card-body px-7 py-4">
      <div className="mh-300px overflow-auto vertical-scroll">
        {!remarksArr.length ? (
          <div className="fs-5 fw-semibold">No records found</div>
        ) : (
          remarksArr.map((remark: taskRemarks, index: number) => {
            return (
              <div
                key={index}
                className="d-flex align-items-start justify-content-between mb-6 gap-4"
              >
                <div className="d-flex align-items-start">
                  <div className="symbol symbol-circle symbol-40px overflow-hidden me-4">
                    <div className="w-30px h-30px" title="">
                      <Avatar
                        firstName={remark.AddedBy}
                        lastName={""}
                        size={30}
                        color={getColor(remark.AddedBy).color}
                        background={getColor(remark.AddedBy).background}
                        length={1}
                        title={remark.AddedBy}
                      />
                    </div>
                  </div>
                  <div className="fw-semibold">
                    {remark.Remark && (
                      <div className="fs-6 fw-semibold text-dark long-break-word mb-3">
                        {remark.Remark}
                      </div>
                    )}
                    {remark.UploadedFile && (
                      <div className="upload-file-display border border-gray-300 d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 text-gray-600">
                        <div className="d-flex gap-3 align-items-center">
                          <div className="w-35px">
                            <ImageDisplay
                              path={getImage(remark.UploadedFile.MimeType)}
                              errorPath="/media/task/doc-1.png"
                              className="img-fit-to-div"
                              altText="documentType"
                            />
                          </div>
                          <div className="fw-semibold">
                            <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                              {remark.UploadedFile.FileName}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="btn btn-sm btn-icon btn-active-color-primary"
                            onClick={() => {
                              setDownloadLoading({
                                loading: true,
                                id: remark.Id,
                              });
                              downloadFileDispatch(
                                { id: remark.Id, destination: "remark" },
                                `${remark.UploadedFile?.FileName || "myFile"}`,
                                () => {
                                  setDownloadLoading({ loading: false, id: 0 });
                                }
                              );
                            }}
                          >
                            {/* <i className="fa-solid fa-arrow-down-long"></i> */}
                            {downloadLoading.loading &&
                            downloadLoading.id === remark.Id ? (
                              <div className=" w-20px text-gray-600">
                                <SmallLoader />
                              </div>
                            ) : (
                              <i className="fa-solid fa-download"></i>
                              // <ImageDisplay
                              //   path="media/task/download-arrow.png"
                              //   altText="download-arrow"
                              //   className="w-20px"
                              //   errorPath="/media/task/download-arrow.png"
                              // />
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-center mt-1 fs-6 mb-2">
                      <div className="text-muted me-2 fs-7">
                        {convert(remark.Date)}
                      </div>
                      <div className="text-primary fs-7">{remark?.AddedBy}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 pe-3">
                  <button
                    className="btn btn-active-color-primary p-0"
                    onClick={() => {
                      setShowForm({ form: true, edit: true });
                      setInitialValue({
                        id: remark.Id,
                        ...getInitialValue(params.id, remark),
                      });
                    }}
                  >
                    <i className="fa-solid fa-pencil p-0"></i>
                  </button>
                  <button
                    className="btn btn-active-color-primary text-gray-600 p-0 ps-2"
                    onClick={() => {
                      setDeleteLoading({ loading: true, id: remark.Id });
                      deleteRemarkDispatch(remark.Id, () => {
                        setDeleteLoading({ loading: false, id: 0 });
                      });
                    }}
                  >
                    {
                      deleteLoading.loading &&
                      deleteLoading.id === remark.Id ? (
                        <SmallLoader />
                      ) : (
                        <i className="fa-solid fa-trash p-0"></i>
                      )
                      // <SmallLoader />
                    }
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteRemarkDispatch: (id: number, successCallback: Function) =>
      dispatch(deleteRemarks(id, successCallback)),
    downloadFileDispatch: (
      downloadDataObj: { id: number; destination: string },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TaskRemarksList);
