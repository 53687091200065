import { connect } from "react-redux";
import { FC, useEffect } from "react";

import Table from "app/components/commonComponent/table/Table";
import { userListState } from "../../../../reducers/user/userListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import MPCustomRow from "./MPCustomRow";
import AMPCustomRow from "./AMPCustomRow";

const HEADERS = [
  
  {
    columnName: "Task Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "StartTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "EndTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Duration",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Assignee",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Status Name",
    id: "NULL",
    className: "min-w-125px",
  }
];

interface props {
  usersMorningPlannerList:any
}

const AllMorningPlannerTable: FC<props> = ({usersMorningPlannerList}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
  }, [searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={usersMorningPlannerList.error}
        headers={HEADERS}
        success={usersMorningPlannerList.usersMorningPlannerList?.success}
        loading={usersMorningPlannerList.loading}
        paginate={{
          show: true,
          pagination: usersMorningPlannerList.usersMorningPlannerList?.pagination,
          PageNumber: Number(searchObj.PageNumber) || 1,
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={usersMorningPlannerList.usersMorningPlannerList?.data?.records}
        tableRow={(row: any, index: number) => (
          <AMPCustomRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    usersMorningPlannerList: state.usersMorningPlannerList,
  };
};


export default connect(mapStateToProps, null)(AllMorningPlannerTable);
