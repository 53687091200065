import { connect } from "react-redux";
import { FC, useState } from "react";

import { downloadFile } from "app/reducers/task/taskAction";
import { getImage } from "app/helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { taskData } from "app/reducers/task/taskDataInterface";

interface props {
  taskDetails: taskData;
  downloadFileDispatch: Function;
}

const TaskReviewMessage: FC<props> = ({
  taskDetails,
  downloadFileDispatch,
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  return (
    <>
      {taskDetails.ReviewObj &&
        taskDetails.ReviewObj.Review &&
        ("Message" in taskDetails.ReviewObj.Review ||
          "FileName" in taskDetails.ReviewObj.Review) && (
          <div className="col-12">
            <div className="card">
              <div className="card-header border-0 align-items-center">
                <div className="card-title fw-bold">Review Message</div>
              </div>
              <div className="card-body px-7 pb-4 pt-0 verflow-auto vertical-scroll text-style-justify">
              
                {"Message" in taskDetails.ReviewObj.Review && (
                  <div>{taskDetails.ReviewObj.Review.Message}</div>
                )}
                {"FileName" in taskDetails.ReviewObj.Review && (
                  <div className="mt-4 upload-file-display border border-gray-300 d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 text-gray-600">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="w-35px">
                        <ImageDisplay
                          path={getImage(taskDetails.ReviewObj.Review.MimeType)}
                          errorPath="/media/task/doc-1.png"
                          className="img-fit-to-div"
                          altText="documentType"
                        />
                      </div>
                      <div className="fw-semibold">
                        <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                          {taskDetails.ReviewObj.Review.FileName}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        onClick={() => {
                          setDownloadLoading(true);
                          downloadFileDispatch(
                            {
                              id: taskDetails.ReviewObj?.Id || 0,
                              destination: "review",
                            },
                            `${
                              taskDetails.ReviewObj?.Review?.FileName ||
                              "myFile"
                            }`,
                            () => {
                              setDownloadLoading(false);
                            }
                          );
                        }}
                      >
                        {downloadLoading ? (
                          <div className="w-20px text-gray-600">
                            <SmallLoader />
                          </div>
                        ) : (
                          <i className="fa-solid fa-download"></i>
                          // <ImageDisplay
                          //   path="media/task/download-arrow.png"
                          //   altText="download-arrow"
                          //   className="w-20px"
                          //   errorPath="/media/task/download-arrow.png"
                          // />
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number; destination: string },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TaskReviewMessage);
