import { GET_DAILY_TASK_LIST_FAILURE, GET_DAILY_TASK_LIST_REQUEST, GET_DAILY_TASK_LIST_SUCCESS } from "../actionTypes"

interface dailyTaskListState {
    loading  : boolean,
    dailyTaskList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:dailyTaskListState = {
    loading  : false,
    dailyTaskList : {},
    error : ""
}

export const dailyTaskListReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_DAILY_TASK_LIST_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_DAILY_TASK_LIST_SUCCESS : return {
            ...state,
            loading : false,
            dailyTaskList : action.payload,
            error : ""
        }
        case GET_DAILY_TASK_LIST_FAILURE : return {
            ...state,
            loading : false,
            dailyTaskList : {},
            error : action.payload
        }
        default : return state
    }
}