import { connect } from "react-redux";
import { FC, useState } from "react";

import { convert, getImage } from "../../../../../../helpers/helperFunction";
import { downloadFile } from "app/reducers/task/taskAction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { taskChanges } from "app/reducers/task/taskDataInterface";
interface props {
  logDetails: taskChanges[];
  downloadFileDispatch: Function;
}

const TaskLogDetails: FC<props> = ({ logDetails, downloadFileDispatch }) => {
  const [downloadLoading, setDownloadLoading] = useState({
    loading: false,
    id: 0,
  });

  return (
    <ErrorBoundary>
      <div className="log-container card card-flush col-12">
        <div className="">
          <div className="card-header pt-5 border-0">
            <div className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Log Details</span>
            </div>
          </div>
          <div className="card-body px-7 py-4">
            {!logDetails.length && (
              <div className="fs-5 fw-semibold">No any activities yet</div>
            )}
            {logDetails.length > 0 && (
              <div className="mh-600px overflow-auto vertical-scroll">
                <div className="timeline">
                  {logDetails.map((log: taskChanges, index: number) => {
                    if (log) {
                      return (
                        <div key={index} className="timeline-item">
                          <div className="timeline-line w-15px mt-6 mb-n12"></div>
                          <div className="align-items-center border border-2 border-success d-flex h-15px justify-content-center rounded-circle timeline-icon w-15px bg-body">
                            <div className="h-5px path1 rounded-circle w-5px"></div>
                          </div>
                          <div className="timeline-content mb-10 mt-n1">
                            <div className="pe-3 mb-5">
                              <div className="fs-5 fw-semibold mb-2">
                                {log?.Message}
                              </div>
                              <div className="d-flex align-items-center mt-1 fs-6 mb-2">
                                <div className="text-muted me-2 fs-7">
                                  {log?.Date ? convert(log?.Date) : "-"} &nbsp;
                                  {/* {getFormatedTime(log?.Date)} */}
                                </div>
                                <div className="text-primary fs-7">
                                  {log?.ChangedBy}
                                </div>
                              </div>
                              {(log?.Review?.Message ||
                                log?.Review?.FileName) && (
                                <div className="width-fit-content border border-dashed border-gray-300 fs-6 fw-semibold mt-1 mb-2 p-3 rounded-2">
                                  {log?.Review?.Message}
                                  {"FileName" in log?.Review && (
                                    <div className="mt-3 upload-file-display border border-gray-300 d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 text-gray-600">
                                      <div className="d-flex gap-3 align-items-center">
                                        <div className="w-35px">
                                          <ImageDisplay
                                            path={getImage(
                                              log?.Review.MimeType
                                            )}
                                            errorPath="/media/task/doc-1.png"
                                            className="img-fit-to-div"
                                            altText="documentType"
                                          />
                                        </div>
                                        <div className="fw-semibold">
                                          <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                                            {log?.Review?.FileName}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <button
                                          className="btn btn-sm btn-icon btn-active-color-primary"
                                          onClick={() => {
                                            setDownloadLoading({
                                              loading: true,
                                              id: log.Id,
                                            });
                                            downloadFileDispatch(
                                              {
                                                id: log.Id,
                                                destination: "review",
                                              },
                                              `${log?.Review?.FileName}`,
                                              () => {
                                                setDownloadLoading({
                                                  loading: false,
                                                  id: log.Id,
                                                });
                                              }
                                            );
                                          }}
                                        >
                                          {downloadLoading.loading &&
                                          downloadLoading.id === log.Id ? (
                                            <div className="w-20px text-gray-600">
                                              <SmallLoader />
                                            </div>
                                          ) : (
                                            <i className="fa-solid fa-download"></i>
                                            // <ImageDisplay
                                            //   path="media/task/download-arrow.png"
                                            //   altText="download-arrow"
                                            //   className="w-20px"
                                            //   errorPath="/media/task/download-arrow.png"
                                            // />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number; destination: string },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TaskLogDetails);
