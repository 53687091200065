import { PageLink, PageTitle } from "_metronic/layout/core";
import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import { searchParams } from "app/helpers/commonInterface";
import { getClientList } from "app/reducers/client/clientAction";
import { connect } from "react-redux";
import Table from "app/components/commonComponent/table/Table";
import { FC, useEffect } from "react";
import { useGetSearchObject } from "app/helpers/customHooks";
import ClientReportTableRow from "./component/ClientReportTableRow";
import { CLIENT_REPORT_LIST } from "app/helpers/routes";
import { clientListState } from "app/reducers/client/getClientListReducer";
import ClientFilter from "./component/ClientFilter";

interface props {
  getClientList: Function;
  clientDetails: clientListState;
}

export const clientReportListPage = {
  title: "Client Report",
  path: absolutePath(CLIENT_REPORT_LIST),
  isSeparator: true,
  isActive: true,
};

const HEADERS = [
  {
    columnName: "Client Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Group Name",
    id: "GroupName",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Contacts",
    id: "NULL",
    className: "min-w-125px",
  },
];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ClientReportList: FC<props> = ({ getClientList, clientDetails }) => {
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getClientList(searchObj);
  }, [getClientList, searchObj]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Client Report</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
        <UsersListSearchComponent />
        <ClientFilter />
      </div>
      <KTCard>
        <Table
          checkBoxColumn={{ show: false }}
          error={clientDetails.error}
          headers={HEADERS}
          success={clientDetails.clientList?.success}
          loading={clientDetails.loading}
          paginate={{
            show: true,
            pagination: clientDetails.clientList?.pagination,
            PageNumber: Number(searchObj.PageNumber || 1),
            PageSize: Number(searchObj.PageSize) || 10,
          }}
          tableData={clientDetails.clientList?.data?.records}
          tableRow={(row: any, index: number) => (
            <ClientReportTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    clientDetails: state.clientList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientList: (searchObj: searchParams) =>
      dispatch(getClientList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientReportList);
