import { response } from "app/helpers/commonInterface";
import {
  GET_TASK_LIST_FAILURE,
  GET_TASK_LIST_REQUEST,
  GET_TASK_LIST_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface taskListState {
  loading: boolean;
  taskList: response<taskData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: taskListState = {
  loading: false,
  taskList: {},
  error: "",
};

export const taskListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_TASK_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_LIST_SUCCESS:
      return {
        loading: false,
        taskList: action.payload,
        error: "",
      };
    case GET_TASK_LIST_FAILURE:
      return {
        loading: false,
        taskList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
