import { connect } from "react-redux";
import { FC, useEffect } from "react";

import { addFileObject } from "app/helpers/commonInterface";
import {
  addVariableFunction,
  getNameFromValue,
  onChangeBodyDataHandler,
} from "./TemplateDetailForm";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import {
  ErrorMessage,
  Field,
  FormikErrors,
  FormikHelpers,
  FormikState,
  FormikTouched,
  FormikValues,
  useFormikContext,
} from "formik";
import { generalFileUpload } from "app/reducers/task/taskAction";
import { getFormData, getImage } from "app/helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";

const HEADER_OPTION = [
  { value: "none", name: "None" },
  { value: "text", name: "Text" },
  { value: "media", name: "Media" },
];

interface props {
  setPdfFile: Function;
  generalFileUploadDispatch: Function;
  pdfFile: { file: File; details: addFileObject } | null;
}

const TemplateHeader: FC<props> = ({
  setPdfFile,
  generalFileUploadDispatch,
  pdfFile,
}) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    setValues,
  }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
    useFormikContext();

  const onChangeHeaderType = (e: any) => {
    setValues({
      ...values,
      header: {
        ...values.header,
        format: e.target.dataset.id,
        text: "",
        example: [],
        mediaFormat: "",
      },
    });
  };

  useEffect(() => {
    if (pdfFile) {
      generalFileUploadDispatch(
        getFormData({ file: pdfFile.file }),
        (data: any) => {
          setPdfFile({ ...pdfFile, details: data });
          setFieldValue("header.example[0]", data.FileUrl);
        }
      );
    }
  }, [pdfFile?.file]);

  return (
    <div>
      <label className="col-form-label fw-semibold fs-5 required">Header</label>

      <div className="">
        <div className="col-6 col-md-3 col-sm-3">
          <DropDown
            className="text-start form-control form-control-lg form-control-solid form-select mb-6"
            placeholder="Select Type"
            options={HEADER_OPTION}
            name="header.format"
            currentValue={{ value: values.header.format }}
            // setFieldValue={setFieldValue}
            defaultValue={{
              value: values.header.format,
              name: getNameFromValue(HEADER_OPTION, values.header.format),
            }}
            apiCallDispatch={onChangeHeaderType}
          />
          <ErrorMessage
            name="header.format"
            component="div"
            className="errorMsg"
          />
        </div>
        <div className="col-12 col-xl-9">
          {values.header.format === "text" && (
            <div>
              <Field
                type="text"
                className={`form-control form-control-lg form-control-solid
                            ${
                              (touched.header as FormikTouched<FormikValues>)
                                ?.text &&
                              (errors.header as FormikErrors<FormikValues>)
                                ?.text &&
                              "is-invalid inValidBorder"
                            }`}
                placeholder="Header"
                id="header.text"
                name="header.text"
                value={values.header.text}
                onChange={(e: any) => {
                  // setValues({ ...values, header: { ...values.header, mediaFormat: "" } })
                  onChangeBodyDataHandler(
                    e.target.value,
                    "header",
                    values,
                    setFieldValue
                  );
                }}
              />
              <div className="d-flex justify-content-between flex-column flex-sm-row mb-2 w-100">
                <ErrorMessage
                  name="header.text"
                  component="div"
                  className="errorMsg"
                />
                <div>
                  <button
                    type="button"
                    className={`btn p-0`}
                    disabled={values.header.example?.length === 1}
                    onClick={() =>
                      addVariableFunction(
                        "header",
                        "text",
                        "example",
                        values,
                        setFieldValue
                      )
                    }
                  >
                    <i className="fa-solid fa-plus"></i> Add Variable
                  </button>
                </div>
              </div>
              {values.header.example?.length ? (
                <div>
                  {values.header.example.map(
                    (example: string, index: number) => {
                      return (
                        <div
                          className="d-flex align-items-start gap-4 col-10"
                          key={index}
                        >
                          <label className="col-form-label fw-semibold fs-6 required">{`{{${
                            index + 1
                          }}}`}</label>

                          <div className="w-100">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid`}
                              placeholder="Header Example"
                              id={`header.example[${index}]`}
                              name={`header.example[${index}]`}
                            />
                            <ErrorMessage
                              name={`header.example[${index}]`}
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          {values.header.format === "media" && (
            <div className="row align-items-center gap-3">
              <div className="d-flex gap-5 align-items-center">
                <label
                  className="d-flex align-items-center gap-3"
                  htmlFor="header-image"
                >
                  <div className="form-check form-check-custom form-check-solid">
                    <Field
                      className="form-check-input"
                      type="radio"
                      id="header-image"
                      name="header.mediaFormat"
                      value="image"
                      onChange={() => {
                        setValues({
                          ...values,
                          header: {
                            ...values.header,
                            example: [""],
                            mediaFormat: "image",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="text-dark fw-semibold d-block fs-6">
                    Image
                  </div>
                </label>
                <label
                  className="d-flex align-items-center gap-3"
                  htmlFor="header-document"
                >
                  <div className="form-check form-check-custom form-check-solid">
                    <Field
                      className="form-check-input"
                      type="radio"
                      id="header-document"
                      name="header.mediaFormat"
                      value="document"
                      onChange={() => {
                        setValues({
                          ...values,
                          header: {
                            ...values.header,
                            example: [""],
                            mediaFormat: "document",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="text-dark d-block fw-semibold fs-6">
                    Document
                  </div>
                </label>
              </div>
              <ErrorMessage
                name="header.mediaFormat"
                component="div"
                className="errorMsg"
              />
              <div className="row">
                <div className="col-lg-8">
                  {!pdfFile?.details && values.header.mediaFormat && (
                    <label
                      htmlFor={`header.example[${0}]`}
                      className="col-lg-9"
                    >
                      <div className="btn btn-light-primary btn-active-light-primary btn-sm">
                        <i className="bi bi-upload me-1"></i>
                        Upload File
                      </div>
                      <Field
                        type="file"
                        name={`header.example[${0}]`}
                        id={`header.example[${0}]`}
                        value={undefined}
                        className="d-none"
                        onChange={(e: any) => {
                          setPdfFile({ file: e.target.files[0] });
                          // setFieldValue("header.file", e.target.files[0])
                          // setValues({ ...values, header: { ...values.header, text: "", example: [], file: e.target.files[0] } })
                          // generalFileUploadDispatch(getFormData({ file: e.target.files[0] }), (data: any) => {
                          //     setPdfFile({ ...pdfFile, details: data })
                          //     setFieldValue("header.example[0]", data.FileUrl)
                          // })
                        }}
                        accept={
                          values.header.mediaFormat === "document"
                            ? ".pdf"
                            : ".jpg, .jpeg, .png"
                        }
                      />
                      <ErrorMessage
                        name={`header.example[${0}]`}
                        component="div"
                        className="errorMsg"
                      />
                    </label>
                  )}
                  {pdfFile?.details && values.header.mediaFormat && (
                    <div className="col-lg-9  upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="w-35px">
                          <ImageDisplay
                            path={getImage(pdfFile.details.MimeType)}
                            errorPath="/media/task/doc-1.png"
                            className="img-fit-to-div"
                            altText="documentType"
                          />
                        </div>
                        <div className="fw-semibold">
                          <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                            {pdfFile.details.FileName}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          className="btn p-0"
                          onClick={() => {
                            setPdfFile(null);
                            setFieldValue("header.example", []);
                          }}
                        >
                          <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {/* <ErrorMessage name="header.file" component="div" className="errorMsg" /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    generalFileUploadDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TemplateHeader);

{
  /* <Field
                                    type="file"
                                    name={`header.example[${0}]`}
                                    id={`header.example[${0}]`}
                                    value={undefined}
                                    onChange={(e: any) => {
                                        setPdfFile(e.target.files[0])
                                        // setFieldValue("header.file", e.target.files[0])
                                        // setValues({ ...values, header: { ...values.header, text: "", example: [], file: e.target.files[0] } })
                                        generalFileUploadDispatch(getFormData({ file: e.target.files[0] }), (data: any) => {
                                            setPdfFile({...pdfFile, details : data})
                                            setFieldValue("header.example[0]", data.FileUrl)
                                        })
                                    }}
                                    accept={values.header.mediaFormat === "document" ? ".pdf" : ".jpg, .jpeg, .png"}
                                /> */
}
