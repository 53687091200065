import { PageLink, PageTitle } from "_metronic/layout/core";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import { absolutePath } from "app/helpers/relativePath";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar";
import { KTCard } from "_metronic/helpers";
import ActivityTable from "./component/ActivityTable";
import { ACTIVITY_LIST, ADD_ACTIVITY } from "app/helpers/routes";
import ActivityFilter from "./component/ActivityFilter";

export const activityListPage = {
  title: "Activities",
  path: absolutePath(ACTIVITY_LIST),
  isSeparator: true,
  isActive: true,
};

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const ActivityList = () => {
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Activities</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
        <UsersListSearchComponent />
        <ActivityFilter />
        <UsersListToolbar
          buttons={[{ title: "Add Activity", to: ADD_ACTIVITY }]}
        />
      </div>
      <KTCard>
        <ActivityTable />
      </KTCard>
    </>
  );
};

export default ActivityList;
