import { connect } from "react-redux"
import { FC, useEffect } from "react"

import { adminHomePage } from "../main/admin/AdminMain"
import { clientListPage } from "../client/ClientList"
import DocumentGrouping from "./component/DocumentGrouping"
import DocumentTable from "./component/DocumentTable"
import DocumentFilter from "./component/DocumentFilter"
import { emptyObject } from "app/helpers/helperFunction"
import { KTCard } from "_metronic/helpers"
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core"
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"
import { getClientById } from "app/reducers/client/clientAction"
import { useParams } from "react-router-dom"

const accountBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    clientListPage,
    emptyObject
]

export interface selctedDocumentList {
    documentListData: any
    isCheckedAll: boolean
    selected: Array<number>
}
interface props {
    selectedDocumentDetails: selctedDocumentList,
    clientDetail: any,
    getClientData: Function
}



const DocumentsList: FC<props> = ({ selectedDocumentDetails, clientDetail, getClientData }) => {
    const params = useParams();

    useEffect(() => {
        if (params.id) {
          getClientData(params.id);
        }
      }, [getClientData, params.id]);
    
    return (
        <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents </PageTitle>
            <PageSubTitle>{clientDetail?.client?.data?.records?.Name|| ""}</PageSubTitle>

            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative'>
                {selectedDocumentDetails.selected.length > 0 ?
                    <>
                        <DocumentGrouping />
                    </>
                    :
                    <>
                        <UsersListSearchComponent />
                        <DocumentFilter />
                    </>
                }
            </div>
            <KTCard>
                <DocumentTable />
            </KTCard>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        selectedDocumentDetails: state.selectedDocumentList,
        clientDetail: state.client
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
      getClientData: (clientId: number) => dispatch(getClientById(clientId)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList)