import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddDailyTask from "./component/AddDailyTask";
import {
  addDailyTask,
  getDailyTask,
  updateDailyTask,
} from "../../../reducers/dailyTask/dailyTaskAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  dailyTaskDetail: any;
  getDailyTaskData: Function;
  updateDailyTaskDispatch: Function;
  addDailyTaskDispatch: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}
export interface dailyTaskDataObject {
  id?: number;
  description: string;
  date?: Date;
  isCompleted?: boolean;
  assignee?: string;
  assigneeId?: string;
}

export interface newDailyDataObject {
  description: "";
}
export interface dailyTaskData {
  assigneeId: string;
  tasks: Array<dailyTaskDataObject>;
}

interface editDailyTaskObject {
  Id: number;
  Description: string;
  Date: Date;
  IsCompleted: boolean;
  Assignee: string;
  AssigneeId: string;
}
export interface editDailyTaskData {
  AssigneeId: string;
  Assignee: string;
  Tasks: Array<editDailyTaskObject>;
}

const newDailyTaskObject: newDailyDataObject = {
  description: "",
};

const initailvalue: dailyTaskData = {
  assigneeId: "",
  tasks: [
    {
      description: "",
    },
  ],
};

const getValueForEdit = (taskData: editDailyTaskData): dailyTaskData => {
  return {
    assigneeId: taskData?.AssigneeId || "",
    tasks:
      taskData?.Tasks?.map((task: editDailyTaskObject) => {
        return {
          description: task.Description,
          id: task.Id,
          date: task.Date,
          isCompleted: task.IsCompleted,
          assignee: task.Assignee,
          assigneeId: task.AssigneeId,
        };
      }) || [],
  };
};

const DailyTaskForm: FC<props> = ({
  masterDataList,
  dailyTaskDetail,
  getDailyTaskData,
  updateDailyTaskDispatch,
  addDailyTaskDispatch,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getDailyTaskData(params.id);
    }
  }, [getDailyTaskData, params]);

  return (
    <ErrorBoundary>
      {params.id && dailyTaskDetail.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && dailyTaskDetail.error && (
        <ErrorDisplay error={dailyTaskDetail.error} />
      )}
      {(params.id
        ? !dailyTaskDetail.loading && dailyTaskDetail.dailyTask?.success
        : true) && (
        <>
          {params.id && (
            <AddDailyTask
              initialValue={getValueForEdit(
                dailyTaskDetail.dailyTask?.data?.records[0]
              )}
              title="Edit Daily Task"
              newDailyTaskObject={newDailyTaskObject}
              dailyTaskDataDispatch={updateDailyTaskDispatch}
              dailyTaskDetail={dailyTaskDetail.dailyTask?.data?.records[0]}
              masterDataList={masterDataList}
            />
          )}
          {!params.id && (
            <AddDailyTask
              initialValue={initailvalue}
              title="Add Daily Task"
              newDailyTaskObject={newDailyTaskObject}
              dailyTaskDataDispatch={addDailyTaskDispatch}
              masterDataList={masterDataList}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};
const mapStateToProps = (state: any) => {
  return {
    dailyTaskDetail: state.dailyTask,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDailyTaskData: (userNumber: number) =>
      dispatch(getDailyTask(userNumber)),
    addDailyTaskDispatch: (data: dailyTaskData, successCallback: Function) =>
      dispatch(addDailyTask(data, successCallback)),
    updateDailyTaskDispatch: (data: dailyTaskData, successCallback: Function) =>
      dispatch(updateDailyTask(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTaskForm);
