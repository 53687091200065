import {
  DELETE_DOCUMENT_TYPE_SUCCESS,
  GET_DOCUMENT_TYPE_LIST_FAILURE,
  GET_DOCUMENT_TYPE_LIST_REQUEST,
  GET_DOCUMENT_TYPE_LIST_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface documentTypeListState {
  loading: boolean;
  documentTypeList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: documentTypeListState = {
  loading: false,
  documentTypeList: {},
  error: "",
};

export const documentTypeListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_DOCUMENT_TYPE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_DOCUMENT_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        documentTypeList: action.payload,
        error: "",
      };
    case GET_DOCUMENT_TYPE_LIST_FAILURE:
      return {
        loading: false,
        documentTypeList: {},
        error: action.payload,
      };
    case DELETE_DOCUMENT_TYPE_SUCCESS:
      return updateStatusFun(
        state,
        "documentTypeList",
        action.payload.data.records.Id
      );
    default:
      return state;
  }
};
