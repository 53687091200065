import DOMPurify from "dompurify";
import { FC } from "react"
import { FormikState, FormikValues, useFormikContext } from "formik"

import { getReplacedStr, selectedTemplateComponent } from "../ClientMessagePopup"

interface props {
    component: selectedTemplateComponent
}

const WPTemplateBody: FC<props> = ({ component }) => {
    const { errors, touched }: FormikState<FormikValues> = useFormikContext()

    return (
        <div className='body mb-3 whatsapp-form-fill-up'>
            <div
                dangerouslySetInnerHTML={
                    {
                        __html:
                            DOMPurify.sanitize(getReplacedStr(component.text, "w-auto body-text form-text", errors, touched))
                    }}>
            </div>
        </div>
    )
}

export default WPTemplateBody