import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddCategory from "./component/AddCategory";
import {
  addCategory,
  getCategory,
  updateCategory,
} from "../../../reducers/category/categoryAction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import Loader from "../../commonComponent/loader/Loader";
import { useAuth } from "../auth";

export interface addCategoryValue {
  id?: number;
  name: string;
  companyId: number | undefined;
}

interface categoryData {
  Id: number;
  Name: string;
  CompanyId: number;
}

interface props {
  addCategoryDispatch: Function;
  updateCategoryDispatch: Function;
  getCategoryData: Function;
  categoryDetails: any;
}
const getInitialValue = (companyId: number | undefined): addCategoryValue => {
  return {
    name: "",
    companyId: companyId,
  };
};
const getValueForEdit = (categoryData: categoryData): addCategoryValue => {
  return {
    id: categoryData.Id,
    name: categoryData.Name,
    companyId: categoryData.CompanyId,
  };
};

const CategoryForm: FC<props> = ({
  addCategoryDispatch,
  categoryDetails,
  updateCategoryDispatch,
  getCategoryData,
}) => {
  const params = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (params.id) {
      getCategoryData(params.id);
    }
  }, [params.id, getCategoryData]);

  return (
    <ErrorBoundary>
      {params.id && categoryDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && categoryDetails.error && (
        <ErrorDisplay error={categoryDetails.error} />
      )}
      {(params.id ? categoryDetails?.category?.success : true) && (
        <>
          {params.id && (
            <AddCategory
              initialValue={getValueForEdit(
                categoryDetails.category.data.records
              )}
              categoryDispatch={updateCategoryDispatch}
              title="Edit Category"
              isUpdate={params.id}
            />
          )}
          {!params.id && (
            <AddCategory
              initialValue={getInitialValue(currentUser?.CompanyId)}
              categoryDispatch={addCategoryDispatch}
              title="Add Category"
              isUpdate={params.id}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    categoryDetails: state.category,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addCategoryDispatch: (data: addCategoryValue, successCallback: Function) =>
      dispatch(addCategory(data, successCallback)),
    getCategoryData: (categoryId: number) => dispatch(getCategory(categoryId)),
    updateCategoryDispatch: (data: any, successCallback: Function) =>
      dispatch(updateCategory(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
