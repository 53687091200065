import { response } from "app/helpers/commonInterface";
import {
  GET_CHECKING_TASK_FAILURE,
  GET_CHECKING_TASK_REQUEST,
  GET_CHECKING_TASK_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface checkingTaskDataState {
  loading: boolean;
  checkingTask: response<taskData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: checkingTaskDataState = {
  loading: false,
  checkingTask: {},
  error: "",
};

export const getCheckingTaskReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_CHECKING_TASK_REQUEST:
      return {
        loading: true,
      };
    case GET_CHECKING_TASK_SUCCESS:
      return {
        loading: false,
        checkingTask: action.payload,
        error: "",
      };
    case GET_CHECKING_TASK_FAILURE:
      return {
        loading: false,
        checkingTask: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
