import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";

import { convert } from "app/helpers/helperFunction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import GetUrlForPdf from "./component/GetUrlForPdf";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import { selectedContactPerson } from "../task/component/TaskGrouping";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";

interface props {
  formatedBill: { show: boolean };
  addedBillDetails: any;
  closeModal: Function;
  contactPersons: Array<selectedContactPerson>;
  savedBillPdfDetails: any;
}

const BillFormat: FC<props> = ({
  savedBillPdfDetails,
  contactPersons,
  closeModal,
  addedBillDetails,
  formatedBill,
}) => {
  return (
    <Modal
      show={formatedBill.show}
      aria-labelledby="contained-modal-formated-bill"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
      className="formated-bill"
    >
      {addedBillDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {addedBillDetails.error && (
        <ErrorDisplay error={addedBillDetails.error} />
      )}
      {!addedBillDetails.loading &&
        addedBillDetails.addedBillData?.success &&
        (JSON.stringify(addedBillDetails.addedBillData?.data?.records) !==
        `{}` ? (
          <>
            <div className="border border-2 m-4">
              <Modal.Header className="d-flex justify-content-center modal-header p-3">
                <Modal.Title className="fs-1">INVOICE</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0 border-top border-top-3">
                <div className="d-flex">
                  <div className="border-end border-end-2 pe-6 ps-4">
                    <ImageDisplay
                      altText="CALogo"
                      className="w-50px"
                      errorPath="/media/auth/logo_512.png"
                      path="/media/auth/logo_512.png"
                      title="CALogo"
                    />
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="border-end border-end-2 align-items-center d-flex fs-1 fw-semibold ps-5 w-75">
                      {
                        addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.Name
                      }
                    </div>
                    <div className="align-items-center d-flex fs-5 fw-semibold justify-content-center w-25">
                      FY : {new Date().getFullYear()}-
                      {+String(new Date().getFullYear()).slice(-2) + 1}
                    </div>
                  </div>
                </div>
                <div className="d-flex border-top border-top-3 fw-semibold">
                  <div className="w-75 border-end border-end-2">
                    <div className="border-bottom border-bottom-2 fs-6 ps-3 py-2">
                      PAN No. :{" "}
                      {
                        addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.PANNumber
                      }
                    </div>
                    <div className="border-bottom border-bottom-2 fs-6 ps-3 py-2 pb-2">
                      Address :{" "}
                      {
                        addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.Address
                      }
                    </div>
                    <div className="fs-6 ps-3 py-1 pb-2 fw-bold">
                      Bill To :{" "}
                      {
                        addedBillDetails.addedBillData?.data?.records
                          ?.ClientName
                      }
                    </div>
                  </div>
                  <div className="w-25">
                    <div className="border-bottom border-bottom-2 fs-6 ps-3 py-4">
                      BillNo :{" "}
                      {
                        addedBillDetails.addedBillData?.data?.records
                          ?.BillNumber
                      }
                    </div>
                    <div className=" fs-6 ps-3 py-4">
                      Date :{" "}
                      {addedBillDetails.addedBillData?.data?.records?.Date
                        ? convert(
                            addedBillDetails.addedBillData?.data?.records?.Date
                          )
                        : "dd/mm/yyyy"}
                    </div>
                  </div>
                </div>
                <div className="border-top border-top-3">
                  <div className="d-flex border-bottom border-bottom-2">
                    <div className="border-end border-end-2 fw-bold py-3 ps-5 w-75 fs-5">
                      Particulars
                    </div>
                    <div className="fw-bold py-3 text-end w-25 pe-5 fs-5">
                      Amount
                    </div>
                  </div>
                  {addedBillDetails.addedBillData?.data?.records?.Tasks?.map(
                    (
                      task: { TypeName: string; Fees: number },
                      index: number
                    ) => (
                      <div
                        className="d-flex border-bottom border-bottom-2"
                        key={index}
                      >
                        <div className="border-end border-end-2 p-2 ps-5 w-75">
                          {task.TypeName}
                        </div>
                        <div className="p-2 pe-5 text-end w-25">
                          {task.Fees}
                        </div>
                      </div>
                    )
                  )}
                  {(addedBillDetails.addedBillData?.data?.records?.Payments
                    .length !== 0 ||
                    addedBillDetails.addedBillData?.data?.records?.Receipts
                      .length !== 0) && (
                    <div className="border-bottom border-bottom-2 fs-6 fw-semibold">
                      <div className="w-75 p-2 border-end border-end-2 d-flex justify-content-center">
                        Reimbursement Expenses
                      </div>
                      <div className="w-25"></div>
                    </div>
                  )}
                  {addedBillDetails.addedBillData?.data?.records?.Payments?.map(
                    (
                      payment: { Narration: string; Amount: string },
                      index: number
                    ) => (
                      <div
                        className="d-flex border-bottom border-bottom-2"
                        key={index}
                      >
                        <div className="border-end border-end-2 p-2 ps-5 w-75">
                          {payment.Narration || "Task Fees"}
                        </div>
                        <div className="p-2 pe-5 text-end w-25">
                          {payment.Amount}
                        </div>
                      </div>
                    )
                  )}
                  {addedBillDetails.addedBillData?.data?.records?.Receipts?.map(
                    (
                      receipt: { Narration: string; Amount: string },
                      index: number
                    ) => (
                      <div
                        className="d-flex border-bottom border-bottom-2"
                        key={index}
                      >
                        <div className="border-end border-end-2 p-2 ps-5 w-75">
                          {receipt.Narration || "Task Fees"}
                        </div>
                        <div className="p-2 pe-5 text-end w-25">
                          - {receipt.Amount}
                        </div>
                      </div>
                    )
                  )}
                  <div className="d-flex border-bottom border-bottom-2 ">
                    <div className="border-end border-end-2 p-2 ps-5 w-75 text-end pe-5 fs-5 fw-bold">
                      SubTotal
                    </div>
                    <div className="p-2 pe-5 text-end w-25 fs-5 fw-bold">
                      {addedBillDetails.addedBillData?.data?.records
                        ?.TotalAmountWithoutTax || 0}
                    </div>
                  </div>
                  <div className="d-flex border-bottom border-bottom-2 ">
                    <div className="border-end border-end-2 p-2 ps-5 w-75 text-end pe-5 fs-5 fw-bold">
                      Tax Amount
                    </div>
                    <div className="p-2 pe-5 text-end w-25 fs-5 fw-bold">
                      {addedBillDetails.addedBillData?.data?.records
                        ?.TotalTaxAmount || 0}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="border-end border-end-2 p-2 ps-5 w-75 text-end pe-5 fs-5 fw-bold">
                      Total Amount
                    </div>
                    <div className="p-2 pe-5 text-end w-25 fs-5 fw-bold">
                      {addedBillDetails.addedBillData?.data?.records
                        ?.TotalAmount || 0}
                    </div>
                  </div>
                </div>
                <div className="border-top border-top-3">
                  <div className="ps-4 py-3 border-bottom border-bottom-2 fs-5 fw-semibold">
                    Bank Details :
                  </div>
                  <div className="ps-4 d-flex justify-content-between">
                    <div>
                      <div className="fs-6 fw-semibold py-1">
                        {
                          addedBillDetails.addedBillData?.data?.records?.Firm
                            ?.Name
                        }
                      </div>
                      <div className="fs-6 fw-semibold py-1">
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.BankName || "-"}
                      </div>
                      <div className="fs-6 fw-semibold py-1">
                        Current A/c. No.{" "}
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.AccountNumber || "-"}
                      </div>
                      <div className="fs-6 fw-semibold py-1">
                        IFSC :{" "}
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.IFSCCode || "-"}
                      </div>
                    </div>
                    <div className="pe-5 pt-3">
                      <ImageDisplay
                        altText="qrCode"
                        errorPath="/media/task/qrCode.png"
                        className="w-100px"
                        // path={
                        //   addedBillDetails.addedBillData?.data?.records?.Firm
                        //     ?.QRCodeFile?.FileUrl
                        // }
                        path={`data:image/jpeg;base64,${addedBillDetails.addedBillData?.data?.records?.base64Qr}`}
                        title="qrCode"
                      />
                    </div>
                  </div>
                  <div className="ps-4 fs-7 fw-semibold py-2">
                    Please Transfer the amount to our following Bank Account or
                    Send a Cheque
                  </div>
                </div>
                <div className="border-top border-top-3 pe-3 pt-4 py-3 text-end">
                  <div className="fw-semibold fs-6">
                    For{" "}
                    {addedBillDetails.addedBillData?.data?.records?.Firm?.Name}
                  </div>
                  <div>
                    <ImageDisplay
                      className="w-125px"
                      altText="signature"
                      errorPath="/media/task/adminSign.png"
                      path="/media/task/adminSign.png"
                      title="signature"
                    />
                  </div>
                  <div className="fw-semibold fs-6">Kalpesh Parekh</div>
                </div>
              </Modal.Body>
            </div>
            <GetUrlForPdf
              billData={addedBillDetails.addedBillData?.data?.records}
            />
            <Formik
              enableReinitialize
              initialValues={{ contact: [] }}
              // validationSchema={whatsappSendValidationSchema}
              onSubmit={async (values, { resetForm }) => {
                //whatsapp send api call
                // const updatedValues = {name : "sent_bill", components : contactPersons.map((con : any) => ({
                //   bodyParameters : [],
                //   contactNumber : con.MobileNumber,
                //   headerParameter:{type: "DOCUMENT", value: "generated bill url"}
                // }))}
                // add upadted values into api call
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form
                    noValidate
                    className="form d-flex align-items-end justify-content-between p-6 pt-4"
                  >
                    <div className="d-flex justify-content-end gap-3 w-100">
                      <ResetButton
                        name="Cancel"
                        className="btn btn-light btn-active-light-primary"
                        onClickCallback={() => closeModal()}
                      />
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        disabled={
                          !savedBillPdfDetails?.billPdf?.data?.records?.FileUrl
                        }
                        className="btn btn-primary"
                        name="Send"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <Modal.Body className="pb-4 pe-4">
            <div className="fs-6 fw-semibold">
              Bill not generated because all task are marked FOC
            </div>
            <div className="text-end">
              <ResetButton
                name="Cancel"
                className="btn btn-sm btn-light btn-active-light-primary"
                onClickCallback={() => closeModal()}
              />
            </div>
          </Modal.Body>
        ))}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    addedBillDetails: state.addUpdateBill,
    savedBillPdfDetails: state.saveBillPdf,
  };
};

export default connect(mapStateToProps)(BillFormat);
