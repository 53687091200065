import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addFirmService,
  getFirmListService,
  getFirmService,
  updateFirmService,
  updateFirmStatusService,
} from "./firmMasterService";
import {
  ADD_FIRM_FAILURE,
  ADD_FIRM_REQUEST,
  ADD_FIRM_SUCCESS,
  GET_FIRM_FAILURE,
  GET_FIRM_LIST_FAILURE,
  GET_FIRM_LIST_REQUEST,
  GET_FIRM_LIST_SUCCESS,
  GET_FIRM_REQUEST,
  GET_FIRM_SUCCESS,
  UPDATE_FIRM_FAILURE,
  UPDATE_FIRM_REQUEST,
  UPDATE_FIRM_STATUS_FAILURE,
  UPDATE_FIRM_STATUS_REQUEST,
  UPDATE_FIRM_STATUS_SUCCESS,
  UPDATE_FIRM_SUCCESS,
} from "../actionTypes";
import { searchParams } from "app/helpers/commonInterface";
import { addFirmValue } from "app/components/pages/firmMaster/FirmMasterForm";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addFirm = (data: addFirmValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_FIRM_REQUEST));

    await addFirmService(data).then(
      (result: any) => {
        dispatch(success(ADD_FIRM_SUCCESS, result));
        showToastMessageSuccess("Firm added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.FIRMS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_FIRM_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getFirmList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_FIRM_LIST_REQUEST));

    getFirmListService(searchObj).then(
      (result: any) => dispatch(success(GET_FIRM_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_FIRM_LIST_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const getFirm = (accountId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_FIRM_REQUEST));

    getFirmService(accountId).then(
      (result: any) => dispatch(success(GET_FIRM_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_FIRM_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const updateFirm = (data: addFirmValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_FIRM_REQUEST));

    await updateFirmService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_FIRM_SUCCESS, result.data));
        showToastMessageSuccess("Firm updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.FIRMS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(UPDATE_FIRM_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const updateFirmStatus = (firmId: number, successCallback: Function) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_FIRM_STATUS_REQUEST));

    updateFirmStatusService(firmId).then(
      (result: any) => {
        dispatch(success(UPDATE_FIRM_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Firm status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.FIRMS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_FIRM_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
