import { convertFieldResponseIntoMuiTextFieldProps } from "@mui/x-date-pickers/internals";
import { toJSON } from "_metronic/assets/ts/_utils";
import { KTSVG } from "_metronic/helpers";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import {
  ResetButton,
  SmallLoader,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { getImage } from "app/helpers/helperFunction";
import { importClient } from "app/reducers/client/clientAction";
import { Button } from "bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { json } from "node:stream/consumers";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

export interface importClientDataValue {
  ClientName: string;
  GSTIN: string;
  PanNumber: string;
  ContactPersonEmail: string;
  ContactPersonName: string;
  ContactPersonPhoneNumber: string;
  GroupName: string;
  SubTaskName: string;
  SubTaskArticle: string;
  SubTaskExpert: string;
  SubTaskFixedDate: string;
  SubTaskManager: string;
  SubTaskOnBoardBefore: Number;
  SubTaskRemarks: string;
  SubTaskStartDate: string;
}

interface props {
  closeModal: Function;
  showModel: boolean;
}

const initialValue = {
  fileObject: Event,
};

const ImportModel: FC<props> = ({ closeModal, showModel }) => {
  const [loading, setLoading] = useState(false);
  const [fileEventObj, SetFileEventObj] = useState<any>(null);
  const [importStatus, setImportStatus] = useState<any[]>([]); // State to store import status for each record
  const [failedRows, setFailedRows] = useState<any[]>([]);
  const dispatch: any = useDispatch();

  const handleFileUpload = (e: any) => {
    // SetFileEventObj(null);
    setImportStatus([]);
    setFailedRows([]);
    SetFileEventObj(e);
  };

  const downloadFailedRows = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(failedRows);
    XLSX.utils.book_append_sheet(wb, ws, "FailedRows");
    XLSX.writeFile(wb, "failed_rows.xlsx");
  };
  const handleDeleteFile = (e: any) => {
    e.preventDefault();
    // Reset other related states
    SetFileEventObj(null);
    setImportStatus([]);
    setFailedRows([]);
  };

  const handelFormSubmit = async () => {
    // console.log(fileEventObj.target.files[0]);

    const reader = new FileReader();
    reader.readAsBinaryString(fileEventObj.target.files[0]);
    reader.onload = async (fileEventObj) => {
      const data = fileEventObj.target!.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      // Map each row to the desired format
      const formattedData = parsedData.map(async (row: any, index) => {
        const client = {
          ClientName: row.ClientName,
          GroupName: row.GroupName,
          GSTIN: row.GSTIN,
          PanNumber: row.PanNumber,
          Legal_Status: row.Legal_Status,
          FirmName: row.FirmName
        };

        // Extract contact details
        const contacts = [];
        for (let i = 1; i <= 3; i++) {
          const contactPersonName = row[`ContactPersonName${i}`] || null;
          const contactPersonEmail = row[`ContactPersonEmail${i}`] || null;
          const contactPersonPhoneNumber =
            row[`ContactPersonPhoneNumber${i}`] || null;
          if (contactPersonName) {
            contacts.push({
              Name: contactPersonName,
              Email: contactPersonEmail,
              PhoneNumber: contactPersonPhoneNumber,
            });
          }
        }

        // Extract subtasks
        const subtasks = [];
        for (let i = 1; i <= 10; i++) {
          const subTaskName = row[`SubTask_Name_${i}`] || null;
          const subTaskStartDate = row[`SubTask_StartDate_${i}`] || null;
          const subTaskFixedDate = row[`SubTask_FixedDate_${i}`] || null;
          const subTaskOnBoardBefore =
            row[`SubTask_OnBoardBefore_${i}`] || null;
          const subTaskArticle = row[`SubTask_Article_${i}`] || null;
          const subTaskManager = row[`SubTask_Manager_${i}`] || null;
          const subTaskExpert = row[`SubTask_Expert_${i}`] || null;
          const subTaskRemarks = row[`SubTask_Remarks_${i}`] || null;
          if (subTaskName) {
            subtasks.push({
              Name: subTaskName,
              StartDate: subTaskStartDate,
              FixedDate: subTaskFixedDate,
              OnBoardBefore: subTaskOnBoardBefore,
              Article: subTaskArticle,
              Manager: subTaskManager,
              Expert: subTaskExpert,
              Remarks: subTaskRemarks,
            });
          }
        }

        const postData = {
          client,
          contacts,
          subtasks,
        };

        try {
          // Dispatch your API call here
          const response = await dispatch(
            importClient(postData, () => {
              // console.log("import data called");
            })
          );

          const success = response.data.success;
          setImportStatus((prevStatus) => [
            ...prevStatus,
            { index, success, clientName: client.ClientName, postData },
          ]);
        } catch (error) {
          setFailedRows((prev) => [...prev, row]);
          setImportStatus((prevStatus) => [
            ...prevStatus,
            { index, success: false, clientName: client.ClientName, postData },
          ]);
        }
      });
    };
  };

  return (
    <Modal
      show={showModel}
      aria-labelledby="contained-modal-otp"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="position-relative">
        <h4 className="w-100" style={{ display: 'block' }}><div className="d-flex justify-content-between"><div style={{ fontWeight: '700', fontSize: '19px' }}> Import Clients Data</div>
          <div > <a className="btn btn-primary btn-sm" href="/DEMO-FINAL-CA.xlsx" download>
            Download
          </a></div>
        </div></h4>

        <button type="button" onClick={() => {
          closeModal();
        }} style={{ position: 'absolute', top: '-20%', right: '-3%' }} className="btn  btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger mt-1"><span className="svg-icon svg-icon-muted svg-icon-2hx "><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px "><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="white"></rect><rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect><rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect></svg></span></button>

      </Modal.Header>
      <Modal.Body className="fs-5 fw-semibold lh-xl modal-body pt-5">
        <div className="bg-light-secondary p-2" style={{ borderRadius: '10px 10px 0 0' }}>Import Data </div>

        {/* <a href="/DEMO-FINAL-CA.xlsx" download> 
          Download sample file
        </a> */}

        <Formik
          enableReinitialize
          initialValues={initialValue}
          // validationSchema={sentMessageValidateSchema}
          onSubmit={async (values) => {
            handelFormSubmit();
            // closeModal();
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form noValidate className="form bg-light-secondary p-2 mb-6" style={{ borderRadius: '0 0 10px 10px ' }}>
                <div className="popup-container">
                  <div className="popup-content d-flex justify-content-between">
                    <label htmlFor="clientsFilePath" className="col-lg-4">
                      <div className="mb-3">
                        <div className="d-flex align-items-center">
                          {fileEventObj != null && fileEventObj.target.files[0] !=  null ? (
                            <div className="col-lg-9 ms-3 w-100 upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                              <div className="d-flex gap-3 align-items-center ">
                                <div className="w-35px">
                                  <ImageDisplay
                                    path={getImage("xlsx")}
                                    errorPath="/media/task/doc-1.png"
                                    className="img-fit-to-div"
                                    altText="documentType"
                                  />
                                </div>
                                <div className="fw-semibold">
                                  <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                                    {fileEventObj.target.files[0]?.name}
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <button className="btn p-0" onClick={(e) => handleDeleteFile(e)}>
                                  <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="d-inline-flex gap-3 btn btn-light-primary btn-active-light-primary btn-sm">
                              {loading ? (
                                <SmallLoader />
                              ) : (
                                <i className="bi bi-upload me-1"></i>
                              )}
                              Upload File
                            </div>
                          )}

                          <Field
                            type="file"
                            value={undefined}
                            className="d-none"
                            id="clientsFilePath"
                            name="clientsFilePath"
                            onChange={(e: any) => {
                              handleFileUpload(e);
                            }}
                            accept=".xlsx"
                          />
                        </div>
                        <ErrorMessage
                          name="clientsFilePath"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </label>
                    <div className="d-flex justify-content-end ">
                      {/* <ResetButton
                        className="btn btn-light btn-sm btn-active-light-primary me-2 "
                        name="Cancel"
                        onClickCallback={() => {
                          closeModal();
                        }}
                        disabled={isSubmitting}
                      /> */}
                      <SubmitButton
                        className="btn btn-primary btn-sm m-auto"
                        isSubmitting={isSubmitting}
                        name="Submit"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        {importStatus.length > 0 && (
          <div style={{ maxHeight: "400px"}}>
            {failedRows.length > 0 && (
            <SubmitButton
              className="btn btn-primary btn-sm"
              name="Download failed rows"
              isSubmitting={false}
              onClickCallback={downloadFailedRows}
            />
            )}

            <div className="bg-light-secondary p-2 mt-6" style={{ fontWeight: '600', fontSize: '18px', borderRadius: '10px 10px 0 0' }}>Import Status</div>
            <div className="bg-light-secondary p-2" style={{maxHeight:'300px',overflow:'scroll'}}>
              {importStatus.map((status, index) => (
                <div key={index} className="row ">
                  <div className="box d-flex flex-start mb-3">
                    <div> {status.clientName}</div>
                    <div
                      style={{
                        marginLeft: '5px', margin: 'auto 10px'
                      }}
                      className={`badge rounded-pill ${status.success
                        ? "bg-success "
                        : "bg-danger"
                        } `}
                    >
                      {status.success ? "Success" : "Failure"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImportModel;
