import { createRoot } from "react-dom/client";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import "./index.scss";
import { AppRoutes } from "./app/routes/AppRoutes";
import { AuthProvider } from "./app/components/pages/auth";
import { Provider } from "react-redux";
import store from "./app/store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { WebSocketProvider } from "app/components/commonComponent/websocketContext/websocketContext";
Chart.register(...registerables);
const { PUBLIC_URL } = process.env;

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <AuthProvider>
            {/* <TimerProvider> */}
            <WebSocketProvider>
              <BrowserRouter basename={PUBLIC_URL}>
                <AppRoutes />
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                />
              </BrowserRouter>
            </WebSocketProvider>
            {/* </TimerProvider> */}
          </AuthProvider>
        </Provider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
