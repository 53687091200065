import { FC, useState } from "react";
import { SmallLoader } from "../buttons/Buttons";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { KTSVG } from "_metronic/helpers";

interface props {
  isActive: boolean;
  id: number;
  updateStatusDispatch: Function;
}

const UserActionsCell: FC<props> = ({ updateStatusDispatch, isActive, id }) => {
  const [loading, setLoading] = useState(false);
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <>
      <button
        className={`btn btn-icon btn-bg-light btn-sm p-2 ${
          !isActive ? "btn-active-light-success" : "btn-active-light-danger"
        }`}
        title={!isActive ? "Active" : "Inactive"}
        onClick={() => {
          setLoading(true);
          updateStatusDispatch(id, () => {
            setLoading(false);
            sendDataChangeWarning();
          });
        }}
      >
        {loading ? (
          <SmallLoader />
        ) : (
          <>
            <KTSVG
              path={`/media/icons/duotune/general/gen0${
                !isActive ? "37" : "34"
              }.svg`}
              className="svg-icon-3"
            />
            {/* {isActive && <i className="fa-solid fa-user-xmark"></i>}
            {!isActive && <i className="fa-solid fa-user-check"></i>} */}
          </>
        )}
      </button>
    </>
  );
};

export default UserActionsCell;
