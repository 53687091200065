import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import GetUrlForPdf from "../../billing/component/GetUrlForPdf";

interface props {
  showWarningModel: boolean;
  apiDispatchFun: Function;
  closeModel: Function;
  proformaInvoiceData: any;
  savedBillPdfDetails: any;
}

const FinalBillConvertWarning: FC<props> = ({
  apiDispatchFun,
  showWarningModel,
  closeModel,
  proformaInvoiceData,
  savedBillPdfDetails,
}) => {
  const [loading, setLoading] = useState(false);

  const proformaInvoiceAddToFinal = () => {
    setLoading(true);
    apiDispatchFun(() => setLoading(false));
  };

  return (
    <Modal
      show={showWarningModel}
      aria-labelledby="contained-modal-otp"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Generate Final Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5 fw-semibold lh-xl modal-body pt-5">
        <div>You can't change invoice details after converting to final. </div>
        <div> By submiting you convert Proforma Invoice to Final Invoice.</div>

        <GetUrlForPdf billData={proformaInvoiceData} />
      </Modal.Body>
      <Modal.Footer className="border-0 pt-2">
        <div className="align-items-center d-flex gap-3 justify-content-end mt-4">
          <ResetButton
            name="Cancel"
            className="btn btn-light btn-sm btn-active-light-primary"
            onClickCallback={() => closeModel()}
          />
          <SubmitButton
            name="Submit"
            isSubmitting={loading}
            className="btn btn-primary btn-sm"
            onClickCallback={proformaInvoiceAddToFinal}
            disabled={!savedBillPdfDetails}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FinalBillConvertWarning;
