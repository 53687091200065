import { connect } from "react-redux";
import { FC } from "react";

import { getTransactionList } from "app/reducers/transaction/transactionAction";
import { searchParams } from "app/helpers/commonInterface";
import TransactionTableRow from "./TransactionTableRow";
import Table from "app/components/commonComponent/table/Table";
import { transactionListState } from "app/reducers/transaction/getTransactionListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import {
  transactionDetail,
} from "app/reducers/transaction/transactionDataInterface";

interface props {
  setTransactionId: Function;
  transactionDetails: transactionListState;
  getTransactionListDetails: Function;
}

const HEADERS = [
  {
    columnName: "Type",
    id: "Type",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Narration",
    id: "NULL",
    className: "min-w-250px min-w-xxl-125px",
  },
  {
    columnName: "Transaction Date",
    id: "TransactionDate",
    className: "min-w-125px",
  },
  {
    columnName: "Amount",
    id: "Amount",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px d-flex justify-content-end pe-5",
  },
];

const TransactionTable: FC<props> = ({
  setTransactionId,
  transactionDetails,
}) => {
  const { searchObj } = useGetSearchObject();

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={transactionDetails.error}
        headers={HEADERS}
        success={transactionDetails.transactionList?.success}
        loading={transactionDetails.loading}
        paginate={{
          show: true,
          pagination: transactionDetails.transactionList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={
          transactionDetails.transactionList?.data?.records?.Transactions
        }
        tableRow={(row: transactionDetail, index: number) => (
          <TransactionTableRow
            row={row}
            key={`row-${index}-${row.Id}`}
            setTransactionId={setTransactionId}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    transactionDetails: state.transactionList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionListDetails: (searchObj: searchParams) =>
      dispatch(getTransactionList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable);
