import { connect } from "react-redux";
import { FC } from "react";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { calculateDuration, convert, convertDate, convertTo12HourFormat, dateConverter } from "app/helpers/helperFunction";
import AddMorningPlanner from "../AddMorningPlanner";
import EditMorningPlanner from "../EditMorningPlanner";
import DeletePlanner from "../DeletePlanner";
interface row {
    Id: Number;
    TaskId:string,
    ActivityId :string,
    TaskName: string;
    ActivityName: string;
    TasktypeName: string;
    ActionName: string;
    StatusName: string;
    StartTime: string;
    EndTime: string;
    Status: Number;
    Date: string;
    AssignId: string;
    ClientName:string
}

type Props = {
    row: row;
    viewMorningAction:any
};

const MPCustomRow: FC<Props> = ({ row,viewMorningAction}) => {
    return (
        <tr>
            {/* <td>{Number(row.Id)}</td> */}
            <td><span className="me-2 bold fs-6">{row.TaskName} {row.ActivityName}</span>
                <div className=" badge bg-primary rounded-pill">{row.TasktypeName}</div>
            </td>
            <td>{row.ClientName != undefined ? row.ClientName : '-'}</td>
            {/* <td>{row.TaskId ? `T-${row.TaskId}` : `A-${row.ActivityId}`}</td> */}
            <td> {convertTo12HourFormat(row.StartTime)}</td>
            <td>{convertTo12HourFormat(row.EndTime)}</td>
            <td>{calculateDuration(row.StartTime,row.EndTime)}</td>
            <td>{convert(row.Date)}</td>
            <td>
                <span className={`fs-7 badge ${row.Status === 4 ? "badge-light-primary" :
                        row.Status === 6 ? "badge-light-success" :
                            row.Status === 3 ? "badge-light-primary" :
                                "badge-light-default" // default class if no condition matches
                    }`}>
                    {
                        row.Status === 4 ? "Sent For Approval" :
                            row.Status === 6 ? "Approved" :
                                row.Status === 3 ? "Added" :
                                    "Default" // default text if no condition matches
                    }
                </span>
            </td>
            {(viewMorningAction && viewMorningAction.viewMorningAction) && (
            <td className="pe-5">
                <div className="d-flex gap-3">
                <EditMorningPlanner id={row.Id} ButtonTitle="Edit" ModalTitle="Edit Morning Planner" />
                <DeletePlanner id={row.Id} type={1} userId={row.AssignId} />
                </div>
            </td>
            )}
        </tr>
    );
};

const mapStateToProps = (state: any) => {
    return {
        viewMorningAction : state.viewMorningAction,
    };
  };
  
export default connect(mapStateToProps, null)(MPCustomRow);