import { response } from "app/helpers/commonInterface";
import {
  GET_URGENT_TASK_FAILURE,
  GET_URGENT_TASK_REQUEST,
  GET_URGENT_TASK_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface urgentTaskListState {
  loading: boolean;
  urgentTaskList: response<taskData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: urgentTaskListState = {
  loading: false,
  urgentTaskList: {},
  error: "",
};

export const urgentTaskListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_URGENT_TASK_REQUEST:
      return {
        loading: true,
      };
    case GET_URGENT_TASK_SUCCESS:
      return {
        loading: false,
        urgentTaskList: action.payload,
        error: "",
      };
    case GET_URGENT_TASK_FAILURE:
      return {
        loading: false,
        urgentTaskList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
