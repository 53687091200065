import DOMPurify from "dompurify";
import { FC, Fragment } from "react"

import { changeWpTemplateTextFormat } from "app/helpers/helperFunction"

interface props {
    templateData: any
}

const RegisteredTemplatePreview: FC<props> = ({ templateData }) => {

    return (
        <div className="w-200px w-md-300px card p-4 shadow-sm position-absolute registered-wp-template-preview">
            {templateData.map((component: any, index: number) => {
                return <Fragment key={index}>
                    <>
                        {(component.type === "HEADER" && component.format === "TEXT") &&
                            <div className="mb-3 fw-bold fs-6 text-dark" 
                            dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize(changeWpTemplateTextFormat(component.text)) }}>
                            </div>}
                        {(component.type === "HEADER" && (component.format === "IMAGE" || component.format === "DOCUMENT")) &&
                            <div>
                                <button className="mb-3 btn btn-sm btn-light p-2 fs-9" disabled>
                                    <i className="bi bi-upload me-1"></i>
                                    Upload File
                                </button>
                            </div>}
                    </>
                    <>
                        {component.type === "BODY" &&
                            <div className="mb-3 fs-7 text-dark fw-semibold" 
                            dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize(changeWpTemplateTextFormat(component.text)) }}>
                            </div>}
                    </>
                    <>
                        {component.type === "FOOTER" &&
                            <div className="text-gray-400 fs-7 fw-semibold">
                                {component.text}
                            </div>}
                    </>
                </Fragment>
            })}
        </div>
    )
}

export default RegisteredTemplatePreview