import {
  GET_RECORD_LIST_IN_CASH_BOOK_FAILURE,
  GET_RECORD_LIST_IN_CASH_BOOK_REQUEST,
  GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS,
  UPDATE_CASH_BOOK_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface cashBookListState {
  loading: boolean;
  cashBookList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: cashBookListState = {
  loading: false,
  cashBookList: {},
  error: "",
};

export const getCashBookListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_RECORD_LIST_IN_CASH_BOOK_REQUEST:
      return {
        loading: true,
      };
    case GET_RECORD_LIST_IN_CASH_BOOK_SUCCESS:
      return {
        loading: false,
        cashBookList: action.payload,
        error: "",
      };
    case GET_RECORD_LIST_IN_CASH_BOOK_FAILURE:
      return {
        loading: false,
        cashBookList: {},
        error: action.payload,
      };
    case UPDATE_CASH_BOOK_STATUS_SUCCESS:
      return {
        ...state,
        cashBookList: {
          ...state["cashBookList"],
          data: {
            ...state["cashBookList"].data,
            records: {
              ...state["cashBookList"].data.records,
              BookRecords: state["cashBookList"].data.records.BookRecords.map(
                (item: any) => {
                  if (
                    String(item.Id) === String(action.payload.data.records.Id)
                  ) {
                    return { ...item, IsActive: !item.IsActive };
                  }
                  return item;
                }
              ),
            },
          },
        },
      };
    default:
      return state;
  }
};
