import { Modal } from "react-bootstrap"
import ImageDisplay from "./ImageDisplay"
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useAuth } from "../pages/auth";

interface props {
    closeModal: Function
    session: { show: boolean }
}

const SessionExpired: FC<props> = ({ closeModal, session }) => {
    const { logout } = useAuth()

    return (
        <Modal show={session.show}
            aria-labelledby="contained-modal-session" centered
            data-toggle="modal" backdrop="static" keyboard={false}
        >
            <Modal.Body className="d-flex align-items-center gap-6">
                <div className="align-items-center d-flex justify-content-center px-2 py-2 rounded-circle"
                    style={{ backgroundColor: "#ffd8d8" }}>
                    <ImageDisplay altText="logout"
                        className="w-25px" errorPath="/media/task/icons8-error-80-5.png" path="/media/task/icons8-exclamation-mark-96.png"
                        title="logout" />
                </div>
                <div>
                    <div className="fs-3 fw-bold mb-1">Your session has expired</div>
                    <div className="fs-6 text-gray-400">Please log in again to continue using the app.</div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <button className="btn btn-primary btn-sm"
                    onClick={() => { logout(); closeModal(); }}>
                    Log in
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SessionExpired