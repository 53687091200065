import { response } from "app/helpers/commonInterface";
import {
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_REQUEST,
  GET_FILE_LIST_SUCCESS,
} from "../actionTypes";
import { clientDocument } from "./clientDocumentsReportListReducer";

export interface clientDocumentsState {
  loading: boolean;
  documentList: response<clientDocument[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: clientDocumentsState = {
  loading: false,
  documentList: {},
  error: "",
};

export const clientDocumentsReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_FILE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList: action.payload,
        error: "",
      };
    case GET_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        documentList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
