import { FC, useEffect, useRef } from "react";
import gsap from "gsap";

interface props {
    stream: MediaStream | undefined
    recorder: MediaRecorder
    timeline: any
}

const AudioVisuliazer: FC<props> = ({ stream, recorder, timeline }) => {
    const canvasRef = useRef<any>(null);
    const audioContext = new AudioContext();
    const audioAnalyzer = audioContext.createAnalyser();
    const dataArr: any = new Uint8Array(audioAnalyzer.frequencyBinCount)
    let refId: any;
    let source: any;
    const CONFIG = {
        DURATION: 0.1,
        fps: 10,
    }
    const BAR_WIDTH = 4
    const PIXELS_PER_SECOND = 100
    const VIZ_CONFIG = {
        bar: {
            width: 4,
            min_height: 0.04,
            max_height: 0.8,
            gap: 2,
        },
        pixelsPerSecond: PIXELS_PER_SECOND,
        //   barDelay: (1 / PIXELS_PER_SECOND) * BAR_WIDTH,
        barDelay: 1 / CONFIG.fps,
    }
    gsap.ticker.fps(CONFIG.fps)
    const BARS: any = []

    const drawBar = ({ x, size }: any) => {
        if (canvasRef.current) {
            const DRAWING_CONTEXT = canvasRef.current.getContext('2d');
            const POINT_X = x - BAR_WIDTH / 2
            const POINT_Y = canvasRef.current.height / 2 - size / 2
            DRAWING_CONTEXT.fillRect(POINT_X, POINT_Y, BAR_WIDTH, size)
        }
    }
    // drawBars updated to iterate through new variables
    const drawBars = () => {
        if (canvasRef.current) {
            const DRAWING_CONTEXT = canvasRef.current.getContext('2d');
            DRAWING_CONTEXT.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
            for (const BAR of BARS) {
                drawBar(BAR)
            }
        }
    }

    const report = () => {
        audioAnalyzer.getByteFrequencyData(dataArr);
        const VOLUME = Math.floor((Math.max(...dataArr) / 255) * 100)
        if (canvasRef.current) {
            const DRAWING_CONTEXT = canvasRef.current.getContext('2d');
            DRAWING_CONTEXT.fillStyle = 'gray'
            const BAR = {
                x: canvasRef.current.width + BAR_WIDTH / 2,
                size: gsap.utils.mapRange(0, 100, 5, canvasRef.current.height * 0.8)(VOLUME)
            }
            BARS.push(BAR)
            timeline
                .to(BAR, {
                    x: `-=${canvasRef.current.width + VIZ_CONFIG.bar.width}`,
                    ease: 'none',
                    // duration: CONFIG.DURATION,
                    // duration: CANVAS.width / VIZ_CONFIG.pixelsPerSecond,
                    duration: canvasRef.current.width / ((VIZ_CONFIG.bar.width + VIZ_CONFIG.bar.gap) * CONFIG.fps),
                }, BARS.length * VIZ_CONFIG.barDelay)
        }
        if (recorder) {
            drawBars()
        }
        if (recorder) { refId = requestAnimationFrame(report) }
        else { audioContext.close() }
    }

    useEffect(() => {
        if (stream) {
            source = audioContext.createMediaStreamSource(stream);
            source.connect(audioAnalyzer);
        }
        report();
    }, [stream, recorder, canvasRef.current])

    // useEffect(() => {
    //     return () => {
    //         cancelAnimationFrame(refId)
    //         audioAnalyzer?.disconnect();
    //         source?.disconnect();
    //         stream?.getTracks().forEach(t => {t.stop(); timeline.clear();})
    //     }
    // }, [])

    // const draw = () => {
    // let timeline = gsap.timeline()
    // const canvas = canvasRef.current;
    // if (canvas) {
    //     const height = canvas.height;
    //     const width = canvas.width;
    //     const context = canvas.getContext('2d');
    //     let x = 0;
    //     const sliceWidth = (width * 1.0) / dataArr.length;
    //     if (context) {
    //         context.lineWidth = 2;
    //         context.strokeStyle = '#000000';
    //         context.clearRect(0, 0, width, height);
    //         context.beginPath();
    //         context.moveTo(0, height / 2);
    //         for (const item of dataArr) {
    //             const y = (item / 255.0) * height;
    //             context.lineTo(x, y);
    //             x += sliceWidth;
    //         }
    //         context.lineTo(x, height / 2);
    //         context.stroke();
    //     }
    // }
    // }

    return (
        <div className="d-flex gap-3 align-items-center">
            <div className="recording-audio">
                <i className="fa-solid fa-microphone fs-3 text-danger"></i>
            </div>
            <canvas width="350" height="100" ref={canvasRef} />
        </div>
    )
}

export default AudioVisuliazer