import { FC } from "react";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import {
  convert,
  getDetailsAgainstTransaction,
} from "app/helpers/helperFunction";
import {
  taskPayments,
  taskReceipts,
} from "app/reducers/task/taskDataInterface";

interface props {
  transactions: taskPayments[] | taskReceipts[];
  title: string;
}

const getTotalAmount = (transactionList: taskPayments[] | taskReceipts[]) => {
  return (transactionList as taskReceipts[]).reduce(
    (acc: number, transaction: taskPayments | taskReceipts) =>
      acc + transaction.Amount,
    0
  );
};

const TaskTransactionDetails: FC<props> = ({ transactions, title }) => {
  return (
    <ErrorBoundary>
      <div className="col-12">
        <div className="card">
          <div className="card-header pt-5 align-items-center border-0">
            <div className="card-title flex-column">
              <div className="fw-bold">{title}</div>
            </div>
            <div className="fw-bold fs-5">
              <span>Total: </span>
              <span
                className={`${
                  title.toLowerCase() === "payments"
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {getTotalAmount(transactions)}
              </span>
            </div>
          </div>
          <div className="card-body px-7 py-4 fs-6 mh-400px overflow-auto">
            {!transactions.length ? (
              <div className="fs-5 fw-semibold">No records found</div>
            ) : (
              transactions.map(
                (transaction: taskPayments | taskReceipts, index: number) => (
                  <div className="m-0" key={index}>
                    <div
                      className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${title}-${index}`}
                    >
                      <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                        <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="5"
                              fill="currentColor"
                            />
                            <rect
                              x="6.0104"
                              y="10.9247"
                              width="12"
                              height="2"
                              rx="1"
                              fill="currentColor"
                            />
                          </svg>
                        </span>

                        <span className="svg-icon toggle-off svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="5"
                              fill="currentColor"
                            />
                            <rect
                              x="10.8891"
                              y="17.8033"
                              width="12"
                              height="2"
                              rx="1"
                              transform="rotate(-90 10.8891 17.8033)"
                              fill="currentColor"
                            />
                            <rect
                              x="6.01041"
                              y="10.9247"
                              width="12"
                              height="2"
                              rx="1"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>

                      <div className="d-flex justify-content-between w-100">
                        <div className="text-gray-700 fw-semibold cursor-pointer mb-0">
                          {TRANSACTION_TYPE[transaction.Type]}
                        </div>
                        <div
                          className={`fw-semibold ${
                            transaction.IsPayment
                              ? "text-danger"
                              : "text-success"
                          }`}
                        >
                          {transaction.Amount}
                        </div>
                      </div>
                    </div>

                    <div
                      id={`${title}-${index}`}
                      className="collapse fs-6 ms-1"
                    >
                      {transaction.Type !== TRANSACTION_TYPE.Other && (
                        <div className="row mb-4 align-items-center d-none">
                          <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                            {getDetailsAgainstTransaction(transaction).property}
                          </div>
                          <div className="col-5">
                            {getDetailsAgainstTransaction(transaction).value}
                          </div>
                        </div>
                      )}
                      <div className="row mb-4 align-items-center">
                        <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                          Firm Name
                        </div>
                        <div className="col-5">
                          {transaction?.FirmName || "-"}
                        </div>
                      </div>
                      <div className="row mb-4 align-items-center">
                        <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                          Book Name
                        </div>
                        <div className="col-5">
                          {transaction?.BookName || "-"}
                        </div>
                      </div>
                      <div className="row mb-4 align-items-center">
                        <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                          Narration
                        </div>
                        <div className="col-5">
                          {transaction?.Narration || "-"}
                        </div>
                      </div>
                      <div className="row mb-4 align-items-center">
                        <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                          Transaction Date
                        </div>
                        <div className="col-5">
                          {transaction?.TransactionDate
                            ? convert(transaction?.TransactionDate)
                            : "-"}
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed"></div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default TaskTransactionDetails;
