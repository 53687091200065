import { connect, useDispatch } from "react-redux";
import { FC, useEffect, useState } from "react";

import { activityData } from "./TaskDetails";
import {
  changeStatusOfActivity,
  updateTaskStatus,
} from "app/reducers/task/taskAction";
import CompletionField from "./CompletionField";
import { changeTextToFirstUppercase } from "app/helpers/helperFunction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { taskActivities, taskData } from "app/reducers/task/taskDataInterface";
import { useParams } from "react-router-dom";
import { SENT_FOR_CHECK } from "app/helpers/constants";
import { statusChangeData } from "../AdminTaskDetailPage";
import { displayName } from "react-quill";

interface props {
  taskDetails: taskData;
  changeActivityStatusDispatch: Function;
  sentForCheckWhenAllChecked: Function;
}

const TaskActivityList: FC<props> = ({
  taskDetails,
  changeActivityStatusDispatch,
  sentForCheckWhenAllChecked,
}) => {
  const dispatch: any = useDispatch();
  const params = useParams();
  const [checkActivityLoader, setCheckActivityLoader] = useState({
    loading: false,
    id: 0,
  });

  const getCompletedActivityProgress = () => {
    const completedNum = taskDetails.Activities.reduce(
      (acc: number, activity: taskActivities) => {
        if (activity.IsCompleted) {
          return (acc = acc + 1);
        }
        return acc;
      },
      0
    );
    return taskDetails.Activities.length
      ? Math.ceil((completedNum / taskDetails.Activities.length) * 100)
      : 0;
  };

  const onChnageDropDownValue = (task: taskActivities) => {
    setCheckActivityLoader({
      loading: true,
      id: task.Id,
    });
    const result = changeActivityStatusDispatch({ id: task.Id }, () =>
      setCheckActivityLoader({
        loading: false,
        id: 0,
      })
    );
    result.then((data: any) => {
      // console.log(data.data.data.records.Activities);

      sentForCheckWhenAllChecked(data.data.data.records.Activities);
    });
  };

  //sent for check when all checkpoints are checked
  // const sentForCheckWhenAllChecked = async (activities: any) => {
  //   for (let obj of activities) {
  //     if (!obj.IsCompleted) {
  //       console.log("all checkpoints are not checked");
  //       return;
  //     }
  //   }
  //   console.log("all checkpoints are checked");
  //   dispatch(
  //     updateTaskStatus({
  //       data: {
  //         taskIds: [Number(params.id)],
  //         status: String(SENT_FOR_CHECK.id), // Convert to string explicitly
  //       },
  //     })
  //   );
  // };

  return (
    <div className="col-12 ">
      <div className="card">
        <div className="card-header align-items-center border-0">
          <div className="card-title fw-bold">Checklist</div>
          <div className="d-flex flex-column w-125px me-2">
            <div className="d-flex flex-stack mb-2">
              <span className="text-muted me-2 fs-7 fw-semibold">
                {getCompletedActivityProgress()}%
              </span>
            </div>
            <div className="progress h-6px w-100 bg-gray-300">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${getCompletedActivityProgress()}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="card-body px-7 pb-4 pt-0 ">
          <div className="d-flex align-items-center justify-content-between flex-wrap ">
            <div className="d-flex gap-4 align-items-center">
              <div className="d-flex align-items-center gap-1">
                <ImageDisplay
                  altText="bullet"
                  className="w-20px"
                  path="/media/icons/duotune/general/gen022.svg"
                  errorPath="/media/icons/duotune/general/gen022.svg"
                  title="bullet"
                />
                <div className="fw-semibold fs-3">
                  {changeTextToFirstUppercase(taskDetails.TypeName)}
                </div>
              </div>
              <div className="text-gray-400 fw-semibold fs-6">
                {taskDetails.CategoryName}
              </div>
            </div>
          </div>
          <div className="mt-3 h-auto">
            {taskDetails.Activities.map(
              (task: taskActivities, index: number) => {
                if (task.RequireCompletionField) {
                  return (
                    <div className="px-4" key={index}>
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex gap-1 align-items-center">
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            {checkActivityLoader.loading &&
                            checkActivityLoader.id === task.Id ? (
                              <SmallLoader />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`checkList.${index}.isDefault`}
                                id={`checkList.${index}.isDefault`}
                                disabled={!task.CompletionFieldValue}
                                checked={task.IsCompleted}
                                onChange={() => {
                                  // setCheckActivityLoader({
                                  //   loading: true,
                                  //   id: task.Id,
                                  // });
                                  // changeActivityStatusDispatch(
                                  //   { id: task.Id },
                                  //   () =>
                                  //     setCheckActivityLoader({
                                  //       loading: false,
                                  //       id: 0,
                                  //     })
                                  // );
                                  onChnageDropDownValue(task);
                                }}
                              />
                            )}
                          </div>
                          <div className="fw-semibold fs-6 col-form-label">
                            {task.ActivityName}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4 position-relative">
                          <CompletionField
                            task={task}
                            onSubmitHandler={changeActivityStatusDispatch}
                          />
                          {task.IsMandatory && (
                            <div className="badge badge-light-info fw-semibold">
                              compulsory
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center flex-wrap px-4"
                      key={index}
                    >
                      <div className="d-flex gap-1 align-items-center">
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          {checkActivityLoader.loading &&
                          checkActivityLoader.id === task.Id ? (
                            <SmallLoader />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={`checkList.${index}.isDefault`}
                              id={`checkList.${index}.isDefault`}
                              checked={task.IsCompleted}
                              onChange={() => {
                                // setCheckActivityLoader({
                                //   loading: true,
                                //   id: task.Id,
                                // });
                                // changeActivityStatusDispatch(
                                //   { id: task.Id },
                                //   () =>
                                //     setCheckActivityLoader({
                                //       loading: false,
                                //       id: 0,
                                //     })
                                // );
                                onChnageDropDownValue(task);
                              }}
                            />
                          )}
                        </div>
                        <label className="col-form-label fw-semibold fs-6">
                          {task.ActivityName}
                        </label>
                      </div>
                      {task.IsMandatory && (
                        <div className="badge badge-light-info fw-semibold">
                          compulsory
                        </div>
                      )}
                    </div>
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeActivityStatusDispatch: (
      activityData: activityData,
      successCallback: Function
    ) => dispatch(changeStatusOfActivity(activityData, successCallback)),
    updateTaskStatusDispatch: (
      data: statusChangeData,
      status: string,
      successCallback: Function,
      failureCallback: Function
    ) =>
      dispatch(
        updateTaskStatus({ data, status, successCallback, failureCallback })
      ),
  };
};

export default connect(null, mapDispatchToProps)(TaskActivityList);
