import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { adminHomePage } from "../main/admin/AdminMain";
import { absolutePath } from "app/helpers/relativePath";
import BankBookTable from "./component/BankBookTable";
import { emptyObject } from "app/helpers/helperFunction";
import { firmListPage } from "../firmMaster/FirmMasterList";
import { FIRM_BANK_BOOK_LIST } from "app/helpers/routes";
import { KTCard } from "_metronic/helpers";
import { PageLink, PageTitle, PageSubTitle } from "_metronic/layout/core";
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { FC } from "react";

interface props {
    bankBookDetails : any
}

export const bankBookListPage = (firmId : number) => {
  return {
    title: "Bank Book",
    path: absolutePath(`${FIRM_BANK_BOOK_LIST}/${firmId}`),
    isSeparator: true,
    isActive: true,
  };
};

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  firmListPage,
  emptyObject,
];

const BankBookList:FC<props> = ({bankBookDetails}) => {
  const params = useParams();

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Bank Books</PageTitle>
      <PageSubTitle>{bankBookDetails.bankBookList?.data?.records?.FirmName || ""}</PageSubTitle>
      <div className="d-flex justify-content-end align-items-end flex-wrap gap-3 mb-4">
        <UsersListSearchComponent />
        <UsersListToolbar
          buttons={[
            {
              title: "Add Bank Book",
              to: `${FIRM_BANK_BOOK_LIST}/${params.id}/add`,
            },
          ]}
        />
      </div>
      <KTCard>
        <BankBookTable />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state :any) => {
    return{
        bankBookDetails: state.bankBookList
    }
}

export default connect(mapStateToProps)(BankBookList);
