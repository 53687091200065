import { absolutePath } from "app/helpers/relativePath"
import { adminHomePage } from "../main/admin/AdminMain"
import { emptyObject } from "app/helpers/helperFunction"
import { FIRMS_LIST, ADD_FIRM } from "app/helpers/routes"
import FirmMasterTable from "./component/FirmMasterTable"
import { KTCard } from "_metronic/helpers"
import { PageLink, PageTitle } from "_metronic/layout/core"
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar"
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"

export const firmListPage = {
    title: 'Firms',
    path: absolutePath(FIRMS_LIST),
    isSeparator: true,
    isActive: true,
}

const firmBreadCrumbs: Array<PageLink> = [
    adminHomePage,
    emptyObject
]

const FirmMasterList = () => {
    return (
        <>
            <PageTitle breadcrumbs={firmBreadCrumbs}>Firms</PageTitle>
            <div className='d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4'>
                <UsersListSearchComponent />
                <UsersListToolbar
                    buttons={[{ title: "Add Firm", to: ADD_FIRM }]} />
            </div>
            <KTCard>
                <FirmMasterTable />
            </KTCard>
        </>
    )
}

export default FirmMasterList