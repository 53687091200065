import {
    GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE,
    GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST,
    GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    UPDATE_USER_STATUS_SUCCESS,
  } from "../actionTypes";
  import { updateStatusFun } from "../stateHelperFunction";
  
  export interface usersByReportingAuthorityListState {
    loading: boolean;
    usersByReportingAuthorityList: any;
    error: string;
  }
  
  interface action {
    type: string;
    payload: any;
  }
  
  const initialState: usersByReportingAuthorityListState = {
    loading: false,
    usersByReportingAuthorityList: {},
    error: "",
  };
  
  export const usersByReportingAuthorityListReducer = (state = initialState, action: action) => {
    switch (action.type) {
      case GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          usersByReportingAuthorityList: action.payload,
          error: "",
        };
      case GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          usersByReportingAuthorityList: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  