import { PAYMENT_STATUS } from "app/helpers/constants";
import {
  ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE,
  ADD_REMARKS_SUCCESS,
  CHANGE_BILL_STATUS_SUCCESS,
  CHANGE_TASK_ACTIVITY_STATUS_SUCCESS,
  CHANGE_TASK_STATUS_SUCCESS,
  DELETE_FILE_SUCCESS,
  DELETE_REMARKS_SUCCESS,
  EDIT_REMARKS_SUCCESS,
  GET_TASK_BY_ID_FAILURE,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
  TASK_ADD_FOR_CHECKING_SUCCESS,
  UPLOAD_FILE_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";
import { response } from "app/helpers/commonInterface";

export interface taskDataState {
  loading: boolean;
  task: response<taskData>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: taskDataState = {
  loading: false,
  task: {},
  error: "",
};

export const getTaskByIdReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_TASK_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_BY_ID_SUCCESS:
      return {
        loading: false,
        task: action.payload,
        error: "",
      };
    case GET_TASK_BY_ID_FAILURE:
      return {
        loading: false,
        task: {},
        error: action.payload,
      };
    case DELETE_FILE_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Files: state.task.data.records.Files.filter(
                (file: any) => file.Id !== action.payload.id
              ),
              Changes: [
                ...state.task.data.records.Changes,
                action.payload.changes,
              ],
            },
          },
        },
      };
    case UPLOAD_FILE_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Files: [
                ...state.task.data.records.Files,
                action.payload.data.records,
              ],
              Changes: [
                ...state.task.data.records.Changes,
                action.payload.data.records.Changes[0],
              ],
            },
          },
        },
      };
    case ADD_REMARKS_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Remarks: [
                ...state.task.data.records.Remarks,
                action.payload.data.records,
              ],
            },
          },
        },
      };
    case EDIT_REMARKS_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Remarks: [
                ...state.task.data.records.Remarks.filter(
                  (file: any) => file.Id !== action.payload.data.records.Id
                ),
                action.payload.data.records,
              ],
            },
          },
        },
      };
    case DELETE_REMARKS_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Remarks: state.task.data.records.Remarks.filter(
                (file: any) => file.Id !== action.payload
              ),
            },
          },
        },
      };
    case CHANGE_TASK_STATUS_SUCCESS:
      if (!state.task.data?.records) return state;
      if (action.payload.log.data.records[0]) {
        return {
          ...state,
          task: {
            ...state.task,
            data: {
              ...state.task.data,
              records: {
                ...state.task.data.records,
                StatusValue: action.payload.status,
                Changes: [
                  ...state.task.data.records.Changes,
                  action.payload.log.data.records[0],
                ],
                ReviewMessage: "",
              },
            },
          },
        };
      } else {
        return state;
      }
    case ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Changes: [
                ...state.task.data.records.Changes,
                action.payload.data.records[0],
              ],
            },
          },
        },
      };
    case CHANGE_TASK_ACTIVITY_STATUS_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Activities: action.payload.data.records.Activities,
              StatusValue: action.payload.data.records.StatusValue,
            },
          },
        },
      };
    case TASK_ADD_FOR_CHECKING_SUCCESS:
      if (!state.task.data?.records) return state;
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            records: {
              ...state.task.data.records,
              Changes: [
                ...state.task.data.records.Changes,
                action.payload.data.records.Changes,
              ],
              StatusValue: action.payload.data.records.StatusValue,
              ReviewObj: {
                ...action.payload.data.records,
                Review: action.payload.data.records.Changes.Review,
                Id: action.payload.data.records.Changes.Id,
              },
            },
          },
        },
      };

    case CHANGE_BILL_STATUS_SUCCESS:
      if (
        state.task?.data?.records &&
        String(state.task?.data?.records.Id) ===
          String(action.payload.taskId[0])
      ) {
        if (
          PAYMENT_STATUS["Fully Paid"] === Number(action.payload.billStatus)
        ) {
          return {
            ...state,
            task: {
              ...state.task,
              data: {
                ...state.task.data,
                records: {
                  ...state.task.data.records,
                  BillingStatus: action.payload.billStatus,
                  BillDetails: {
                    ...state.task.data.records.BillDetails,
                    PaidAmount: state.task.data.records.BillDetails.TotalAmount,
                  },
                },
              },
            },
          };
        }
        return {
          ...state,
          task: {
            ...state.task,
            data: {
              ...state.task.data,
              records: {
                ...state.task.data.records,
                BillingStatus: action.payload.billStatus,
              },
            },
          },
        };
      } else return state;
    default:
      return state;
  }
};
