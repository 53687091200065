import {
  ADD_CHAT_LIST_DATA_SOCKET_STATE,
  ADD_DATA_SOCKET_STATE,
  ADD_SOCKET_INSTANCE,
} from "../actionTypes";

export interface socketChatState {
  companyId: number;
  taskId: string;
  CreatedAt: Date;
  Type?: string;
  FirstName: string;
  LastName: string;
}

export interface socketDataChangeState {
  companyId: number;
  Type?: string;
  IsMasterDataChange: boolean;
}

export interface socketData {
  chatMessage: Map<string, socketChatState[]>;
  dataChangeMessage: socketDataChangeState;
  socket: WebSocket | null;
}

interface action {
  type: string;
  payload: any;
}

const initialState: socketData = {
  chatMessage: new Map<string, []>(),
  dataChangeMessage: { companyId: 0, Type: "", IsMasterDataChange: false },
  socket: null,
};

export const websocketReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case ADD_SOCKET_INSTANCE:
      return { ...state, socket: action.payload };
    case ADD_DATA_SOCKET_STATE:
      if ("IsMasterDataChange" in action.payload) {
        return { ...state, dataChangeMessage: action.payload };
      } else if ("taskId" in action.payload) {
        if (state.chatMessage.has(action.payload.taskId)) {
          const oldChat = state.chatMessage.get(action.payload.taskId) || [];
          return {
            ...state,
            chatMessage: state.chatMessage.set(action.payload.taskId, [
              ...oldChat,
              action.payload,
            ]),
          };  
        } else {
          return {
            ...state,
            chatMessage: state.chatMessage.set(action.payload.taskId, [
              action.payload,
            ]),
          };
        }
      }
      return state;
    case ADD_CHAT_LIST_DATA_SOCKET_STATE:
      return {
        ...state,
        chatMessage: state.chatMessage.set(
          action.payload.taskId,
          action.payload.chatList
        ),
      };
    default:
      return state;
  }
};
