import { addTaskTypeValue } from "../../components/pages/taskType/TaskTypeForm";
import { searchParams } from "../../helpers/commonInterface";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addTaskTypeService,
  getTaskTypeListService,
  getTaskTypeService,
  updateTaskTypeService,
  updateTaskTypeStatusService,
} from "./taskTypeService";
import {
  ADD_TASK_TYPE_FAILURE,
  ADD_TASK_TYPE_REQUEST,
  ADD_TASK_TYPE_SUCCESS,
  GET_TASK_TYPE_BY_ID_FAILURE,
  GET_TASK_TYPE_BY_ID_REQUEST,
  GET_TASK_TYPE_BY_ID_SUCCESS,
  GET_TASK_TYPE_LIST_FAILURE,
  GET_TASK_TYPE_LIST_REQUEST,
  GET_TASK_TYPE_LIST_SUCCESS,
  UPDATE_TASK_TYPE_FAILURE,
  UPDATE_TASK_TYPE_REQUEST,
  UPDATE_TASK_TYPE_STATUS_FAILURE,
  UPDATE_TASK_TYPE_STATUS_REQUEST,
  UPDATE_TASK_TYPE_STATUS_SUCCESS,
  UPDATE_TASK_TYPE_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addTaskType = (
  data: addTaskTypeValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_TASK_TYPE_REQUEST));

    await addTaskTypeService(data).then(
      (result: any) => {
        dispatch(success(ADD_TASK_TYPE_SUCCESS, result));
        showToastMessageSuccess("Task Type added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.TASK_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_TASK_TYPE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getTaskTypeList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_TASK_TYPE_LIST_REQUEST));

    getTaskTypeListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_TASK_TYPE_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TASK_TYPE_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getTaskType = (typeId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_TASK_TYPE_BY_ID_REQUEST));

    getTaskTypeService(typeId).then(
      (result: any) =>
        dispatch(success(GET_TASK_TYPE_BY_ID_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TASK_TYPE_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const updateTaskType = (
  data: addTaskTypeValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_TASK_TYPE_REQUEST));

    await updateTaskTypeService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_TASK_TYPE_SUCCESS, result.data));
        showToastMessageSuccess("Type updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.TASK_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_TASK_TYPE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const updateTaskTypeStatus = (
  typeId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_TASK_TYPE_STATUS_REQUEST));

    updateTaskTypeStatusService(typeId).then(
      (result: any) => {
        dispatch(success(UPDATE_TASK_TYPE_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Type status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.TASK_TYPES,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_TASK_TYPE_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
