import StatusCell from "app/components/commonComponent/table/StatusCell";
import { CLIENT_REPORT_DETAILS } from "app/helpers/routes";
import { client } from "app/reducers/client/clientDataInterfce";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

interface props {
  row: client;
}

const ClientReportTableRow: FC<props> = ({ row }) => {
  const contacts = useMemo(() => row.ContactPersons.slice(0, 2), []);
  return (
    <tr>
      <td className="text-dark fw-bold ps-3">
        <Link
          className="text-dark text-hover-primary"
          to={`${CLIENT_REPORT_DETAILS}/${row.Id}`}
        >
          {row?.Name || "-"}
        </Link>
      </td>
      <td className="">{row?.GroupName || "-"}</td>
      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td className="pe-5">
        {contacts.length ? (
          <div className="align-items-center d-flex flex-column flex-sm-row gap-6">
            {contacts.map((contact) => (
              <div className="w-100">
                <div>{contact.Name}</div>
                <div>
                  <a href={`tel:${contact.MobileNumber}`}>
                    {contact.MobileNumber}
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};

export default ClientReportTableRow;
