import { FC } from "react"

interface props {
    isActive: boolean
}

const StatusCell: FC<props> = ({ isActive }) => {
    return (
        <span className={`fs-7 badge ${isActive ? "badge-light-success" : "badge-light-danger"}`}>
            {isActive ? "Active" : "Deactive"}
        </span>
    )
}

export default StatusCell