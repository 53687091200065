import { WHATSAPP_TEMPLATE_DATA_FAILURE, WHATSAPP_TEMPLATE_DATA_REQUEST, WHATSAPP_TEMPLATE_DATA_SUCCESS } from "../actionTypes"

export interface taskListState {
    loading  : boolean,
    wpTemplateList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:taskListState = {
    loading  : false,
    wpTemplateList : {},
    error : ""
}

export const whatsappTemplateDataListReducer = (state = initialState, action : action) => {
    switch(action.type){
        case WHATSAPP_TEMPLATE_DATA_REQUEST : return {
            loading : true
        }
        case WHATSAPP_TEMPLATE_DATA_SUCCESS : return {
            loading : false,
            wpTemplateList : action.payload,
            error : ""
        }
        case WHATSAPP_TEMPLATE_DATA_FAILURE : return {
            loading : false,
            wpTemplateList : {},
            error : action.payload
        }
        default : return state
    }
}