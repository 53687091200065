import {EDIT_EVENING_REPORTING_REQUEST, EDIT_EVENING_REPORTING_SUCCESS, EDIT_EVENING_REPORTING_FAILURE } from "../actionTypes"

interface editEveningReportingState {
    loading  : boolean,
    editEveningReporting : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:editEveningReportingState = {
    loading  : false,
    editEveningReporting: {},
    error : ""
}

export const editEveningReportingReducer = (state = initialState, action : action) => {
    switch(action.type){
        case EDIT_EVENING_REPORTING_REQUEST : return {
            ...state,
            loading : true
        }
        case EDIT_EVENING_REPORTING_SUCCESS : return {
            ...state,
            loading : false,
            editEveningReporting : 'Task updated successfully',
            error : ""
        }
        case EDIT_EVENING_REPORTING_FAILURE : return {
            ...state,
            loading : false,
            editEveningReporting : 'Task not updated',
            error : action.payload
        }
        default : return state
    }
}