import { connect } from "react-redux"
import { FC, useState } from "react"

import { changeTextToFirstUppercase } from "app/helpers/helperFunction"
import EditProfile from "../EditProfile"
import { profileState } from "app/reducers/profile/profileReducer"
import { showForm } from "./UserDetails"

interface props {
    profileDetails: profileState
}

const BankDetails: FC<props> = ({ profileDetails }) => {
    const [showForm, setShowForm] = useState<showForm>({ profileForm: false });

    return (
        <div className="card px-7 py-4">
            <div className="d-flex justify-content-between align-items-center py-3">
                <div className="text-gray-800 fs-2 fw-bold ">Bank Details</div>
                <div>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => setShowForm({ profileForm: !showForm.profileForm })}
                    >
                        Edit Bank Details
                    </button>
                </div>
            </div>
            {!showForm.profileForm &&
                <div className="border-top border-top-1 pt-5">
                    <div className="row gap-3">
                        <div className='col-12 col-lg-6 col-md-5 col-sm-6 col-xxl-4'>
                            <div className='d-flex gap-2 mb-3'>
                                <span className="col-4 text-muted fw-bold me-2">Bank Name</span>
                                <span className="col-8 fw-bold fs-6 me-5">
                                    {profileDetails.profileData?.BankName}
                                </span>
                            </div>
                            <div className='d-flex gap-2 mb-3'>
                                <span className="col-4 text-muted fw-bold me-2">Branch Name</span>
                                <span className="col-8 fw-bold fs-6 me-5">
                                    {profileDetails.profileData?.BranchName}
                                </span>
                            </div>
                            <div className='d-flex gap-2'>
                                <span className="col-4 text-muted fw-bold me-2">IFSCCode</span>
                                <span className="col-8 fw-bold fs-6 me-5">
                                    {profileDetails.profileData?.IFSCCode}
                                </span>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 col-md-5 col-sm-6 col-xxl-4'>
                            <div className='d-flex gap-2 mb-3'>
                                <span className="col-4 text-muted fw-bold me-2">Account Number</span>
                                <span className="col-8 fw-bold fs-6 me-5">
                                    {profileDetails.profileData?.AccountNumber}
                                </span>
                            </div>
                            <div className='mb-3 d-flex gap-2'>
                                <span className="col-4 text-muted fw-bold me-2">Account Type</span>
                                <span className="col-8 fw-bold fs-6 me-5">
                                    {changeTextToFirstUppercase(profileDetails.profileData?.AccountType || "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showForm.profileForm &&
                <EditProfile setShowForm={setShowForm} component="bank" />
            }
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileDetails: state.profile
    }
}

export default connect(mapStateToProps)(BankDetails)