import { connect } from "react-redux"
import { FC, useState } from "react"

import Avatar from "app/components/commonComponent/Avatar"
import { convert, getColor, getRole } from "app/helpers/helperFunction"
import EditProfile from "../EditProfile"
import { KTSVG } from "_metronic/helpers"
import { profileState } from "app/reducers/profile/profileReducer"

export interface showForm {
    profileForm: boolean
}

interface props {
    profileDetails: profileState
}

const UserDetails: FC<props> = ({ profileDetails }) => {
    const [showForm, setShowForm] = useState<showForm>({ profileForm: false });

    return (
        <div className='card px-7 py-4 mb-3 gap-2'>
            <div className='d-flex flex-column flex-sm-row gap-5'>
                <div className=''>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <Avatar
                            firstName={profileDetails.profileData?.FirstName}
                            lastName={profileDetails.profileData?.LastName}
                            size={140}
                            color={getColor(profileDetails.profileData?.LastName).color}
                            background={getColor(profileDetails.profileData?.LastName).background}
                        />
                    </div>
                </div>
                {!showForm.profileForm && <div className='w-100'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='text-gray-800 fs-2 fw-bolder me-1'>
                                    {profileDetails.profileData?.FirstName} {profileDetails.profileData?.LastName}
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => setShowForm({ profileForm: !showForm.profileForm })}
                            >
                                Edit Profile
                            </button>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap gap-sm-4 mb-2">
                        <div className='d-flex flex-wrap fw-bold fs-6 mb-3 align-items-center me-3'>
                            {profileDetails.profileData?.Roles && <div
                                className='d-flex align-items-center'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/communication/com006.svg'
                                    className='svg-icon-4 me-2'
                                />
                                <div className="fw-bold fs-5">{getRole(profileDetails.profileData?.Roles)}</div>
                            </div>}
                        </div>
                        <div className="mb-3 d-flex flex-row flex-wrap align-items-baseline me-3">
                            <i className="fa-solid fa-envelope me-2"></i>
                            <div className="fw-bold fs-5">{profileDetails.profileData?.Email}</div>
                        </div>
                        {!profileDetails.profileData?.Roles?.includes("Master_Admin") &&
                            <div className="mb-3 d-flex align-items-center align-items-center me-3">
                                <i className="fa-solid fa-building me-2"></i>
                                <div className="fw-bold fs-5">{profileDetails.profileData?.CompanyName}</div>
                            </div>
                        }
                    </div>
                    <div className=''>
                        <div className='d-flex gap-2 mb-3 align-items-center'>
                            <span className="col-4 col-lg-3 col-md-2 col-xl-2 fw-bold me-2 text-muted">Phone Number</span>
                            <span className="col-8 fw-bold fs-6 me-5">
                                {profileDetails.profileData?.PhoneNumber}
                            </span>
                        </div>
                        <div className='d-flex mb-3 gap-2 align-items-center'>
                            <span className="col-4 col-lg-3 col-md-2 col-xl-2 fw-bold me-2 text-muted">Registration Date</span>
                            <span className="col-8 fw-bold fs-6 me-5">
                                {convert(profileDetails.profileData?.RegistrationDate)}
                            </span>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <span className="col-4 col-lg-3 col-md-2 col-xl-2 fw-bold me-2 text-muted">Joining Date</span>
                            <span className="col-8 fw-bold fs-6 me-5">
                                {convert(profileDetails.profileData?.JoiningDate)}</span>
                        </div>
                    </div>
                </div>}
                {
                    showForm.profileForm &&
                    <EditProfile setShowForm={setShowForm} component="user" />
                }
            </div>

        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileDetails: state.profile
    }
}

export default connect(mapStateToProps)(UserDetails)