import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddTaskType from "./component/AddTaskType";
import {
  addTaskType,
  getTaskType,
  updateTaskType,
} from "../../../reducers/taskType/taskTypeAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";
import { updateMasterData } from "app/reducers/masterData/masterDataAction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  addTaskTypeDispatch: Function;
  updateTaskTypeDispatch: Function;
  getTaskTypeData: Function;
  taskTypeData: any;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

export interface editActivityObject {
  Id?: number;
  IsActive?: boolean;
  Name: string;
  TaskTypeId?: number;
  IsMandatory: boolean;
  RequireCompletionField: boolean;
  RequireChecking: boolean;
  CompletionFieldName: string;
  CompletionFieldType: string;
  MinValue: number;
}
export interface activityObject {
  id?: number;
  isActive?: boolean;
  name: string;
  taskTypeId?: number;
  isMandatory: boolean;
  requireCompletionField: boolean;
  completionFieldName: string;
  completionFieldType: string;
  minValue: number;
}

export interface addTaskTypeValue {
  id?: number;
  isActive?: boolean;
  name: string;
  hoursToComplete: string;
  idealDaysToComplete: string;
  categoryId: number;
  defaultAssigneeId: string;
  defaultManagerId: string;
  defaultExpertId: string;
  isRepeatative: string;
  requireConfirmation: boolean;
  standardFees: string;
  repeatation: string;
  governmentFixDate: string | Date;
  activities: Array<activityObject>;
  onboardBefore: string;
  changeExistsUser?: boolean;
  changeExistsManager?: boolean;
  changeExistsExpert?: boolean;
}
export interface editTaskTypeValue {
  Id: number;
  IsActive: boolean;
  Name: string;
  HoursToComplete: string;
  IdealDaysToComplete: string;
  CategoryId: number;
  CategoryName: string;
  DefaultAssigneeId: string;
  DefaultAssigneeName: string;
  DefaultManagerId: string;
  DefaultManagerName: string;
  DefaultExpertId: string;
  DefaultExpertName: string;
  IsRepeatative: boolean;
  RequireConfirmation: boolean;
  StandardFees: string;
  Repeatation: string;
  GovernmentFixDate: string;
  Activities: Array<editActivityObject>;
  OnboardBefore: string;
}

const initialValue: addTaskTypeValue = {
  name: "",
  hoursToComplete: "",
  idealDaysToComplete: "",
  categoryId: 0,
  defaultAssigneeId: "",
  defaultManagerId: "",
  defaultExpertId: "",
  isRepeatative: "",
  requireConfirmation: true,
  standardFees: "",
  repeatation: "",
  governmentFixDate: "",
  activities: [],
  onboardBefore: "",
  changeExistsUser: false,
  changeExistsManager: false,
  changeExistsExpert: false,
};

const getActivityData = (
  activityData: Array<editActivityObject>
): Array<activityObject> => {
  return activityData.map((activity: editActivityObject) => {
    return {
      id: activity.Id,
      isActive: activity.IsActive,
      name: activity.Name,
      taskTypeId: activity.TaskTypeId,
      isMandatory: activity.IsMandatory,
      requireCompletionField: activity.RequireCompletionField,
      requireChecking: activity.RequireChecking,
      completionFieldName: activity.CompletionFieldName,
      completionFieldType: activity.CompletionFieldType,
      minValue: activity.MinValue,
    };
  });
};

const getValueForEdit = (taskTypedata: editTaskTypeValue): addTaskTypeValue => {
  return {
    id: taskTypedata.Id,
    isActive: taskTypedata.IsActive,
    name: taskTypedata.Name,
    hoursToComplete: taskTypedata.HoursToComplete,
    idealDaysToComplete: taskTypedata.IdealDaysToComplete,
    categoryId: taskTypedata.CategoryId,
    defaultAssigneeId: taskTypedata.DefaultAssigneeId,
    defaultManagerId: taskTypedata.DefaultManagerId,
    defaultExpertId: taskTypedata.DefaultExpertId,
    isRepeatative: String(taskTypedata.IsRepeatative),
    requireConfirmation: taskTypedata.RequireConfirmation,
    standardFees: taskTypedata.StandardFees,
    repeatation: taskTypedata.Repeatation,
    governmentFixDate: taskTypedata.GovernmentFixDate,
    onboardBefore: taskTypedata.OnboardBefore,
    activities: getActivityData(taskTypedata.Activities),
    changeExistsUser: false,
    changeExistsManager: false,
    changeExistsExpert: false,
  };
};

const TaskTypeForm: FC<props> = ({
  masterDataList,
  addTaskTypeDispatch,
  taskTypeData,
  updateTaskTypeDispatch,
  getTaskTypeData,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getTaskTypeData(params.id);
    }
  }, [getTaskTypeData, params.id]);

  return (
    <ErrorBoundary>
      {params.id && taskTypeData.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && taskTypeData.error && (
        <ErrorDisplay error={taskTypeData.error} />
      )}
      {(params.id ? taskTypeData?.taskType?.success : true) && (
        <>
          {params.id && (
            <AddTaskType
              initialValue={getValueForEdit(
                taskTypeData?.taskType?.data?.records
              )}
              taskTypeDispatch={updateTaskTypeDispatch}
              title="Edit Task Type"
              taskTypeDetails={taskTypeData?.taskType?.data?.records}
              masterDataList={masterDataList}
            />
          )}
          {!params.id && (
            <AddTaskType
              initialValue={initialValue}
              taskTypeDispatch={addTaskTypeDispatch}
              title="Add Task Type"
              masterDataList={masterDataList}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    taskTypeData: state.taskType,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTaskTypeDispatch: (data: addTaskTypeValue, successCallback: Function) =>
      dispatch(addTaskType(data, successCallback)),
    updateTaskTypeDispatch: (
      data: addTaskTypeValue,
      successCallback: Function
    ) => dispatch(updateTaskType(data, successCallback)),
    getTaskTypeData: (typeId: number) => dispatch(getTaskType(typeId)),
    updateMasterDataDispatch: (
      type: string,
      payloadData: { masterType: string; data: any }
    ) => dispatch(updateMasterData(type, payloadData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypeForm);
