import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import DropDown from "../dropDown/DropDown";
import { getSearchParameter } from "../../../helpers/helperFunction";

type props = {
  pageSize: string;
};

const OPTIONS = [
  { value: 10, name: "10" },
  { value: 30, name: "30" },
  { value: 50, name: "50" },
  { value: 80, name: "80" },
  { value: 100, name: "100" }
]
const ItemsPerPage: FC<props> = ({ pageSize }) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(() => getSearchParameter(searchParam), [searchParam]);


  const handlePageSize = async (e: any) => {
    setSearchParam({ ...searchObj, PageSize: e.target.dataset.id, PageNumber: "1" });
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <DropDown
        className="form-select form-select-sm form-select-solid"
        placeholder='PageSize'
        options={OPTIONS}
        name="pagesize"
        apiCallDispatch={handlePageSize}
        defaultValue={{ value: pageSize || 10, name: pageSize || "10" }}
      />
    </div>
  );
};

export default ItemsPerPage;
