import {
    ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST, SELECT_ALL_DOCUMENT_CHECKBOX, SELECT_DOCUMENT_CHECKBOX,
    UNSELECT_ALL_DOCUMENT_CHECKBOX, UNSELECT_DOCUMENT_CHECKBOX
} from "../actionTypes"


export interface selectedDocumentState {
    selected: Array<number>
    isCheckedAll: boolean
    documentListData: Array<any>
}

interface action {
    type: string
    payload: any
}

const initialState: selectedDocumentState = {
    selected: [],
    isCheckedAll: false,
    documentListData: []
}

export const clientDocumentSelectionReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case SELECT_DOCUMENT_CHECKBOX: return {
            ...state,
            selected: [...state.selected, action.payload],
            isCheckedAll: Number(state.selected.length) + 1 === Number(state.documentListData.length)
        }
        case SELECT_ALL_DOCUMENT_CHECKBOX: return {
            ...state,
            selected: state.documentListData.map((document: any) => document.Id),
            isCheckedAll: true
        }
        case UNSELECT_ALL_DOCUMENT_CHECKBOX: return {
            ...state,
            selected: [],
            isCheckedAll: false
        }
        case UNSELECT_DOCUMENT_CHECKBOX: return {
            ...state,
            selected: state.selected.filter((id: number) => id !== Number(action.payload)),
            isCheckedAll: Number(state.selected.length - 1) === Number(state.documentListData.length)
        }
        case ADD_PARENT_DATA_FOR_SELECTED_DOCUMENT_LIST: return {
            ...state,
            documentListData: action.payload.data.records,
        }
        default: return state
    }
}