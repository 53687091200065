import { FC, useRef, useState } from "react"

import RegisteredTemplatePreview from "./RegisteredTemplatePreview"
import { useOutsideAlerter } from "app/helpers/customHooks";

interface props {
    row: any
}

export const getClassName = (status: string) => {
    switch (status) {
        case "PENDING": return "warning"
        case "APPROVED": return "success"
        case "REJECTED": return "danger"
    }
}

const RegisteredTemplateTableRow: FC<props> = ({ row }) => {
    const [showPreview, setShowPreview] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setShowPreview);

    return (
        <tr>
            <td className='text-dark fw-bold ps-2'>{row?.name || "-"}</td>
            <td className=''>{row?.category.charAt(0) + row?.category.slice(1).toLowerCase() || "-"}</td>
            <td>
                <span className={`badge badge-light-${getClassName(row?.status)}`}>
                    {row?.status.charAt(0) + row?.status.slice(1).toLowerCase() || "-"}
                </span>
            </td>
            <td className=''>
                <div className="position-relative" ref={wrapperRef}>
                    <button className="btn btn-light btn-active-light-primary btn-sm"
                        onClick={() => setShowPreview(!showPreview)}>
                        View
                    </button>
                    {showPreview && <RegisteredTemplatePreview templateData={row.components} />}
                    {/* <div className={`pop-up-overlay ${showPreview ? "" : "hidden"}`}
                        onClick={() => setShowPreview(false)}></div> */}
                </div>
            </td>
        </tr>
    )
}

export default RegisteredTemplateTableRow