import { response } from "app/helpers/commonInterface";
import {
  GET_CLIENT_REPORT_DETAILS_FAILURE,
  GET_CLIENT_REPORT_DETAILS_REQUEST,
  GET_CLIENT_REPORT_DETAILS_SUCCESS,
} from "../actionTypes";
import { client } from "./clientDataInterfce";

export interface clientDetailsState {
  loading: boolean;
  clientDetails: response<client>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: clientDetailsState = {
  loading: false,
  clientDetails: {},
  error: "",
};

export const getClientReportDetailsByIdReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_CLIENT_REPORT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_CLIENT_REPORT_DETAILS_SUCCESS:
      return {
        loading: false,
        clientDetails: action.payload,
        error: "",
      };
    case GET_CLIENT_REPORT_DETAILS_FAILURE:
      return {
        loading: false,
        clientDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
