import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import { searchParams } from "app/helpers/commonInterface";
import {
  addProformaInvoiceService,
  convertFinalProformaInvoiceService,
  getProformaInvoiceListService,
  getProformaInvoiceService,
  updateProformaInvoiceService,
} from "./proformaInvoiceService";
import {
  ADD_PROFORMA_INVOICE_FAILURE,
  ADD_PROFORMA_INVOICE_REQUEST,
  ADD_PROFORMA_INVOICE_SUCCESS,
  FINAL_PROFORMA_INVOICE_FAILURE,
  FINAL_PROFORMA_INVOICE_REQUEST,
  FINAL_PROFORMA_INVOICE_SUCCESS,
  GET_PROFORMA_INVOICE_FAILURE,
  GET_PROFORMA_INVOICE_LIST_FAILURE,
  GET_PROFORMA_INVOICE_LIST_REQUEST,
  GET_PROFORMA_INVOICE_LIST_SUCCESS,
  GET_PROFORMA_INVOICE_REQUEST,
  GET_PROFORMA_INVOICE_SUCCESS,
  UPDATE_PROFORMA_INVOICE_FAILURE,
  UPDATE_PROFORMA_INVOICE_REQUEST,
  UPDATE_PROFORMA_INVOICE_SUCCESS,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";

export const addProformaInvoice = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_PROFORMA_INVOICE_REQUEST));

    await addProformaInvoiceService(data).then(
      (result: any) => {
        // dispatch(success(ADD_BILL_SUCCESS, result.data))
        dispatch(success(ADD_PROFORMA_INVOICE_SUCCESS, result));
        showToastMessageSuccess("Proforma invoice added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_PROFORMA_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getProformaInvoiceList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_PROFORMA_INVOICE_LIST_REQUEST));

    getProformaInvoiceListService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_PROFORMA_INVOICE_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_PROFORMA_INVOICE_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getProformaInvoice = (invoiceId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_PROFORMA_INVOICE_REQUEST));

    getProformaInvoiceService(invoiceId).then(
      (result: any) =>
        dispatch(success(GET_PROFORMA_INVOICE_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_PROFORMA_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateProformaInvoice = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_PROFORMA_INVOICE_REQUEST));

    await updateProformaInvoiceService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_PROFORMA_INVOICE_SUCCESS, result.data));
        showToastMessageSuccess("Proforma invoice updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_PROFORMA_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const convertToFinalProformaInvoice = (
  billId: number,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(FINAL_PROFORMA_INVOICE_REQUEST));

    await convertFinalProformaInvoiceService(billId).then(
      (result: any) => {
        dispatch(success(FINAL_PROFORMA_INVOICE_SUCCESS, result.data));
        showToastMessageSuccess("Final invoice generated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            FINAL_PROFORMA_INVOICE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
