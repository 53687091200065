import { connect } from "react-redux";
import { FC, useEffect } from "react";

import DocumentTypeTableRow from "./DocumentTypeTableRow";
import { getDocumentTypeList } from "../../../../reducers/documentType/documentTypeAction";
import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  getDocumentList: Function;
  documentTypeDetails: any;
}

const HEADERS = [
  {
    columnName: "Document Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Time Period",
    id: "Repeatation",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end pe-5",
  },
];

const DocumentTypeTable: FC<props> = ({
  documentTypeDetails,
  getDocumentList,
}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getDocumentList(searchObj);
  }, [getDocumentList, searchObj]);

  return (
    <>

      <Table
        checkBoxColumn={{ show: false }}
        error={documentTypeDetails.error}
        headers={HEADERS}
        success={documentTypeDetails.documentTypeList?.success}
        loading={documentTypeDetails.loading}
        paginate={{ show: false }}
        tableData={documentTypeDetails.documentTypeList?.data?.records}
        tableRow={(row: any, index: number) => (
          <DocumentTypeTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    documentTypeDetails: state.documentTypeList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDocumentList: (searchObj: any) =>
      dispatch(getDocumentTypeList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeTable);
