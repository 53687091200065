import {
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  getDataFromFilterQueryString,
  getSearchParameter,
} from "./helperFunction";

//close pop up on outside click
export function useOutsideAlerter(
  ref: RefObject<HTMLDivElement>,
  setPopupState: Function
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setPopupState(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setPopupState]);
}

//screen width
export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    function updateScreenWidth() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);
  return screenWidth;
}

export function useGetSearchObject() {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );

  return { setSearchParam, searchObj };
}

export function useGetFilterObject() {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const filterObj = getDataFromFilterQueryString(searchObj.Filters);

  return { setSearchParam, filterObj, searchObj };
}
