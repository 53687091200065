import { GET_EVENING_REPORTING_FAILURE, GET_EVENING_REPORTING_SUCCESS, GET_EVENING_REPORTING_REQUEST, DELETE_EVENING_REPORTING_REQUEST, DELETE_EVENING_REPORTING_SUCCESS, DELETE_EVENING_REPORTING_FAILURE } from "../actionTypes"

interface eveningReportingListState {
    deleteLoading:boolean,
    loading  : boolean,
    eveningReportingList : any,
    error : string
}

interface action {
    type : string
    payload : any
}

const initialState:eveningReportingListState = {
    deleteLoading:false,
    loading  : false,
    eveningReportingList : {},
    error : ""
}

export const eveningReportingReducer = (state = initialState, action : action) => {
    switch(action.type){
        case GET_EVENING_REPORTING_REQUEST : return {
            ...state,
            loading : true
        }
        case GET_EVENING_REPORTING_SUCCESS : return {
            ...state,
            loading : false,
            eveningReportingList : action.payload,
            error : ""
        }
        case GET_EVENING_REPORTING_FAILURE : return {
            ...state,
            loading : false,
            eveningReportingList : {},
            error : action.payload
        }

        case DELETE_EVENING_REPORTING_REQUEST : return {
            ...state,
            deleteLoading : true
        }
        case DELETE_EVENING_REPORTING_SUCCESS : return {
            ...state,
            deleteLoading : false,
            eveningReportingList : action.payload,
            error : ""
        }
        case DELETE_EVENING_REPORTING_FAILURE : return {
            ...state,
            deleteLoading : false,
            eveningReportingList : {},
            error : action.payload
        }
        default : return state
    }
}