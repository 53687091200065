import { connect } from "react-redux";
import { FC, useEffect, useState } from "react";

import BillingTableRow from "./BillingTableRow";
import BillingForm from "../BillingForm";
import { getBillList } from "app/reducers/billing/billingAction";
import { searchParams } from "app/helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { billListState } from "app/reducers/billing/getBillListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  billingDetails: billListState;
  getBillingDetails: Function;
}

const HEADERS = [
  {
    columnName: "Invoice No",
    id: "BillNumber",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Task",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Firm",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Total Amount",
    id: "TotalAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Paid Amount",
    id: "PaidAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Invoice Date",
    id: "Date",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "PaymentStatus",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end",
  },
];

const BillingTable: FC<props> = ({ billingDetails, getBillingDetails }) => {
  const { searchObj } = useGetSearchObject();
  const [bill, setBill] = useState({ show: false, edit: false, billId: null });

  const closeModal = () => {
    setBill({ show: false, billId: null, edit: true });
  };

  useEffect(() => {
    getBillingDetails(searchObj);
  }, [getBillingDetails, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={billingDetails.error}
        headers={HEADERS}
        success={billingDetails.billList?.success}
        loading={billingDetails.loading}
        paginate={{
          show: true,
          pagination: billingDetails.billList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={billingDetails.billList?.data?.records}
        tableRow={(row: any, index: number) => (
          <BillingTableRow
            row={row}
            key={`row-${index}-${row.id}`}
            setBill={setBill}
          />
        )}
      />
      <BillingForm bill={bill} closeModal={closeModal} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    billingDetails: state.billList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBillingDetails: (searchObj: searchParams) =>
      dispatch(getBillList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingTable);
