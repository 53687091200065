import { KTSVG } from "_metronic/helpers"
import { searchParams } from "app/helpers/commonInterface";
import { deleteEveningReporting, deleteMorningPlanner, deleteUsersEveningReporting, deleteUsersMorningPlanner } from "app/reducers/morningPlanner/plannerAction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";


// type means :- 1- delete morning planner,2- delete Evening Reporting
interface props {
    id: any,
    userId:string,
    type:number,
    deleteMorningPlannerDispatch:Function,
    deleteUsersMorningPlannerDispatch:Function,
    deleteEveningReportingDispatch:Function,
    deleteUsersEveningReportingDispatch:Function
}
const DeletePlanner = ({
    id,
    userId,
    type,
    deleteMorningPlannerDispatch,
    deleteEveningReportingDispatch,
    deleteUsersMorningPlannerDispatch,
    deleteUsersEveningReportingDispatch
}:props) => {

    
const handleDelete = () => {
    // Your delete logic here
    switch(type) {
        case 1:
            deleteMorningPlannerDispatch(id, { type: type, userId: userId });
            break;
        case 2:
            deleteEveningReportingDispatch(id, { type: type, userId: userId });
            break;
        case 3:
            deleteUsersMorningPlannerDispatch(id, { type: type, userId: userId });
            break;
        case 4:
            deleteUsersEveningReportingDispatch(id, { type: type, userId: userId });
            break;
        default:
      
    }
};
    return (
        <>
            <button
                className={`btn btn-icon btn-bg-light btn-sm btn-active-light-danger `}
                // style={{ marginLeft: '10px' }}
                title="Delete"
                onClick={()=>handleDelete()}
            >
                <KTSVG
                    path="/media/icons/duotune/general/gen027.svg"
                    className="svg-icon-3"
                />
            </button>

        </>
    )
}


const mapStateToProps = (state: any) => {
    return {
        morningPlannerList:state.morningPlannerList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteMorningPlannerDispatch:(id:any,searchObj:searchParams)=>dispatch(deleteMorningPlanner(id,searchObj)),
        deleteUsersMorningPlannerDispatch:(id:any,searchObj:searchParams)=>dispatch(deleteUsersMorningPlanner(id,searchObj)),
        deleteEveningReportingDispatch:(id:any,searchObj:searchParams)=>dispatch(deleteEveningReporting(id,searchObj)),
        deleteUsersEveningReportingDispatch:(id:any,searchObj:searchParams)=>dispatch(deleteUsersEveningReporting(id,searchObj))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeletePlanner);
