import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddClient from "./component/AddClient";
import {
  addClient,
  getClientById,
  getLegalStatusList,
  updateClient,
} from "../../../reducers/client/clientAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";
import { getGroupList } from "../../../reducers/groups/groupAction";
import { getFilterQueryString } from "../../../helpers/helperFunction";
import { searchParams } from "../../../helpers/commonInterface";
import { useAuth } from "../auth";
import { workCategoryIdDataObject } from "../task/component/CustomDropdown";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { Search } from "_metronic/partials";

interface props {
  addClientDispatch: Function;
  updateClientDispatch: Function;
  getClientData: Function;
  clientDetail: any;
  getGroupDetails: Function;
  groupDetails: any;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  legalStatusList:any,
  getLegalStatusListDispatch:Function
}

export interface contact {
  name: string;
  email: string;
  phoneNumber: string;
  companyId: number | undefined;
  clientId?: number;
  id?: number;
  groupId?: number;
  isDefault?: boolean;
}

export interface clientContactPerson {
  id?: number;
  contactPerson: contact;
}

export interface addClientValue {
  id?: number;
  name: string;
  gstin: string;
  FirmName :string;
  groupId: number | null;
  Legal_Status:number;
  panNumber:string;
  companyId: number | undefined;
  clientContacts: Array<clientContactPerson>;
  workCategoryId: Array<workCategoryIdDataObject>;
  groupContacts: Array<clientContactPerson>;
}

const gteInitialValue = (companyId: number | undefined): addClientValue => {
  return {
    name: "",
    gstin: "",
    panNumber:"",
    groupId: null,
    Legal_Status:0,
    FirmName:"",
    companyId: companyId,
    clientContacts: [],
    workCategoryId: [],
    groupContacts: [],
  };
};

const getNewContactPersonObject = (
  companyId: number | undefined
): clientContactPerson => {
  return {
    contactPerson: {
      name: "",
      email: "",
      phoneNumber: "",
      companyId: companyId,
    },
  };
};

const getContactData = (contactData: any) => {
  return contactData.reduce(
    (acc: any, data: any) => {
      if (data?.GroupId) {
        return {
          ...acc,
          group: acc.group.concat({
            id: data.Id,
            contactPerson: {
              name: data.Name,
              email: data.Email,
              phoneNumber: data.MobileNumber,
              companyId: data.CompanyId,
              clientId: data.ClientId,
              id: data.ContactPersonId,
              isDefault: data.IsDefault,
              groupId: data.GroupId,
              isActive: data.IsActive,
            },
          }),
        };
      } else {
        return {
          ...acc,
          client: acc.client.concat({
            id: data.Id,
            contactPerson: {
              name: data.Name,
              email: data.Email,
              phoneNumber: data.MobileNumber,
              companyId: data.CompanyId,
              clientId: data.ClientId,
              id: data.ContactPersonId,
              isDefault: data.IsDefault,
              isActive: data.IsActive,
            },
          }),
        };
      }
    },
    { client: [], group: [] }
  );
};

const getValueForEdit = (
  clientData: any,
  companyId: number | undefined
): addClientValue => {
  const { group, client } = getContactData(clientData?.ContactPersons);
  console.log(clientData);
  return {
    id: clientData.Id,
    name: clientData.Name,
    gstin: clientData.GSTIN,
    groupId: clientData.GroupId,
    Legal_Status:clientData.Legal_Status,
    FirmName:clientData.FirmName,
    companyId: clientData.CompanyId,
    clientContacts: client,
    workCategoryId: [],
    groupContacts: group,
    panNumber : clientData.PanNumber
  };

  
};

const ClientForm: FC<props> = ({
  masterDataList,
  groupDetails,
  addClientDispatch,
  updateClientDispatch,
  clientDetail,
  getClientData,
  getGroupDetails,
  legalStatusList,
  getLegalStatusListDispatch
}) => {
  const params = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (params.id) {
      getClientData(params.id);
    }
    getGroupDetails({
      Paginate: false,
      Filters: getFilterQueryString({ IsActive: true }),
    });
  }, [params.id, getClientData, getGroupDetails]);

  useEffect(()=>{
    getLegalStatusListDispatch({IsActive : true})
  },[getLegalStatusListDispatch])
  return (
    <ErrorBoundary>
      {params.id && clientDetail.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && clientDetail.error && (
        <ErrorDisplay error={clientDetail.error} />
      )}
      {(params.id ? clientDetail.client?.success : true) && (
        <>
          {params.id && (
            <AddClient
              initialValue={getValueForEdit(
                clientDetail?.client?.data?.records,
                currentUser?.CompanyId
              )}
              legalStatusList={legalStatusList.legalStatusList}
              clientDataDispatch={updateClientDispatch}
              title="Edit Client"
              isUpdate={params.id}
              groupDetails={groupDetails.groupList?.data?.records}
              clientDetails={clientDetail?.client?.data?.records}
              newGroupContactPersonObject={getNewContactPersonObject(
                currentUser?.CompanyId
              )}
              masterDataList={masterDataList}
            />
          )}
          {!params.id && (
            <AddClient
              initialValue={gteInitialValue(currentUser?.CompanyId)}
              clientDataDispatch={addClientDispatch}
              title="Add Client"
              legalStatusList={legalStatusList.legalStatusList}
              isUpdate={params.id}
              groupDetails={groupDetails.groupList?.data?.records}
              newGroupContactPersonObject={getNewContactPersonObject(
                currentUser?.CompanyId
              )}
              masterDataList={masterDataList}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    clientDetail: state.client,
    groupDetails: state.groupList,
    masterDataList: state.masterData,
    legalStatusList : state.legalStatusList
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addClientDispatch: (data: addClientValue, successCallback: Function) =>
      dispatch(addClient(data, successCallback)),
    getClientData: (clientId: number) => dispatch(getClientById(clientId)),
    updateClientDispatch: (data: addClientValue, successCallback: Function) =>
      dispatch(updateClient(data, successCallback)),
    getGroupDetails: (searchObj: searchParams) =>
      dispatch(getGroupList(searchObj)),
    getLegalStatusListDispatch:(SearchObj:searchParams)=>
      dispatch(getLegalStatusList(SearchObj))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
