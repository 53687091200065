import {
  GET_FIRM_LIST_FAILURE,
  GET_FIRM_LIST_REQUEST,
  GET_FIRM_LIST_SUCCESS,
  UPDATE_FIRM_STATUS_SUCCESS,
} from "../actionTypes";
import { updateStatusFun } from "../stateHelperFunction";

export interface firmListState {
  loading: boolean;
  firmList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: firmListState = {
  loading: false,
  firmList: {},
  error: "",
};

export const getFirmListReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_FIRM_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_FIRM_LIST_SUCCESS:
      return {
        loading: false,
        firmList: action.payload,
        error: "",
      };
    case GET_FIRM_LIST_FAILURE:
      return {
        loading: false,
        firmList: {},
        error: action.payload,
      };
    case UPDATE_FIRM_STATUS_SUCCESS:
      return updateStatusFun(state, "firmList", action.payload.data.records.Id);
    default:
      return state;
  }
};
