import { ADD_EVENING_REPORTING_ENDPOINT, ADD_MORNING_PLANNER_ENDPOINT, DELTE_EVENING_REPORTING_ENDPOINT, DELTE_MORNING_PLANNER_ENDPOINT, EDIT_EVENING_REPORTING_ENDPOINT, EDIT_MORNING_PLANNER_ENDPOINT, GET_ALL_DAILY_MORNING_PLANNER_ENDPOINT, GET_DAILY_MORNING_PLANNER_ENDPOINT, GET_EVENING_REPORING_BY_ID_ENDPOINT, GET_MORNING_PLANNER_BY_ID_ENDPOINT, GET_MORNING_PLANNER_ENDPOINT, GET_USERS_EVENING_REPORTING_ENDPOINT, GET_USERS_MORNING_PLANNER_ENDPOINT, GET_USER_EVENING_PLANNER_ENDPOINT } from "app/helpers/config";
import { axiosInstance } from "../axiosInstance";
import { searchParams } from "app/helpers/commonInterface";
import { getSearchQueryString } from "app/helpers/helperFunction";

export const getMorningPlannerService = () => {
    return axiosInstance.get(
      `${GET_MORNING_PLANNER_ENDPOINT}`
    );
  };

  export const getDailyMorningPlannerService = (userId : string | null) => {
    let params = userId != null ? `?userId=${userId}` : '';
    return axiosInstance.get(`${GET_DAILY_MORNING_PLANNER_ENDPOINT}${params}`);
  };

  export const getAllDailyMorningPlannerService = (searchObj : searchParams) => {
    const queryString = getSearchQueryString(searchObj);
    return axiosInstance.get(
      `${GET_ALL_DAILY_MORNING_PLANNER_ENDPOINT}?${queryString}`
    );
  };

export const deleteMorningPlannerService = (id:any,searchObj:searchParams) => {
  const queryString = getSearchQueryString(searchObj);
    return axiosInstance.delete(
      `${DELTE_MORNING_PLANNER_ENDPOINT}/${id}?${queryString}`
    );
};

export const deleteEveningReportingService = (id:any,searchObj:searchParams) => {
  const queryString = getSearchQueryString(searchObj);
    return axiosInstance.delete(
      `${DELTE_EVENING_REPORTING_ENDPOINT}/${id}?${queryString}`
    );
};

  export const getMorningPlannerByIdService = (id:number) => {
    return axiosInstance.get(
      `${GET_MORNING_PLANNER_BY_ID_ENDPOINT}/${id}`
    );
  };

  export const getEveningReportingByIdService = (id:number) => {
    return axiosInstance.get(
      `${GET_EVENING_REPORING_BY_ID_ENDPOINT}/${id}`
    );
  };


export const getEveningReportingService = () => {
    return axiosInstance.get(
      `${GET_USER_EVENING_PLANNER_ENDPOINT}`
    );
  };

export const getUsersMorningPlannerService= (searchObj:searchParams) =>{
  const queryString = getSearchQueryString(searchObj);
    return axiosInstance.get(
        `${GET_USERS_MORNING_PLANNER_ENDPOINT}?${queryString}`
      );
}

export const getUsersEveningReportingService= (searchObj:searchParams) =>{
  const queryString = getSearchQueryString(searchObj);
    return axiosInstance.get(
        `${GET_USERS_EVENING_REPORTING_ENDPOINT}?${queryString}`
      );
}

export const addMorningPlannerService=(data:any)=>{
    return axiosInstance.post(
        `${ADD_MORNING_PLANNER_ENDPOINT}`,data
      );
}

export const editMorningPlannerService=(data:any)=>{
  return axiosInstance.put(
      `${EDIT_MORNING_PLANNER_ENDPOINT}`,data
    );
}

export const editEveningReportingService=(data:any)=>{
  return axiosInstance.put(
      `${EDIT_EVENING_REPORTING_ENDPOINT}`,data
    );
}

export const addEveningReportingService=(data:any)=>{
    return axiosInstance.post(
        `${ADD_EVENING_REPORTING_ENDPOINT}`,data
      );
}