import { connect } from "react-redux";
import { Form, Formik, FormikValues } from "formik";
import { FC, useRef, useState } from "react";

import DropDown from "../../../commonComponent/dropDown/DropDown";
import FilterButton from "../../../commonComponent/tableFilter/FilterButton";
import { getFilterQueryString } from "../../../../helpers/helperFunction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}
const TaskTypeFilter: FC<props> = ({ masterDataList }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();

  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`card shadow-sm w-300px w-md-325px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <Formik
            enableReinitialize
            initialValues={filterObj}
            onSubmit={async (values: any) => {
              setSearchParam({
                ...searchObj,
                PageNumber: "1",
                Filters: getFilterQueryString(values),
              });
            }}
          >
            {({ resetForm, values, isSubmitting, setFieldValue }) => {
              return (
                <Form noValidate className="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Category</label>
                    <DropDown
                      className={`text-start form-control form-control-lg form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder="Select Category"
                      displayLoader={masterDataList.loading}
                      options={masterDataList.masterData?.data?.records?.Categories?.map(
                        (category: { Id: number; Name: string }) => {
                          return { value: category.Id, name: category.Name };
                        }
                      )}
                      setFieldValue={setFieldValue}
                      name="CategoryId"
                      currentValue={{
                        value: (values as FormikValues).CategoryId,
                        name: masterDataList.masterData?.data?.records?.Categories?.find(
                          (category: { Id: number; Name: string }) =>
                            String(values.CategoryId) === String(category.Id)
                        )?.Name,
                      }}
                      defaultValue={{
                        value: values.CategoryId,
                        name: masterDataList.masterData?.data?.records?.Categories?.find(
                          (category: { Id: number; Name: string }) =>
                            String(values.CategoryId) === String(category.Id)
                        )?.Name,
                      }}
                      showSearch={true}
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <ResetButton
                      name="Reset"
                      className="btn btn-light btn-active-light-primary btn-sm"
                      onClickCallback={() => {
                        resetHandler(resetForm);
                        setShowDropDown(false);
                      }}
                    />
                    <SubmitButton
                      className="btn btn-primary btn-sm"
                      isSubmitting={isSubmitting}
                      name="Apply"
                      onClickCallback={() => setShowDropDown(false)}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

export default connect(mapStateToProps)(TaskTypeFilter);
