import {
  GET_PROFORMA_INVOICE_FAILURE,
  GET_PROFORMA_INVOICE_REQUEST,
  GET_PROFORMA_INVOICE_SUCCESS,
  UPDATE_PROFORMA_INVOICE_SUCCESS,
} from "../actionTypes";

export interface proformaInvoiceState {
  loading: boolean;
  proformaInvoice: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: proformaInvoiceState = {
  loading: false,
  proformaInvoice: {},
  error: "",
};

export const getProformaInvoiceByIdReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_PROFORMA_INVOICE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PROFORMA_INVOICE_SUCCESS:
    case GET_PROFORMA_INVOICE_SUCCESS:
      return {
        loading: false,
        proformaInvoice: action.payload,
        error: "",
      };
    case GET_PROFORMA_INVOICE_FAILURE:
      return {
        loading: false,
        proformaInvoice: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
