import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import {
  EDIT_DAILY_TASK,
  VIEW_DAILY_TASK_BY_ARTICLE,
} from "../../../../helpers/routes";
import { KTSVG } from "../../../../../_metronic/helpers";

interface task {
  Assignee: string;
  AssigneeId: string;
  Date: string;
  Description: string;
  Id: number;
  IsCompleted: boolean;
}
interface props {
  row: {
    Assignee: string;
    AssigneeId: string;
    UserNumber: number;
    Tasks: Array<task>;
  };
}

const DailyTaskTableRow: FC<props> = ({ row }) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_DAILY_TASK}/${row.UserNumber}`));
  };

  const viewDailyTask = () => {
    navigate(absolutePath(`${VIEW_DAILY_TASK_BY_ARTICLE}/${row.UserNumber}`));
  };

  const countProgress = () => {
    const completedTask = row.Tasks.reduce((acc: any, task: task) => {
      if (task.IsCompleted) return (acc = acc + 1);
      return acc;
    }, 0);

    return row.Tasks.length
      ? Math.ceil((completedTask / row.Tasks.length) * 100)
      : 0;
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row?.Assignee || "-"}</td>
      <td>
        {
          <div className="d-flex flex-column w-100 me-2">
            <div className="d-flex flex-stack mb-2">
              <span className="text-muted me-2 fs-7 fw-semibold">
                {countProgress()}%
              </span>
            </div>
            <div className="bg-gray-300 h-6px progress w-100 w-125px">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${countProgress()}%` }}
              ></div>
            </div>
          </div>
        }
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <button
            className={`btn btn-active-light-primary btn-bg-light btn-sm fs-6 text-gray-600 text-hover-primary`}
            title="View Tasks"
            onClick={viewDailyTask}
          >
            View Tasks
          </button>
        </div>
      </td>
    </tr>
  );
};

export default DailyTaskTableRow;
