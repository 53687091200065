import { response } from "app/helpers/commonInterface";
import {
  GET_FOLLOWUP_TASK_FAILURE,
  GET_FOLLOWUP_TASK_REQUEST,
  GET_FOLLOWUP_TASK_SUCCESS,
} from "../actionTypes";
import { taskData } from "./taskDataInterface";

export interface followupTaskDataState {
  loading: boolean;
  followupTaskList: response<taskData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: followupTaskDataState = {
  loading: false,
  followupTaskList: {},
  error: "",
};

export const getFollowupTaskReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_FOLLOWUP_TASK_REQUEST:
      return {
        loading: true,
      };
    case GET_FOLLOWUP_TASK_SUCCESS:
      return {
        loading: false,
        followupTaskList: action.payload,
        error: "",
      };
    case GET_FOLLOWUP_TASK_FAILURE:
      return {
        loading: false,
        followupTaskList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
