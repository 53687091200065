import {
  GET_PROFORMA_INVOICE_LIST_FAILURE,
  GET_PROFORMA_INVOICE_LIST_REQUEST,
  GET_PROFORMA_INVOICE_LIST_SUCCESS,
} from "../actionTypes";

export interface proformaInvoiceListState {
  loading: boolean;
  proformaInvoiceList: any;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: proformaInvoiceListState = {
  loading: false,
  proformaInvoiceList: {},
  error: "",
};

export const getProformaInvoiceListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_PROFORMA_INVOICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_PROFORMA_INVOICE_LIST_SUCCESS:
      return {
        loading: false,
        proformaInvoiceList: action.payload,
        error: "",
      };
    case GET_PROFORMA_INVOICE_LIST_FAILURE:
      return {
        loading: false,
        proformaInvoiceList: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
