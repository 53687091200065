import { editProfileData } from "../../helpers/commonInterface";
import { editProfileService, getProfileDataService } from "./profileService";
import { failure, request, success } from "../dispatchFunctions";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
} from "../actionTypes";
import { GetErrorMessage } from "../stateHelperFunction";

interface editProfileDataPassByFunction {
  data: editProfileData;
  isEditProfileDetails: boolean;
  successCallback?: Function;
  errorCallback?: Function;
}

export const getProfileData = () => {
  return async (dispatch: any) => {
    dispatch(request(GET_PROFILE_REQUEST));

    await getProfileDataService(null).then(
      (result: any) => {
        dispatch(success(GET_PROFILE_SUCCESS, result.data.data.records));
      },
      (error: any) =>
        dispatch(
          failure(GET_PROFILE_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const getUserByIdData = (userId:string|null) => {
  return async (dispatch: any) => {
    dispatch(request(GET_USER_BY_ID_REQUEST));

    await getProfileDataService(userId).then(
      (result: any) => {
        dispatch(success(GET_USER_BY_ID_SUCCESS, result.data.data.records));
      },
      (error: any) =>
        dispatch(
          failure(GET_USER_BY_ID_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const editProfile = ({
  data,
  isEditProfileDetails,
  successCallback,
  errorCallback,
}: editProfileDataPassByFunction) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_PROFILE_REQUEST));

    await editProfileService(data).then(
      (result: any) => {
        dispatch(success(EDIT_PROFILE_SUCCESS, result));
        showToastMessageSuccess("Profile updated");
        isEditProfileDetails && dispatch(getProfileData());
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(EDIT_PROFILE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        isEditProfileDetails &&
          showToastMessageFailure(GetErrorMessage(error?.response?.data));
        errorCallback && errorCallback(GetErrorMessage(error?.response?.data));
      }
    );
  };
};


export const editUser = (data: any) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_USER_REQUEST));

    await editProfileService(data).then(
      (result: any) => {
        dispatch(success(EDIT_USER_SUCCESS, result));
        showToastMessageSuccess("User updated");
      },
      (error: any) => {
        dispatch(
          failure(EDIT_USER_FAILURE, GetErrorMessage(error?.response?.data))
        );
      }
    );
   };
};
