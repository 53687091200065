import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddDocumentType from "./component/AddDocumentType";
import {
  addDocumentType,
  editDocumentType,
  getDocumentType,
} from "../../../reducers/documentType/documentTypeAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";

interface props {
  documentTypeDetail: any;
  getDocumentTypeDispatch: Function;
  addDocumentTypeDispatch: Function;
  updateDocumentTypeDispatch: Function;
}

export interface documentTypeData {
  id?: number;
  name: string;
  isActive: boolean;
  repeatation: string;
}

interface getDataById {
  Id: number;
  Name: string;
  IsActive: boolean;
  Repeatation: string;
}

const initialValue: documentTypeData = {
  name: "",
  isActive: true,
  repeatation: "",
};

const getValueForEdit = (documentType: getDataById): documentTypeData => {
  return {
    id: documentType.Id,
    name: documentType.Name,
    isActive: documentType.IsActive,
    repeatation: documentType.Repeatation,
  };
};

const DocumentTypeForm: FC<props> = ({
  getDocumentTypeDispatch,
  documentTypeDetail,
  updateDocumentTypeDispatch,
  addDocumentTypeDispatch,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getDocumentTypeDispatch(params.id);
    }
  }, [params.id, getDocumentTypeDispatch]);

  return (
    <ErrorBoundary>
      {params.id && documentTypeDetail.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && documentTypeDetail.error && (
        <ErrorDisplay error={documentTypeDetail.error} />
      )}
      {(params.id ? documentTypeDetail.documentType?.success : true) && (
        <>
          {params.id && (
            <AddDocumentType
              initialValue={getValueForEdit(
                documentTypeDetail?.documentType?.data?.records
              )}
              documentTypeDispatch={updateDocumentTypeDispatch}
              title="Edit Document Type"
            />
          )}
          {!params.id && (
            <AddDocumentType
              initialValue={initialValue}
              documentTypeDispatch={addDocumentTypeDispatch}
              title="Add Document Type"
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    documentTypeDetail: state.documentType,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDocumentTypeDispatch: (id: number) => dispatch(getDocumentType(id)),
    updateDocumentTypeDispatch: (
      data: documentTypeData,
      successCallback: Function
    ) => dispatch(editDocumentType(data, successCallback)),
    addDocumentTypeDispatch: (
      data: documentTypeData,
      successCallback: Function
    ) => dispatch(addDocumentType(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeForm);
